import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { StyledLoadingButton } from "../../../customComponents/styled/styledButtons";
import {
  StyledSelect,
  StyledTextField,
} from "../../../customComponents/styled/styledInputs";
import { useGetSubSectionQuery } from "../sections/sectionsApiSlice";
import { useUpdateTraitMutation } from "./traitsApiSlice";
import swal from "sweetalert";

function EditTraitCategoryForm({ item, setItem }) {
  const { data: subsections } = useGetSubSectionQuery("all"); // to display in select menu
  const [updateTrait, { isLoading }] = useUpdateTraitMutation();

  const createTraitForm = useFormik({
    initialValues: {
      id: item.id,
      name: item.name,
      subsection_id: "",
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string().required("Required"),
      subsection_id: yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await updateTrait(values).unwrap();
        if (response.id) {
          swal("Success", "Trait updated successfully", "success");
          createTraitForm.resetForm();
          setItem(null);
        } else {
          swal("Error", "Trait update failed", "error");
        }
      } catch (error) {
        swal("Error", "Something went wrong", "error");
      }
    },
  });
  return (
    <>
      <Box className="paperHeader">
        <Typography variant="formHeading">Edit Trait Category</Typography>
      </Box>
      <Box className="paperBody">
        <form onSubmit={createTraitForm.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">Trait</Typography>
                <StyledTextField
                  autoFocus
                  variant="outlined"
                  id="name"
                  name="name"
                  value={createTraitForm.values.name}
                  onChange={createTraitForm.handleChange}
                  error={
                    createTraitForm.touched.name &&
                    Boolean(createTraitForm.errors.name)
                  }
                />
                <FormHelperText variant="error">
                  {createTraitForm.touched.name && createTraitForm.errors.name}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography variant="formSubHeading">subsection</Typography>
                <StyledSelect
                  displayEmpty
                  id="subsection_id"
                  name="subsection_id"
                  value={createTraitForm.values.subsection_id}
                  onChange={createTraitForm.handleChange}
                  error={
                    createTraitForm.touched.subsection_id &&
                    Boolean(createTraitForm.errors.subsection_id)
                  }
                >
                  <MenuItem value="" disabled>
                    <em>select subsection</em>
                  </MenuItem>
                  {subsections?.map((subsection) => (
                    <MenuItem value={subsection.id} key={subsection.id}>
                      {subsection.name}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <FormHelperText variant="error">
                  {createTraitForm.touched.subsection_id &&
                    createTraitForm.errors.subsection_id}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} textAlign={"right"}>
              <StyledLoadingButton loading={isLoading} type="submit">
                Submit
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default EditTraitCategoryForm;
