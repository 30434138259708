import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CustomMenuList from "../../../customComponents/CustomMenuList";
import { FilledButton } from "../../../customComponents/styled/styledButtons";
import {
  StyledSelect,
  StyledTextField,
} from "../../../customComponents/styled/styledInputs";
import { StyledPaper } from "../../../customComponents/styled/styledPaper";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../customComponents/styled/styledTable";
import DeleteIcon from "@mui/icons-material/Delete";
import { PersonalVideoSharp } from "@mui/icons-material";

const validationSchema = yup.object({
  day: yup.string().required("Required"),
  start: yup.string().required("Required"),
  end: yup.string().required("Required"),
  duration: yup.string().required("Required"),
  whereToAdd: yup.string().required("Required"),
  dayToSkip: yup.string().required("Required"),
});

function Templates({ selectedTemplate, setSelectedTemplate, templates }) {
  const formik = useFormik({
    initialValues: {
      day: "",
      start: "",
      end: "",
      duration: "",
      whereToAdd: "",
      dayToSkip: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const [selectedDay, setSelectedDay] = useState("sunday");
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const periods = [
    {
      day: "monday",
      periods: [
        {
          start: "08:00",
          end: "09:00",
        },
        {
          start: "09:00",
          end: "10:00",
        },
        {
          start: "10:00",
          end: "11:00",
        },
        {
          start: "11:00",
          end: "12:00",
        },
        {
          start: "12:00",
          end: "13:00",
        },
        {
          start: "13:00",
          end: "14:00",
        },
        {
          start: "14:00",
          end: "15:00",
        },
        {
          start: "15:00",
          end: "16:00",
        },
      ],
    },
    {
      day: "Tuesday",
      periods: [
        {
          start: "08:00",
          end: "09:00",
        },
        {
          start: "09:00",
          end: "10:00",
        },
        {
          start: "10:00",
          end: "11:00",
        },
        {
          start: "11:00",
          end: "12:00",
        },
        {
          start: "12:00",
          end: "13:00",
        },
        {
          start: "13:00",
          end: "14:00",
        },
        {
          start: "14:00",
          end: "15:00",
        },
        {
          start: "15:00",
          end: "16:00",
        },
      ],
    },
  ];

  const handleAddDay = () => {
    alert(selectedDay);
  };

  return (
    <Grid container xs={12}>
      <Grid item container columnSpacing={3} xs={12}>
        <Grid item xs={12} lg={4}>
          <CustomMenuList
            activeTab={selectedTemplate}
            setActiveTab={setSelectedTemplate}
            heading="Templates"
            list={templates}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <StyledPaper>
            <Box className="paperHeader">
              <Typography className="paperHeading">
                Add Days To {templates[selectedTemplate]} Session
              </Typography>
            </Box>
            <Box className="paperBody">
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <FormControl fullWidth>
                    <Typography variant="formSubHeading">Days</Typography>
                    <StyledSelect
                      id="day"
                      name="day"
                      value={selectedDay}
                      onChange={(e) => setSelectedDay(e.target.value)}
                    >
                      {days.map((item, i) => (
                        <MenuItem value={item} key={i}>
                          {item}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <FilledButton variant="outlined" sx={{ flex: "1" }}>
                    +
                  </FilledButton>
                </Grid>
                <Grid item xs={12} md={12} textAlign={"right"}>
                  <FilledButton type="submit" onClick={handleAddDay}>
                    save
                  </FilledButton>
                </Grid>
              </Grid>
            </Box>
          </StyledPaper>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <StyledPaper>
          <Box className="paperHeader">
            <Typography className="paperHeading">
              Add periods on {templates[selectedTemplate]} session
            </Typography>
          </Box>
          <Box className="paperBody">
            <Box component="form" onSubmit={formik.handleSubmit}>
              <Grid container columnSpacing={1} rowSpacing={3}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Typography variant="formSubHeading">Day</Typography>
                    <StyledSelect
                      id="day"
                      name="day"
                      value={formik.values.day}
                      onChange={formik.handleChange}
                    >
                      {days.map((item, i) => (
                        <MenuItem value={item} key={i}>
                          {item}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    <FormHelperText variant="error">
                      {formik.touched.day && formik.errors.day}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {/* beggining second row inputs */}

                <Grid item xs={12} lg={4}>
                  <FormControl error fullWidth>
                    <Typography variant="formSubHeading">start</Typography>
                    <StyledTextField
                      autoFocus
                      variant="outlined"
                      id="start"
                      name="start"
                      type="time"
                      value={formik.values.start}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.start && Boolean(formik.errors.start)
                      }
                    />
                    <FormHelperText variant="error">
                      {formik.touched.start && formik.errors.start}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl error fullWidth>
                    <Typography variant="formSubHeading">end</Typography>
                    <StyledTextField
                      autoFocus
                      variant="outlined"
                      id="end"
                      name="end"
                      type="time"
                      value={formik.values.end}
                      onChange={formik.handleChange}
                      error={formik.touched.end && Boolean(formik.errors.end)}
                    />
                    <FormHelperText variant="error">
                      {formik.touched.end && formik.errors.end}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl error fullWidth>
                    <Typography variant="formSubHeading">
                      PERIOD DURATION (MINUTES)
                    </Typography>
                    <StyledTextField
                      autoFocus
                      variant="outlined"
                      id="duration"
                      name="duration"
                      value={formik.values.duration}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.duration &&
                        Boolean(formik.errors.duration)
                      }
                    />
                    <FormHelperText variant="error">
                      {formik.touched.duration && formik.errors.duration}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {/* beggining third row inputs */}
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth>
                    <Typography htmlFor="whereToAdd" variant="formSubHeading">
                      Where to add:
                    </Typography>
                    <StyledSelect
                      id="whereToAdd"
                      name="whereToAdd"
                      value={formik.values.whereToAdd}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.whereToAdd &&
                        Boolean(formik.errors.whereToAdd)
                      }
                    >
                      <MenuItem value="score">Score Based</MenuItem>
                      <MenuItem value="grade">Grade Based</MenuItem>
                    </StyledSelect>
                    <FormHelperText variant="error">
                      {formik.touched.whereToAdd && formik.errors.whereToAdd}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControl fullWidth>
                    <Typography variant="formSubHeading">
                      Day to skip
                    </Typography>
                    <StyledSelect
                      id="dayToSkip"
                      name="dayToSkip"
                      value={formik.values.dayToSkip}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.dayToSkip &&
                        Boolean(formik.errors.dayToSkip)
                      }
                      helperText={
                        formik.touched.dayToSkip && formik.errors.dayToSkip
                      }
                    >
                      <MenuItem value="Kindergarten">Kindergarten</MenuItem>
                      <MenuItem value="nursery">nursery</MenuItem>
                      <MenuItem value="primary">pri</MenuItem>
                      <MenuItem value="secondary">sec</MenuItem>
                    </StyledSelect>
                    <FormHelperText variant="error">
                      {formik.touched.dayToSkip && formik.errors.dayToSkip}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} textAlign={"right"}>
                  <FilledButton type="submit">save</FilledButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </StyledPaper>
      </Grid>
      <Grid item xs={12}>
        <StyledPaper>
          <Box className="paperHeader">
            <Typography className="paperHeading">
              {templates[selectedTemplate]} Period Allotment
            </Typography>
          </Box>
          <TableContainer sx={{ pb: 4 }}>
            <Table sx={{ width: "100%", boxShadow: "none" }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>day</StyledTableCell>
                  <StyledTableCell align="left">period</StyledTableCell>
                  <StyledTableCell align="left">delete</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {periods.map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row">
                      {row.day}
                    </StyledTableCell>

                    {row.periods.map((period, i) => (
                      <StyledTableCell key={i}>
                        {period.start}-{period.end}
                      </StyledTableCell>
                    ))}

                    <StyledTableCell align="right">
                      <DeleteIcon
                        sx={{ color: "primary.main", cursor: "pointer" }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledPaper>
      </Grid>
    </Grid>
  );
}

export default Templates;
