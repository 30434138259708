import { apiSlice } from "../../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Trait", "Behavior"],
});

const traitsApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getTrait: builder.query({
      query: (parameter) => `/trait/${parameter}`,
      providesTags: ["Trait"],
    }),
    getBehavior: builder.query({
      query: (parameter) => `/trait/behavior/${parameter}`,
      providesTags: ["Behavior"],
    }),
    createTrait: builder.mutation({
      query: (values) => ({
        url: "/trait/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Trait"],
    }),
    createBehavior: builder.mutation({
      query: (values) => ({
        url: "/trait/behavior/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Trait"],
    }),
    updateTrait: builder.mutation({
      query: (values) => ({
        url: "/trait/update",
        method: "PUT",
        body: { ...values },
      }),
      invalidatesTags: ["Trait"],
    }),
    updateBehavior: builder.mutation({
      query: (values) => ({
        url: "/trait/behavior/update",
        method: "PUT",
        body: { ...values },
      }),
      invalidatesTags: ["Behavior", "Trait"],
    }),
    deleteTrait: builder.mutation({
      query: (values) => ({
        url: `/trait/${values.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Trait"],
    }),
    deleteBehavior: builder.mutation({
      query: (values) => ({
        url: `/trait/behavior/${values.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Trait"],
    }),
  }),
});

export const {
  useGetTraitQuery,
  useGetBehaviorQuery,
  useCreateTraitMutation,
  useCreateBehaviorMutation,
  useUpdateTraitMutation,
  useUpdateBehaviorMutation,
  useDeleteTraitMutation,
  useDeleteBehaviorMutation,
} = traitsApiSlice;
