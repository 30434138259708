import React from 'react';
import { Grid, 
         Box, 
         FormControl, 
         Typography, 
         MenuItem, 
         FormHelperText } from '@mui/material';
import { useFormik } from "formik";
import * as yup from "yup";
import { StyledSelect,
         StyledTextField, } from "../../../customComponents/styled/styledInputs";
  import { FilledButton } from "../../../customComponents/styled/styledButtons";


const validationSchema = yup.object({
  section: yup.string().required("Required"),
  commentType: yup.string().required("Required"),
    
  });

function EditCommentForm({item}) {
    const formik = useFormik ({
        initialValues: {
            section: item.section,
            commentType: item.commentType,
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
    })
  return (
    <>
    <Typography variant="formHeading" sx={{marginBottom: "20px"}}>Edit House Form</Typography>

<Box component="form" onSubmit={formik.handleSubmit}>

<Grid container rowSpacing={3}>

<Grid item xs={12} md={12}>
                  <FormControl error={formik.touched.section && formik.errors.section} fullWidth>
                      <Typography variant="formSubHeading">SECTION</Typography>
                      <StyledSelect 
                      id="section" 
                      name="section" 
                      onChange={formik.handleChange} 
                      value={formik.values.section} 
                      error={formik.touched.section && Boolean(formik.errors.section)}>
                          <MenuItem value="Kindergarten">Kindergarten</MenuItem>
                          <MenuItem value="Nursery">Nursery</MenuItem>
                          <MenuItem value="Primary">Primary</MenuItem>
                          <MenuItem value="Secondary">Secondary</MenuItem>
                      </StyledSelect>
                      <FormHelperText variant="error">
                         {formik.touched.section && formik.errors.section}
                      </FormHelperText>
                  </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                  <FormControl error={formik.touched.commentType && formik.errors.commentType} fullWidth>
                      <Typography variant="formSubHeading">COMMENT TYPE</Typography>
                      <StyledTextField 
                      id="commentType" 
                      name="commentType" 
                      value={formik.values.commentType} 
                      onChange={formik.handleChange} 
                      error={formik.touched.commentType && Boolean(formik.errors.commentType)} />

                      <FormHelperText variant="error">
                         {formik.touched.commentType && formik.errors.commentType}
                      </FormHelperText>    
                  </FormControl>
              </Grid>


    <Grid item xs={12} md={12} textAlign={"right"}>
  <FilledButton type="submit" sx={{textTransform: "none"}}>Update</FilledButton>
</Grid>
</Grid>
</Box>
    </>
  )
}

export default EditCommentForm