import React from 'react';
import {StyledPaper} from "../../customComponents/styled/styledPaper";
import { Grid, 
         Input, 
         Typography, 
         FormControl, 
         MenuItem, 
         TableContainer, 
         Table, 
         TableHead, 
         TableRow, 
         Box, 
         FormHelperText} from "@mui/material";
import { StyledTableCell } from '../../customComponents/styled/styledTable';
import { StyledSelect, StyledTextField } from "../../customComponents/styled/styledInputs";
import { FilledButton } from "../../customComponents/styled/styledButtons";
import { useFormik } from 'formik';
import * as yup from "yup";


function ScheduleMessages() {
    const validationSchema = yup.object ({
        medium: yup.string().required("Required"),
        sendTo: yup.string().required("Required"),
        content: yup.string().required("You cannot send a blank message"),
        date: yup.string().required("Schedule a Date"),
        cycle: yup.string().required("Required"),
    });

    const formik = useFormik({
        initialValues: {
            medium: "",
            sendTo: "",
            content: "",
            date: "",
            cycle: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));
        }
    });


  return (
    <>
    <StyledPaper sx={{ padding: "15px"}}>
        <Typography variant="formHeading" sx={{ marginBottom: "15px"}}>Schedule A Message</Typography>

        <Box component="form" onSubmit={formik.handleSubmit}>

        <Grid container spacing={3}>
                                    {/* FIRST ROW INPUTS */}
             <Grid item xs={12} md={6}>
                <FormControl error={formik.touched.medium && formik.errors.medium} fullWidth>
                    <Typography htmlFor="medium" variant="formSubHeading">MEDIUM</Typography>
                    <StyledSelect 
                    id="medium" 
                    name="medium" 
                    value={formik.values.medium} 
                    onChange={formik.handleChange} 
                    error={formik.touched.medium && Boolean(formik.errors.medium)} helperText={formik.touched.medium && formik.errors.medium}>
                        <MenuItem value="SMS(Basic)">SMS(Basic)</MenuItem>
                        <MenuItem value="SMS (Premium)">SMS(Premium)</MenuItem>
                        <MenuItem value="Email">Email</MenuItem>
                    </StyledSelect>
                    <FormHelperText variant="error">
                  {formik.touched.medium && formik.errors.medium}
                </FormHelperText>
                </FormControl>
            </Grid>


                                
            <Grid item xs={12} md={6}>
            <FormControl error={formik.touched.sendTo && formik.errors.sendTo } fullWidth>
                    <Typography htmlFor="sendTo" variant="formSubHeading">SEND TO</Typography>
                    <StyledSelect 
                    id="sendTo" 
                    name="sendTo" 
                    value={formik.values.sendTo} 
                    onChange={formik.handleChange} 
                    error={formik.touched.sendTo && Boolean(formik.errors.sendTo)} helperText={formik.touched.sendTo && formik.errors.sendTo}>
                        <MenuItem value="AllStudentsTeachers">All Students & Teachers</MenuItem>
                        <MenuItem value="Students">Students</MenuItem>
                        <MenuItem value="Teachers">Teachers</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                    </StyledSelect>
                    <FormHelperText variant="error">
                  {formik.touched.sendTo && formik.errors.sendTo}
                </FormHelperText>
                </FormControl>
            </Grid>
                                 {/* SECOND ROW INPUT */}
            <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                    <Typography variant="formSubHeading">RECIPIENTS (SEPARATE EMAILS OR NUMBERS BY COMMAS)</Typography>
                    <Input readOnly disableUnderline sx={{ 
                                                    backgroundColor: "rgba(211,211,211,0.5)", 
                                                    height: "100%", 
                                                    border: "1px solid #ccc",
                                                    boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
                                                    borderRadius: "4px",

                                                    "& :hover": {
                                                        cursor: "not-allowed",
                                                    }}}/>
                </FormControl>
            </Grid>
                                              
                                              {/* THIRD ROW INPUTS */}
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <Typography variant="formSubHeading">SENDER</Typography>
                    <Input readOnly defaultValue="Schoola" disableUnderline sx={{ 
                                                    backgroundColor: "rgba(211,211,211,0.5)", 
                                                    height: "100%",
                                                    paddingLeft: "10px",
                                                    fontWeight: "600", 
                                                    color: "#ccc",
                                                    border: "1px solid #ccc",
                                                    boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
                                                    borderRadius: "4px",

                                                    "& :hover": {
                                                        cursor: "not-allowed",
                                                    }}}/>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <Typography variant="formSubHeading">SUBJECT</Typography>
                    <Input readOnly disableUnderline sx={{ 
                                                    backgroundColor: "rgba(211,211,211,0.5)", 
                                                    height: "100%", 
                                                    border: "1px solid #ccc",
                                                    boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
                                                    borderRadius: "4px",

                                                    "& :hover": {
                                                        cursor: "not-allowed",
                                                    }}}/>
                </FormControl>
            </Grid>
                                                
                                                {/* FOURTH ROW */}
                                                <Grid item xs={12} md={12}>
                <FormControl error={formik.touched.content && formik.errors.content} fullWidth>
                    <Typography htmlFor="content" variant="formSubHeading">CONTENT</Typography>
                <textarea   
                minRows={7} 
                id="content" 
                name="content" 
                value={formik.values.content} 
                error={formik.touched.content && Boolean(formik.errors.content)} 
                onChange={formik.handleChange}
                style={{
                           height: "10rem",
                           border: "1px solid #ccc",
                           boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
                           borderRadius: "4px",}} />

                 <FormHelperText variant="error">
                  {formik.touched.content && formik.errors.content}
                </FormHelperText>
                </FormControl>
                
            </Grid>



            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <Typography variant="formSubHeading">DATE (OPTIONAL)</Typography>
                    <StyledTextField type="date"/>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                <FormControl error={formik.touched.cycle && formik.errors.cycle} fullWidth>
                    <Typography htmlFor="cycle" variant="formSubHeading">CYCLE</Typography>
                    <StyledSelect
                    id="cycle" 
                    name="cycle" 
                    value={formik.values.cycle} 
                    onChange={formik.handleChange} 
                    error={formik.touched.cycle && Boolean(formik.errors.cycle)}>
                        <MenuItem value="Once">Once</MenuItem>
                        <MenuItem value="Daily">Daily</MenuItem>
                        <MenuItem value="Weekly">Weekly</MenuItem>
                        <MenuItem value="Monthly">Monthly</MenuItem>
                        <MenuItem value="Quarterly">Quarterly</MenuItem>
                        <MenuItem value="Annually">Annually</MenuItem>
                        <MenuItem value="Birthdays">Birthdays</MenuItem>
                        <MenuItem value="Continous (Until Stopped)">Continous (Until Stopped)</MenuItem>
                    </StyledSelect>
                    <FormHelperText variant="error">
                  {formik.touched.cycle && formik.errors.cycle}
                </FormHelperText>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                    <Typography variant="formSubHeading">ATTACHMENT (OPTIONAL)</Typography>
                    <StyledTextField type="file"/>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={12} textAlign={"right"}>
              <FilledButton type="submit" sx={{textTransform: "none"}}>Save</FilledButton>
            </Grid>
        </Grid>
        </Box>
    </StyledPaper>

    <StyledPaper  sx={{ paddingBottom: "20px"}}>
        <Typography  sx={{ padding: "15px"}} variant="formHeading">Scheduled Messages</Typography>

        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow sx={{borderBottom: "2px solid lightgrey "}}>
                        <StyledTableCell>MEDIUM</StyledTableCell>
                        <StyledTableCell>RECIPIENTS</StyledTableCell>
                        <StyledTableCell>SUBJECT</StyledTableCell>
                        <StyledTableCell>CYCLE</StyledTableCell>
                        <StyledTableCell>CREATED</StyledTableCell>
                        <StyledTableCell>ACTIVE</StyledTableCell>
                        <StyledTableCell>EDIT</StyledTableCell>
                        <StyledTableCell>DELETE</StyledTableCell>
                    </TableRow>
                </TableHead>
            </Table>
        </TableContainer>
    </StyledPaper>
    </>
  )
}

export default ScheduleMessages