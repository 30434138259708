import React from "react";
import {
  StyledSelect,
  StyledTextField,
} from "../../../customComponents/styled/styledInputs";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { StyledLoadingButton } from "../../../customComponents/styled/styledButtons";
import { useUpdateAssessmentMutation } from "./assessmentsApiSlice";
import swal from "sweetalert";

const validationSchema = yup.object({
  type: yup.string().required("Required"),
  score: yup
    .number("must be a number")
    .max(100, "max is 100")
    .min(0, "min is 0")
    .required("Required"),
  subsection_id: yup.string().required("Required"),
});
function EditAssessment({ item, setItem, subsections }) {
  const [updateAssessment, { isLoading: updateAssessmentLoading }] =
    useUpdateAssessmentMutation();

  const formik = useFormik({
    initialValues: {
      id: item.id,
      type: item.name,
      score: item.obtainable_score,
      subsection_id: item.subsection_id,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const body = {
        id: values.id,
        name: values.type,
        obtainable_score: parseInt(values.score),
        subsection_id: values.subsection_id,
      };
      try {
        const response = await updateAssessment(body).unwrap();
        if (response.id) {
          swal("Success", "Assessment updated successfully", "success");
          formik.resetForm();
          setItem(null);
        } else {
          swal("Error", "Error updating assessment", "error");
        }
      } catch (error) {
        swal("Error", "Something went wrong", "error");
      }
    },
  });
  return (
    <>
      <Box className="paperHeader">
        <Typography variant="formHeading">Edit Obtainable Score</Typography>
      </Box>
      <Box className="paperBody">
        <form onSubmit={formik.handleSubmit}>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12} lg={6}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">type</Typography>
                <StyledTextField
                  autoFocus
                  variant="outlined"
                  id="type"
                  name="type"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                />
                <FormHelperText variant="error">
                  {formik.touched.type && formik.errors.type}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">
                  obtainable Score
                </Typography>
                <StyledTextField
                  autoFocus
                  variant="outlined"
                  id="score"
                  name="score"
                  value={formik.values.score}
                  onChange={formik.handleChange}
                  error={formik.touched.score && Boolean(formik.errors.score)}
                />
                <FormHelperText variant="error">
                  {formik.touched.score && formik.errors.score}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">sub-section</Typography>
                <StyledSelect
                  variant="outlined"
                  id="subsection_id"
                  name="subsection_id"
                  value={formik.values.subsection_id}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.subsection_id &&
                    Boolean(formik.errors.subsection_id)
                  }
                >
                  <MenuItem value="" disabled>
                    <em>select subsection</em>
                  </MenuItem>
                  {subsections?.map((section) => (
                    <MenuItem key={section.id} value={section.id}>
                      {section.name}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <FormHelperText variant="error">
                  {formik.touched.subsection_id && formik.errors.subsection_id}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} textAlign={"right"}>
              <StyledLoadingButton
                loading={updateAssessmentLoading}
                type="submit"
              >
                Update
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default EditAssessment;
