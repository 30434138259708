import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Record", "Comment", "Trait"],
});

const recordApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getRecordsBySubjectAndClass: builder.query({
      query: ({ subject_id, classroom_id }) =>
        `/record/subject?subject_id=${subject_id}&classroom_id=${classroom_id}`,
      providesTags: ["Record"],
    }),
    createRecord: builder.mutation({
      query: (values) => ({
        url: "/record/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Record"],
    }),
    deleteRecordsBySubjectAndClass: builder.mutation({
      query: (values) => ({
        url: `/record/${values.subjectId}/${values.classId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Record"],
    }),
    deleteRecordsBySubjectClassAndAssessment: builder.mutation({
      query: (values) => ({
        url: `/record/${values.subjectId}/${values.classId}/${values.assessmentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Record"],
    }),
    getCommentByStudent: builder.query({
      query: (parameter) => `/comment/student/${parameter}`,
      providesTags: ["Comment"],
    }),
    getCommentBySubjectAndClassroom: builder.query({
      query: (parameter) => `/comment/subject/${parameter}`,
      providesTags: ["Comment"],
    }),
    createComment: builder.mutation({
      query: (values) => ({
        url: "/comment/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Comment"],
    }),
    deleteComment: builder.mutation({
      query: (values) => ({
        url: `/comment/delete`,
        method: "DELETE",
        body: { ...values },
      }),
      invalidatesTags: ["Comment"],
    }),
    getStudentTraits: builder.query({
      query: ({ student_id, classroom_id }) =>
        `/trait/grade/student/${student_id}/${classroom_id}`,
      providesTags: ["Trait"],
    }),
    createStudentTraits: builder.mutation({
      query: (values) => ({
        url: "/trait/grade/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Trait"],
    }),
    deleteStudentTraits: builder.mutation({
      query: (values) => ({
        url: "/trait/grade/delete",
        method: "DELETE",
        body: { ...values },
      }),
      invalidatesTags: ["Trait"],
    }),
  }),
});

export const {
  useGetRecordsBySubjectAndClassQuery,
  useCreateRecordMutation,
  useCreateCommentMutation,
  useGetCommentByStudentQuery,
  useGetCommentBySubjectAndClassroomQuery,
  useDeleteRecordsBySubjectAndClassMutation,
  useDeleteRecordsBySubjectClassAndAssessmentMutation,
  useDeleteCommentMutation,
  useGetStudentTraitsQuery,
  useCreateStudentTraitsMutation,
  useDeleteStudentTraitsMutation,
} = recordApiSlice;
