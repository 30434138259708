import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { Typography, Box, Grid, LinearProgress } from "@mui/material";
import { useState } from "react";
import StudentList from "./StudentList";
import AddStudentForm from "./AddStudentForm";
import AddBulkStudent from "./AddBulkStudent";


function Students() {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} lg={2}>
          <StyledPaper>
            <Box sx={{ padding: "10px", textAlign: "center" }}>
              <Typography>MENU</Typography>
            </Box>
            <Box
              component="ul"
              sx={{
                listStyle: "none",
                borderTop: "1px solid #e0e0e0",
                paddingBottom: 4,
                "& li": {
                  padding: "8px 14px",
                  color: "primary.main",
                  cursor: "pointer",
                  fontSize: "12px",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                  "&.active": {
                    backgroundColor: "primary.main",
                    color: "white",
                  },
                },
              }}
            >
              <Box
                component="li"
                className={activeTab === 0 && "active"}
                onClick={() => setActiveTab(0)}
              >
                Add New Student
              </Box>
              <Box
                component="li"
                className={activeTab === 1 && "active"}
                onClick={() => setActiveTab(1)}
              >
                Add Bulk Student
              </Box>
              <Box
                component="li"
                className={activeTab === 2 && "active"}
                onClick={() => setActiveTab(2)}
              >
                Student List
              </Box>
            </Box>
            {loading && <LinearProgress />}
          </StyledPaper>
        </Grid>
        <Grid item xs={12} lg={10}>
          {activeTab === 0 && <AddStudentForm />}
          {activeTab === 1 && <AddBulkStudent />}
          {activeTab === 2 && <StudentList setLoading={setLoading} />}
        </Grid>
      </Grid>
    </>
  );
}

export default Students;
