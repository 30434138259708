import { createTheme, alpha } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { selectCurrentColor } from "./themeSlice";

const useTheme = () => {
  const currentColor = useSelector(selectCurrentColor);
  const color = currentColor || "#2371d1";

  return createTheme({
    palette: {
      primary: {
        main: color,
        transparent: alpha(color, 0.08),
      },
    },
    typography: {
      fontFamily: ["Nunito", "sans-serif"].join(","),
      // fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"],
      formHeading: {
        color: "#333333",
        fontWeight: 300,
        fontSize: "18px",
        lineHeight: "24px",
        display: "block",
        textTransform: "capitalize",
      },
      formSubHeading: {
        fontSize: "12px",
        marginBottom: "5px",
        textTransform: "uppercase",
        fontWeight: 400,
        color: "#9A9A9A",
        display: "block",
      },
    },
  });
};

export default useTheme;
