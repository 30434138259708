import { StyledPaper } from "../../customComponents/styled/styledPaper";
import {
  Typography,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Stack,
  CircularProgress,
} from "@mui/material";

import {
  StyledFileInput,
  StyledSelect,
  StyledTextField,
} from "../../customComponents/styled/styledInputs";
import { useFormik } from "formik";
import * as yup from "yup";
import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
import { useState } from "react";
import { useCreateTeacherMutation } from "./staffApiSlice";
import { useUploadFileMutation } from "../../../app/utilsApiSlice";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../auth/authSlice";
import { useCreateUserMutation } from "../../../app/authApiSlice";

const validationSchema = yup.object({
  title: yup.string().required("Required"),
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  email: yup.string().required("Required"),
  gender: yup.string().required("Required"),
  phone: yup.string().required("Required"),
  address: yup.string().required("Required"),
  dob: yup.string().required("Required"),
  // photo: yup.string().required("Required"),
  // signature: yup.string().required("Required"),
  // resume: yup.string().required("Required"),
});

function StaffRegForm() {
  const user = useSelector(selectCurrentUser);
  const school_id = user.current_school_id;

  const [fileLoading, setFileLoading] = useState({
    photo: false,
    signature: false,
    resume: false,
  });

  const [createTeacher, { isLoading: createTeacherLoading }] =
    useCreateTeacherMutation();
  const [createUser, { isLoading: createUserLoading }] =
    useCreateUserMutation();

  const [uploadFile] = useUploadFileMutation();

  const formik = useFormik({
    initialValues: {
      title: "Mr.",
      first_name: "",
      last_name: "",
      gender: "",
      phone: "",
      email: "",
      address: "",
      dob: "",
      photo: "",
      signature: "",
      resume: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const createUserBody = {
          ...values,
          school_id,
          username: values.email,
          roles: ["teacher"],
        };
        const userResponse = await createUser(createUserBody).unwrap();
        const user_id = userResponse.user.id;
        const createTeacherBody = {
          ...values,
          user_id,
          school_id,
        };
        await createTeacher(createTeacherBody).unwrap();
        swal("Success", "teacher created successfully", "success");
        formik.resetForm();
      } catch (error) {
        swal("Error", "Something went wrong", "error");
        console.log(error);
      }
    },
  });

  const handleFileChange = async (e) => {
    try {
      setFileLoading({ ...fileLoading, [e.target.name]: true }); // set file uploading status
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const response = await uploadFile(formData).unwrap();
      if (response) {
        setFileLoading({ ...fileLoading, [e.target.name]: false }); //setfile uploading status
        console.log(response);
        formik.setFieldValue(e.target.name, response.url);
      }
    } catch (error) {
      console.log(error);
      setFileLoading({ ...fileLoading, [e.target.name]: false }); //setfile uploading status
    }
  };

  const isLoading = createTeacherLoading || createUserLoading;

  return (
    <StyledPaper>
      <Box className="paperHeader">
        <Typography variant="formHeading">
          New Staff Registration Form
        </Typography>
      </Box>
      <Box className="paperBody">
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Grid container columnSpacing={1} rowSpacing={3}>
            {/* first row inputs */}

            <Grid item xs={12} lg={2}>
              <FormControl fullWidth>
                <Typography variant="formSubHeading">title</Typography>
                <StyledSelect
                  id="title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                >
                  <MenuItem value="Mr.">Mr</MenuItem>
                  <MenuItem value="Mrs.">Mrs</MenuItem>
                  <MenuItem value="Miss.">Miss</MenuItem>
                  <MenuItem value="Alhaji">Alhaji</MenuItem>
                  <MenuItem value="Hajiya">Hajiya</MenuItem>
                  <MenuItem value="Mallam">Mallam</MenuItem>
                  <MenuItem value="Mallama">Mallama</MenuItem>
                </StyledSelect>
                <FormHelperText variant="error">
                  {formik.touched.title && formik.errors.title}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={5}>
              <FormControl
                fullWidth
                error={formik.touched.first_name && formik.errors.first_name}
              >
                <Typography variant="formSubHeading">first name</Typography>
                <StyledTextField
                  bold
                  variant="outlined"
                  id="first_name"
                  name="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.first_name &&
                    Boolean(formik.errors.first_name)
                  }
                />
                <FormHelperText variant="error">
                  {formik.touched.first_name && formik.errors.first_name}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={5}>
              <FormControl
                fullWidth
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
              >
                <Typography variant="formSubHeading">last name</Typography>
                <StyledTextField
                  bold
                  variant="outlined"
                  id="last_name"
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.last_name && Boolean(formik.errors.last_name)
                  }
                />
                <FormHelperText variant="error">
                  {formik.touched.last_name && formik.errors.last_name}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/* end of first row */}

            {/* SECOND ROW INPUTS */}
            {/* Gender */}

            <Grid item xs={12} md={4}>
              <FormControl
                error={formik.touched.gender && formik.errors.gender}
                fullWidth
              >
                <Typography htmlFor="gender" variant="formSubHeading">
                  gender
                </Typography>
                <StyledSelect
                  displayEmpty
                  id="gender"
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                >
                  <MenuItem value="" disabled>
                    <em>select gender</em>
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </StyledSelect>
                <FormHelperText variant="error">
                  {formik.touched.gender && formik.errors.gender}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* Date of Birth */}

            <Grid item xs={12} md={4}>
              <FormControl
                error={formik.touched.dob && formik.errors.dob}
                fullWidth
              >
                <Typography htmlFor="dob" variant="formSubHeading">
                  Date of Birth
                </Typography>
                <StyledTextField
                  type="date"
                  variant="outlined"
                  id="dob"
                  name="dob"
                  value={formik.values.dob}
                  onChange={formik.handleChange}
                  error={formik.touched.dob && Boolean(formik.errors.dob)}
                />
                <FormHelperText variant="error">
                  {formik.touched.dob && formik.errors.dob}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* Phone Number */}

            <Grid item xs={12} md={4}>
              <FormControl
                error={formik.touched.phone && formik.errors.phone}
                fullWidth
              >
                <Typography htmlFor="phone" variant="formSubHeading">
                  Phone Number
                </Typography>
                <StyledTextField
                  variant="outlined"
                  id="phone"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                />
                <FormHelperText variant="error">
                  {formik.touched.phone && formik.errors.phone}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* Email */}

            <Grid item xs={12} lg={4}>
              <FormControl
                error={formik.touched.email && formik.errors.email}
                fullWidth
              >
                <Typography htmlFor="email" variant="formSubHeading">
                  EMAIL
                </Typography>
                <StyledTextField
                  type="email"
                  variant="outlined"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                />
                <FormHelperText variant="error">
                  {formik.touched.email && formik.errors.email}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* Address */}

            <Grid item xs={12} lg={8}>
              <FormControl
                error={formik.touched.address && formik.errors.address}
                fullWidth
              >
                <Typography htmlFor="address" variant="formSubHeading">
                  ADDRESS
                </Typography>
                <StyledTextField
                  variant="outlined"
                  id="address"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                />
                <FormHelperText variant="error">
                  {formik.touched.address && formik.errors.address}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* Fourth row input */}

            {/* Passport */}
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <Typography variant="formSubHeading">passport</Typography>

                <Stack direction="row" spacing={1}>
                  <StyledFileInput
                    type="file"
                    id="photo"
                    name="photo"
                    onChange={(e) => handleFileChange(e)}
                    error={formik.touched.photo && Boolean(formik.errors.photo)}
                  />
                  {fileLoading.photo && <CircularProgress />}
                </Stack>

                <FormHelperText variant="error">
                  {formik.touched.photo && formik.errors.photo}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <Typography variant="formSubHeading">signature</Typography>
                <Stack direction="row" spacing={1}>
                  <StyledFileInput
                    type="file"
                    id="signature"
                    name="signature"
                    onChange={(e) => handleFileChange(e)}
                    error={
                      formik.touched.signature &&
                      Boolean(formik.errors.signature)
                    }
                  />
                  {fileLoading.signature && <CircularProgress />}
                </Stack>

                <FormHelperText variant="error">
                  {formik.touched.signature && formik.errors.signature}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <Typography variant="formSubHeading">recent resume</Typography>
                <Stack direction="row" spacing={1}>
                  <StyledFileInput
                    type="file"
                    id="resume"
                    name="resume"
                    onChange={(e) => handleFileChange(e)}
                    error={
                      formik.touched.resume && Boolean(formik.errors.resume)
                    }
                  />
                  {fileLoading.resume && <CircularProgress />}
                </Stack>
                <FormHelperText variant="error">
                  {formik.touched.resume && formik.errors.resume}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/* Submit */}

            <Grid item xs={12} md={12} textAlign={"right"}>
              <StyledLoadingButton loading={isLoading} type="submit">
                SUBMIT
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </StyledPaper>
  );
}

export default StaffRegForm;
