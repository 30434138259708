import {
  Grid,
  LinearProgress,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { StyledPaper } from "../../../customComponents/styled/styledPaper";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../customComponents/styled/styledTable";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  useCreateAssessmentMutation,
  useDeleteAssessmentMutation,
  useGetAssessmentsQuery,
} from "./assessmentsApiSlice";
import { useGetSubSectionQuery } from "../sections/sectionsApiSlice";
import CustomWarningDialog from "../../../customComponents/CustomWarningDialog";
import swal from "sweetalert";
import CreateAssessment from "./CreateAssessment";
import EditAssessment from "./EditAssessment";

const validationSchema = yup.object({
  name: yup.string().required("Required"),
  obtainable_score: yup
    .number("must be a number")
    .max(100, "max is 100")
    .min(0, "min is 0")
    .required("Required"),
  subsection_id: yup.string().required("Required"),
});

function Assessments() {
  const { data: assessments, loading: getAssessmentsLoading } =
    useGetAssessmentsQuery("all");
  const { data: subsections } = useGetSubSectionQuery("all");
  const [createAssessment] = useCreateAssessmentMutation();
  const [deleteAssessment] = useDeleteAssessmentMutation();
  const [focusedItem, setFocusedItem] = useState(null);
  const [openWarning, setOpenWarning] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      obtainable_score: "",
      subsection_id: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const body = {
        name: values.name,
        obtainable_score: parseInt(values.obtainable_score),
        subsection_id: values.subsection_id,
      };
      try {
        const response = await createAssessment(body).unwrap();
        if (response.id) {
          swal("Success", "Assessments created successfully", "success");
          formik.resetForm();
        } else {
          swal(
            "Error",
            response.message || "Error creating assessment",
            "error"
          );
        }
      } catch (error) {
        swal(
          "Error",
          error.data?.message || "Error creating assessment",
          "error"
        );
      }
    },
  });

  const handleDelete = async (item) => {
    try {
      const response = await deleteAssessment(item);
      if (response.data) {
        swal("Success", "Assessment deleted successfully", "success");
      } else {
        swal("Error", "Error deleting assessment", "error");
      }
    } catch (error) {
      swal("Error", "Something went wrong", "error");
    }
  };

  return (
    <>
      <CustomWarningDialog
        open={openWarning}
        setOpen={setOpenWarning}
        item={itemToDelete}
        action={handleDelete}
        message="Are you sure you want to delete this assessment?"
      />
      <Grid container columnSpacing={3}>
        <Grid item xs={12} lg={4}>
          <StyledPaper>
            {focusedItem ? (
              <EditAssessment
                item={focusedItem}
                setItem={setFocusedItem}
                subsections={subsections}
              />
            ) : (
              <CreateAssessment />
            )}
          </StyledPaper>
        </Grid>
        <Grid item xs={12} lg={8}>
          <StyledPaper>
            {getAssessmentsLoading && <LinearProgress />}
            <Box className="paperHeader">
              <Typography variant="formHeading">Assessments</Typography>
            </Box>
            <TableContainer sx={{ pb: 4 }}>
              <Table sx={{ width: "100%", boxShadow: "none" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>subsection</StyledTableCell>
                    <StyledTableCell>name</StyledTableCell>
                    <StyledTableCell>obtainable score</StyledTableCell>
                    <StyledTableCell align="left">edit</StyledTableCell>
                    <StyledTableCell align="left">delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assessments
                    ?.map((ass) => ass)
                    .sort(
                      (first, second) =>
                        first.subsection_id - second.subsection_id
                    )
                    ?.map((row, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell component="th" scope="row">
                          {
                            subsections?.find(
                              (subsection) =>
                                subsection.id === row.subsection_id
                            )?.name
                          }
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.obtainable_score}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <SvgIcon
                            sx={{ color: "primary.main", cursor: "pointer" }}
                            component={EditIcon}
                            inheritViewBox
                            onClick={() => {
                              setFocusedItem(row);
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <DeleteIcon
                            onClick={() => {
                              setItemToDelete(row);
                              setOpenWarning(true);
                            }}
                            sx={{ color: "primary.main", cursor: "pointer" }}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </Grid>
      </Grid>
    </>
  );
}

export default Assessments;
