import {
  Grid,
  LinearProgress,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { StyledPaper } from "../../../customComponents/styled/styledPaper";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../customComponents/styled/styledTable";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import EditGradeForm from "./EditGradeForm";
import { useGetGradesQuery, useDeleteGradeMutation } from "./gradesApiSlice";
import { useGetSubSectionQuery } from "../sections/sectionsApiSlice";
import CreateGradeForm from "./CreateGradeForm";
import swal from "sweetalert";
import CustomWarningDialog from "../../../customComponents/CustomWarningDialog";

function Grades() {
  const [focusedItem, setFocusedItem] = useState(null);
  const [openWarning, setOpenWarning] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const { data: subsections } = useGetSubSectionQuery("all");
  const { data: grades, isLoading: getGradesLoading } =
    useGetGradesQuery("all");
  const [deleteGrade] = useDeleteGradeMutation();

  const handleDelete = async (item) => {
    try {
      const response = await deleteGrade(item).unwrap();
      if (response.message) {
        swal("Success", "Grade deleted successfully", "success");
      } else {
        swal("Error", "Grade not deleted", "error");
      }
    } catch (error) {
      swal("Error", "Something went wrong", "error");
    }
  };

  return (
    <>
      <CustomWarningDialog
        open={openWarning}
        setOpen={setOpenWarning}
        item={itemToDelete}
        action={handleDelete}
        message="Are you sure you want to delete this grade?"
      />

      <Grid container columnSpacing={3}>
        <Grid item sx={12} md={4}>
          <StyledPaper>
            {focusedItem ? (
              <EditGradeForm
                item={focusedItem}
                setItem={setFocusedItem}
                subsections={subsections}
              />
            ) : (
              <CreateGradeForm />
            )}
          </StyledPaper>
        </Grid>
        <Grid item sx={12} md={8}>
          {subsections?.map((item) => (
            <StyledPaper key={item.id}>
              {getGradesLoading && <LinearProgress />}
              <Box className="paperHeader">
                <Typography variant="formHeading">
                  {item.name} Grades
                </Typography>
              </Box>
              <TableContainer sx={{ pb: 4 }}>
                <Table sx={{ width: "100%", boxShadow: "none" }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Grade</StyledTableCell>
                      <StyledTableCell>type</StyledTableCell>
                      <StyledTableCell>Min Score</StyledTableCell>
                      <StyledTableCell>Max Score</StyledTableCell>
                      <StyledTableCell align="left">
                        description
                      </StyledTableCell>
                      <StyledTableCell align="left">edit</StyledTableCell>
                      <StyledTableCell align="left">delete</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* map grades according to subsection */}

                    {grades
                      ?.filter((grade) => grade.subsection_id === item.id)
                      .map((row) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell component="th" scope="row">
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.type}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.min_score}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.max_score}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.description}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <SvgIcon
                              sx={{ color: "primary.main", cursor: "pointer" }}
                              component={EditIcon}
                              inheritViewBox
                              onClick={() => {
                                setFocusedItem(row);
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <DeleteIcon
                              onClick={() => {
                                setItemToDelete(row);
                                setOpenWarning(true);
                              }}
                              sx={{ color: "primary.main", cursor: "pointer" }}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </StyledPaper>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

export default Grades;
