// import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
// import { logout } from "../pages/auth/authSlice";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { apiSlice } from "../../app/apiSlice";

// function Header({ drawerWidth, handleDrawerToggle }) {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     dispatch(logout());
//     dispatch(apiSlice.util.resetApiState());
//     navigate("/login");
//   };
 
//   return (
//     <AppBar
//       position="fixed"
//       sx={{
//         width: { sm: `calc(100% - ${drawerWidth}px)` },
//         ml: { sm: `${drawerWidth}px` },
//         boxShadow: "none",
//         backgroundColor: "#f1f1f1",
//         borderBottom: "1px solid #e7e7e7",
//       }}
//     >
//       <Toolbar>
//         <IconButton
//           aria-label="open drawer"
//           edge="start"
//           onClick={handleDrawerToggle}
//           sx={{ mr: 2, display: { sm: "none" } }}
//         >
//           <MenuIcon sx={{ color: "primary.main" }} />
//         </IconButton>
//         <Box sx={{ flexGrow: 1 }}></Box>
//         <Box
//           sx={{
//             height: 40,
//             width: 48,
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             background: "#fff",
//           }}
//         >
//           <PowerSettingsNewIcon
//             sx={{
//               color: "primary.main",
//               cursor: "pointer",
//               "& :hover": {
//                 color: "red",
//               },
//             }}
//             onClick={handleLogout}
//           />
//         </Box>
//         <Box
//           sx={{
//             height: 40,
//             width: 48,
//             display: "flex",
//             gap: 2,
//             alignItems: "center",
//             justifyContent: "center",
//             background: "balck",
//           }}
//         >
//           <PowerSettingsNewIcon
//             sx={{
//               color: "primary.main",
//               cursor: "pointer",
//               "& :hover": {
//                 color: "red",
//               },
//             }}
//             onClick={handleLogout}
//           />
//         </Box>
//       </Toolbar>
//     </AppBar>
//   );
// }

// export default Header;




import { AppBar, Box, IconButton, Toolbar, Menu, MenuItem, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SchoolIcon from "@mui/icons-material/School"; 
import { logout } from "../pages/auth/authSlice";
import { useDispatch, useSelector } from "react-redux"; // Fetching schools from Redux store
import { useNavigate } from "react-router-dom";
import { apiSlice } from "../../app/apiSlice";
import React, { useState } from "react"; 
import { useGetMySchoolsQuery } from "../pages/settings/school/schoolApiSlice";

function Header({ drawerWidth, handleDrawerToggle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State for the menu dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { school_id: currentSchool } = useSelector((state) => state.auth);
  
  const { data: schools } = useGetMySchoolsQuery();

  const handleLogout = () => {
    dispatch(logout());
    dispatch(apiSlice.util.resetApiState());
    navigate("/login");
  };

  const handleSwitchClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSchoolSwitch = (schoolId) => {
    console.log("Switching to school:", schoolId);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        boxShadow: "none",
        backgroundColor: "#f1f1f1",
        borderBottom: "1px solid #e7e7e7",
      }}
    >
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon sx={{ color: "primary.main" }} />
        </IconButton>
        <Box sx={{ flexGrow: 1 }}></Box>
        
        {/* Switch Schools Button */}
        <Box
          sx={{
            height: 40,
            width: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
          }}
        >
          <SchoolIcon
            sx={{
              color: "primary.main",
              cursor: "pointer",
              "& :hover": {
                color: "red",
              },
            }}
            onClick={handleSwitchClick}
          />
        </Box>

        {/* Dropdown for switching schools */}
        <Menu
          id="school-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'school-switch-button',
          }}
        >
          {schools?.map((school, i) => (
            <MenuItem
              key={i}
              selected={school.id === currentSchool?.id}
              onClick={() => handleSchoolSwitch(school.id)}
            >
              {school.name}
            </MenuItem>
          ))}
        </Menu>

        {/* Logout Button */}
        <Box
          sx={{
            height: 40,
            width: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
          }}
        >
          <PowerSettingsNewIcon
            sx={{
              color: "primary.main",
              cursor: "pointer",
              "& :hover": {
                color: "red",
              },
            }}
            onClick={handleLogout}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
