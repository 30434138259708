import { Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

function Main({ drawerWidth }) {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar />
      <Box sx={{ padding: "30px 15px", background: "#F7F7F8", flex: 1 }}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
}

export default Main;
