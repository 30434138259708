import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Fee"],
});

const feeScheduleApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getFees: builder.query({
      query: (parameter) => `/fees/fee/${parameter}`,
      providesTags: ["Record"],
    }),
    createFee: builder.mutation({
      query: (values) => ({
        url: "/fees/fee",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Record"],
    }),
    deleteFee: builder.mutation({
      query: (values) => ({
        url: `/fees/fee/${values.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Record"],
    }),
  }),
});

export const { useGetFeesQuery, useCreateFeeMutation, useDeleteFeeMutation } =
  feeScheduleApiSlice;
