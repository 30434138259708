import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { StyledPaper } from "../../../customComponents/styled/styledPaper";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../customComponents/styled/styledTable";
import Switch from "@mui/material/Switch";

function Preferences() {
  const options = [
    { name: "Allow Practice", active: true },
    { name: "Department", active: true },
    { name: "Houses", active: true },
    { name: "Midterm Comments", active: false },
    { name: "Skill Evaluation	", active: true },
    { name: "Subject Associations", active: false },
    { name: "Term Comment	", active: false },
    { name: "Timetable", active: true },
    { name: "Trait Comment", active: false },
    { name: "Use Public Bank", active: false },
  ];

  return (
    <>
      <StyledPaper>
        <Box className="paperHeader">
          <Typography variant="formHeading">Schoola Preferences</Typography>
        </Box>
        <TableContainer sx={{ pb: 4 }}>
          <Table sx={{ width: "100%", boxShadow: "none" }}>
            <TableHead>
              <TableRow>
                <StyledTableCell>option</StyledTableCell>
                <StyledTableCell align="left">status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {options.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Switch
                      size="small"
                      sx={{
                        color: "primary.main",
                        cursor: "pointer",
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPaper>
    </>
  );
}

export default Preferences;
