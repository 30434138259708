import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import { visuallyHidden } from "@mui/utils";
import PrintIcon from "@mui/icons-material/Print";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import { useReactToPrint } from "react-to-print";
import { FilledButton } from "../../customComponents/styled/styledButtons";
import { StyledTextField } from "../../customComponents/styled/styledInputs";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const headCells = [
    {
      id: "recipient",

      label: "Recipient",
    },
    {
      id: "content",

      label: "Content",
    },
    {
      id: "date",
      label: "Date",
    },
  ];

  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  rows: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { setSearchkey, searchKey, handlePrint } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },

        "& .search": {
          margin: "0px 5px",
        },
      }}
    >
      <FilledButton onClick={handlePrint}>
        Print
        <PrintIcon />
      </FilledButton>
      <StyledTextField
        className="search"
        placeholder="search..."
        value={searchKey}
        onChange={(e) => setSearchkey(e.target.value)}
      />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  setSearchkey: PropTypes.func.isRequired,
  searchkey: PropTypes.string.isRequired,
  handlePrint: PropTypes.func.isRequired,
};

export default function MessageHistoryTable({ rows }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchkey, setSearchkey] = React.useState("");
  const componentToPrintRef = React.useRef();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <EnhancedTableToolbar
        setSearchkey={setSearchkey}
        searchkey={searchkey}
        handlePrint={handlePrint}
      />
      <div style={{ display: "none" }}>
        {/* Table to print, containing all the table data without searching or sorting */}
        <TableContainer ref={componentToPrintRef}>
          <Table>
            <TableHead>
              <TableRow sx={{ borderBottom: "2px solid lightgrey" }}>
                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell>Recipient</StyledTableCell>
                <StyledTableCell>Content</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell>{i + 1}</StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.recipient}
                  </StyledTableCell>
                  <StyledTableCell>{row.content}</StyledTableCell>
                  <StyledTableCell>{row.date}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .filter((row) => {
                return row.recipient
                  .toLowerCase()
                  .includes(searchkey.toLowerCase());
              })
              .map((row, index) => {
                const isItemSelected = isSelected(row.recipient);

                // search function

                return (
                  <StyledTableRow hover tabIndex={-1} key={row.recipient}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ width: "25%" }}
                    >
                      {row.recipient}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        textAlign: "left",
                        width: "35%",
                        padding: "0 15px",
                      }}
                    >
                      {row.content}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "left", width: "20%" }}>
                      {row.date}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <StyledTableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
