import {
  Typography,
  Box,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  Avatar,
  LinearProgress,
} from "@mui/material";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import { useGetClassesQuery } from "./classApiSlice";

function ClassStudents({ item }) {
  const { data, isLoading } = useGetClassesQuery(`${item?.id}/students`);

  return (
    <StyledPaper>
      <Box className="paperHeader">
        <Typography variant="paperHeading">Students</Typography>
      </Box>
      <TableContainer sx={{ pb: 4 }}>
        <Table sx={{ width: "100%", boxShadow: "none" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>photo</StyledTableCell>
              <StyledTableCell>reg no</StyledTableCell>
              <StyledTableCell>name</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && <LinearProgress />}
            {data?.map((student) => (
              <StyledTableRow key={student.id}>
                <StyledTableCell component="th" scope="row">
                  <Avatar src={student.photo} />
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {student.id}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {student.first_name} {student.last_name}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledPaper>
  );
}

export default ClassStudents;
