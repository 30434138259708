import { Grid, 
  MenuItem, 
  Typography, 
  FormControl, 
  TableContainer, 
  Table, 
  SvgIcon,
  TableBody, 
  TableRow, 
  TableHead, 
  Box, 
  FormHelperText } from '@mui/material';
import React, { useState } from 'react';
import { StyledSelect, StyledTextField } from '../../../customComponents/styled/styledInputs';
import { StyledPaper } from '../../../customComponents/styled/styledPaper';
import {FilledButton} from "../../../customComponents/styled/styledButtons";
import { StyledTableCell, StyledTableRow } from "../../../customComponents/styled/styledTable";
import { useFormik } from "formik";
import * as yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import EditCommentForm from './EditCommentForm';

const validationSchema = yup.object({
  section: yup.string().required("Required"),
  commentType: yup.string().required("Required"),
  
});

function Comments() {
const [focusedComment, setFocusedComment] = useState(null);


  const formik = useFormik ({
      initialValues: {
          section: "",
          commentType: "",
      },
      enableReinitialize: true,
      validationSchema: validationSchema,
  onSubmit: (values) => {
    alert(JSON.stringify(values, null, 2));
  },
  })

  const comments = [
      { commentType: "Bravery", section: "Primary"},
      { commentType: "Athleticism", section: "Secondary"},
      { commentType: "Attitude to work", section: "Kindergarten"},
      { commentType: "Comment 4", section: "Nursery"}
  ]
return (
  <div style={{padding: "0 10px"}}>
      <Grid container columnSpacing={4}>
          <Grid item xs={12} md={4}>
          <StyledPaper sx={{padding: "15px"}}>
            {focusedComment ?(
              <EditCommentForm item={focusedComment} />
            ) : ( 
            <>
          <Typography variant="formHeading" sx={{marginBottom: "20px"}}>Setup Comment Type</Typography>

          <Box component="form" onSubmit={formik.handleSubmit}>

          <Grid container rowSpacing={3}>

          <Grid item xs={12} md={12}>
                  <FormControl error={formik.touched.section && formik.errors.section} fullWidth>
                      <Typography variant="formSubHeading">SECTION</Typography>
                      <StyledSelect 
                      id="section" 
                      name="section" 
                      onChange={formik.handleChange} 
                      value={formik.values.section} 
                      error={formik.touched.section && Boolean(formik.errors.section)}>
                          <MenuItem value="Kindergarten">Kindergarten</MenuItem>
                          <MenuItem value="Nursery">Nursery</MenuItem>
                          <MenuItem value="Primary">Primary</MenuItem>
                          <MenuItem value="Secondary">Secondary</MenuItem>
                      </StyledSelect>
                      <FormHelperText variant="error">
                         {formik.touched.section && formik.errors.section}
                      </FormHelperText>
                  </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                  <FormControl error={formik.touched.commentType && formik.errors.commentType} fullWidth>
                      <Typography variant="formSubHeading">COMMENT TYPE</Typography>
                      <StyledTextField 
                      id="commentType" 
                      name="commentType" 
                      value={formik.values.commentType} 
                      onChange={formik.handleChange} 
                      error={formik.touched.commentType && Boolean(formik.errors.commentType)} />

                      <FormHelperText variant="error">
                         {formik.touched.commentType && formik.errors.commentType}
                      </FormHelperText>    
                  </FormControl>
              </Grid>

              <Grid item xs={12} md={12} textAlign={"right"}>
            <FilledButton type="submit" sx={{textTransform: "none"}}>Submit</FilledButton>
          </Grid>
          </Grid>
          </Box>
          </>
          )}
         </StyledPaper>

          </Grid>

                           {/* Generated Table */}

          <Grid item xs={12} md={8}>
      <StyledPaper sx={{paddingBottom: "25px"}}>
          <Box sx={{ padding: "15px"}}>
        <Typography  variant="formHeading">Comments</Typography>
        </Box>
        <TableContainer>
          <Table>
          <TableHead>
        <TableRow sx={{borderBottom: "2px solid lightgrey "}}>
          <StyledTableCell>COMMENT TYPE</StyledTableCell>
          <StyledTableCell>SECTION</StyledTableCell>
          <StyledTableCell>EDIT</StyledTableCell>
          <StyledTableCell textAlign={"right"}>DELETE</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {comments.map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row">
                      {row.commentType}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.section}
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <SvgIcon
                        sx={{ color: "primary.main", cursor: "pointer" }}
                        component={EditIcon}
                        inheritViewBox
                        onClick={() => {
                          setFocusedComment(row);
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <DeleteIcon
                        sx={{ color: "primary.main", cursor: "pointer" }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
        
      </TableBody>
          </Table>
        </TableContainer>

      </StyledPaper>
    </Grid>
      </Grid>
      
      </div>
)
}

export default Comments