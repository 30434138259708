import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box } from "@mui/system";
import { FormControl, FormHelperText, Grid, Typography } from "@mui/material";
import { StyledTextField } from "../../../customComponents/styled/styledInputs";
import { StyledLoadingButton } from "../../../customComponents/styled/styledButtons";
import { useUpdateSectionMutation } from "./sectionsApiSlice";
import swal from "sweetalert";

const sectionFormValidation = yup.object({
  name: yup.string().required("Required"),
  head_title: yup.string().required("Required"),
});

function EditSectionForm({ item, setFocusedItem }) {
  const [updateSection, { isLoading }] = useUpdateSectionMutation();
  const sectionForm = useFormik({
    initialValues: {
      id: item?.id,
      name: item?.name,
      head_title: item?.head_title,
    },
    enableReinitialize: true,
    validationSchema: sectionFormValidation,
    onSubmit: async (values) => {
      try {
        await updateSection(values).unwrap();
        swal("Success", "Section updated successfully", "success");
        sectionForm.resetForm();
        setFocusedItem(null);
      } catch (error) {}
    },
  });

  return (
    <>
      <Box className="paperHeader">
        <Typography variant="formHeading">Edit Section</Typography>
      </Box>
      <Box className="paperBody">
        <form onSubmit={sectionForm.handleSubmit}>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">Section Name</Typography>
                <StyledTextField
                  autoFocus
                  variant="outlined"
                  id="name"
                  name="name"
                  value={sectionForm.values.name}
                  onChange={sectionForm.handleChange}
                  error={
                    sectionForm.touched.name && Boolean(sectionForm.errors.name)
                  }
                />
                <FormHelperText variant="error">
                  {sectionForm.touched.name && sectionForm.errors.name}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">
                  HEAD'S TITLE (E.G: HEAD TEACHER)
                </Typography>
                <StyledTextField
                  variant="outlined"
                  id="head_title"
                  name="head_title"
                  value={sectionForm.values.head_title}
                  onChange={sectionForm.handleChange}
                  error={
                    sectionForm.touched.head_title &&
                    Boolean(sectionForm.errors.head_title)
                  }
                />
                <FormHelperText variant="error">
                  {sectionForm.touched.head_title &&
                    sectionForm.errors.head_title}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} textAlign={"right"}>
              <StyledLoadingButton loading={isLoading} type="submit">
                update
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default EditSectionForm;
