import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Broadsheet"],
});

const classBroadsheetApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getBroadsheet: builder.query({
      query: () => `/report/broadsheet`,
      providesTags: ["Broadsheet"],
    }),
    createBroadsheet: builder.mutation({
      query: (values) => ({
        url: "/report/broadsheet",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Broadsheet"],
    }),
    deleteBroadsheet: builder.mutation({
      query: (values) => ({
        url: "/report/broadsheet",
        method: "DELETE",
        body: { ...values },
      }),
      invalidatesTags: ["Broadsheet"],
    }),
  }),
});

export const {
  useGetBroadsheetQuery,
  useCreateBroadsheetMutation,
  useDeleteBroadsheetMutation,
} = classBroadsheetApiSlice;
