import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Classes"],
});

const classApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getClasses: builder.query({
      query: (parameter) => `/classroom/${parameter}`,
      providesTags: ["Classes"],
    }),
    createClassroom: builder.mutation({
      query: (values) => ({
        url: "/classroom/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Classes"],
    }),
    updateClassroom: builder.mutation({
      query: (values) => ({
        url: "/classroom/update",
        method: "PUT",
        body: { ...values },
      }),
      invalidatesTags: ["Classes"],
    }),
    deleteClassroom: builder.mutation({
      query: (values) => ({
        url: `/classroom/${values.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Classes"],
    }),
    addTeacherToClassroom: builder.mutation({
      query: (values) => ({
        url: `/classroom/${values.class_Id}/manager/add`,
        method: "PATCH",
        body: { user_id: values.user_id },
      }),
      invalidatesTags: ["Classes"],
    }),
    removeTeacherFromClassroom: builder.mutation({
      query: (values) => ({
        url: `/classroom/${values.class_Id}/manager/remove`,
        method: "PATCH",
        body: { ...values },
      }),
      invalidatesTags: ["Classes"],
    }),
    addSubjectToClassroom: builder.mutation({
      query: (values) => ({
        url: `/classroom/${values.class_Id}/subjects/add`,
        method: "PATCH",
        body: { ...values },
      }),
      invalidatesTags: ["Classes", "Teacher"],
    }),
    replaceSubjectTeacher: builder.mutation({
      query: (values) => ({
        url: `/classroom/${values.class_Id}/subjects/replace-teacher`,
        method: "PATCH",
        body: { ...values },
      }),
      invalidatesTags: ["Classes", "Teacher"],
    }),
    removeSubjectFromClassroom: builder.mutation({
      query: (values) => ({
        url: `/classroom/${values.class_Id}/subjects/${values.subject_Id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Classes", "Teacher"],
    }),
  }),
});

export const {
  useGetClassesQuery,
  useCreateClassroomMutation,
  useAddSubjectToClassroomMutation,
  useAddTeacherToClassroomMutation,
  useDeleteClassroomMutation,
  useUpdateClassroomMutation,
  useReplaceSubjectTeacherMutation,
  useRemoveTeacherFromClassroomMutation,
  useRemoveSubjectFromClassroomMutation,
} = classApiSlice;
