import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Invoice"],
});

const invoiceApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getInvoice: builder.query({
      query: (parameter) => `/fees/invoice${parameter}`,
      providesTags: ["Invoice"],
    }),
    createInvoice: builder.mutation({
      query: (values) => ({
        url: "/fees/invoice",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Invoice"],
    }),
    deleteInvoice: builder.mutation({
      query: (values) => ({
        url: `/fees/invoice${values.id}`,
        method: "DELETE",
        body: { values },
      }),
      invalidatesTags: ["Invoice"],
    }),
  }),
});

export const {
  useGetInvoiceQuery,
  useCreateInvoiceMutation,
  useDeleteInvoiceMutation,
} = invoiceApiSlice;
