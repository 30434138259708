import {
  Avatar,
  Box,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useEffect } from "react";
import swal from "sweetalert";
import {
  FilledButton,
  StyledLoadingButton,
} from "../../customComponents/styled/styledButtons";
import { StyledTableCell } from "../../customComponents/styled/styledTable";
import { useGetClassesQuery } from "../classroom/classApiSlice";
import {
  useGetAttendanceQuery,
  useCreateAttendanceMutation,
  useClearAttendanceMutation,
} from "./attendanceApiSlice";

function AttendanceSheet({ myClasses, activeTab }) {
  const { data: students, isLoading: fetchingStudents } = useGetClassesQuery(
    `${myClasses[activeTab]?.id}/students`
  );

  const { data: attendanceList, isLoading: fetchingAttendance } =
    useGetAttendanceQuery(myClasses[activeTab]?.id);

  const [createAttendance, { isLoading: creatingAttendance }] =
    useCreateAttendanceMutation();

  const [clearAttendance, { isLoading: clearingAttendance }] =
    useClearAttendanceMutation();

  const [attendanceBody, setAttendanceBody] = React.useState([]);

  useEffect(() => {
    setAttendanceBody(
      students?.map((student) => ({
        student_id: student.id,
        days_absent:
          attendanceList?.length > 0
            ? attendanceList.find((item) => item.student.id === student.id)
                ?.days_absent
            : 0,
        updated_at:
          attendanceList?.length > 0
            ? attendanceList.find((item) => item.student.id === student.id)
                ?.updated_at
            : new Date().toISOString(),
      })) || []
    );
  }, [students, attendanceList]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAttendanceBody((prevState) => {
      return prevState.map((item) => {
        if (item.student_id === parseInt(name)) {
          return { ...item, days_absent: parseInt(value) };
        }
        return item;
      });
    });
  };

  const handleIncrement = (event) => {
    const { name } = event.target;
    const newAttendanceBody = attendanceBody.map((item) => {
      if (item.student_id === parseInt(name)) {
        return { ...item, days_absent: item.days_absent + 1 };
      } else {
        return item;
      }
    });
    setAttendanceBody(newAttendanceBody);
  };

  const handleDecrement = (event) => {
    const { name } = event.target;
    setAttendanceBody((prevState) => {
      return prevState.map((item) => {
        if (item.student_id === parseInt(name)) {
          return { ...item, days_absent: item.days_absent - 1 };
        }
        return item;
      });
    });
  };

  const handleSubmit = async () => {
    const body = {
      classroom_id: myClasses[activeTab].id,
      attendance: attendanceBody,
    };
    try {
      const response = await createAttendance(body).unwrap();
      if (response.status === "success") {
        swal("Success", response.message, "success");
      } else {
        swal("Error", response.message, "error");
      }
    } catch (error) {
      swal("Error", error.message || "something went wrong", "error");
    }
  };

  const handleReset = async () => {
    const body = {
      classroom_id: myClasses[activeTab].id,
    };
    try {
      const response = await clearAttendance(body).unwrap();
      if (response.status === "success") {
        swal("Success", response.message, "success");
      } else {
        swal("Error", response.message, "error");
      }
    } catch (error) {
      swal("Error", error.message || "something went wrong", "error");
    }
  };

  return (
    <div>
      {(fetchingStudents || fetchingAttendance) && <LinearProgress />}
      <TableContainer sx={{ pb: 4 }}>
        <Table sx={{ width: "100%", boxShadow: "none" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>student</StyledTableCell>
              <StyledTableCell>last updated</StyledTableCell>
              <StyledTableCell>absence</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students?.map((student) => (
              <TableRow
                key={student.id}
                sx={{
                  padding: "10px 2px",
                  "& th": {
                    padding: "8px ",
                  },
                }}
              >
                <StyledTableCell component="th" scope="row">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ mr: 1 }}>
                      <Avatar src={student.image} />
                    </Box>
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        fontSize: "1.1rem",
                        lineHeight: "1.2rem",
                        color: "primary.main",
                        "& > span": {
                          fontSize: "12px",
                          color: "#333",
                          fontStyle: "italic",
                        },
                      }}
                    >
                      {student.first_name + " " + student.last_name} <br />
                      <span>{student.id}</span>
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {attendanceBody?.find(
                    (item) => item.student_id === student.id
                  )?.updated_at &&
                    format(
                      parseISO(
                        attendanceBody?.find(
                          (item) => item.student_id === student.id
                        )?.updated_at
                      ),
                      "MMM dd, yyyy"
                    )}
                </StyledTableCell>

                <StyledTableCell align="left">
                  <Box
                    component="input"
                    type="number"
                    sx={{
                      width: "50px",
                      height: "35px",
                      padding: "1px 2px",
                    }}
                    name={student.id}
                    value={
                      attendanceBody?.find(
                        (attendance) => attendance.student_id === student.id
                      )?.days_absent
                    }
                    onChange={handleChange}
                  />
                </StyledTableCell>

                <StyledTableCell align="left">
                  <Stack spacing={1} direction={{ md: "column", lg: "row" }}>
                    <FilledButton name={student.id} onClick={handleIncrement}>
                      +
                    </FilledButton>
                    <FilledButton name={student.id} onClick={handleDecrement}>
                      -
                    </FilledButton>
                  </Stack>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack spacing={1} direction="row">
        <StyledLoadingButton
          loading={creatingAttendance}
          onClick={handleSubmit}
        >
          Save Changes
        </StyledLoadingButton>
        <StyledLoadingButton loading={clearingAttendance} onClick={handleReset}>
          Reset
        </StyledLoadingButton>
      </Stack>
    </div>
  );
}

export default AttendanceSheet;
