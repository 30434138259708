import {
  Avatar,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { StyledSelect } from "../../customComponents/styled/styledInputs";
import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
import { Box } from "@mui/system";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import {
  useAddStudentToClassroomMutation,
  useRemoveStudentFromClassroomMutation,
} from "./studentApiSlice";
import { useGetClassesQuery } from "../classroom/classApiSlice";
import swal from "sweetalert";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";

const validationSchema = yup.object({
  classroom: yup.string().required("Required"),
});

function PromoteStudentsDialog({
  open,
  setOpen,
  selectedStudents,
  setSelectedStudents,
  currentClassroom,
}) {
  const { data: classrooms } = useGetClassesQuery("all");
  const [addStudentToClass, { isLoading: isAddingStudents }] =
    useAddStudentToClassroomMutation();
  const [removeStudentFromClass, { isLoading: isRemovingStudents }] =
    useRemoveStudentFromClassroomMutation();

  const formik = useFormik({
    initialValues: {
      classroom: (currentClassroom && currentClassroom) || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (values.classroom === currentClassroom) {
          return swal("Error", "Please select a different class", "error");
        }
        const studentIds = [];

        selectedStudents.forEach((student) => studentIds.push(student.id));

        const res = await addStudentToClass({
          classroom_id: values.classroom,
          body: { student_ids: studentIds },
        }).unwrap();

        if (res) {
          await removeStudentFromClass({
            classroom_id: currentClassroom,
            body: { student_ids: studentIds },
          }).unwrap();

          swal("Success", "Students promoted", "success");
          setSelectedStudents([]);
          handleClose();
        }
      } catch (error) {
        handleClose();
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "400px",
          sm: { width: "100%" },
        },
      }}
    >
      <Box sx={{ display: "flex", padding: 2, justifyContent: "flex-end" }}>
        <CloseSharpIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
      </Box>
      <Divider />

      <DialogContent>
        <StyledPaper>
          <Box className="paperHeader">Promote students</Box>
          <Box className="paperBody">
            <Box component="form" onSubmit={formik.handleSubmit}>
              <Grid container columnSpacing={1} rowSpacing={3}>
                <Grid item xs={12} md={8}>
                  <FormControl
                    error={formik.touched.classroom && formik.errors.classroom}
                    fullWidth
                  >
                    <Typography htmlFor="classroom" variant="formSubHeading">
                      classroom
                    </Typography>
                    <StyledSelect
                      displayEmpty
                      id="classroom"
                      name="classroom"
                      value={formik.values.classroom}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.classroom &&
                        Boolean(formik.errors.classroom)
                      }
                    >
                      <MenuItem value="" disabled>
                        <em>select classroom</em>
                      </MenuItem>
                      {classrooms?.map((classroom) => (
                        <MenuItem key={classroom.id} value={classroom.id}>
                          {classroom.name}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    <FormHelperText variant="error">
                      {formik.touched.classroom && formik.errors.classroom}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  textAlign={"right"}
                  sx={{
                    display: {
                      md: "flex",
                    },
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    mb: 0.5,
                  }}
                >
                  <StyledLoadingButton
                    loading={isAddingStudents || isRemovingStudents}
                    type="submit"
                  >
                    Promote
                  </StyledLoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <TableContainer sx={{ pb: 4 }}>
            <Table sx={{ width: "100%", boxShadow: "none" }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>photo</StyledTableCell>
                  <StyledTableCell>name</StyledTableCell>
                  <StyledTableCell align="right">id</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedStudents?.map((student) => (
                  <StyledTableRow key={student.id}>
                    <StyledTableCell component="th" scope="row">
                      <Avatar
                        alt="passport"
                        src={student.photo}
                        sx={{ width: 24, height: 24 }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      {student.first_name} {student.last_name}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {student.id}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledPaper>
      </DialogContent>
    </Dialog>
  );
}

export default PromoteStudentsDialog;
