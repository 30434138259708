import { alpha, Box, Divider, Grid, SvgIcon, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as RulerIcon } from "../../../assets/icons/ruler.svg";
import { ReactComponent as GroupIcon } from "../../../assets/icons/account-group.svg";
import { Link } from "react-router-dom";

const QuickLink = ({ title, description, Icon, link }) => {
  return (
    <Box
      sx={{
        borderRadius: "5px",
        boxShadow: "0 1px 2.94px 0.06px rgb(4 26 55 / 16%)",
        marginBottom: "30px",
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",
        backgroundColor: "primary.transparent",
        display: "flex",

        "& .cardBlock": {
          padding: "25px",
        },
        "& .cardTitle": {
          fontSize: "10px",
          textTransform: "uppercase",
          textAlign: "center",
        },
        "& .cardIcon": {
          height: "32px",
          width: "27px",
          margin: "0 auto",
          color: "primary.main",
        },
        "&:hover": {},
        "& .link": {
          textDecoration: "none",
          color: "inherit",
        },
      }}
    >
      <Box className="cardBlock">
        <Link className="link" to={link}>
          <Typography className="cardTitle">{title}</Typography>
          <Box sx={{ textAlign: "center", mt: 1 }}>
            <SvgIcon className="cardIcon" component={Icon} inheritViewBox />
          </Box>
        </Link>
      </Box>
    </Box>
  );
};

function QuickLinksCard() {
  return (
    <>
      <Box className="paperHeader">
        <Typography gutterBottom>Quick Links</Typography>
        <Divider />
      </Box>
      <Box className="paperBody">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <QuickLink
              title="manage class attendance"
              link="#"
              Icon={GroupIcon}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuickLink
              title="class end of term score(S)"
              link="#"
              Icon={RulerIcon}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default QuickLinksCard;
