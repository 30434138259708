import { TextField, Select, Box, TextareaAutosize } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)(({ theme, bold }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#2b2b2b",
    border: "1px solid #ccc",
    fontSize: "14px",
    fontWeight: bold ? "bold" : "normal",
    lineHeight: "1.42857143",
    color: "#555",
    width: "100%",
    height: "20px",
    padding: "6px 12px",
    boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:disabled": {
      backgroundColor: "#eee",
      color: "#888888",
    },

    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#2b2b2b",
    border: "1px solid #ccc",
    fontSize: "14px",
    lineHeight: "1.42857143",
    color: "#555",
    width: "100%",
    height: "20px",
    padding: "6px 12px",
    boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledFileInput = styled("input")(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  borderRadius: 4,
  position: "relative",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#2b2b2b",
  border: "1px solid #ccc",
  fontSize: "14px",
  lineHeight: "1.42857143",
  color: "#555",
  width: "100%",
  padding: "5px 12px",
  boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
  transition: theme.transitions.create([
    "border-color",
    "background-color",
    "box-shadow",
  ]),

  "&:focus": {
    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    borderColor: theme.palette.primary.main,
  },
}));

const StyledTextArea = styled(TextareaAutosize)(({ theme, bold }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },

  borderRadius: 4,
  position: "relative",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#2b2b2b",
  border: "1px solid #ccc",
  fontSize: "14px",
  fontWeight: bold ? "bold" : "normal",
  lineHeight: "1.42857143",
  color: "#555",
  width: "100%",
  height: "20px",
  padding: "6px 12px",
  boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
  transition: theme.transitions.create([
    "border-color",
    "background-color",
    "box-shadow",
  ]),

  "&:disabled": {
    backgroundColor: "#eee",
    color: "#888888",
  },

  "&:focus": {
    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    borderColor: theme.palette.primary.main,
    outlineColor: theme.palette.primary.main,
  },
}));

export { StyledTextField, StyledSelect, StyledFileInput, StyledTextArea };
