import {
  Grid,
  Box,
  Typography,
  FormControl,
  FormHelperText,
  TableBody,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  SvgIcon,
  LinearProgress,
} from "@mui/material";
import React, { useEffect } from "react";
import { StyledPaper } from "../../../customComponents/styled/styledPaper";
import { useFormik } from "formik";
import * as yup from "yup";
import { StyledTextField } from "../../../customComponents/styled/styledInputs";
import { StyledLoadingButton } from "../../../customComponents/styled/styledButtons";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../customComponents/styled/styledTable";
import {
  useGetSessionQuery,
  useCreateSessionMutation,
  useCreateTermMutation,
} from "./sessionApiSlice";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import EditSessionForm from "./EditSessionForm";
import EditTermForm from "./EditTermForm";
import { format, parseISO } from "date-fns";
import swal from "sweetalert";

const validationSchema = yup.object({
  name: yup.string().required("Session name is required"),
  starts_at: yup.string().required("Required"),
  ends_at: yup.string().required("Required"),
});

const addTermValidation = yup.object({
  session_name: yup.string().required("current session required"),
  name: yup.string().required("Term name is required"),
  starts_at: yup.string().required("Required"),
  ends_at: yup.string().required("Required"),
});

function Session() {
  const { data: sessions, isLoading: fetchingSessions } =
    useGetSessionQuery("all");
  const { data: currentSession } = useGetSessionQuery("current");
  const [createSession, { isLoading: creatingSession }] =
    useCreateSessionMutation();

  const [createTerm, { isLoading: creatingTerm }] = useCreateTermMutation();

  const [selectedSession, setSelectedSession] = React.useState(null);
  const [sessionToEdit, setSessionToEdit] = React.useState(null);
  const [termToEdit, setTermToEdit] = React.useState(null);

  useEffect(() => {
    if (selectedSession) {
      const updatedSession = sessions.find(
        (session) => session.id === selectedSession.id
      );
      setSelectedSession(updatedSession);
    }
  }, [sessions]);

  useEffect(() => {
    setSelectedSession(currentSession);
  }, [currentSession]);

  const formik = useFormik({
    initialValues: {
      name: "",
      starts_at: "",
      ends_at: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await createSession(values).unwrap();
        if (response) {
          swal("Success", "Session Created Successfully", "success");
          formik.resetForm();
        } else {
          swal("Error", "Error creating session ", "error");
        }
      } catch (error) {
        swal("Error", "something went wrong", "error");
      }
    },
  });

  const addTermForm = useFormik({
    initialValues: {
      session_id: currentSession?.id || "",
      session_name: currentSession?.name || "",
      name: "",
      starts_at: "",
      ends_at: "",
    },
    enableReinitialize: true,
    validationSchema: addTermValidation,
    onSubmit: async (values) => {
      try {
        const response = await createTerm(values).unwrap();
        if (response) {
          swal("Success", "Term Created Successfully", "success");
          addTermForm.resetForm();
        } else {
          swal("Error", "Error creating term", "error");
        }
      } catch (error) {
        swal("Error", "Something went wrong", "error");
      }
    },
  });

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} md={6}>
          <StyledPaper>
            {sessionToEdit ? (
              <EditSessionForm
                sessionToEdit={sessionToEdit}
                setSessionToEdit={setSessionToEdit}
              />
            ) : (
              <>
                <Box className="paperHeader">
                  <Typography variant="formHeading">Create Session</Typography>
                </Box>
                <Box className="paperBody">
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container columnSpacing={1} rowSpacing={2}>
                      <Grid item xs={12}>
                        <FormControl error fullWidth>
                          <Typography variant="formSubHeading">
                            Session Name
                          </Typography>
                          <StyledTextField
                            autoFocus
                            variant="outlined"
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.name && Boolean(formik.errors.name)
                            }
                          />
                          <FormHelperText variant="error">
                            {formik.touched.name && formik.errors.name}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      {/* End of first row */}

                      {/* End of second row */}

                      <Grid item xs={12} lg={6}>
                        <FormControl error fullWidth>
                          <Typography variant="formSubHeading">
                            Start date
                          </Typography>
                          <StyledTextField
                            type="date"
                            variant="outlined"
                            id="starts_at"
                            name="starts_at"
                            value={formik.values.starts_at}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.starts_at &&
                              Boolean(formik.errors.starts_at)
                            }
                          />
                          <FormHelperText variant="error">
                            {formik.touched.starts_at &&
                              formik.errors.starts_at}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* End of third row */}

                      <Grid item xs={12} lg={6}>
                        <FormControl error fullWidth>
                          <Typography variant="formSubHeading">
                            End Date
                          </Typography>
                          <StyledTextField
                            type="date"
                            variant="outlined"
                            id="ends_at"
                            name="ends_at"
                            value={formik.values.ends_at}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.ends_at &&
                              Boolean(formik.errors.ends_at)
                            }
                          />
                          <FormHelperText variant="error">
                            {formik.touched.ends_at && formik.errors.ends_at}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} textAlign={"right"}>
                        <StyledLoadingButton
                          loading={creatingSession}
                          type="submit"
                        >
                          submit
                        </StyledLoadingButton>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </>
            )}
          </StyledPaper>

          <StyledPaper>
            {termToEdit ? (
              <EditTermForm
                termToEdit={termToEdit}
                setTermToEdit={setTermToEdit}
              />
            ) : (
              <>
                <Box className="paperHeader">
                  <Typography variant="formHeading">Add Term</Typography>
                </Box>
                <Box className="paperBody">
                  <form onSubmit={addTermForm.handleSubmit}>
                    <Grid container columnSpacing={1} rowSpacing={2}>
                      <Grid item xs={12} lg={6}>
                        <FormControl error fullWidth>
                          <Typography variant="formSubHeading">
                            Session Name
                          </Typography>
                          <StyledTextField
                            disabled
                            variant="outlined"
                            id="session_name"
                            session_name="session_name"
                            value={addTermForm.values.session_name}
                            onChange={addTermForm.handleChange}
                            error={
                              addTermForm.touched.session_name &&
                              Boolean(addTermForm.errors.session_name)
                            }
                          />
                          <FormHelperText variant="error">
                            {addTermForm.touched.session_name &&
                              addTermForm.errors.session_name}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} lg={6}>
                        <FormControl error fullWidth>
                          <Typography variant="formSubHeading">
                            Term Name
                          </Typography>
                          <StyledTextField
                            variant="outlined"
                            id="name"
                            name="name"
                            value={addTermForm.values.name}
                            onChange={addTermForm.handleChange}
                            error={
                              addTermForm.touched.name &&
                              Boolean(addTermForm.errors.name)
                            }
                          />
                          <FormHelperText variant="error">
                            {addTermForm.touched.name &&
                              addTermForm.errors.name}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      {/* End of first row */}

                      {/* End of second row */}

                      <Grid item xs={12} lg={6}>
                        <FormControl error fullWidth>
                          <Typography variant="formSubHeading">
                            Start date
                          </Typography>
                          <StyledTextField
                            type="date"
                            variant="outlined"
                            id="starts_at"
                            name="starts_at"
                            value={addTermForm.values.starts_at}
                            onChange={addTermForm.handleChange}
                            error={
                              addTermForm.touched.starts_at &&
                              Boolean(addTermForm.errors.starts_at)
                            }
                          />
                          <FormHelperText variant="error">
                            {addTermForm.touched.starts_at &&
                              addTermForm.errors.starts_at}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* End of third row */}

                      <Grid item xs={12} lg={6}>
                        <FormControl error fullWidth>
                          <Typography variant="formSubHeading">
                            End Date
                          </Typography>
                          <StyledTextField
                            type="date"
                            variant="outlined"
                            id="ends_at"
                            name="ends_at"
                            value={addTermForm.values.ends_at}
                            onChange={addTermForm.handleChange}
                            error={
                              addTermForm.touched.ends_at &&
                              Boolean(addTermForm.errors.ends_at)
                            }
                          />
                          <FormHelperText variant="error">
                            {addTermForm.touched.ends_at &&
                              addTermForm.errors.ends_at}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} textAlign={"right"}>
                        <StyledLoadingButton
                          loading={creatingTerm}
                          type="submit"
                        >
                          submit
                        </StyledLoadingButton>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </>
            )}
          </StyledPaper>
        </Grid>

        {/* right pane  */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            {fetchingSessions && <LinearProgress />}
            <Box className="paperHeader">
              <Typography variant="formHeading">Sessions</Typography>
            </Box>
            <TableContainer sx={{ pb: 4 }}>
              <Table sx={{ width: "100%", boxShadow: "none" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>session </StyledTableCell>
                    <StyledTableCell>start </StyledTableCell>
                    <StyledTableCell>end </StyledTableCell>
                    <StyledTableCell>edit </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sessions?.map((session) => (
                    <StyledTableRow key={session.id}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        onClick={() => setSelectedSession(session)}
                      >
                        <Box className="tableCellLink">
                          {session.name}
                          {session.id === currentSession?.id && "(current)"}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {format(parseISO(session.starts_at), "dd-MM-yyyy")}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {format(parseISO(session.ends_at), "dd-MM-yyyy")}
                      </StyledTableCell>

                      <StyledTableCell>
                        <SvgIcon
                          sx={{ color: "primary.main", cursor: "pointer" }}
                          component={EditIcon}
                          inheritViewBox
                          onClick={() => setSessionToEdit(session)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>

          <StyledPaper>
            <Box className="paperHeader">
              <Typography variant="formHeading">
                {selectedSession?.name} Terms
              </Typography>
            </Box>
            <TableContainer sx={{ pb: 4 }}>
              <Table sx={{ width: "100%", boxShadow: "none" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>term </StyledTableCell>
                    <StyledTableCell>start </StyledTableCell>
                    <StyledTableCell>end </StyledTableCell>
                    <StyledTableCell align="left">edit</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedSession?.terms?.map((row, i) => (
                    <StyledTableRow key={i}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {format(parseISO(row.starts_at), "dd-MM-yyyy")}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {format(parseISO(row.ends_at), "dd-MM-yyyy")}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <SvgIcon
                          sx={{ color: "primary.main", cursor: "pointer" }}
                          component={EditIcon}
                          inheritViewBox
                          onClick={() => setTermToEdit(row)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </Grid>
      </Grid>
    </>
  );
}

export default Session;
