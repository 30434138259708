import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { StyledPaper } from "./styled/styledPaper";

function CustomMenuList({ list, heading, activeTab, setActiveTab, icon }) {
  return (
    <>
      <StyledPaper>
        <Box sx={{ padding: "10px", textAlign: "center" }}>
          <Typography sx={{ textTransform: "capitalize" }}>
            {heading}
          </Typography>
        </Box>
        <Box
          component="ul"
          sx={{
            listStyle: "none",
            paddingBottom: 4,
            "& li": {
              borderTop: "1px solid #e0e0e0",
              padding: "8px 14px",
              color: "primary.main",
              cursor: "pointer",
              fontSize: "12px",
              textTransform: "capitalize",

              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
              "&:nth-of-type(odd)": {
                backgroundColor: "#f9f9f9",
              },
              "&.active": {
                backgroundColor: "primary.main",
                color: "white",
              },
            },
          }}
        >
          {list?.map((item, index) => (
            <Box
              key={index}
              component="li"
              className={activeTab === index && "active"}
              onClick={() => setActiveTab(index)}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box>{icon}</Box> <Box>{item}</Box>
              </Stack>
            </Box>
          ))}
        </Box>
      </StyledPaper>
    </>
  );
}

export default CustomMenuList;
