import React from "react";
import {
  Avatar,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  LinearProgress,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  StyledFileInput,
  StyledSelect,
  StyledTextField,
} from "../../customComponents/styled/styledInputs";
import {
  FilledButton,
  StyledLoadingButton,
} from "../../customComponents/styled/styledButtons";
import ChangePasswordDialog from "./ChangePasswordDialog";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from "./dashboardApiSlice";
import { useUploadFileMutation } from "../../../app/utilsApiSlice";
import { format, parseISO } from "date-fns";
import swal from "sweetalert";

const validationSchema = yup.object({
  title: yup.string().required("Required"),
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  gender: yup.string().required("Required"),
  dob: yup.string().required("Required"),
  phone: yup.string().required("Required"),
  email: yup.string().email("Enter a valid email").required("Required"),
  address: yup.string().required("Required"),
});

function EditProfile() {
  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const [fileLoading, setFileLoading] = React.useState({
    passport: false,
    signature: false,
    resume: false,
  });

  const { data: userInfo, isLoading: getProfileLoading } = useGetProfileQuery();

  const [updateProfile, { isLoading: updateProfileLoading }] =
    useUpdateProfileMutation();

  const [uploadFile] = useUploadFileMutation();

  const formik = useFormik({
    initialValues: {
      title: userInfo?.title || "Mr.",
      first_name: userInfo?.first_name || "",
      last_name: userInfo?.last_name || "",
      gender: userInfo?.gender || "",
      dob: userInfo?.dob ? format(parseISO(userInfo.dob), "yyyy-MM-dd") : "",
      phone: userInfo?.phone || "",
      email: userInfo?.email || "",
      address: userInfo?.address || "",
      passport: userInfo?.passport || "",
      signature: userInfo?.signature || "",
      resume: userInfo?.resume || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await updateProfile(values);
        console.log(response);
        if (response.status === 200) {
          swal("Success", "Profile updated successfully", "success");
        }
      } catch (error) {
        console.log(error);
        swal("Error", "Something went wrong", "error");
      }
    },
  });

  const handleFileChange = async (e) => {
    try {
      setFileLoading({ ...fileLoading, [e.target.name]: true }); // set file uploading status
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const response = await uploadFile(formData).unwrap();
      if (response) {
        setFileLoading({ ...fileLoading, [e.target.name]: false }); //setfile uploading status
        console.log(response);
        formik.setFieldValue(e.target.name, response.url);
      }
    } catch (error) {
      console.log(error);
      setFileLoading({ ...fileLoading, [e.target.name]: false }); //setfile uploading status
    }
  };

  return (
    <>
      <Grid container columnSpacing={3} rowSpacing={1}>
        {/* Dialogs */}
        <ChangePasswordDialog
          open={openChangePassword}
          setOpen={setOpenChangePassword}
        />
        <Grid item xs={12} lg={8}>
          <StyledPaper>
            {getProfileLoading && <LinearProgress />}
            <Box className="paperHeader">Edit Profile</Box>
            <Box className="paperBody">
              <form onSubmit={formik.handleSubmit}>
                <Grid container columnSpacing={1} rowSpacing={3}>
                  <Grid item xs={12} lg={2}>
                    <FormControl fullWidth>
                      <Typography variant="formSubHeading">title</Typography>
                      <StyledSelect
                        id="title"
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.title && Boolean(formik.errors.title)
                        }
                      >
                        <MenuItem value="Mr.">Mr</MenuItem>
                        <MenuItem value="Mrs.">Mrs</MenuItem>
                        <MenuItem value="Miss.">Miss</MenuItem>
                        <MenuItem value="Alhaji">Alhaji</MenuItem>
                        <MenuItem value="Hajiya">Hajiya</MenuItem>
                        <MenuItem value="Mallam">Mallam</MenuItem>
                        <MenuItem value="Mallama">Mallama</MenuItem>
                      </StyledSelect>
                      <FormHelperText variant="error">
                        {formik.touched.title && formik.errors.title}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={5}>
                    <FormControl fullWidth>
                      <Typography variant="formSubHeading">
                        first name
                      </Typography>
                      <StyledTextField
                        bold={true}
                        variant="outlined"
                        id="first_name"
                        name="first_name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.first_name &&
                          Boolean(formik.errors.first_name)
                        }
                      />
                      <FormHelperText variant="error">
                        {formik.touched.first_name && formik.errors.first_name}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={5}>
                    <FormControl fullWidth>
                      <Typography variant="formSubHeading">
                        last name
                      </Typography>
                      <StyledTextField
                        bold={true}
                        variant="outlined"
                        id="last_name"
                        name="last_name"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.last_name &&
                          Boolean(formik.errors.last_name)
                        }
                      />
                      <FormHelperText variant="error">
                        {formik.touched.last_name && formik.errors.last_name}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {/* end of first row */}
                  <Grid item xs={12} lg={4}>
                    <FormControl fullWidth>
                      <Typography variant="formSubHeading">Gender</Typography>
                      <StyledSelect
                        bold={true}
                        id="gender"
                        name="gender"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.gender && Boolean(formik.errors.gender)
                        }
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </StyledSelect>
                      <FormHelperText variant="error">
                        {formik.touched.gender && formik.errors.gender}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <FormControl fullWidth>
                      <Typography variant="formSubHeading">
                        date of birth
                      </Typography>

                      <StyledTextField
                        type="date"
                        id="dob"
                        name="dob"
                        value={formik.values.dob}
                        onChange={formik.handleChange}
                        error={formik.touched.dob && Boolean(formik.errors.dob)}
                      />

                      <FormHelperText variant="error">
                        {formik.touched.dob && formik.errors.dob}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <FormControl fullWidth>
                      <Typography variant="formSubHeading">
                        phone number
                      </Typography>
                      <StyledTextField
                        variant="outlined"
                        id="phone"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                      />
                      <FormHelperText variant="error">
                        {formik.touched.phone && formik.errors.phone}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {/* end of second row */}

                  <Grid item xs={12} lg={4}>
                    <FormControl fullWidth>
                      <Typography variant="formSubHeading">email</Typography>
                      <StyledTextField
                        variant="outlined"
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                      />
                      <FormHelperText variant="error">
                        {formik.touched.email && formik.errors.email}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={8}>
                    <FormControl fullWidth>
                      <Typography variant="formSubHeading">address</Typography>
                      <StyledTextField
                        variant="outlined"
                        id="address"
                        name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.address &&
                          Boolean(formik.errors.address)
                        }
                      />
                      <FormHelperText variant="error">
                        {formik.touched.address && formik.errors.address}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {/* end of third row */}

                  <Grid item xs={12} lg={4}>
                    <FormControl fullWidth>
                      <Typography variant="formSubHeading">passport</Typography>

                      <Stack direction="row" spacing={1}>
                        <StyledFileInput
                          type="file"
                          id="passport"
                          name="passport"
                          onChange={(e) => handleFileChange(e)}
                          error={
                            formik.touched.passport &&
                            Boolean(formik.errors.passport)
                          }
                        />
                        {fileLoading.passport && <CircularProgress />}
                      </Stack>

                      <FormHelperText variant="error">
                        {formik.touched.passport && formik.errors.passport}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControl fullWidth>
                      <Typography variant="formSubHeading">
                        signature
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <StyledFileInput
                          type="file"
                          id="signature"
                          name="signature"
                          onChange={(e) => handleFileChange(e)}
                          error={
                            formik.touched.signature &&
                            Boolean(formik.errors.signature)
                          }
                        />
                        {fileLoading.signature && <CircularProgress />}
                      </Stack>

                      <FormHelperText variant="error">
                        {formik.touched.signature && formik.errors.signature}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControl fullWidth>
                      <Typography variant="formSubHeading">
                        recent resume
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <StyledFileInput
                          type="file"
                          id="resume"
                          name="resume"
                          onChange={(e) => handleFileChange(e)}
                          error={
                            formik.touched.resume &&
                            Boolean(formik.errors.resume)
                          }
                        />
                        {fileLoading.resume && <CircularProgress />}
                      </Stack>
                      <FormHelperText variant="error">
                        {formik.touched.resume && formik.errors.resume}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {/* end of fifth row */}
                  <Grid item xs={12} md={12} textAlign={"right"}>
                    <StyledLoadingButton
                      loading={updateProfileLoading}
                      type="submit"
                    >
                      Update Profile
                    </StyledLoadingButton>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} lg={4}>
          <StyledPaper>
            <Box
              className="paperBody"
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                alt="Logo"
                src={require("../../../assets/images/schoola-school-logo.png")}
                sx={{ width: "124px", height: "124px", mb: 2 }}
              />
              <Typography variant="formHeading">Fatima bello isyaku</Typography>

              <Typography
                sx={{
                  color: "#333",
                  fontSize: "12px",
                  fontWeight: 600,
                  mb: 2,
                }}
              >
                Fatima bello isyaku
              </Typography>
            </Box>
          </StyledPaper>
          <StyledPaper>
            <FilledButton fullWidth onClick={() => setOpenChangePassword(true)}>
              Change Password
            </FilledButton>
          </StyledPaper>
        </Grid>
      </Grid>
    </>
  );
}

export default EditProfile;
