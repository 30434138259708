import { Grid, LinearProgress } from "@mui/material";
import { useState } from "react";
import CustomMenuList from "../../customComponents/CustomMenuList";
import { useGetClassesQuery } from "../classroom/classApiSlice";
import { useGetAssessmentsQuery } from "../settings/assessments/assessmentsApiSlice";
import { useGetTeacherSubjectsQuery } from "../staff/staffApiSlice";
import ScoreSheet from "./ScoreSheet";

const CAManager = () => {
  const [activeSubject, setActiveSubject] = useState(0);
  const [activeAssessment, setActiveAssessment] = useState(0);
  const { data: assessments, isLoading: loadingAssessments } =
    useGetAssessmentsQuery("all");
  const { data: mySubjects, isLoading: loadingMySubjects } =
    useGetTeacherSubjectsQuery();
  const { data: classrooms } = useGetClassesQuery("all");

  const subjectsMenu = mySubjects?.map(
    (subject) =>
      `${subject.name}: ${
        classrooms?.find((item) => subject?.classroom_id === item.id)?.name
      }`
  );

  const subSectionAssessments =
    assessments?.filter(
      (assessment) =>
        assessment.subsection_id === mySubjects?.[activeSubject]?.subsection_id
    ) || [];

  const assessmentsMenu = subSectionAssessments?.map(
    (assessment) => assessment.name
  );

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} lg={3}>
          {loadingMySubjects && <LinearProgress />}
          <CustomMenuList
            activeTab={activeSubject}
            setActiveTab={setActiveSubject}
            heading="Subject(s)"
            list={subjectsMenu || []}
            icon={
              <img
                src={require(`../../../assets/images/books.png`)}
                alt="subject"
                height={25}
                width={25}
              />
            }
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          {loadingAssessments && <LinearProgress />}
          <CustomMenuList
            activeTab={activeAssessment}
            setActiveTab={setActiveAssessment}
            heading="Assessment(s)"
            list={assessmentsMenu || []}
            icon={
              <img
                src={require(`../../../assets/images/assessment.png`)}
                alt="subject"
                height={20}
                width={20}
              />
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ScoreSheet
            mySubjects={mySubjects || []}
            activeSubject={activeSubject}
            myAssessments={subSectionAssessments || []}
            activeAssessment={activeAssessment}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CAManager;
