import { Dialog, DialogContent, Divider, Grid } from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import ClassUpdate from "./ClassUpdate";
import ClassStudents from "./ClassStudents";
import ClassSubjects from "./ClassSubjects";
import ClassManagers from "./ClassManagers";

function ClassDialog({ open, setOpen, selectedClass, setSelectedClass }) {
  const handleClose = () => {
    setOpen(false);
    setSelectedClass(null);
  };
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          maxWidth: "800px",
        },
      }}
    >
      <Box sx={{ display: "flex", padding: 2, justifyContent: "flex-end" }}>
        <CloseSharpIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
      </Box>
      <Divider />

      <DialogContent>
        {/* /<ClassInformationSheet classInfo={selectedClass} /> */}
        <ClassUpdate item={selectedClass} setItem={setSelectedClass} />
        <ClassManagers item={selectedClass} setItem={setSelectedClass} />
        <ClassSubjects item={selectedClass} setItem={setSelectedClass} />
        <ClassStudents item={selectedClass} setItem={setSelectedClass} />
      </DialogContent>
    </Dialog>
  );
}

export default ClassDialog;
