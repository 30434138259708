import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ChromePicker } from "react-color";

const CustomColorPicker = ({ open, setOpen, value, setValue }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">Pick a color</DialogTitle>
      <DialogContent>
        <ChromePicker disableAlpha color={value} onChangeComplete={setValue} />
      </DialogContent>
    </Dialog>
  );
};

export default CustomColorPicker;
