import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  LinearProgress,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FilledButton } from "../../customComponents/styled/styledButtons";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CachedIcon from "@mui/icons-material/Cached";
import { StyledTextField } from "../../customComponents/styled/styledInputs";
import { useGetClassesQuery } from "../classroom/classApiSlice";
import swal from "sweetalert";
import {
  useCreateRecordMutation,
  useDeleteRecordsBySubjectClassAndAssessmentMutation,
  useGetRecordsBySubjectAndClassQuery,
} from "../quick-entry/recordApiSlice";
import CustomWarningDialog from "../../customComponents/CustomWarningDialog";

function ScoreSheet({
  myAssessments,
  activeAssessment,
  mySubjects,
  activeSubject,
}) {
  const [scoresheet, setScoresheet] = useState([]);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);

  const { data: students, isLoadingStudents } = useGetClassesQuery(
    `${mySubjects[activeSubject]?.classroom_id}/students`
  );

  const { data: records, isLoading: isLoadingRecords } =
    useGetRecordsBySubjectAndClassQuery({
      subject_id: mySubjects[activeSubject]?.id,
      classroom_id: mySubjects[activeSubject]?.classroom_id,
    });

  const [createRecord, { isLoading: createRecordLoading }] =
    useCreateRecordMutation();
  const [deleteRecord, { isLoading: deleteRecordLoading }] =
    useDeleteRecordsBySubjectClassAndAssessmentMutation();

  useEffect(() => {
    const assessmentRecords = records?.filter(
      (item) => item.assessment.id === myAssessments[activeAssessment]?.id
    );

    setScoresheet(
      assessmentRecords?.map((item) => ({
        student_id: item.student_id.toString(),
        score: item.score,
        assessment_id: item.assessment_id,
      }))
    );
  }, [records, myAssessments, activeAssessment]);

  const handleChange = (event) => {
    let { name: student_id, value } = event.target;
    const obtainable = myAssessments[activeAssessment]?.obtainable_score;

    if (value > obtainable) {
      swal({
        title: "Invalid Score",
        text: `The score you entered is greater than the obtainable score of ${obtainable}`,
        icon: "error",
      });
      value = obtainable;
    }

    setScoresheet((prevState) => {
      const newScoreSheet = prevState
        .map((item) => {
          if (item.student_id === student_id) {
            return { ...item, score: value };
          }
          return item;
        })
        .filter((item) => item.student_id !== student_id);
      return [
        ...newScoreSheet,
        {
          student_id,
          score: parseInt(value),
          assessment_id: myAssessments[activeAssessment]?.id,
        },
      ];
    });
  };

  const handleSave = async () => {
    try {
      const body = {
        subject_id: mySubjects[activeSubject]?.id,
        classroom_id: mySubjects[activeSubject]?.classroom_id,
        records: scoresheet,
      };
      const response = await createRecord(body).unwrap();
      if (response) {
        swal({
          title: "Success",
          text: "Score sheet saved successfully",
          icon: "success",
        });
      }
    } catch (error) {
      console.log(error);
      swal({
        title: "Error",
        text: "An error occured while saving the score sheet",
        icon: "error",
      });
    }
  };

  const handleDelete = async () => {
    try {
      const values = {
        subjectId: mySubjects[activeSubject]?.id,
        classId: mySubjects[activeSubject]?.classroom_id,
        assessmentId: myAssessments[activeAssessment]?.id,
      };
      console.log("delete", values);
      const response = await deleteRecord(values).unwrap();
      console.log(response);
      if (response) {
        swal("Success", "record deleted successfully", "success");
        setScoresheet([]);
      }
    } catch (error) {
      console.log(error);
      swal("Error", "Error deleting score sheet", "error");
    }
  };

  return (
    <Grid container spacing={3}>
      <CustomWarningDialog
        open={openWarningDialog}
        setOpen={setOpenWarningDialog}
        action={handleDelete}
        message="!!!WARNING!!! Irreversable Action!! are sure you want to clear these record?"
      />
      <Grid item xs={12} md={11}>
        <StyledPaper>
          <Box className="paperHeader">
            <Typography
              variant="formHeading"
              sx={{
                textAlign: "center",
                color: "primary.main",
                textTransform: "capitalize",
              }}
            >
              {myAssessments[activeAssessment]?.name} score sheet
            </Typography>
          </Box>
          <Box className="paperBody">
            {isLoadingStudents && <LinearProgress />}
            <Grid container spacing={2}>
              {students?.map((student) => (
                <React.Fragment key={student.id}>
                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                      <StyledTextField
                        required
                        id="name"
                        name="name"
                        value={student.first_name + " " + student.last_name}
                        bold="true"
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                      <StyledTextField
                        required
                        id="student_id"
                        name="student_id"
                        value={student.id}
                        bold="true"
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                      <Typography variant="formSubHeading">
                        Obtainable
                      </Typography>
                      <StyledTextField
                        required
                        type="number"
                        id="obtainable"
                        name="obtainable"
                        bold="true"
                        disabled
                        value={
                          myAssessments[activeAssessment]?.obtainable_score
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                      <Typography variant="formSubHeading">Obtained</Typography>
                      <StyledTextField
                        required
                        type="number"
                        id="obtained"
                        name={student.id}
                        bold="true"
                        onChange={handleChange}
                        value={
                          scoresheet?.find(
                            (item) => parseInt(item.student_id) === student.id
                          )?.score ?? 0
                        }
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </StyledPaper>
      </Grid>
      <Grid item xs={12} md={1}>
        <Stack
          direction={{ xs: "row", md: "column" }}
          spacing={1}
          sx={{
            "& .btn-icon": {
              fontSize: "14px",
            },
          }}
        >
          {createRecordLoading ? (
            <CircularProgress size={28} />
          ) : (
            <Tooltip placement="left" title="save">
              <FilledButton>
                <SaveIcon className="btn-icon" onClick={handleSave} />
              </FilledButton>
            </Tooltip>
          )}
          <Tooltip placement="left" title="Add">
            <FilledButton>
              <AddIcon className="btn-icon" />
            </FilledButton>
          </Tooltip>
          {deleteRecordLoading ? (
            <CircularProgress size={28} />
          ) : (
            <Tooltip placement="left" title="Clear records">
              <FilledButton onClick={() => setOpenWarningDialog(true)}>
                <DeleteIcon className="btn-icon" />
              </FilledButton>
            </Tooltip>
          )}
          <Tooltip placement="left" title="Edit">
            <FilledButton>
              <SvgIcon
                component={EditIcon}
                className="btn-icon"
                inheritViewBox
              />
            </FilledButton>
          </Tooltip>
          <Tooltip placement="left" title="Refresh">
            <FilledButton>
              <CachedIcon className="btn-icon" />
            </FilledButton>
          </Tooltip>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ScoreSheet;
