import { Grid } from "@mui/material";
import { useState } from "react";
import CustomMenuList from "../../customComponents/CustomMenuList";
import { useGetClassesQuery } from "../classroom/classApiSlice";
import { useGetTeacherSubjectsQuery } from "../staff/staffApiSlice";
import ScoreSheet from "./ScoreSheet";

const QuickEntry = () => {
  const [activeSubject, setActiveSubject] = useState(0);
  const { data: mySubjects } = useGetTeacherSubjectsQuery();
  const { data: classrooms } = useGetClassesQuery("all");

  const subjectsMenu = mySubjects?.map(
    (subject) =>
      `${subject.name}: ${
        classrooms?.find((item) => subject.classroom_id === item.id)?.name
      }`
  );

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} lg={3}>
          <CustomMenuList
            activeTab={activeSubject}
            setActiveTab={setActiveSubject}
            heading="CA Subject(s)"
            list={subjectsMenu}
            icon={
              <img
                src={require(`../../../assets/images/books.png`)}
                alt="subject"
                height={25}
                width={25}
              />
            }
          />
        </Grid>
        <Grid item xs={12} lg={9}>
          <ScoreSheet
            subjects={mySubjects || []}
            activeSubject={activeSubject}
            classrooms={classrooms || []}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default QuickEntry;
