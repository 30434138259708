import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { StyledTextField } from "../../customComponents/styled/styledInputs";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  FilledButton,
  StyledLoadingButton,
} from "../../customComponents/styled/styledButtons";
import { useUpdateSubjectMutation } from "./subjectsApiSlice";
import swal from "sweetalert";

const validationSchema = yup.object({
  name: yup.string().required("Subject name is required"),
  description: yup.string().required("Required"),
});

function EditSubjectForm({ focusedSubject, setOpenEdit, setFocusedSubject }) {
  const [updateSubject, { isLoading }] = useUpdateSubjectMutation();

  const formik = useFormik({
    initialValues: {
      id: focusedSubject.id,
      name: focusedSubject.name,
      description: focusedSubject.description,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await updateSubject(values).unwrap();
        if (response.id) {
          swal("Success", "Subject updated successfully", "success");
        } else {
          swal("Error", "Subject not updated", "error");
        }
      } catch (error) {
        console.log(error);
        swal("Error", "Something went wrong", "error");
      }
    },
  });

  return (
    <>
      <Box
        className="paperHeader"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="formHeading">Edit Subject</Typography>
        <FilledButton
          onClick={() => {
            setFocusedSubject(null);
            setOpenEdit(false);
          }}
        >
          New Subject
        </FilledButton>
      </Box>
      <Box className="paperBody">
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Grid container columnSpacing={1} rowSpacing={3}>
            <Grid item xs={12}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">Subject Name</Typography>
                <StyledTextField
                  autoFocus
                  variant="outlined"
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                />
                <FormHelperText variant="error">
                  {formik.touched.name && formik.errors.name}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* beggining second row inputs */}

            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Typography variant="formSubHeading">description</Typography>
                <StyledTextField
                  autoFocus
                  variant="outlined"
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                />
                <FormHelperText variant="error">
                  {formik.touched.description && formik.errors.description}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} textAlign={"right"}>
              <StyledLoadingButton loading={isLoading} type="submit">
                Update
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default EditSubjectForm;
