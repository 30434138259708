import { Typography, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { React, useState } from "react";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import StaffTable from "./StaffTable";
import EditStaffDialog from "./EditStaffDialog";
import { useGetTeacherQuery, useDeleteTeacherMutation } from "./staffApiSlice";
import CustomWarningDialog from "../../customComponents/CustomWarningDialog";
import swal from "sweetalert";
import { useGetSchoolProfileQuery } from "../settings/school/schoolApiSlice";

function StaffList() {
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);

  const [deleteTeacher] = useDeleteTeacherMutation();
  const { data: schoolInfo } = useGetSchoolProfileQuery();

  const {
    data: teachers,
    isLoading: getTeachersLoading,
    error: getTeachersError,
  } = useGetTeacherQuery("all");

  const handleDelete = async (item) => {
    try {
      const response = await deleteTeacher(item).unwrap();
      if (response) {
        swal("Success", "Teacher deleted successfully", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (getTeachersError) {
    swal("Error", getTeachersError.message || "something went wrong", "error");
  }

  return (
    <>
      <StyledPaper>
        <CustomWarningDialog
          open={openWarningDialog}
          setOpen={setOpenWarningDialog}
          item={selectedStaff}
          action={handleDelete}
          message="Are you sure you want to delete this teacher?"
        />
        <EditStaffDialog
          open={openEdit}
          setOpen={setOpenEdit}
          selectedStaff={selectedStaff}
        />

        <Box className="paperHeader">
          <Typography variant="formHeading">Teachers</Typography>
          <Typography variant="formSubHeading" sx={{ textTransform: "none" }}>
            Click on a staff to view and modify their information
          </Typography>
        </Box>
        {getTeachersLoading && <LinearProgress />}
        <StaffTable
          schoolInfo={schoolInfo}
          rows={teachers}
          setSelectedStaff={setSelectedStaff}
          setOpenEdit={setOpenEdit}
          setOpenWarningDialog={setOpenWarningDialog}
        />
      </StyledPaper>
    </>
  );
}

export default StaffList;
