import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { StyledLoadingButton } from "../../../customComponents/styled/styledButtons";
import {
  StyledSelect,
  StyledTextField,
} from "../../../customComponents/styled/styledInputs";
import { useCreateBehaviorMutation, useGetTraitQuery } from "./traitsApiSlice";
import swal from "sweetalert";

function CreateBehaviorForm() {
  const { data: traits } = useGetTraitQuery("all");
  const [createBehavior, { isLoading: createBehaviorLoading }] =
    useCreateBehaviorMutation();

  const Formik = useFormik({
    initialValues: {
      name: "",
      trait_id: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("Required"),
      trait_id: yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await createBehavior(values).unwrap();
        if (response) {
          swal("Success", "Behavior created successfully", "success");
          Formik.resetForm();
        } else {
          swal("Error", "Behavior creation failed", "error");
        }
      } catch (error) {
        swal("Error", "Something went wrong", "error");
      }
    },
  });
  return (
    <>
      <Box className="paperHeader">
        <Typography variant="formHeading">Create Behavior</Typography>
      </Box>
      <Box className="paperBody">
        <form onSubmit={Formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">Behavior</Typography>
                <StyledTextField
                  autoFocus
                  variant="outlined"
                  id="name"
                  name="name"
                  value={Formik.values.name}
                  onChange={Formik.handleChange}
                  error={Formik.touched.name && Boolean(Formik.errors.name)}
                />
                <FormHelperText variant="error">
                  {Formik.touched.name && Formik.errors.name}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography variant="formSubHeading">trait</Typography>
                <StyledSelect
                  id="trait_id"
                  name="trait_id"
                  value={Formik.values.trait_id}
                  onChange={Formik.handleChange}
                  error={
                    Formik.touched.trait_id && Boolean(Formik.errors.trait_id)
                  }
                >
                  <MenuItem value="" disabled>
                    select subsection
                  </MenuItem>
                  {traits?.map((trait) => (
                    <MenuItem value={trait.id} key={trait.id}>
                      {trait.name}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <FormHelperText variant="error">
                  {Formik.touched.trait_id && Formik.errors.trait_id}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} textAlign={"right"}>
              <StyledLoadingButton
                loading={createBehaviorLoading}
                type="submit"
              >
                Submit
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default CreateBehaviorForm;
