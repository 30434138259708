import {
  Typography,
  Box,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  FormHelperText,
  MenuItem,
  FormControl,
  Grid,
  SvgIcon,
  CircularProgress,
} from "@mui/material";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
import { StyledSelect } from "../../customComponents/styled/styledInputs";
import * as yup from "yup";
import { useFormik } from "formik";
import { useGetSubjectsQuery } from "../subjects/subjectsApiSlice";
import { useGetTeacherQuery } from "../staff/staffApiSlice";
import {
  useAddSubjectToClassroomMutation,
  useGetClassesQuery,
  useRemoveSubjectFromClassroomMutation,
  useReplaceSubjectTeacherMutation,
} from "./classApiSlice";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import CustomWarningDialog from "../../customComponents/CustomWarningDialog";

function ClassSubjects({ item, setItem }) {
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [focusedSubject, setFocusedSubject] = useState(null);
  const { data: teachers } = useGetTeacherQuery("all");
  const { data: subjects } = useGetSubjectsQuery("all");
  const { data: classrooms } = useGetClassesQuery("all"); //to get updated list of classrooms

  useEffect(() => {
    //set the selected class item to the updated list of classrooms item to reflect the changes in the modal
    const updatedClassroom = classrooms.find((c) => c.id === item.id);
    setItem(updatedClassroom);
  }, [classrooms]);

  const [addSubjectToClass, { isLoading: addSubjectToClassLoading }] =
    useAddSubjectToClassroomMutation();

  const [replaceSubjectTeacher, { isLoading: replaceSubjectTeacherLoading }] =
    useReplaceSubjectTeacherMutation();

  const [
    removeSubjectFromClassroom,
    { isLoading: removeSubjectFromClassroomLoading },
  ] = useRemoveSubjectFromClassroomMutation();

  const classForm = useFormik({
    initialValues: {
      teacher: focusedSubject?.teacher_id || "",
      subject: focusedSubject?.id || "",
    },
    validationSchema: yup.object({
      teacher: yup.string().required("Required"),
      subject: yup.string().required("Required"),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      const body = {
        class_Id: item.id,
        teacher_id: values.teacher,
        subject_id: values.subject,
      };
      if (focusedSubject) {
        handleReplaceSubjectTeacher(body);
      } else {
        handleAddSubjectToClass(body);
      }
    },
  });

  const handleAddSubjectToClass = async (body) => {
    try {
      const response = await addSubjectToClass(body).unwrap();
      if (response.message) {
        classForm.resetForm();
        swal("Success", "Subject added successfully", "success");
      }
    } catch (error) {
      console.log(error);
      swal("Error", "Something went wrong", "error");
    }
  };

  const handleReplaceSubjectTeacher = async (body) => {
    try {
      const response = await replaceSubjectTeacher(body).unwrap();
      if (response.message) {
        classForm.resetForm();
        swal("Success", "Teacher Replaced successfully", "success");
      }
    } catch (error) {
      console.log(error);
      swal("Error", "Something went wrong", "error");
    }
  };

  const handleRemoveSubject = async () => {
    const body = {
      class_Id: item.id,
      subject_Id: focusedSubject.id,
    };
    try {
      const response = await removeSubjectFromClassroom(body).unwrap();
      console.log(response);
      if (response.message) {
        swal("Success", response.message, "success");
      }
    } catch (error) {
      console.log(error);
      swal("Error", "Something went wrong", "error");
    }
  };

  console.log("focusedSubject", focusedSubject);

  return (
    <StyledPaper>
      {/* modals */}
      <CustomWarningDialog
        open={openWarningDialog}
        setOpen={setOpenWarningDialog}
        item={focusedSubject}
        action={handleRemoveSubject}
        message="!!!WARNING!!! This will remove the subject from the class. Are you sure you want to continue?"
      />
      <Box className="paperHeader">
        <Typography variant="paperHeading">
          Current Subjects In Class
        </Typography>
      </Box>
      <Box className="paperBody">
        <form onSubmit={classForm.handleSubmit}>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item xs="auto">
              <FormControl fullWidth>
                <Typography variant="formSubHeading">subject</Typography>
                <StyledSelect
                  displayEmpty
                  id="subject"
                  name="subject"
                  value={classForm.values.subject}
                  onChange={classForm.handleChange}
                  disabled={!!focusedSubject}
                  error={
                    classForm.touched.subject &&
                    Boolean(classForm.errors.subject)
                  }
                >
                  <MenuItem value="" disabled>
                    <em>select subject</em>
                  </MenuItem>
                  {subjects?.map((subject) => (
                    <MenuItem key={subject.id} value={subject.id}>
                      {subject.name}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <FormHelperText variant="error">
                  {classForm.touched.subject && classForm.errors.subject}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs="auto">
              <FormControl fullWidth>
                <Typography variant="formSubHeading">Teacher</Typography>
                <StyledSelect
                  displayEmpty
                  id="teacher"
                  name="teacher"
                  value={classForm.values.teacher}
                  onChange={classForm.handleChange}
                  error={
                    classForm.touched.teacher &&
                    Boolean(classForm.errors.teacher)
                  }
                >
                  <MenuItem value="" disabled>
                    <em>select teacher</em>
                  </MenuItem>
                  {teachers?.map((teacher) => (
                    <MenuItem key={teacher.user_id} value={teacher.user_id}>
                      {teacher.first_name} {teacher.last_name}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <FormHelperText variant="error">
                  {classForm.touched.teacher && classForm.errors.teacher}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid
              item
              xs="auto"
              sx={{
                display: "flex",
                alignItems: "flex-end",
                mb: 0.5,
              }}
            >
              <StyledLoadingButton
                loading={addSubjectToClassLoading}
                type="submit"
              >
                {focusedSubject ? "Save" : "Add"}
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
      <TableContainer
        sx={{ pb: 4 }}
        onClick={() => {
          setFocusedSubject(null);
        }}
      >
        <Table sx={{ width: "100%", boxShadow: "none" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Subject </StyledTableCell>
              <StyledTableCell align="right">edit</StyledTableCell>
              <StyledTableCell align="right">remove</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {item?.subjects?.map((subject) => (
              <StyledTableRow key={subject.id}>
                <StyledTableCell component="th" scope="row">
                  {subject.name}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <SvgIcon
                    className="tableCellLink"
                    component={EditIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setFocusedSubject(subject);
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  {removeSubjectFromClassroomLoading &&
                  focusedSubject === subject ? (
                    <CircularProgress size={20} />
                  ) : (
                    <DeleteIcon
                      className="tableCellLink"
                      onClick={() => {
                        setFocusedSubject(subject);
                        setOpenWarningDialog(true);
                      }}
                    />
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledPaper>
  );
}

export default ClassSubjects;
