import {
  Grid,
  Stack,
  Box,
  Typography,
  FormControl,
  MenuItem,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import React, { useEffect } from "react";
import { FilledButton } from "../../customComponents/styled/styledButtons";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import {
  StyledSelect,
  StyledTextArea,
  StyledTextField,
} from "../../customComponents/styled/styledInputs";
import { useGetClassesQuery } from "../classroom/classApiSlice";
import {
  useGetCurrentTermQuery,
  useGetSessionQuery,
} from "../settings/session/sessionApiSlice";
import { useGetAssessmentsQuery } from "../settings/assessments/assessmentsApiSlice";
import { useGetSchoolProfileQuery } from "../settings/school/schoolApiSlice";
import swal from "sweetalert";
import {
  useCreateCommentMutation,
  useCreateRecordMutation,
  useDeleteRecordsBySubjectAndClassMutation,
  useGetCommentBySubjectAndClassroomQuery,
  useGetRecordsBySubjectAndClassQuery,
} from "./recordApiSlice";
import CustomWarningDialog from "../../customComponents/CustomWarningDialog";
import { useHotkeys } from "react-hotkeys-hook";

function ScoreSheet({ subjects, activeSubject, classrooms }) {
  const [scoreSheet, setScoreSheet] = React.useState([]);
  const [commentSheet, setCommentSheet] = React.useState([]);
  const [openWarningDialog, setOpenWarningDialog] = React.useState(false);
  useHotkeys("ctrl+s", (e) => {
    e.preventDefault();
    handleSave();
  });

  const { data: students, isLoading: isLoadingStudents } = useGetClassesQuery(
    `${subjects[activeSubject]?.classroom_id}/students`
  );
  const { data: session } = useGetSessionQuery("current");
  const { data: term } = useGetCurrentTermQuery();
  const { data: assessments } = useGetAssessmentsQuery("all");
  const { data: schoolInfo } = useGetSchoolProfileQuery();
  const { data: records, isLoading: isLoadingRecords } =
    useGetRecordsBySubjectAndClassQuery({
      subject_id: subjects[activeSubject]?.id,
      classroom_id: subjects[activeSubject]?.classroom_id,
    });
  const { data: comments } = useGetCommentBySubjectAndClassroomQuery(
    `${subjects[activeSubject]?.id}/${subjects[activeSubject]?.classroom_id}`
  );

  const [createRecord, { isLoading: createRecordLoading }] =
    useCreateRecordMutation();
  const [createComment, { isLoading: createCommentLoading }] =
    useCreateCommentMutation();
  const [deleteRecord, { isLoading: deleteRecordLoading }] =
    useDeleteRecordsBySubjectAndClassMutation();

  const myAssessments = assessments?.filter(
    (assessment) =>
      assessment.subsection_id === subjects[activeSubject]?.subsection_id
  );
  const [offeringStatus, setOfferingStatus] = React.useState([]);

  // Initialize offering status when students are loaded
  useEffect(() => {
    if (students) {
      setOfferingStatus(
        students.map((student) => ({
          student_id: student.id,
          offering: 1, // Default value for offering
        }))
      );
    }
  }, [students]);

  // Handle the offering status change for a specific student
  const handleOfferingChange = (event, studentId) => {
    const value = event.target.value;
    setOfferingStatus((prevStatus) =>
      prevStatus.map((status) =>
        status.student_id === studentId
          ? { ...status, offering: value }
          : status
      )
    );
  };
  const handleMarkAllOffering = () => {
    setOfferingStatus((prevStatus) =>
      prevStatus.map((status) => ({
        ...status,
        offering: 1, // Set all students to offering
      }))
    );
  };

  useEffect(() => {
    //set score sheet to records gotten from db
    if (records) {
      setScoreSheet(
        records?.map((item) => ({
          student_id: item.student_id.toString(),
          score: item.score,
          assessment_id: item.assessment.id.toString(),
        }))
      );
    }

    if (comments) {
      setCommentSheet(
        comments?.map((item) => ({
          student_id: item.student_id,
          title: item.title || "",
          content: item.content || "",
        }))
      );
    }
  }, [records, comments]);

  useEffect(()=>{
    const handleKeyDown = (e) =>{
      if((e.ctrlKey && e.key === 's') || (e.metaKey && e.key ==="s")){
        e.preventDefault();
        handleSave()
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return ()=>{
      window.removeEventListener("keydown", handleKeyDown)
    }

  },[])

  const handleChange = (event) => {
    let { name: assessment, id: student, value } = event.target;

    //get the obtainable score for validation
    const obtainable = myAssessments?.find(
      (item) => parseInt(item.id) === parseInt(assessment)
    ).obtainable_score;

    if (value > obtainable) {
      // validation
      swal(
        "Invalid Score",
        "Score cannot be greater than obtainable score",
        "error"
      );
      value = obtainable;
    }

    if (value === "") {
      value = 0;
    }

    // insert or update existing score sheet entry
    setScoreSheet((prevState) => {
      const newScoreSheet = prevState
        ?.map((item) => {
          if (
            item.student_id === parseInt(student) &&
            item.assessment_id === assessment
          ) {
            return { ...item, score: value };
          } else {
            return item;
          }
        })
        .filter(
          (item) =>
            item.student_id !== student || item.assessment_id !== assessment
        );
      return [
        ...newScoreSheet,
        {
          student_id: student,
          score: typeof value === "string" ? parseInt(value) : value,
          assessment_id: assessment,
        },
      ];
    });
  };

  const handleCommentChange = (event) => {
    let { id: student, value } = event.target;
    setCommentSheet((prevState) => {
      const newCommentSheet = prevState
        .map((item) => {
          if (item.student_id === parseInt(student)) {
            return { ...item, content: value };
          } else {
            return item;
          }
        })
        .filter((item) => item.student_id !== parseInt(student));
      return [
        ...newCommentSheet,
        {
          student_id: parseInt(student),
          title: "",
          content: value,
        },
      ];
    });
  };

  const handleSave = async () => {
    const recordBody = {
      subject_id: subjects[activeSubject]?.id,
      classroom_id: subjects[activeSubject]?.classroom_id,
      records: scoreSheet,
    };
    const commentBody = {
      subject_id: subjects[activeSubject]?.id,
      classroom_id: subjects[activeSubject]?.classroom_id,
      comments: commentSheet,
    };
    try {
      const response = await createRecord(recordBody).unwrap();
      if (response) {
        const createCommentResponse = await createComment(commentBody).unwrap();
        if (createCommentResponse) {
          swal("Success", "Comment saved", "success");
        } else {
          swal("Error", "Error Saving Comment", "error");
        }
      }
    } catch (error) {
      swal("Error", "Error saving score sheet", "error");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deleteRecord({
        classId: subjects[activeSubject]?.classroom_id,
        subjectId: subjects[activeSubject]?.id,
      }).unwrap();
      if (response) {
        swal("Success", response.message, "success");
        setScoreSheet([]);
      }
    } catch (error) {
      swal("Error", "Error deleting score sheet", "error");
    }
  };

  return (
    <Grid container columnSpacing={3}>
      {/* delete warning */}
      <CustomWarningDialog
        open={openWarningDialog}
        setOpen={setOpenWarningDialog}
        action={handleDelete}
        message="!!!WARNING!!! Irreversable Action!! are sure you want to clear these record?"
      />
      <Grid item xs={12} lg={11}>
        <StyledPaper>
          {isLoadingStudents && <LinearProgress />}
          {isLoadingRecords && <LinearProgress />}
          <Box className="paperBody" sx={{ textAlign: "center" }}>
            <Typography variant="formHeading" color="primary.main">
              {schoolInfo?.name} this component
            </Typography>
            <Typography variant="formSubHeading">
              <b>Session:</b>
              {session?.name}|<b>Term:</b> {term?.name} |<b>Class:</b>
              {
                classrooms?.find(
                  (item) => item.id === subjects[activeSubject]?.classroom_id
                )?.name
              }
              |<b>Subject:</b> {subjects[activeSubject]?.name}
            </Typography>
            <Grid container spacing={2}>
              {students?.map((student) => (
                <>
                  <Grid item xs={12} lg={3}>
                    <FormControl fullWidth>
                      <StyledSelect
                        required
                        id="offering"
                        name="offering"
                        value={
                          offeringStatus.find(
                            (status) => status.student_id === student.id
                          )?.offering || 1 // Default to 1 (offering) if no value is found
                        }
                        onChange={(event) => handleOfferingChange(event, student.id)}
                        displayEmpty
                        
                      >
                        <MenuItem value={1}>offering</MenuItem>
                        <MenuItem value={2}>not offering</MenuItem>
                      </StyledSelect>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <FormControl fullWidth>
                      <StyledTextField
                        required
                        value={student.first_name + " " + student.last_name}
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControl fullWidth>
                      <StyledTextField required disabled value={student.id} />
                    </FormControl>
                  </Grid>
                  {/* first row */}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      columnGap: "4px",
                    }}
                  >
                    {/* the container that houses the assessments */}
                    {myAssessments?.map((assessment) => (
                      <FormControl fullWidth>
                        <Typography variant="formSubHeading">
                          {assessment.name + `(${assessment.obtainable_score})`}
                        </Typography>
                        <StyledTextField
                          required
                          type="number"
                          id={student.id}
                          name={assessment.id}
                          value={
                            scoreSheet?.find(
                              (item) =>
                                parseInt(item.student_id) ===
                                  parseInt(student.id) &&
                                parseInt(item.assessment_id) ===
                                  parseInt(assessment.id)
                            )?.score ?? 0
                          }
                          onChange={handleChange}
                        />
                      </FormControl>
                    ))}
                    <FormControl fullWidth>
                      <Typography variant="formSubHeading">total</Typography>
                      <StyledTextField
                        value={
                          scoreSheet
                            ?.filter(
                              (item) =>
                                parseInt(item.student_id) ===
                                parseInt(student.id)
                            )
                            .reduce(
                              (acc, curr) => acc + parseInt(curr.score),
                              0
                            ) ?? 0
                        }
                        disabled
                        name="total"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <StyledTextArea
                        id={student.id}
                        name="comment"
                        onChange={handleCommentChange}
                        value={
                          commentSheet?.find(
                            (item) =>
                              parseInt(item.student_id) === parseInt(student.id)
                          )?.content || ""
                        }
                        minRows={2}
                      />
                    </FormControl>
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        </StyledPaper>
      </Grid>
      <Grid item xs={12} lg={1}>
        <Stack
          direction={{ xs: "row", md: "column" }}
          spacing={1}
          sx={{
            "& .btn-icon": {
              fontSize: "14px",
            },
          }}
        >
          {createRecordLoading || createCommentLoading ? (
            <CircularProgress size={28} />
          ) : (
            <FilledButton className="btn-icon" onClick={handleSave}>
              <SaveIcon />
            </FilledButton>
          )}
          {deleteRecordLoading ? (
            <CircularProgress size={28} />
          ) : (
            <FilledButton
              className="btn-icon"
              onClick={() => setOpenWarningDialog(true)}
            >
              <DeleteIcon />
            </FilledButton>
          )}
          <FilledButton
            variant="contained"
            sx={{ backgroundColor: "#449D44", borderRadius: "40%" }}
            onClick={handleMarkAllOffering}
          >
            <DoneIcon className="btn-icon" />
          </FilledButton>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ScoreSheet;
