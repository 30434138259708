import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Fees"],
});


const optionsApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getOptions: builder.query({
      query: (parameter) => `/fees/split-option/${parameter}`,
      providesTags: ["Fees"],
    }),
    createOption: builder.mutation({
      query: (values) => ({
        url: "/fees/split-option",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Fees"],
    }),
    deleteOption: builder.mutation({
      query: (parameter) => ({
        url: `/fees/split-option/${parameter.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Fees"],
    }),
  }),
});

export const {
  useGetOptionsQuery,
  useCreateOptionMutation,
  useDeleteOptionMutation,
  
} = optionsApiSlice;
