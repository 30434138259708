import { FormControl, FormHelperText, MenuItem, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";

import { StyledTextField } from "../../../customComponents/styled/styledInputs";
import { StyledSelect } from "../../../customComponents/styled/styledInputs"; // Corrected path
import { StyledLoadingButton } from "../../../customComponents/styled/styledButtons";
import { useCreateSectionMutation } from "./sectionsApiSlice";
import { useGetTeacherQuery } from "../../staff/staffApiSlice";
import swal from "sweetalert";


const sectionFormValidation = yup.object({
  name: yup.string().required("Required"),
  head_title: yup.string().required("Required"),
  head_id: yup.string().required("Required"),
});

function AddSectionForm() {
  const [createSection, { isLoading: creatingSession }] = useCreateSectionMutation();
  const { data: teachers } = useGetTeacherQuery("all");

  const sectionForm = useFormik({
    initialValues: {
      name: "",
      head_title: "",
      head_id: 0,
    },
    enableReinitialize: true,
    validationSchema: sectionFormValidation,
    onSubmit: async (values) => {
      console.log(values);
      
      try {
        const response = await createSection(values).unwrap();
        if (response.id) {
          swal("Success", "Section created successfully", "success");
          sectionForm.resetForm();
        } else {
          swal("Error", "Section creation failed", "error");
        }
      } catch (error) {
        swal("Error", "Something went wrong", "error");
      }
    },
  });

  return (
    <>
      <Box className="paperHeader">
        <Typography variant="formHeading">Setup Sections</Typography>
      </Box>
      <Box className="paperBody">
        <form onSubmit={sectionForm.handleSubmit}>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">Section Name</Typography>
                <StyledTextField
                  variant="outlined"
                  id="name"
                  name="name"
                  value={sectionForm.values.name}
                  onChange={sectionForm.handleChange}
                  error={
                    sectionForm.touched.name && Boolean(sectionForm.errors.name)
                  }
                />
                <FormHelperText variant="error">
                  {sectionForm.touched.name && sectionForm.errors.name}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">
                  HEAD'S TITLE (E.G: HEAD TEACHER)
                </Typography>
                <StyledTextField
                  variant="outlined"
                  id="head_title"
                  name="head_title"
                  value={sectionForm.values.head_title}
                  onChange={sectionForm.handleChange}
                  error={
                    sectionForm.touched.head_title &&
                    Boolean(sectionForm.errors.head_title)
                  }
                />
                <FormHelperText variant="error">
                  {sectionForm.touched.head_title &&
                    sectionForm.errors.head_title}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Typography htmlFor="head_id" variant="formSubHeading">
                  SELECT STAFF
                </Typography>
                <StyledSelect
                  displayEmpty
                  id="head_id"
                  name="head_id"
                  value={sectionForm.values.head_id}
                  onChange={sectionForm.handleChange}
                >
                  <MenuItem value="" disabled>
                    <em>select staff</em>
                  </MenuItem>

                  {teachers?.map((teacher, i) => (
                    <MenuItem value={teacher?.user_id} key={i}>
                      {teacher?.first_name} {teacher?.last_name}
                    </MenuItem>
                  ))}
                </StyledSelect>
                
              </FormControl>
            </Grid>
            <Grid item xs={12} textAlign={"right"}>
              <StyledLoadingButton loading={creatingSession} type="submit">
                submit
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default AddSectionForm;

