import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Fees"],
});


const paymentSummaryApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentSummary: builder.query({
      query: () => `/fees/summary`,
      providesTags: ["Fees"],
    }),
  }),
});

export const {
  useGetPaymentSummaryQuery,
  
} = paymentSummaryApiSlice;
