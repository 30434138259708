import { Box } from "@mui/system";
import React from "react";
import { FilledButton } from "../../customComponents/styled/styledButtons";
import Grades from "./grades/Grades";
import Preferences from "./preferences/Preferences";
import School from "./school/School";
import Assessments from "./assessments/Assessments";
import Sections from "./sections/Sections";
import Session from "./session/Session";
import Templetes from "./templates/Templetes";
import Traits from "./traits/Traits";
import Comments from "./comments/Comments";

function Settings() {
  const [activeTab, setActiveTab] = React.useState("school");

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          columnGap: 0.5,
          rowGap: 0.5,
          flexWrap: "wrap",
          mb: 1,
        }}
        id="tabs"
      >
        <FilledButton
          sx={{ textTransform: "none" }}
          className={activeTab === "school" && "activeTab"}
          onClick={() => setActiveTab("school")}
        >
          School
        </FilledButton>
        <FilledButton
          sx={{ textTransform: "none" }}
          className={activeTab === "session" && "activeTab"}
          onClick={() => setActiveTab("session")}
        >
          Session
        </FilledButton>
        <FilledButton
          sx={{ textTransform: "none" }}
          className={activeTab === "sections" && "activeTab"}
          onClick={() => setActiveTab("sections")}
        >
          Sections
        </FilledButton>
        {/* <FilledButton
          sx={{ textTransform: "none" }}
          className={activeTab === "departments" && "activeTab"}
          onClick={() => setActiveTab("departments")}
        >
          Departments
        </FilledButton> */}
        <FilledButton
          sx={{ textTransform: "none" }}
          className={activeTab === "traits" && "activeTab"}
          onClick={() => setActiveTab("traits")}
        >
          Traits
        </FilledButton>
        <FilledButton
          sx={{ textTransform: "none" }}
          className={activeTab === "grades" && "activeTab"}
          onClick={() => setActiveTab("grades")}
        >
          Grades
        </FilledButton>
        <FilledButton
          sx={{ textTransform: "none" }}
          className={activeTab === "assessments" && "activeTab"}
          onClick={() => setActiveTab("assessments")}
        >
          Assessments
        </FilledButton>

        {/* <FilledButton
          sx={{ textTransform: "none" }}
          className={activeTab === "templetes" && "activeTab"}
          onClick={() => setActiveTab("templetes")}
        >
          Templates
        </FilledButton> */}

        {/* <FilledButton
          sx={{ textTransform: "none" }}
          className={activeTab === "preferences" && "activeTab"}
          onClick={() => setActiveTab("preferences")}
        >
          Preferences
        </FilledButton> */}
      </Box>

      {activeTab === "school" && <School />}
      {activeTab === "session" && <Session />}
      {activeTab === "sections" && <Sections />}
      {/* {activeTab === "departments" && <Departments />} */}
      {activeTab === "comments" && <Comments />}
      {activeTab === "traits" && <Traits />}
      {activeTab === "grades" && <Grades />}
      {activeTab === "assessments" && <Assessments />}
      {/* {activeTab === "examOffice" && <ExamOffice />} */}
      {activeTab === "templetes" && <Templetes />}
      {activeTab === "preferences" && <Preferences />}
    </div>
  );
}

export default Settings;
