import {
  Grid,
  LinearProgress,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { StyledPaper } from "../../../customComponents/styled/styledPaper";

import {
  StyledTableCell,
  StyledTableRow,
} from "../../../customComponents/styled/styledTable";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import EditSectionForm from "./EditSectionForm";
import EditSubSectionForm from "./EditSubSectionForm";
import {
  useDeleteSectionMutation,
  useDeleteSubSectionMutation,
  useGetSectionQuery,
  useGetSubSectionQuery,
} from "./sectionsApiSlice";
import AddSectionForm from "./AddSectionForm";
import AddSubsectionForm from "./AddSubsectionForm";
import CustomWarningDialog from "../../../customComponents/CustomWarningDialog";
import swal from "sweetalert";

function Sections() {
  const { data: sectionData, isLoading: sectionLoading } =
    useGetSectionQuery("all");

  const { data: subSectionData, isLoading: subSectionLoading } =
    useGetSubSectionQuery("all");

  const [deleteSection] = useDeleteSectionMutation();
  const [deleteSubSection] = useDeleteSubSectionMutation();

  const [editSection, setEditSection] = React.useState(false);
  const [focusedSection, setFocusedSection] = React.useState(null);
  const [focusedSubSection, setFocusedSubSection] = React.useState(null);
  const [itemToDelete, setItemToDelete] = React.useState(null);
  const [openWarning, setOpenWarning] = React.useState(false);

  const handleDelete = async ({ fn, item }) => {
    try {
      const response = await fn(item).unwrap();
      console.log(response);
      if (response.message) {
        swal("Success", response.message, "success");
      } else {
        swal("Success", "Error deleting item", "success");
      }
    } catch (error) {
      swal("Error", "Somethng went wrong", "error");
      console.log(error);
    }
  };

  return (
    <>
      <CustomWarningDialog
        open={openWarning}
        setOpen={setOpenWarning}
        item={itemToDelete}
        action={handleDelete}
        message="Are you sure you want to delete this item?"
      />

      <Grid container columnSpacing={3}>
        <Grid item xs={12} md={4}>
          <StyledPaper>
            {editSection ? (
              <EditSectionForm
                item={focusedSection}
                setFocusedItem={setFocusedSection}
              />
            ) : (
              <AddSectionForm />
            )}
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={8}>
          <StyledPaper
            onClick={(e) => {
              setEditSection(false);
              setFocusedSection(null);
            }}
          >
            {sectionLoading && <LinearProgress />}
            <Box className="paperHeader">
              <Typography variant="formHeading">Sections</Typography>
            </Box>
            <TableContainer sx={{ pb: 4 }}>
              <Table sx={{ width: "100%", boxShadow: "none" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Section </StyledTableCell>
                    <StyledTableCell align="left">head title</StyledTableCell>
                    <StyledTableCell align="left">Section head</StyledTableCell>
                    <StyledTableCell>edit</StyledTableCell>
                    <StyledTableCell align="right">delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sectionData?.map((section) => (
                    <StyledTableRow key={section.id}>
                      <StyledTableCell component="th" scope="section">
                        <Box
                          className="tableCellLink"
                          onClick={(e) => {
                            e.stopPropagation();
                            setFocusedSection(section);
                          }}
                        >
                          {section.name}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {section.head_title}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {section?.head?.profile?.first_name} {section?.head?.profile?.last_name}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        <SvgIcon
                          sx={{ color: "primary.main", cursor: "pointer" }}
                          component={EditIcon}
                          inheritViewBox
                          onClick={(e) => {
                            e.stopPropagation();
                            setFocusedSection(section);
                            setEditSection(true);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <DeleteIcon
                          className="tableCellLink"
                          onClick={() => {
                            setItemToDelete({
                              item: section,
                              fn: deleteSection,
                            });
                            setOpenWarning(true);
                          }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={4}>
          <StyledPaper>
            {focusedSubSection ? (
              <EditSubSectionForm item={focusedSubSection} />
            ) : (
              <AddSubsectionForm focusedSection={focusedSection} />
            )}
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={8}>
          <StyledPaper>
            {subSectionLoading && <LinearProgress />}
            <Box className="paperHeader">
              <Typography variant="formHeading">Sub-Sections</Typography>
            </Box>
            <TableContainer sx={{ pb: 4 }}>
              <Table sx={{ width: "100%", boxShadow: "none" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name </StyledTableCell>
                    <StyledTableCell align="left">report type</StyledTableCell>
                    <StyledTableCell align="left">templates</StyledTableCell>
                    <StyledTableCell align="left">edit</StyledTableCell>
                    <StyledTableCell align="right">delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subSectionData?.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.report_type}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.templates.length}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <SvgIcon
                          sx={{ color: "primary.main", cursor: "pointer" }}
                          component={EditIcon}
                          inheritViewBox
                          onClick={() => {
                            setFocusedSubSection(row);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <DeleteIcon
                          className="tableCellLink"
                          onClick={() => {
                            setItemToDelete({
                              item: row,
                              fn: deleteSubSection,
                            });
                            setOpenWarning(true);
                          }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </Grid>
      </Grid>
    </>
  );
}

export default Sections;
