import "./App.css";
import Page404 from "./components/pages/auth/Page404.js"
import { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  selectCurrentUser,
  logout,
  login,
} from "./components/pages/auth/authSlice.js";
import { useSelector, useDispatch } from "react-redux";
import {
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Dashboard,
  Classes,
  Staff,
  Subjects,
  Roles,
  MessageCenter,
  Settings,
  Students,
  TimeTable,
  Login,
  ClassBroadsheet,
  ClassReport,
  StudentReport,
  CAManager,
  QuickEntry,
  ExamManager,
  ManageAttendance,
  EditProfile,
  Houses,
  ClassSubjects,
  ClassReportSheet,
  AccountSettings,
  FeesSchedule,
  Invoices,
  PaymentsDashboard,
} from "./components/pages/index";
import Layout from "./components/layout/Layout";
import SectionReport from "./components/pages/SectionReport/SectionReport";
import swal from "sweetalert";
import useTheme from "./theme/theme";

const ProtectedRoute = ({ user, allowedRoles, children }) => {
  //wrapper component for protected routes
  const dispatch = useDispatch();
  const isAuth = !!user;

  const userRoles = user?.roles
    .filter((role) => role.school_id === user.current_school_id)
    .map((item) => item.role.name);

  // const userRoles = ["admin", "teacher"]; // for testing, delete this line and uncomment the line above when done

  const hasRequiredRole = userRoles?.filter((role) =>
    allowedRoles.includes(role)
  )?.length
    ? true
    : false;

  if (!isAuth) {
    return <Navigate to="/login" replace />;
  }

  //log the user out if they dont have any roles
  if (!userRoles?.length) {
    swal("Error", "sorry, user doesnt have any role", "error");
    return dispatch(logout());
  }

  //prevent the user from galavanting
  if (!hasRequiredRole) {
    swal("Error", "Access Denied", "error");
    return <Navigate to="/" />;
  }

  return children;
};

const NoNav = () => (
  <>
    <Outlet />
  </>
);

function AppRoutes() {
  //all the routes
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    //check saved user in local storage
    const userString = localStorage.getItem("user");
    const access_tokenString = localStorage.getItem("access_token");
    const refresh_tokenString = localStorage.getItem("refresh_token");

    if (userString && access_tokenString && refresh_tokenString) {
      const user = JSON.parse(userString);
      const access_token = JSON.parse(access_tokenString);
      const refresh_token = JSON.parse(refresh_tokenString);
      dispatch(login({ user, access_token, refresh_token }));
    }
  }, []);

  useEffect(() => {
    if (!!user && location.pathname === "/login") {
      navigate("/");
    }
  }, [location, user]);

  return (
    <Routes>
      <Route element={<NoNav />}>
        {/* public routes that dont have the sidebar nav */}
        <Route path="login" element={<Login />} />
        <Route path="*" element={<Page404/>} />
      </Route>

      <Route
        element={
          <ProtectedRoute
            user={user}
            allowedRoles={["admin", "teacher", "accountant"]}
          >
            <Layout />
          </ProtectedRoute>
        }
      >
        {/* shared routes by both admin, accounant and teacher */}
        <Route path="/" element={<Dashboard />} />
        <Route path="edit-profile" element={<EditProfile />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} allowedRoles={["admin"]}>
            <Layout />
          </ProtectedRoute>
        }
      >
        {/* routes strictly for admin */}
        <Route path="classes" element={<Classes />} />
        <Route path="staff" element={<Staff />} />
        <Route path="subjects" element={<Subjects />} />
        <Route path="roles" element={<Roles />} />
        <Route path="message-center" element={<MessageCenter />} />
        <Route path="students" element={<Students />} />
        <Route path="timetable" element={<TimeTable />} />
        <Route path="settings" element={<Settings />} />
      </Route>

      <Route
        element={
          <ProtectedRoute user={user} allowedRoles={["teacher"]}>
            <Layout />
          </ProtectedRoute>
        }
      >
        {/* routes strictly for teacher */}
        <Route path="class-broadsheet" element={<ClassBroadsheet />} />
        <Route path="class-report" element={<ClassReport />} />
        <Route path="student-report" element={<StudentReport />} />
        <Route path="ca-manager" element={<CAManager />} />
        <Route path="quick-entry" element={<QuickEntry />} />
        <Route path="exam-manager" element={<ExamManager />} />
        <Route path="manage-attendance" element={<ManageAttendance />} />
        <Route path="houses" element={<Houses />} />
        <Route path="class-subjects/:classId" element={<ClassSubjects />} />
        <Route
          path="class-reportsheet/:classId"
          element={<ClassReportSheet />}
        />
        <Route path="section-report" element={<SectionReport />} />
      </Route>

      <Route
        element={
          <ProtectedRoute user={user} allowedRoles={["accountant"]}>
            <Layout />
          </ProtectedRoute>
        }
      >
        {/* routes strictly for accountant */}
        <Route path="account-settings" element={<AccountSettings />} />
        <Route path="fees-schedule" element={<FeesSchedule />} />
        <Route path="invoices" element={<Invoices />} />
        <Route path="payments-dashboard" element={<PaymentsDashboard />} />
      </Route>
    </Routes>
  );
}

function App() {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <AppRoutes />
      </div>
    </ThemeProvider>
  );
}

export default App;
