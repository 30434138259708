import { Button, styled } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const FilledButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: theme.palette.primary.main,
  border: "1px solid transparent",
  padding: " 6px 12px",
  fontSize: "14px",
  fontWeight: "normal",
  lineHeight: "1.42857143",
  textAlign: "center",
  whiteSpace: "nowrap",
  borderRadius: "4px",
  minWidth: "10px",
  textTransform: "none",

  "&:hover": {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  "&.activeTab": {
    boxShadow: "inset 0 3px 5px rgb(0 0 0 / 13%)",
    color: theme.palette.primary.main,
    backgroundColor: "#fff",
    border: "none",
  },
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  color: "white",
  backgroundColor: theme.palette.primary.main,
  border: "1px solid transparent",
  padding: " 6px 12px",
  fontSize: "14px",
  fontWeight: "normal",
  lineHeight: "1.42857143",
  textAlign: "center",
  whiteSpace: "nowrap",
  borderRadius: "4px",
  minWidth: "10px",
  textTransform: "none",

  "&:hover": {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  "&.activeTab": {
    boxShadow: "inset 0 3px 5px rgb(0 0 0 / 13%)",
    color: theme.palette.primary.main,
    backgroundColor: "#fff",
    border: "none",
  },

  "& > .MuiLoadingButton-loadingIndicator": {
    color: "white",
  },
}));

export { FilledButton, StyledLoadingButton };
