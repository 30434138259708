import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import {
  StyledFileInput,
  StyledSelect,
  StyledTextField,
} from "../../customComponents/styled/styledInputs";
import { useFormik } from "formik";
import * as yup from "yup";
import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import {
  useAddStudentToClassroomMutation,
  useCreateStudentMutation,
} from "./studentApiSlice";
import { useUploadFileMutation } from "../../../app/utilsApiSlice";
import { useGetClassesQuery } from "../classroom/classApiSlice";
import swal from "sweetalert";

const validationSchema = yup.object({
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  gender: yup.string().required("Required"),
});

function AddStudentForm() {
  const { data: classrooms } = useGetClassesQuery("all");
  const [createStudent, { isLoading: createStudentLoading }] =
    useCreateStudentMutation();
  const [uploadFile, { isLoading: fileLoading }] = useUploadFileMutation();

  const [addStudentToClass] = useAddStudentToClassroomMutation();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      dob: "",
      gender: "",
      classroom: "",
      address: "",
      phone: "",
      photo: "",
      reg_number: "",
    },

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values)
      try {
        const response = await createStudent(values).unwrap();
        if (response.id) {
          swal("Success", "Student created successfully", "success");
          const res = await addStudentToClass({
            body: {
              student_ids: [response.id],
            },
            classroom_id: values.classroom,
          }).unwrap();
          if (res) {
            swal("Success", "Student added to classroom", "success");
            formik.resetForm();
          }
        }
      } catch (error) {
        if (error.status === 500) {
          swal("Error", error.data.message, "error");
        } else {
          swal("Error", "Something went wrong", "error");
        }
      }
    },
  });

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const response = await uploadFile(formData).unwrap();
      if (response) {
        formik.setFieldValue(e.target.name, response.url);
      }
    } catch (error) {}
  };

  const states = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "FCT - Abuja",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
  ];

  return (
    <StyledPaper>
      <Box className="paperHeader">
        <Typography variant="formHeading">
          New Student Registration Form
        </Typography>
      </Box>
      <Box className="paperBody">
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Grid container columnSpacing={1} rowSpacing={3}>
            <Grid item xs={12} lg={4}>
              <FormControl
                fullWidth
                error={formik.touched.first_name && formik.errors.first_name}
              >
                <Typography variant="formSubHeading">first name</Typography>
                <StyledTextField
                  bold
                  variant="outlined"
                  id="first_name"
                  name="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.first_name &&
                    Boolean(formik.errors.first_name)
                  }
                />
                <FormHelperText variant="error">
                  {formik.touched.first_name && formik.errors.first_name}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={4}>
              <FormControl
                fullWidth
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
              >
                <Typography variant="formSubHeading">last name</Typography>
                <StyledTextField
                  bold
                  variant="outlined"
                  id="last_name"
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.last_name && Boolean(formik.errors.last_name)
                  }
                />
                <FormHelperText variant="error">
                  {formik.touched.last_name && formik.errors.last_name}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={4}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">registration number</Typography>
                <StyledTextField
                  variant="outlined"
                  id="reg_number"
                  name="reg_number"
                  value={formik.values.reg_number}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.reg_number && Boolean(formik.errors.reg_number)
                  }
                />
                <FormHelperText variant="error">
                  {formik.touched.reg_number && formik.errors.reg_number}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/* end of first row */}

            {/* beggining second row inputs */}

            <Grid item xs={12} md={4}>
              <FormControl
                error={formik.touched.dob && formik.errors.dob}
                fullWidth
              >
                <Typography htmlFor="section" variant="formSubHeading">
                  Date of birth
                </Typography>
                <StyledTextField
                  type="date"
                  id="dob"
                  name="dob"
                  value={formik.values.dob}
                  onChange={formik.handleChange}
                  error={formik.touched.dob && Boolean(formik.errors.dob)}
                />

                <FormHelperText variant="error">
                  {formik.touched.dob && formik.errors.dob}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl
                error={formik.touched.gender && formik.errors.gender}
                fullWidth
              >
                <Typography htmlFor="gender" variant="formSubHeading">
                  gender
                </Typography>
                <StyledSelect
                  displayEmpty
                  id="gender"
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                >
                  <MenuItem value="" disabled>
                    <em>select gender</em>
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </StyledSelect>
                <FormHelperText variant="error">
                  {formik.touched.gender && formik.errors.gender}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <Typography variant="formSubHeading">photo</Typography>

                <Stack direction="row" spacing={1}>
                  <StyledFileInput
                    type="file"
                    id="photo"
                    name="photo"
                    onChange={(e) => handleFileChange(e)}
                    error={formik.touched.photo && Boolean(formik.errors.photo)}
                  />
                  {fileLoading && <CircularProgress />}
                </Stack>

                <FormHelperText variant="error">
                  {formik.touched.photo && formik.errors.photo}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* beginning of third row inputs */}

            <Grid item xs={12} md={4}>
              <FormControl
                error={formik.touched.classroom && formik.errors.classroom}
                fullWidth
              >
                <Typography htmlFor="classroom" variant="formSubHeading">
                  classroom
                </Typography>
                <StyledSelect
                  displayEmpty
                  id="classroom"
                  name="classroom"
                  value={formik.values.classroom}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.classroom && Boolean(formik.errors.classroom)
                  }
                >
                  <MenuItem value="" disabled>
                    <em>select classroom</em>
                  </MenuItem>
                  {classrooms?.map((classroom) => (
                    <MenuItem key={classroom.id} value={classroom.id}>
                      {classroom.name}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <FormHelperText variant="error">
                  {formik.touched.classroom && formik.errors.classroom}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">phone</Typography>
                <StyledTextField
                  variant="outlined"
                  id="phone"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                />
                <FormHelperText variant="error">
                  {formik.touched.phone && formik.errors.phone}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">email</Typography>
                <StyledTextField
                  variant="outlined"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                />
                <FormHelperText variant="error">
                  {formik.touched.email && formik.errors.email}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/* end of third row inputs */}

            <Grid item xs={12}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">address</Typography>
                <StyledTextField
                  variant="outlined"
                  id="address"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                />
                <FormHelperText variant="error">
                  {formik.touched.address && formik.errors.address}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} textAlign={"right"}>
              <StyledLoadingButton loading={createStudentLoading} type="submit">
                SUBMIT
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </StyledPaper>
  );
}

export default AddStudentForm;
