import {
  Divider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";

function NotificationsCard() {
  const notifications = [
    { details: "You have a new message", date: "1 day ago" },
    { details: "You have a new message", date: "2 days ago" },
    { details: "You have a new message", date: "3 days ago" },
    { details: "lorem", date: "4 days ago" },
  ];

  return (
    <>
      <Box className="paperHeader">
        <Typography gutterBottom>Notifications</Typography>
        <Divider />
      </Box>
      <Box className="paperBody">
        <TableContainer sx={{ pb: 4 }}>
          <Table sx={{ width: "100%", boxShadow: "none" }}>
            <TableHead>
              <TableRow>
                <StyledTableCell>notifications</StyledTableCell>
                <StyledTableCell align="left">time</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notifications.map((row, i) => (
                <TableRow key={i} sx={{ background: "#f5f5f5" }}>
                  <StyledTableCell component="th" scope="row">
                    {row.details}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.date}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default NotificationsCard;
