import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { useGetClassesQuery } from "../classroom/classApiSlice";
import EditStudentDialog from "./EditStudentDialog";
import EnhancedTable from "./EnhancedTable";
import { useGetStudentQuery } from "./studentApiSlice";

function StudentList() {
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedStudent, setSelectedStudent] = React.useState(false);

  const { data: classrooms, isLoading: getClassRoomsLoading } =
    useGetClassesQuery("all");

  const { data, isLoading } = useGetStudentQuery("all");

  // get students for each classroom

  const headCells = [
    {
      id: "photo",

      label: "Photo",
    },
    {
      id: "reg_no",

      label: "Reg Number",
    },
    {
      id: "schoola_id",
      label: "Schoola ID",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "id_card",
      label: "Id Card",
    },
    {
      id: "reset",
      label: "Reset",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "remove",
      label: "Remove",
    },
  ];

  return (
    <>
      {classrooms?.map((classroom) => (
        <StyledPaper key={classroom.id}>
          <EditStudentDialog
            open={openEdit}
            setOpen={setOpenEdit}
            selectedStudent={selectedStudent}
          />
          <Box className="paperHeader">
            <Typography variant="formHeading">
              {classroom.name} students
            </Typography>
            <Typography variant="formSubHeading" sx={{ textTransform: "none" }}>
              Click on a student to view and modify their information
            </Typography>
          </Box>
          <EnhancedTable
            headCells={headCells}
            classroom={classroom}
            setSelectedStudent={setSelectedStudent}
            setOpenEdit={setOpenEdit}
          />
        </StyledPaper>
      ))}
    </>
  );
}

export default StudentList;
