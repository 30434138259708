import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
  name: "theme",
  initialState: { color: "#2574d1" },
  reducers: {
    setColor: (state, action) => {
      const { color } = action.payload;
      state.color = color;
    },
  },
});

export const { setColor } = themeSlice.actions;

export default themeSlice.reducer;
export const selectCurrentColor = (state) => state.theme.color;
