import React from "react";
import {
  StyledSelect,
  StyledTextField,
} from "../../../customComponents/styled/styledInputs";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { StyledLoadingButton } from "../../../customComponents/styled/styledButtons";
import { useCreateGradeMutation } from "./gradesApiSlice";
import { useGetSubSectionQuery } from "../sections/sectionsApiSlice";
import swal from "sweetalert";

const validationSchema = yup.object({
  name: yup.string().required("Grade is required"),
  min_score: yup
    .number("must be a number")
    .max(100, "max is 100")
    .min(0, "min is 0")
    .required("Required"),
  max_score: yup
    .number()
    .max(100, "max is 100")
    .min(0, "min is 0")
    .required("Required"),
  type: yup.string().required("Required"),
  description: yup.string().required("Required"),
  subsection_id: yup.string().required("Required"),
});

function CreateGradeForm() {
  const { data: subsections } = useGetSubSectionQuery("all");
  const [createGrade, { isLoading: createGradeLoading }] =
    useCreateGradeMutation();

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      type: "",
      min_score: "",
      max_score: "",
      subsection_id: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // handleCreateGrade(values)
      let items =
        values.subsection_id === "all"
          ? subsections?.map((subsection) => ({
              ...values,
              subsection_id: subsection.id,
            }))
          : [{ ...values }];

      handleCreateGrade(items);
    },
  });

  const handleCreateGrade = async (items) => {
    items.forEach(async (item) => {
      try {
        const response = await createGrade(item).unwrap();
        if (response.id) {
          swal("Success", "Grade created successfully", "success");
          formik.resetForm();
        } else {
          swal("Error", "Error creating grade", "error");
        }
      } catch (error) {
        swal("Error", "Something went wrong", "error");
      }
    });
  };

  return (
    <>
      <Box className="paperHeader">
        <Typography variant="formHeading">Setup Grades</Typography>
      </Box>
      <Box className="paperBody">
        <form onSubmit={formik.handleSubmit}>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">Grade</Typography>
                <StyledTextField
                  variant="outlined"
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                />
                <FormHelperText variant="error">
                  {formik.touched.name && formik.errors.name}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* {second Row begin} */}

            <Grid item xs={12} lg={6}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">Minimum score</Typography>
                <StyledTextField
                  variant="outlined"
                  id="min_score"
                  name="min_score"
                  value={formik.values.min_score}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.min_score && Boolean(formik.errors.min_score)
                  }
                />
                <FormHelperText variant="error">
                  {formik.touched.min_score && formik.errors.min_score}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">Maximum Score</Typography>
                <StyledTextField
                  variant="outlined"
                  id="max_score"
                  name="max_score"
                  value={formik.values.max_score}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.max_score && Boolean(formik.errors.max_score)
                  }
                />
                <FormHelperText variant="error">
                  {formik.touched.max_score && formik.errors.max_score}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* end of second row  */}

            <Grid item xs={12}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">Description</Typography>
                <StyledTextField
                  variant="outlined"
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                />
                <FormHelperText variant="error">
                  {formik.touched.description && formik.errors.description}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                error={formik.touched.type && formik.errors.type}
                fullWidth
              >
                <Typography variant="formSubHeading">type</Typography>
                <StyledSelect
                  displayEmpty
                  variant="outlined"
                  id="type"
                  name="type"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                >
                  <MenuItem value="" disabled>
                    <em>select type</em>
                  </MenuItem>
                  <MenuItem value="score">score</MenuItem>
                  <MenuItem value="comment">comment</MenuItem>
                </StyledSelect>
                <FormHelperText variant="error">
                  {formik.touched.type && formik.errors.type}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl
                error={
                  formik.touched.subsection_id && formik.errors.subsection_id
                }
                fullWidth
              >
                <Typography variant="formSubHeading">sub-section</Typography>
                <StyledSelect
                  displayEmpty
                  variant="outlined"
                  id="subsection_id"
                  name="subsection_id"
                  value={formik.values.subsection_id}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.subsection_id &&
                    Boolean(formik.errors.subsection_id)
                  }
                >
                  <MenuItem value="" disabled>
                    <em>select subsection</em>
                  </MenuItem>
                  <MenuItem value="all">All subsections</MenuItem>
                  {subsections?.map((subsection) => (
                    <MenuItem key={subsection.id} value={subsection.id}>
                      {subsection.name}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <FormHelperText variant="error">
                  {formik.touched.subsection_id && formik.errors.subsection_id}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} textAlign={"right"}>
              <StyledLoadingButton loading={createGradeLoading} type="submit">
                Submit
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default CreateGradeForm;
