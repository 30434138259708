import { Grid, LinearProgress } from "@mui/material";
import { useState } from "react";
import CustomMenuList from "../../customComponents/CustomMenuList";
import { useGetClassesQuery } from "../classroom/classApiSlice";
import AttendanceSheet from "./AttendanceSheet";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

function ManageAttendance() {
  const [activeTab, setActiveTab] = useState(0);

  const { data: myClasses, isLoading: myClassesLoading } =
    useGetClassesQuery("all/mine");

  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={12} md={3}>
        {myClassesLoading && <LinearProgress />}
        <CustomMenuList
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          heading="menu"
          list={myClasses?.map((classroom) => `${classroom.name} attendance`)}
          icon={<CalendarMonthIcon />}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <AttendanceSheet myClasses={myClasses || []} activeTab={activeTab} />
      </Grid>
    </Grid>
  );
}

export default ManageAttendance;
