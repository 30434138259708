import {
  Grid,
  MenuItem,
  Typography,
  FormControl,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Box,
  FormHelperText,
  Avatar,
} from "@mui/material";
import React, { useState } from "react";
import {
  StyledSelect,
  StyledTextField,
} from "../../customComponents/styled/styledInputs";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { FilledButton } from "../../customComponents/styled/styledButtons";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import { useFormik } from "formik";
import * as yup from "yup";
import { useGetClassesQuery } from "../classroom/classApiSlice";
import { useGetSessionQuery } from "../settings/session/sessionApiSlice";
import { useGetSubSectionQuery } from "../settings/sections/sectionsApiSlice";
import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
import swal from "sweetalert";
import { useCreateStudentReportMutation } from "./studentReportApiSlice";
import { PictureAsPdf } from "@mui/icons-material";
import { Link } from "react-router-dom";

const validationSchema = yup.object({
  class: yup.object().required("Required"),
  student: yup.object().required("Required"),
  session: yup.object().required("Required"),
  term: yup.string().required("Required"),
  template: yup.string().required("Required"),
});

function StudentReport() {
  const formik = useFormik({
    initialValues: {
      class: "",
      student: "",
      session: "",
      term: "",
      template: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const body = {
          student_id: values.student.id,
          term_id: values.term,
          classroom_id: values.class.id,
          template_id: values.template,
        };

        console.log(body);
        const response = await createStudentReport(body).unwrap();
        console.log(response);
        if (response.id) {
          setStudentReport({ student: values.student, report: response });
          swal(
            "Success",
            `${values.student.first_name} ${values.student.last_name}'s report generated successfully`,
            "success"
          );
        } else {
          swal("Error", "Report generation failed", "error");
        }
      } catch (error) {
        console.log(error);
        swal("Error", "something went wrong", "error");
      }
    },
  });
  const [createStudentReport, { isLoading: isCreatingStudentReport }] =
    useCreateStudentReportMutation();
  const [studentReport, setStudentReport] = useState(null);

  const { data: classrooms } = useGetClassesQuery("all");
  const { data: sessions } = useGetSessionQuery("all");
  const { data: subsection } = useGetSubSectionQuery(
    formik.values.class.subsection_id
  );
  const { data: students } = useGetClassesQuery(
    `${formik.values.class.id}/students`
  );

  return (
    <div style={{ padding: "0 10px" }}>
      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={4}>
          <StyledPaper sx={{ padding: "15px" }}>
            <Typography variant="formHeading" sx={{ marginBottom: "20px" }}>
              Student Report Download Form
            </Typography>

            <Box component="form" onSubmit={formik.handleSubmit}>
              <Grid container rowSpacing={3}>
                <Grid item xs={12} md={12}>
                  <FormControl
                    error={formik.touched.class && formik.errors.class}
                    fullWidth
                  >
                    <Typography variant="formSubHeading">CLASS</Typography>
                    <StyledSelect
                      displayEmpty
                      id="class"
                      name="class"
                      value={formik.values.class}
                      error={
                        formik.touched.class && Boolean(formik.errors.class)
                      }
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="" disabled>
                        <em>Select a class</em>
                      </MenuItem>
                      {classrooms?.map((item) => (
                        <MenuItem key={item.id} value={item}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </StyledSelect>

                    <FormHelperText variant="error">
                      {formik.touched.class && formik.errors.class}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormControl
                    error={formik.touched.student && formik.errors.student}
                    fullWidth
                  >
                    <Typography variant="formSubHeading">STUDENT</Typography>
                    <StyledSelect
                      displayEmpty
                      id="student"
                      name="student"
                      value={formik.values.student}
                      error={
                        formik.touched.student && Boolean(formik.errors.student)
                      }
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="" disabled>
                        <em>Select a student</em>
                      </MenuItem>
                      {students?.map((student) => (
                        <MenuItem key={student.id} value={student}>
                          {student.first_name} {student.last_name}
                        </MenuItem>
                      ))}
                    </StyledSelect>

                    <FormHelperText variant="error">
                      {formik.touched.student && formik.errors.student}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormControl
                    error={formik.touched.session && formik.errors.session}
                    fullWidth
                  >
                    <Typography variant="formSubHeading">SESSION</Typography>
                    <StyledSelect
                      displayEmpty
                      id="session"
                      name="session"
                      value={formik.values.session}
                      error={
                        formik.touched.session && Boolean(formik.errors.session)
                      }
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="" disabled>
                        <em>Select a session</em>
                      </MenuItem>
                      {sessions?.map((item) => (
                        <MenuItem key={item.id} value={item}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </StyledSelect>

                    <FormHelperText variant="error">
                      {formik.touched.session && formik.errors.session}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormControl
                    error={formik.touched.term && formik.errors.term}
                    fullWidth
                  >
                    <Typography variant="formSubHeading">TERM</Typography>
                    <StyledSelect
                      displayEmpty
                      id="term"
                      name="term"
                      onChange={formik.handleChange}
                      value={formik.values.term}
                      error={formik.touched.term && Boolean(formik.errors.term)}
                    >
                      <MenuItem value="" disabled>
                        <em>Select a term</em>
                      </MenuItem>
                      {formik.values.session?.terms?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    <FormHelperText variant="error">
                      {formik.touched.term && formik.errors.term}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormControl
                    error={formik.touched.template && formik.errors.template}
                    fullWidth
                  >
                    <Typography variant="formSubHeading">TEMPLATE</Typography>
                    <StyledSelect
                      id="template"
                      name="template"
                      value={formik.values.template}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.template &&
                        Boolean(formik.errors.template)
                      }
                    >
                      <MenuItem value="" disabled>
                        <em>Select a template</em>
                      </MenuItem>
                      {subsection?.templates?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    <FormHelperText variant="error">
                      {formik.touched.template && formik.errors.template}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} textAlign={"right"}>
                  <StyledLoadingButton
                    loading={isCreatingStudentReport}
                    type="submit"
                    sx={{ textTransform: "none" }}
                  >
                    Get Report
                  </StyledLoadingButton>
                </Grid>
              </Grid>
            </Box>
          </StyledPaper>
        </Grid>

        {/* Generated Table */}

        <Grid item xs={12} md={8}>
          <StyledPaper sx={{ paddingBottom: "25px" }}>
            <Box sx={{ padding: "15px" }}>
              <Typography variant="formHeading">Student Report</Typography>
              <Typography
                variant="formSubHeading"
                sx={{ textTransform: "none" }}
              >
                Select student to view report
              </Typography>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ borderBottom: "2px solid lightgrey " }}>
                    <StyledTableCell>PHOTO</StyledTableCell>
                    <StyledTableCell>ID</StyledTableCell>
                    <StyledTableCell>NAME</StyledTableCell>
                    {/* <StyledTableCell>SEND</StyledTableCell> */}
                    <StyledTableCell>DOWNLOAD</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentReport && (
                    <StyledTableRow>
                      <StyledTableCell>
                        <Avatar src={studentReport?.student?.image} />
                      </StyledTableCell>
                      <StyledTableCell>
                        {studentReport.student.id}
                      </StyledTableCell>
                      <StyledTableCell>
                        {studentReport.student.first_name}{" "}
                        {studentReport.student.last_name}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Link
                          to={studentReport.report.file_url}
                          target="_blank"
                          download={`${studentReport.student.first_name}
                          ${studentReport.student.last_name}.pdf`}
                        >
                          <PictureAsPdf style={{ color: "red" }} />
                        </Link>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </Grid>
      </Grid>
    </div>
  );
}

export default StudentReport;
