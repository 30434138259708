import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Typography,
} from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { StyledTextField } from "../../customComponents/styled/styledInputs";
import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
import { Box } from "@mui/system";
import swal from "sweetalert";
import { useUpdatePasswordMutation } from "../../../app/authApiSlice";

function ChangePasswordDialog({ open, setOpen }) {
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: yup.object({
      oldPassword: yup.string().required("Old password is required"),
      newPassword: yup.string().required("New password is required"),
      confirmPassword: yup
        .string()
        .required("Confirm password is required")
        .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      const body = {
        old_password: values.oldPassword,
        new_password: values.newPassword,
      };

      try {
        const response = await updatePassword(body).unwrap();
        if (response) {
          formik.resetForm();
          swal("Success", "Password changed successfully", "success");
          handleClose();
        }
        console.log(response);
      } catch (error) {
        console.log(error);
        if (error.status === 401) {
          swal("Error", "Old password is incorrect", "error");
        } else {
          swal("Error", "Something went wrong", "error");
        }
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Change Password</DialogTitle>
      <Divider />
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              "& > *": {
                mb: 2,
              },
            }}
          >
            <FormControl error fullWidth>
              <Typography variant="formSubHeading">old password</Typography>
              <StyledTextField
                autoComplete="false"
                autoFocus
                type="password"
                variant="outlined"
                id="oldPassword"
                name="oldPassword"
                value={formik.values.oldPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.oldPassword &&
                  Boolean(formik.errors.oldPassword)
                }
              />
              <FormHelperText variant="error">
                {formik.touched.oldPassword && formik.errors.oldPassword}
              </FormHelperText>
            </FormControl>
            <FormControl error fullWidth>
              <Typography variant="formSubHeading">new password</Typography>
              <StyledTextField
                autoComplete="false"
                type="password"
                variant="outlined"
                id="newPassword"
                name="newPassword"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
              />
              <FormHelperText variant="error">
                {formik.touched.newPassword && formik.errors.newPassword}
              </FormHelperText>
            </FormControl>
            <FormControl error fullWidth>
              <Typography variant="formSubHeading">
                confrim new password
              </Typography>
              <StyledTextField
                autoComplete="false"
                type="password"
                variant="outlined"
                id="confirmPassword"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
              />
              <FormHelperText variant="error">
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword}
              </FormHelperText>
            </FormControl>
            <StyledLoadingButton loading={isLoading} fullWidth type="submit">
              submit
            </StyledLoadingButton>
            <Button
              fullWidth
              variant="contained"
              size="small"
              color="error"
              onClick={handleClose}
            >
              cancel
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ChangePasswordDialog;
