import { Box } from "@mui/material";
import { useState } from "react";
import Header from "./Header";
import Main from "./Main";
import SideNav from "./SideNav";

function Layout() {
  const drawerWidth = 260;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Header
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
      />
      <SideNav
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
      />
      <Main />
    </Box>
  );
}

export default Layout;
