import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Attendance"],
});

const attendanceApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAttendance: builder.query({
      query: (parameter) => `/attendance/${parameter}`,
      providesTags: ["Attendance"],
    }),
    createAttendance: builder.mutation({
      query: (values) => ({
        url: "/attendance/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Attendance"],
    }),
    clearAttendance: builder.mutation({
      query: (values) => ({
        url: "/attendance/clear",
        method: "DELETE",
        body: { ...values },
      }),
      invalidatesTags: ["Attendance"],
    }),
  }),
});

export const {
  useGetAttendanceQuery,
  useCreateAttendanceMutation,
  useClearAttendanceMutation,
} = attendanceApiSlice;
