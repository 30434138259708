import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import swal from "sweetalert";
import { logout } from "../components/pages/auth/authSlice.js";

const authPlatformName = process.env.REACT_APP_AUTH_PLATFORM_NAME;
const authPlatformKey = process.env.REACT_APP_AUTH_PLATFORM_KEY;

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_SCHOOLA_AUTH_SERVICE,
  //credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    headers.set("x-api-key-name", authPlatformName);
    headers.set("x-api-key", authPlatformKey);
    const access_token = getState().auth.access_token;
    if (access_token) {
      headers.set("authorization", `Bearer ${access_token}`);
    }
    return headers;
  },
});

const baseQuerywithAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    // unauthorized
    swal("Error", "Unauthorized or expired session", "error");
    api.dispatch(logout()); // logout user
    api.dispatch(authApiSlice.util.resetApiState());
  }

  return result; //if everything is ok, return the result
};

export const authApiSlice = createApi({
  reducerPath: "authApi",
  baseQuery: baseQuerywithAuth,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (values) => ({
        url: "/auth/login",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["User"],
    }),
    getAllUsers: builder.query({
      query: (school_id) => `/auth/users/${school_id}`,
      providesTags: ["Users"],
    }),
    createUser: builder.mutation({
      query: (values) => ({
        url: "/auth/add-user",
        method: "POST",
        body: { ...values },
      }),
    }),
    updatePassword: builder.mutation({
      query: (values) => ({
        url: "/auth/update-password",
        method: "PATCH",
        body: { ...values },
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useLoginMutation,
  useCreateUserMutation,
  useUpdatePasswordMutation,
  useGetAllUsersQuery,
} = authApiSlice;
