import {
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  StyledFileInput,
  StyledSelect,
  StyledTextField,
} from "../../customComponents/styled/styledInputs";
import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
import { Box } from "@mui/system";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { format, parseISO } from "date-fns";
import { useUploadFileMutation } from "../../../app/utilsApiSlice";
import { useUpdateTeacherMutation } from "./staffApiSlice";
import swal from "sweetalert";

const validationSchema = yup.object({
  title: yup.string().required("Required"),
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  email: yup.string().required("Required"),
  gender: yup.string().required("Required"),
  phone: yup.string().required("Required"),
  address: yup.string().required("Required"),
  dob: yup.string().required("Required"),
  // photo: yup.string().required("Required"),
  // signature: yup.string().required("Required"),
  // resume: yup.string().required("Required"),
});

function EditStaffDialog({ open, setOpen, selectedStaff }) {
  const [fileLoading, setFileLoading] = useState({
    photo: false,
    signature: false,
    resume: false,
  });

  const [uploadFile] = useUploadFileMutation();
  const [updateTeacher, { isLoading: updateTeacherLoading }] =
    useUpdateTeacherMutation();

  const formik = useFormik({
    initialValues: {
      id: selectedStaff?.id || "",
      title: selectedStaff?.title || "Mr.",
      first_name: selectedStaff?.first_name || "",
      last_name: selectedStaff?.last_name || "",
      gender: selectedStaff?.gender || "",
      dob: selectedStaff?.dob
        ? format(parseISO(selectedStaff.dob), "yyyy-MM-dd")
        : "",
      phone: selectedStaff?.phone || "",
      email: selectedStaff?.email || "",
      address: selectedStaff?.address || "",
      photo: selectedStaff?.photo || "",
      signature: selectedStaff?.signature || "",
      resume: selectedStaff?.resume || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await updateTeacher(values).unwrap();
        if (response) {
          swal("Success", "Information updated successfully", "success");
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleFileChange = async (e) => {
    try {
      setFileLoading({ ...fileLoading, [e.target.name]: true }); // set file uploading status
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const response = await uploadFile(formData).unwrap();
      if (response) {
        setFileLoading({ ...fileLoading, [e.target.name]: false }); //setfile uploading status
        console.log(response);
        formik.setFieldValue(e.target.name, response.url);
      }
    } catch (error) {
      console.log(error);
      setFileLoading({ ...fileLoading, [e.target.name]: false }); //setfile uploading status
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          maxWidth: "800px",
        },
      }}
    >
      <Box sx={{ display: "flex", padding: 2, justifyContent: "flex-end" }}>
        <CloseSharpIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
      </Box>
      <Divider />

      <DialogContent>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} lg={8}>
            <StyledPaper>
              <Box className="paperHeader">Edit Profile</Box>
              <Box className="paperBody">
                <Box component="form" onSubmit={formik.handleSubmit}>
                  <Grid container columnSpacing={1} rowSpacing={3}>
                    <Grid item xs={12} lg={2}>
                      <FormControl fullWidth>
                        <Typography variant="formSubHeading">title</Typography>
                        <StyledSelect
                          id="title"
                          name="title"
                          value={formik.values.title}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.title && Boolean(formik.errors.title)
                          }
                        >
                          <MenuItem value="Mr.">Mr</MenuItem>
                          <MenuItem value="Mrs.">Mrs</MenuItem>
                          <MenuItem value="Miss.">Miss</MenuItem>
                          <MenuItem value="Alhaji">Alhaji</MenuItem>
                          <MenuItem value="Hajiya">Hajiya</MenuItem>
                          <MenuItem value="Mallam">Mallam</MenuItem>
                          <MenuItem value="Mallama">Mallama</MenuItem>
                        </StyledSelect>
                        <FormHelperText variant="error">
                          {formik.touched.title && formik.errors.title}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={5}>
                      <FormControl
                        fullWidth
                        error={
                          formik.touched.first_name && formik.errors.first_name
                        }
                      >
                        <Typography variant="formSubHeading">
                          first name
                        </Typography>
                        <StyledTextField
                          bold
                          variant="outlined"
                          id="first_name"
                          name="first_name"
                          value={formik.values.first_name}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.first_name &&
                            Boolean(formik.errors.first_name)
                          }
                        />
                        <FormHelperText variant="error">
                          {formik.touched.first_name &&
                            formik.errors.first_name}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={5}>
                      <FormControl
                        fullWidth
                        error={
                          formik.touched.last_name &&
                          Boolean(formik.errors.last_name)
                        }
                      >
                        <Typography variant="formSubHeading">
                          last name
                        </Typography>
                        <StyledTextField
                          bold
                          variant="outlined"
                          id="last_name"
                          name="last_name"
                          value={formik.values.last_name}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.last_name &&
                            Boolean(formik.errors.last_name)
                          }
                        />
                        <FormHelperText variant="error">
                          {formik.touched.last_name && formik.errors.last_name}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {/* end of first row */}

                    {/* SECOND ROW INPUTS */}
                    {/* Gender */}

                    <Grid item xs={12} md={4}>
                      <FormControl
                        error={formik.touched.gender && formik.errors.gender}
                        fullWidth
                      >
                        <Typography htmlFor="gender" variant="formSubHeading">
                          gender
                        </Typography>
                        <StyledSelect
                          displayEmpty
                          id="gender"
                          name="gender"
                          value={formik.values.gender}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.gender &&
                            Boolean(formik.errors.gender)
                          }
                        >
                          <MenuItem value="" disabled>
                            <em>select gender</em>
                          </MenuItem>
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                        </StyledSelect>
                        <FormHelperText variant="error">
                          {formik.touched.gender && formik.errors.gender}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {/* Date of Birth */}

                    <Grid item xs={12} md={4}>
                      <FormControl
                        error={formik.touched.dob && formik.errors.dob}
                        fullWidth
                      >
                        <Typography htmlFor="dob" variant="formSubHeading">
                          Date of Birth
                        </Typography>
                        <StyledTextField
                          type="date"
                          variant="outlined"
                          id="dob"
                          name="dob"
                          value={formik.values.dob}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.dob && Boolean(formik.errors.dob)
                          }
                        />
                        <FormHelperText variant="error">
                          {formik.touched.dob && formik.errors.dob}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {/* Phone Number */}

                    <Grid item xs={12} md={4}>
                      <FormControl
                        error={formik.touched.phone && formik.errors.phone}
                        fullWidth
                      >
                        <Typography htmlFor="phone" variant="formSubHeading">
                          Phone Number
                        </Typography>
                        <StyledTextField
                          variant="outlined"
                          id="phone"
                          name="phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.phone && Boolean(formik.errors.phone)
                          }
                        />
                        <FormHelperText variant="error">
                          {formik.touched.phone && formik.errors.phone}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {/* Email */}

                    <Grid item xs={12} lg={4}>
                      <FormControl
                        error={formik.touched.email && formik.errors.email}
                        fullWidth
                      >
                        <Typography htmlFor="email" variant="formSubHeading">
                          EMAIL
                        </Typography>
                        <StyledTextField
                          type="email"
                          variant="outlined"
                          id="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                        />
                        <FormHelperText variant="error">
                          {formik.touched.email && formik.errors.email}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {/* Address */}

                    <Grid item xs={12} lg={8}>
                      <FormControl
                        error={formik.touched.address && formik.errors.address}
                        fullWidth
                      >
                        <Typography htmlFor="address" variant="formSubHeading">
                          ADDRESS
                        </Typography>
                        <StyledTextField
                          variant="outlined"
                          id="address"
                          name="address"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.address &&
                            Boolean(formik.errors.address)
                          }
                        />
                        <FormHelperText variant="error">
                          {formik.touched.address && formik.errors.address}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {/* Fourth row input */}

                    {/* Passport */}
                    <Grid item xs={12} lg={4}>
                      <FormControl fullWidth>
                        <Typography variant="formSubHeading">
                          passport
                        </Typography>

                        <Stack direction="row" spacing={1}>
                          <StyledFileInput
                            type="file"
                            id="photo"
                            name="photo"
                            onChange={(e) => handleFileChange(e)}
                            error={
                              formik.touched.photo &&
                              Boolean(formik.errors.photo)
                            }
                          />
                          {fileLoading.photo && <CircularProgress />}
                        </Stack>

                        <FormHelperText variant="error">
                          {formik.touched.photo && formik.errors.photo}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl fullWidth>
                        <Typography variant="formSubHeading">
                          signature
                        </Typography>
                        <Stack direction="row" spacing={1}>
                          <StyledFileInput
                            type="file"
                            id="signature"
                            name="signature"
                            onChange={(e) => handleFileChange(e)}
                            error={
                              formik.touched.signature &&
                              Boolean(formik.errors.signature)
                            }
                          />
                          {fileLoading.signature && <CircularProgress />}
                        </Stack>

                        <FormHelperText variant="error">
                          {formik.touched.signature && formik.errors.signature}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormControl fullWidth>
                        <Typography variant="formSubHeading">
                          recent resume
                        </Typography>
                        <Stack direction="row" spacing={1}>
                          <StyledFileInput
                            type="file"
                            id="resume"
                            name="resume"
                            onChange={(e) => handleFileChange(e)}
                            error={
                              formik.touched.resume &&
                              Boolean(formik.errors.resume)
                            }
                          />
                          {fileLoading.resume && <CircularProgress />}
                        </Stack>
                        <FormHelperText variant="error">
                          {formik.touched.resume && formik.errors.resume}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {/* Submit */}

                    <Grid item xs={12} md={12} textAlign={"right"}>
                      <StyledLoadingButton
                        loading={updateTeacherLoading}
                        type="submit"
                      >
                        Update
                      </StyledLoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </StyledPaper>
          </Grid>

          <Grid item xs={12} lg={4}>
            {/* PHOTO BOX */}
            <StyledPaper sx={{ height: "40%" }}>
              <Box
                className="paperBody"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  "& img": {
                    width: "124px",
                    height: "124px",
                    borderRadius: "50%",
                    border: "5px solid #eeeee",
                    marginBottom: 2,
                  },
                  "& .nameText": {
                    fontSize: "18px",
                    lineHeight: "20px",
                    marginBottom: "5px",
                    fontWeight: 300,
                  },
                  "& .regNo": {
                    marginBottom: "10px",
                  },
                }}
              >
                <Box
                  component="img"
                  alt="staff photo"
                  src={selectedStaff?.photo || ""}
                />
                <Typography variant="formHeading" className="nameText">
                  {formik.values.fullName}
                </Typography>
              </Box>
            </StyledPaper>

            {/* RESUME BOX */}
            <StyledPaper sx={{ height: "49%" }}>
              <Box
                className="paperBody"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  "& img": {
                    width: "124px",
                    height: "124px",
                    borderRadius: "50%",
                    border: "5px solid #eeeee",
                    marginBottom: 2,
                  },
                  "& .nameText": {
                    fontSize: "18px",
                    lineHeight: "20px",
                    marginBottom: "5px",
                    fontWeight: 300,
                  },
                  "& .regNo": {
                    marginBottom: "10px",
                  },
                }}
              >
                <Typography variant="formHeading" className="nameText">
                  Resume
                </Typography>
                <Box component="a" href={selectedStaff?.resume || ""}>
                  <Box
                    component="img"
                    alt="resume"
                    src={require("../../../assets/images/resume.png")}
                    sx={{ border: "3px solid lightgray" }}
                  />
                </Box>
              </Box>
            </StyledPaper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default EditStaffDialog;
