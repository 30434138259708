import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { StyledLoadingButton } from "../../../customComponents/styled/styledButtons";
import {
  StyledSelect,
  StyledTextField,
} from "../../../customComponents/styled/styledInputs";
import { useUpdateBehaviorMutation } from "./traitsApiSlice";
import swal from "sweetalert";

function EditBehaviorForm({ item, setItem, traits, selectedTrait }) {
  const [updateBehavior, { isLoading }] = useUpdateBehaviorMutation();

  const createBehaviorForm = useFormik({
    initialValues: {
      id: item?.id,
      name: item?.name,
      trait_id: selectedTrait?.id,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string().required("Required"),
      trait_id: yup.string().required("Required"),
    }),

    onSubmit: async (values) => {
      try {
        const response = await updateBehavior(values).unwrap();
        if (response.id) {
          swal("Success", "Behavior updated successfully", "success");
          createBehaviorForm.resetForm();
          setItem(null);
        } else {
          swal("Error", "Behavior update failed", "error");
        }
      } catch (error) {
        swal("Error", "Something went wrong", "error");
      }
    },
  });

  return (
    <>
      <Box className="paperHeader">
        <Typography variant="formHeading">Create Behavior</Typography>
      </Box>
      <Box className="paperBody">
        <form onSubmit={createBehaviorForm.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">Behavior</Typography>
                <StyledTextField
                  autoFocus
                  variant="outlined"
                  id="name"
                  name="name"
                  value={createBehaviorForm.values.name}
                  onChange={createBehaviorForm.handleChange}
                  error={
                    createBehaviorForm.touched.name &&
                    Boolean(createBehaviorForm.errors.name)
                  }
                />
                <FormHelperText variant="error">
                  {createBehaviorForm.touched.name &&
                    createBehaviorForm.errors.name}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography variant="formSubHeading">trait</Typography>
                <StyledSelect
                  displayEmpty
                  id="trait_id"
                  name="trait_id"
                  value={createBehaviorForm.values.trait_id}
                  onChange={createBehaviorForm.handleChange}
                  error={
                    createBehaviorForm.touched.trait_id &&
                    Boolean(createBehaviorForm.errors.trait_id)
                  }
                >
                  <MenuItem value="" disabled>
                    <em>Select a trait</em>
                  </MenuItem>
                  {traits?.map((trait) => (
                    <MenuItem value={trait.id} key={trait.id}>
                      {trait.name}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <FormHelperText variant="error">
                  {createBehaviorForm.touched.trait_id &&
                    createBehaviorForm.errors.trait_id}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} textAlign={"right"}>
              <StyledLoadingButton loading={isLoading} type="submit">
                Submit
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default EditBehaviorForm;
