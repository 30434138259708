import { Box, Typography } from "@mui/material";
import React from "react";

function Footer() {
  return (
    <>
      <Box
        sx={{
          padding: "10px 15px",
          textAlign: "right",
          borderTop: "1px solid #e7e7e7",
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="p"
          sx={{ fontSize: "14px" }}
          color={"primary.main"}
          noWrap
          component="div"
        >
          &copy; {new Date().getFullYear()} Schoola SRC | All rights reserved.
        </Typography>
      </Box>
    </>
  );
}

export default Footer;
