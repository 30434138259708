import React from "react";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { Typography, Grid } from "@mui/material";
import StaffRegForm from "./new-staff-form";
import { FilledButton } from "../../customComponents/styled/styledButtons";
import StaffList from "./StaffList";

function Staff() {
  const [activeTab, setActiveTab] = React.useState("StaffRegForm");

  return (
    <Grid container columnSpacing={4} sx={{ padding: "0 20px" }}>
      <Grid item xs={12} md={2} sx={{ textAlign: "center" }}>
        <StyledPaper sx={{ paddingTop: "15px", paddingBottom: "25px" }}>
          <Typography
            sx={{
              fontSize: "15px",
              borderBottom: "1px solid lightgrey",
              paddingBottom: "12px",
              width: "100%",
            }}
            variant="formHeading"
          >
            MENU
          </Typography>

          <Typography
            sx={{
              width: "100%",
              fontSize: "12px",
              borderRadius: "0",
              color: "primary.main",
              backgroundColor: "white",
              textTransform: "none",
              padding: "7px 15px",
              textAlign: "start",
              border: "none",
              "&:hover": {
                backgroundColor: "rgba(211,211,211, 0.2)",
                border: "none",
                cursor: "pointer",
              },
              "&.activeTab": {
                boxShadow: "none",
                backgroundColor: "primary.main",
                color: "white",
                border: "none",
              },
            }}
            className={activeTab === "StaffRegForm" && "activeTab"}
            onClick={() => setActiveTab("StaffRegForm")}
          >
            Add New Staff
          </Typography>

          <Typography
            sx={{
              width: "100%",
              fontSize: "12px",
              borderRadius: "0",
              color: "primary.main",
              backgroundColor: "white",
              textTransform: "none",
              padding: "7px 15px",
              textAlign: "start",
              border: "none",
              "&:hover": {
                backgroundColor: "rgba(211,211,211, 0.2)",
                border: "none",
                cursor: "pointer",
              },
              "&.activeTab": {
                boxShadow: "none",
                backgroundColor: "primary.main",
                color: "white",
                border: "none",
              },
            }}
            className={activeTab === "StaffList" && "activeTab"}
            onClick={() => setActiveTab("StaffList")}
          >
            Staff List
          </Typography>
        </StyledPaper>
      </Grid>

      <Grid item xs={12} md={10}>
        {activeTab === "StaffRegForm" && <StaffRegForm />}
        {activeTab === "StaffList" && <StaffList />}
      </Grid>
    </Grid>
  );
}

export default Staff;
