import { Typography, Grid, FormControl, TextField, Input } from '@mui/material';
import {React, useState} from 'react';
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { StyledTextField } from "../../customComponents/styled/styledInputs";
import { FilledButton } from "../../customComponents/styled/styledButtons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';



function Settings() {

    
    const handleChangeBasic = event =>{
       var x = (event.target.value * 10);
       document.getElementById("basic_sms_cost").value = x;
    }
    
    const handleChangePremium = event => {
       var y = (event.target.value * 50);
       document.getElementById("premium-sms-cost").value = y;

    }

  return (
    <>
             {/* BASIC SMS BOX */}
    <StyledPaper sx={{ padding: "15px" }}>
        <Typography variant="h6"> Basic SMS Topup</Typography>
        <Typography variant="formHeading" sx={{marginBottom: "30px", }}>Basic SMS will only deliver to numbers without active Do Not Disturb (DND).</Typography>

        <Typography variant="p" sx={{color: "primary.main" }}> <FontAwesomeIcon icon={faMoneyBill}/> Balance: <span style={{fontWeight: "700" }}>0</span></Typography>

        
        <Grid container spacing={3}>
            
            <Grid item xs={12} md={6} >
            <FormControl fullWidth>
            <Typography variant="formSubHeading">SMS CREDITS</Typography>
            <StyledTextField onChange={handleChangeBasic} type="number" id="basic-sms" name="basic-sms"  />
            </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
            <FormControl fullWidth>
            <Typography variant="formSubHeading">CALCULATED COSTS</Typography>
            <Input readOnly type="text" id="basic_sms_cost" name="basic-sms-cost" disableUnderline  sx={{
                                                    backgroundColor: "rgba(211,211,211,0.5)", 
                                                    height: "100%",
                                                    paddingLeft: "10px",
                                                    fontWeight: "600", 
                                                    border: "1px solid #ccc",
                                                    boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
                                                    borderRadius: "4px", "& :hover": { cursor: "not-allowed"} }}/>
            </FormControl>
            </Grid>

            <Grid item xs={12} md={12} textAlign={"right"}>
              <FilledButton type="submit" sx={{textTransform: "none"}}>Buy Basic SMS</FilledButton>
            </Grid>
            
        </Grid>
    </StyledPaper>

                                                        {/* PREMIUM SMS BOX */}

<StyledPaper sx={{ padding: "15px" }}>
<Typography variant="h6"> Premium SMS Topup</Typography>
<Typography variant="formHeading" sx={{marginBottom: "30px", }}>Premium SMS will deliver to any number, anywhere, anytime.</Typography>

<Typography variant="p" sx={{color: "primary.main", }}><FontAwesomeIcon icon={faMoneyBill}/> Balance: <span style={{fontWeight: "700" }}>0</span></Typography>


<Grid container spacing={3}>
    
    <Grid item xs={12} md={6} >
    <FormControl fullWidth>
    <Typography variant="formSubHeading">SMS CREDITS</Typography>
    <StyledTextField type="number" id="premium-sms" name="premium-sms" onChange={handleChangePremium} />
    </FormControl>
    </Grid>

    <Grid item xs={12} md={6}>
    <FormControl fullWidth>
    <Typography variant="formSubHeading">CALCULATED COSTS</Typography>
    <Input id="premium-sms-cost" name="premium-sms-cost" disableUnderline readOnly sx={{
                                                    backgroundColor: "rgba(211,211,211,0.5)", 
                                                    height: "100%", 
                                                    border: "1px solid #ccc",
                                                    paddingLeft: "10px",
                                                    fontWeight: "600",
                                                    boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
                                                    borderRadius: "4px", "& :hover": { cursor: "not-allowed"} }}/>
    </FormControl>
    </Grid>

    <Grid item xs={12} md={12} textAlign={"right"}>
      <FilledButton type="submit" sx={{textTransform: "none"}}>Buy Premium SMS</FilledButton>
    </Grid>
    
</Grid>
</StyledPaper>

                                                                    {/* SMS SETTINGS */}
     <StyledPaper sx={{ padding: "15px" }}>
     <Typography variant="h6" sx={{marginBottom: "20px"}}> SMS Settings</Typography>


        <Grid container spacing={3}>
    
          <Grid item xs={12} md={6} >
            <FormControl fullWidth>
             <Typography variant="formSubHeading">Message Sender</Typography>
              <StyledTextField id="sms" name="sms" />
            </FormControl>
        </Grid>

    <Grid item xs={12} md={6}>
    <FormControl fullWidth>
    <Typography variant="formSubHeading">REPLIES SHOULD GO TO</Typography>
    <StyledTextField id="sms" name="sms" sx={{backgroundColor: "#ccc"}}/>
    </FormControl>
    </Grid>

    <Grid item xs={12} md={12} textAlign={"right"}>
      <FilledButton type="submit" sx={{textTransform: "none"}}>Save</FilledButton>
    </Grid>
    
</Grid>
</StyledPaper>

                                             {/* EMAIL SETTINGS */}

                                             <StyledPaper sx={{ padding: "15px" }}>
     <Typography variant="h6" sx={{marginBottom: "20px"}}> Email Settings</Typography>


        <Grid container spacing={3}>
    
          <Grid item xs={12} md={6} >
            <FormControl fullWidth>
             <Typography variant="formSubHeading">Message Sender</Typography>
              <StyledTextField id="sms" name="sms" />
            </FormControl>
        </Grid>

    <Grid item xs={12} md={6}>
    <FormControl fullWidth>
    <Typography variant="formSubHeading">REPLIES SHOULD GO TO</Typography>
    <StyledTextField id="sms" name="sms" sx={{backgroundColor: "#ccc"}}/>
    </FormControl>
    </Grid>

    <Grid item xs={12} md={12} textAlign={"right"}>
      <FilledButton type="submit" sx={{textTransform: "none"}}>Save</FilledButton>
    </Grid>
    
</Grid>
</StyledPaper>
</>
  )
}

export default Settings