import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Student"],
});

const studentApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getStudent: builder.query({
      query: (parameter) => `/student/${parameter}`,
      providesTags: ["Student"],
    }),
    createStudent: builder.mutation({
      query: (values) => ({
        url: "/student/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Student", "Classes"],
    }),
    updateStudent: builder.mutation({
      query: (values) => ({
        url: "/student/update",
        method: "PUT",
        body: { ...values },
      }),
      invalidatesTags: ["Student", "Classes"],
    }),
    deleteStudent: builder.mutation({
      query: (values) => ({
        url: `/student/${values.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Student", "Classes"],
    }),
    getStudentClassrooms: builder.query({
      query: (values) => `/student/${values.id}/classes`,
      providesTags: ["Student"],
    }),

    addStudentToClassroom: builder.mutation({
      query: (values) => ({
        url: `/classroom/${values.classroom_id}/students/add`,
        method: "PATCH",
        body: values.body,
      }),
      invalidatesTags: ["Student"],
    }),
    removeStudentFromClassroom: builder.mutation({
      query: (values) => ({
        url: `/classroom/${values.classroom_id}/students/remove`,
        method: "PATCH",
        body: values.body,
      }),
      invalidatesTags: ["Classes", "Student"],
    }),
  }),
});

export const {
  useCreateStudentMutation,
  useDeleteStudentMutation,
  useGetStudentQuery,
  useUpdateStudentMutation,
  useAddStudentToClassroomMutation,
  useGetStudentClassroomsQuery,
  useRemoveStudentFromClassroomMutation,
} = studentApiSlice;
