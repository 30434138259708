import { apiSlice } from "../../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Template"],
});

const templatesApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getTemplates: builder.query({
      query: (parameter) => `/template/${parameter}`,
      providesTags: ["Template"],
    }),
    createTemplate: builder.mutation({
      query: (values) => ({
        url: "/template/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Template"],
    }),
    updateTemplate: builder.mutation({
      query: (values) => ({
        url: "/template/update",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Template"],
    }),
    deleteTemplate: builder.mutation({
      query: (values) => ({
        url: `/template/${values.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Template"],
    }),
  }),
});

export const {
  useGetTemplatesQuery,
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useUpdateTemplateMutation,
} = templatesApiSlice;
