import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import {
  StyledSelect,
  StyledTextField,
} from "../../../customComponents/styled/styledInputs";
import { StyledLoadingButton } from "../../../customComponents/styled/styledButtons";
import {
  useCreateSubSectionMutation,
  useGetSectionQuery,
} from "./sectionsApiSlice";
import swal from "sweetalert";

const subSectionFormValidation = yup.object({
  name: yup.string().required("Required"),
  report_type: yup.string().required("Required"),
  section_id: yup.string().required("Required"),
});

function AddSubsectionForm({ focusedSection }) {
  const [createSubsection, { isLoading: creatingSubsection }] =
    useCreateSubSectionMutation();
  const { data: sectionData } = useGetSectionQuery("all");

  const subSectionForm = useFormik({
    initialValues: {
      name: "",
      report_type: "",
      section_id: focusedSection?.id || "",
    },
    enableReinitialize: true,
    validationSchema: subSectionFormValidation,
    onSubmit: async (values) => {
      try {
        const response = await createSubsection(values).unwrap();
        if (response) {
          swal("Success", "Subsection created successfully", "success");
          subSectionForm.resetForm();
        } else {
          swal("Error", "Subsection creation failed", "error");
        }
      } catch (error) {
        swal("Error", "Something went wrong", "error");
      }
    },
  });
  return (
    <>
      <Box className="paperHeader">
        <Typography variant="formHeading">Setup Sub-Sections</Typography>
      </Box>
      <Box className="paperBody">
        <form onSubmit={subSectionForm.handleSubmit}>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography variant="formSubHeading">Section</Typography>
                <StyledSelect
                  disabled={focusedSection !== null}
                  displayEmpty
                  id="section_id"
                  name="section_id"
                  value={subSectionForm.values.section_id}
                  onChange={subSectionForm.handleChange}
                  error={
                    subSectionForm.touched.section_id &&
                    Boolean(subSectionForm.errors.section_id)
                  }
                >
                  <MenuItem value="" disabled>
                    <em>select section</em>
                  </MenuItem>
                  {sectionData?.map((section) => (
                    <MenuItem value={section.id} key={section.id}>
                      {section.name}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <FormHelperText variant="error">
                  {subSectionForm.touched.section_id &&
                    subSectionForm.errors.section_id}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">
                  Sub-Section Name
                </Typography>
                <StyledTextField
                  variant="outlined"
                  id="name"
                  name="name"
                  value={subSectionForm.values.name}
                  onChange={subSectionForm.handleChange}
                  error={
                    subSectionForm.touched.name &&
                    Boolean(subSectionForm.errors.name)
                  }
                />
                <FormHelperText variant="error">
                  {subSectionForm.touched.name && subSectionForm.errors.name}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography variant="formSubHeading">Report Type</Typography>
                <StyledSelect
                  displayEmpty
                  id="report_type"
                  name="report_type"
                  value={subSectionForm.values.report_type}
                  onChange={subSectionForm.handleChange}
                  error={
                    subSectionForm.touched.report_type &&
                    Boolean(subSectionForm.errors.report_type)
                  }
                >
                  <MenuItem value="" disabled>
                    <em>select report type</em>
                  </MenuItem>
                  <MenuItem value="score">Score Based</MenuItem>
                  <MenuItem value="skill">Comment/Skill Based</MenuItem>
                </StyledSelect>
                <FormHelperText variant="error">
                  {subSectionForm.touched.report_type &&
                    subSectionForm.errors.report_type}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} textAlign={"right"}>
              <StyledLoadingButton loading={creatingSubsection} type="submit">
                submit
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default AddSubsectionForm;
