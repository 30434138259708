import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Subject"],
});

export const subjectApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getSubjects: builder.query({
      query: (parameter) => `subject/${parameter}`,
      providesTags: ["Subject"],
    }),
    createSubject: builder.mutation({
      query: (values) => ({
        url: `/subject/create`,
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Subject"],
    }),
    updateSubject: builder.mutation({
      query: (values) => ({
        url: `/subject/update`,
        method: "PUT",
        body: { ...values },
      }),
      invalidatesTags: ["Subject"],
    }),
    deleteSubject: builder.mutation({
      query: (values) => ({
        url: `/subject/${values.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Subject"],
    }),
  }),
});

export const {
  useGetSubjectsQuery,
  useCreateSubjectMutation,
  useUpdateSubjectMutation,
  useDeleteSubjectMutation,
} = subjectApiSlice;
