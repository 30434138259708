import React from 'react';
import { Grid, 
         Box, 
         FormControl, 
         Typography, 
         MenuItem, 
         FormHelperText } from '@mui/material';
import { useFormik } from "formik";
import * as yup from "yup";
import { StyledSelect,
         StyledTextField, } from "../../customComponents/styled/styledInputs";
  import { FilledButton } from "../../customComponents/styled/styledButtons";


const validationSchema = yup.object({
    house: yup.string().required("Required"),
    houseMaster: yup.string().required("Required"),
    
  });

function EditHouseForm({item}) {
    const formik = useFormik ({
        initialValues: {
            house: item.name,
            houseMaster: item.houseMaster,
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
    })
  return (
    <>
    <Typography variant="formHeading" sx={{marginBottom: "20px"}}>Edit House Form</Typography>

<Box component="form" onSubmit={formik.handleSubmit}>

<Grid container rowSpacing={3}>

    <Grid item xs={12} md={12}>
        <FormControl error={formik.touched.house && formik.errors.house} fullWidth>
            <Typography variant="formSubHeading">NAME OF HOUSE</Typography>
            <StyledTextField 
            id="house" 
            name="house" 
            value={formik.values.house} 
            onChange={formik.handleChange} 
            error={formik.touched.house && Boolean(formik.errors.house)} />

            <FormHelperText variant="error">
               {formik.touched.house && formik.errors.house}
            </FormHelperText>    
        </FormControl>
    </Grid>


    <Grid item xs={12} md={12}>
        <FormControl error={formik.touched.houseMaster && formik.errors.houseMaster} fullWidth>
            <Typography variant="formSubHeading">HOUSE MASTER / MISTRESS</Typography>
            <StyledSelect 
            id="houseMaster" 
            name="houseMaster" 
            onChange={formik.handleChange} 
            value={formik.values.houseMaster} 
            error={formik.touched.houseMaster && Boolean(formik.errors.houseMaster)}>
                <MenuItem value="Abdulalim Ladan">Abdulalim Ladan</MenuItem>
                <MenuItem value="Nasir Mustapha">Nasir Mustapha</MenuItem>
                <MenuItem value="Abdullahi Bature">Abdullahi Bature</MenuItem>
                <MenuItem value="Falilah Muhammad">Falilah Muhammad</MenuItem>
            </StyledSelect>
            <FormHelperText variant="error">
               {formik.touched.houseMaster && formik.errors.houseMaster}
            </FormHelperText>
        </FormControl>
    </Grid>

    <Grid item xs={12} md={12} textAlign={"right"}>
  <FilledButton type="submit" sx={{textTransform: "none"}}>Update</FilledButton>
</Grid>
</Grid>
</Box>
    </>
  )
}

export default EditHouseForm