import React from "react";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { Grid, Typography, Box, LinearProgress, Divider } from "@mui/material";
import { BsCashCoin } from "react-icons/bs";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { useGetPaymentSummaryQuery } from "./paymentSummaryApiSlice";

function PaymentsDashboard() {
  const { data: summary, isLoading: summaryLoading } =
    useGetPaymentSummaryQuery();

  console.log(summary);

  // const currentInvoice = summary?.current_invoice;
  // const currentPayment = summary?.current_payment;
  // const currentUnpaid = currentInvoice - currentPayment;

  // const totalInvoice = summary?.total_invoice;
  // const totalPayment = summary?.total_payment;
  // const totalUnpaid = totalInvoice - totalPayment;

  return (
    <>
      <StyledPaper sx={{ padding: "0px 0px 40px 0px" }}>
        <Typography variant="formHeading" sx={{ padding: "20px" }}>
          Payment Dashboard
        </Typography>
        {summaryLoading && <LinearProgress />}
        <Divider sx={{ margin: "0 20px 20px 20px" }} />

        <Grid container spacing={4} sx={{ padding: "0 20px" }}>
          {/*  CURRENT INVOICE */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                background: "#f0f8ff",
                textAlign: "center",
                borderRadius: "4px",
                color: "primary.main",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                alignItems: "center",
                paddingBottom: "30px",
              }}
            >
              <Typography
                variant="formSubHeading"
                sx={{ color: "primary.main", paddingTop: "20px" }}
              >
                CURRENT INVOICE
              </Typography>

              <DescriptionOutlinedIcon />

              <p> ₦{summary?.current_invoice?.toLocaleString() || 0.0}</p>
            </Box>
          </Grid>

          {/* CURRENT PAYMENTS */}

          <Grid item xs={12} md={4}>
            <Box
              sx={{
                background: "#54dfc3",
                textAlign: "center",
                borderRadius: "4px",
                color: "white",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "30px",
              }}
            >
              <Typography
                variant="formSubHeading"
                sx={{ color: "white", paddingTop: "20px" }}
              >
                CURRENT PAYMENTS
              </Typography>

              <BsCashCoin style={{ fontSize: "22px" }} />

              <p>
                {" "}
                ₦
                {(() => {
                  if (summary?.current_payment) {
                    return summary?.current_payment?.toLocaleString();
                  } else {
                    return 0.0;
                  }
                })()}
              </p>
            </Box>
          </Grid>

          {/* CURRENT UNPAID */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                background: "#ff7b91",
                textAlign: "center",
                borderRadius: "4px",
                color: "white",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "30px",
              }}
            >
              <Typography
                variant="formSubHeading"
                sx={{ color: "white", paddingTop: "20px" }}
              >
                CURRENT UNPAID
              </Typography>

              <AccountBalanceWalletOutlinedIcon style={{ fontSize: "22px" }} />

              <p>
                {" "}
                ₦
                {(() => {
                  if (summary?.current_invoice) {
                    const unpaid =
                      summary?.current_invoice - summary?.current_payment;
                    return unpaid?.toLocaleString();
                  } else {
                    return 0.0;
                  }
                })()}
              </p>
            </Box>
          </Grid>

          {/* ALL INVOICE */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                background: "#54dfc3",
                textAlign: "center",
                borderRadius: "4px",
                color: "white",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                alignItems: "center",
                paddingBottom: "30px",
              }}
            >
              <Typography
                variant="formSubHeading"
                sx={{ color: "white", paddingTop: "20px" }}
              >
                ALL INVOICE
              </Typography>

              <DescriptionOutlinedIcon />

              <p> ₦{summary?.total_invoice?.toLocaleString() || 0}</p>
            </Box>
          </Grid>

          {/* ALL PAYMENTS */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                background: "#ff7b91",
                textAlign: "center",
                borderRadius: "4px",
                color: "white",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "30px",
              }}
            >
              <Typography
                variant="formSubHeading"
                sx={{ color: "white", paddingTop: "20px" }}
              >
                ALL PAYMENTS
              </Typography>

              <BsCashCoin style={{ fontSize: "22px" }} />

              <p>
                {" "}
                ₦
                {(() => {
                  if (summary?.total_payment) {
                    return summary?.total_payment?.toLocaleString();
                  } else {
                    return 0.0;
                  }
                })() || 0.0}
              </p>
            </Box>
          </Grid>

          {/* ALL UNPAID */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                background: "#f0f8ff",
                textAlign: "center",
                borderRadius: "4px",
                color: "primary.main",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "30px",
              }}
            >
              <Typography
                variant="formSubHeading"
                sx={{ color: "primary.main", paddingTop: "20px" }}
              >
                ALL UNPAID
              </Typography>

              <AccountBalanceWalletOutlinedIcon style={{ fontSize: "22px" }} />

              <p>
                {" "}
                ₦
                {(() => {
                  if (summary?.total_invoice) {
                    const unpaid =
                      summary?.total_invoice - summary?.total_payment;
                    return unpaid.toLocaleString();
                  } else {
                    return 0.0;
                  }
                })()}
              </p>
            </Box>
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  );
}

export default PaymentsDashboard;
