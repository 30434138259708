import React from "react";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { Typography, Grid, Box } from "@mui/material";
import Settings from "./settings";
import ScheduleMessages from "./scheduleMessages";
import SendMessages from "./sendMessages";
import SettingsIcon from "@mui/icons-material/Settings";
import AccessTimeFilledOutlinedIcon from "@mui/icons-material/AccessTimeFilledOutlined";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import SendIcon from "@mui/icons-material/Send";
import History from "./history";

function MessageCenter() {
  const [activeTab, setActiveTab] = React.useState("Settings");
  return (
    <>
      <Grid container columnSpacing={5} sx={{ padding: "0 20px" }}>
        <Grid item xs={12} md={3}>
          <StyledPaper sx={{ paddingTop: "15px", paddingBottom: "25px" }}>
            <Typography
              sx={{
                borderBottom: "1px solid lightgrey",
                paddingBottom: "12px",
                width: "100%",
                paddingLeft: "30px",
              }}
              variant="formHeading"
            >
              MENU
            </Typography>

            <Box
              sx={{
                color: "primary.main",
                gap: "5px",
                borderBottom: "1px solid #ccc",
                fontSize: "12px",
                padding: "10px 0px 10px 15px",
                display: "flex",
                alignItems: "center",

                "&:hover": {
                  backgroundColor: "rgba(211,211,211, 0.4)",
                  border: "none",
                  borderBottom: "1px solid #ccc",
                  cursor: "pointer",
                },
                "&.activeTab": {
                  boxShadow: "none",
                  backgroundColor: "primary.main",
                  color: "white",
                  border: "none",
                },
              }}
              className={activeTab === "Settings" && "activeTab"}
              onClick={() => setActiveTab("Settings")}
            >
              <SettingsIcon sx={{ fontSize: "15px", marginBottom: "2px" }} />
              Settings
            </Box>

            <Box
              sx={{
                color: "primary.main",
                gap: "5px",
                borderBottom: "1px solid #ccc",
                fontSize: "12px",
                padding: "10px 0px 10px 15px",
                display: "flex",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "rgba(211,211,211, 0.4)",
                  border: "none",
                  borderBottom: "1px solid #ccc",
                  cursor: "pointer",
                },
                "&.activeTab": {
                  boxShadow: "none",
                  backgroundColor: "primary.main",
                  color: "white",
                  border: "none",
                },
              }}
              className={activeTab === "ScheduleMessages" && "activeTab"}
              onClick={() => setActiveTab("ScheduleMessages")}
            >
              <AccessTimeFilledOutlinedIcon
                sx={{ fontSize: "15px", marginBottom: "2px" }}
              />
              Schedule Messages
            </Box>

            <Box
              sx={{
                color: "primary.main",
                gap: "5px",
                borderBottom: "1px solid #ccc",
                fontSize: "12px",
                padding: "10px 0px 10px 15px",
                display: "flex",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "rgba(211,211,211, 0.4)",
                  border: "none",
                  borderBottom: "1px solid #ccc",
                  cursor: "pointer",
                },
                "&.activeTab": {
                  boxShadow: "none",
                  backgroundColor: "primary.main",
                  color: "white",
                  border: "none",
                },
              }}
              className={activeTab === "SendMessages" && "activeTab"}
              onClick={() => setActiveTab("SendMessages")}
            >
              <SendIcon sx={{ fontSize: "15px", marginBottom: "2px" }} />
              Send Messages
            </Box>

            <Box
              sx={{
                color: "primary.main",
                gap: "5px",
                padding: "10px 0px 10px 15px",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "rgba(211,211,211, 0.4)",
                  border: "none",
                  cursor: "pointer",
                },
                "&.activeTab": {
                  boxShadow: "none",
                  backgroundColor: "primary.main",
                  color: "white",
                  border: "none",
                },
              }}
              className={activeTab === "History" && "activeTab"}
              onClick={() => setActiveTab("History")}
            >
              <RestoreFromTrashIcon
                sx={{ fontSize: "15px", marginBottom: "2px" }}
              />{" "}
              History
            </Box>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={9}>
          {activeTab === "Settings" && <Settings />}
          {activeTab === "ScheduleMessages" && <ScheduleMessages />}
          {activeTab === "SendMessages" && <SendMessages />}
          {activeTab === "History" && <History />}
        </Grid>
      </Grid>
    </>
  );
}

export default MessageCenter;
