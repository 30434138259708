import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  minHeight: "10px",
  padding: "4px 16px",
  [`&.${tableCellClasses.head}`]: {
    fontSize: "12px",
    textTransform: "uppercase",
    color: "#9A9A9A",
    fontWeight: 400,
    borderTop: "0",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textTransform: "lowercase",
    "&::first-letter": {
      textTransform: "uppercase",
    },
    "& .tableCellLink": {
      fontWeight: "bold",
      cursor: "pointer",
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: "#ccc",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export { StyledTableCell, StyledTableRow };
