import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import { FilledButton } from "../../customComponents/styled/styledButtons";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import NotificationsCard from "./NotificationsCard";
import QuickLinksCard from "./QuickLinksCard";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../auth/authSlice";

function Dashboard() {
  const [activeTab, setActiveTab] = React.useState(1);
  const user = useSelector(selectCurrentUser);
  const placeHolderImage = require("../../../assets/images/schoola-school-logo.png");

  return (
    <>
      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Box sx={{ padding: "30px 0" }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  component="img"
                  src={user?.teacher?.image || placeHolderImage}
                  sx={{
                    width: "50%",
                    height: "50%",
                    borderRadius: "50%",
                  }}
                />
              </Box>
              <Box sx={{ marginTop: "20px", textAlign: "center" }}>
                <Typography
                  sx={{
                    color: "#5a7391",
                    fontSize: "20px",
                    fontWeight: 600,
                    marginBottom: "7px",
                    textTransform: "capitalize",
                  }}
                >
                  Welcome, {user?.profile?.first_name}{" "}
                  {user?.profile?.last_name}
                </Typography>
                <Link to="edit-profile">
                  <FilledButton sx={{ marginTop: "10px" }}>
                    Edit Profile
                  </FilledButton>
                </Link>
              </Box>

              <Box
                sx={{
                  marginTop: "30px",
                  "& .navItem": {
                    fontSize: "16px",
                    fontWeight: 400,
                    display: "block",
                    padding: "10px 15px",
                    textTransform: "capitalize",
                    color: "#93a3b5",
                    cursor: "pointer",
                  },
                  "& .navItem.active": {
                    color: "primary.main",
                    backgroundColor: "primary.transparent",
                    borderLeft: "2px solid",
                    borderLeftColor: "primary.main",
                    marginLeft: "-2px",
                  },
                  "& .navItem:hover": {
                    backgroundColor: "#f3f6fa",
                  },
                }}
              >
                <Typography
                  className={activeTab === 1 ? "navItem active" : "navItem"}
                  onClick={() => setActiveTab(1)}
                >
                  notifications
                </Typography>
                <Typography
                  className={activeTab === 2 ? "navItem active" : "navItem"}
                  onClick={() => setActiveTab(2)}
                >
                  quick links
                </Typography>
              </Box>
            </Box>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={8}>
          <StyledPaper>
            {activeTab === 1 ? <NotificationsCard /> : <QuickLinksCard />}
          </StyledPaper>
        </Grid>
      </Grid>
    </>
  );
}

export default Dashboard;
