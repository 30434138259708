import {
  Grid,
  Stack,
  Box,
  Typography,
  FormControl,
  MenuItem,
} from "@mui/material";
import React from "react";
import { FilledButton } from "../../customComponents/styled/styledButtons";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import {
  StyledSelect,
  StyledTextField,
} from "../../customComponents/styled/styledInputs";

function ScoreSheet() {
  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={12} lg={11}>
        <StyledPaper>
          <Box className="paperBody" sx={{ textAlign: "center" }}>
            <Typography variant="formHeading" color="primary.main">
              Schoola international school
            </Typography>
            <Typography variant="formSubHeading">
              <b>Session:</b>2020/2020 | <b>Term:</b> Second Term |<b>Class:</b>{" "}
              JSS2 | <b>Subject:</b> English
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <FormControl fullWidth>
                  <StyledSelect required id="class" name="class">
                    <MenuItem value="offering">offering</MenuItem>
                    <MenuItem value="notOffering">not offering</MenuItem>
                  </StyledSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={5}>
                <FormControl fullWidth>
                  <StyledTextField
                    required
                    value="student name"
                    disabled
                    bold="true"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormControl fullWidth>
                  <StyledTextField
                    required
                    value="student reg"
                    disabled
                    bold="true"
                  />
                </FormControl>
              </Grid>
              {/* first row */}
              <Grid item xs={12} lg={3}>
                <FormControl fullWidth>
                  <Typography variant="formSubHeading">c.a</Typography>
                  <StyledTextField
                    required
                    type="number"
                    id="class"
                    name="class"
                    value="0"
                    disabled
                    bold="true"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={3}>
                <FormControl fullWidth>
                  <Typography variant="formSubHeading">exam</Typography>
                  <StyledTextField
                    required
                    type="number"
                    id="class"
                    name="class"
                    bold="true"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={3}>
                <FormControl fullWidth>
                  <Typography variant="formSubHeading">total</Typography>
                  <StyledTextField
                    required
                    type="number"
                    id="class"
                    name="class"
                    disabled
                    bold="true"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={3}>
                <FormControl fullWidth>
                  <Typography variant="formSubHeading">grade</Typography>
                  <StyledTextField
                    required
                    type="number"
                    id="class"
                    name="class"
                    disabled
                    bold="true"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </StyledPaper>
      </Grid>
      <Grid item xs={12} lg={1}>
        <Stack
          direction={{ xs: "row", md: "column" }}
          spacing={1}
          sx={{
            "& .btn-icon": {
              fontSize: "14px",
            },
          }}
        >
          <FilledButton>
            <SaveIcon className="btn-icon" />
          </FilledButton>
          <FilledButton>
            <DeleteIcon className="btn-icon" />
          </FilledButton>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ScoreSheet;
