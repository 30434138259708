import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { StyledLoadingButton } from "../../../customComponents/styled/styledButtons";
import { StyledTextField } from "../../../customComponents/styled/styledInputs";
import { useUpdateTermMutation } from "./sessionApiSlice";
import { format, parseISO } from "date-fns";
import swal from "sweetalert";

const addTermValidation = yup.object({
  name: yup.string().required("Session name is required"),
  starts_at: yup.string().required("Required"),
  ends_at: yup.string().required("Required"),
});
function EditTermForm({ termToEdit, setTermToEdit }) {
  const [updateTerm, { isLoading }] = useUpdateTermMutation();
  const addTermForm = useFormik({
    initialValues: {
      id: termToEdit.id,
      name: termToEdit.name,
      starts_at: format(parseISO(termToEdit.starts_at), "yyyy-MM-dd"),
      ends_at: format(parseISO(termToEdit.starts_at), "yyyy-MM-dd"),
    },
    enableReinitialize: true,
    validationSchema: addTermValidation,
    onSubmit: async (values) => {
      try {
        const response = await updateTerm(values).unwrap();
        if (response) {
          swal("Success", "Term updated Successfully", "success");
          addTermForm.resetForm();
          setTermToEdit(null);
        }
      } catch (error) {
        swal("Error", "something went wrong", "error");
      }
    },
  });

  return (
    <div>
      <Box className="paperHeader">
        <Typography variant="formHeading">update term</Typography>
      </Box>
      <Box className="paperBody">
        <form onSubmit={addTermForm.handleSubmit}>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">Term Name</Typography>
                <StyledTextField
                  autoFocus
                  variant="outlined"
                  id="name"
                  name="name"
                  value={addTermForm.values.name}
                  onChange={addTermForm.handleChange}
                  error={
                    addTermForm.touched.name && Boolean(addTermForm.errors.name)
                  }
                />
                <FormHelperText variant="error">
                  {addTermForm.touched.name && addTermForm.errors.name}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* End of first row */}

            {/* End of second row */}

            <Grid item xs={12} lg={6}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">Start date</Typography>
                <StyledTextField
                  type="date"
                  variant="outlined"
                  id="starts_at"
                  name="starts_at"
                  value={addTermForm.values.starts_at}
                  onChange={addTermForm.handleChange}
                  error={
                    addTermForm.touched.starts_at &&
                    Boolean(addTermForm.errors.starts_at)
                  }
                />
                <FormHelperText variant="error">
                  {addTermForm.touched.starts_at &&
                    addTermForm.errors.starts_at}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/* End of third row */}

            <Grid item xs={12} lg={6}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">End Date</Typography>
                <StyledTextField
                  type="date"
                  variant="outlined"
                  id="ends_at"
                  name="ends_at"
                  value={addTermForm.values.ends_at}
                  onChange={addTermForm.handleChange}
                  error={
                    addTermForm.touched.ends_at &&
                    Boolean(addTermForm.errors.ends_at)
                  }
                />
                <FormHelperText variant="error">
                  {addTermForm.touched.ends_at && addTermForm.errors.ends_at}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} textAlign={"right"}>
              <StyledLoadingButton loading={isLoading} type="submit">
                update
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
}

export default EditTermForm;
