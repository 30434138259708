import { Grid } from "@mui/material";
import { useState } from "react";
import CustomMenuList from "../../customComponents/CustomMenuList";
import ExamsScoreSheet from "./ExamsScoreSheet";

function SectionReport() {
  const [activeClass, setActiveClass] = useState(0);
  const [activeStudent, setActiveStudent] = useState(0);

  const classes = [
    {
      name: "jss1",
      students: [
        {
          name: "John",
          id: 1,
          subjects: [
            {
              name: "English",
              ca: 10,
              exams: 20,
              total: 30,
              grade: "f",
              remarks: "failed",
            },
            {
              name: "maths",
              ca: 20,
              exams: 20,
              total: 40,
              grade: "f",
              remarks: "failed",
            },
          ],
        },
        {
          name: "Jane",
          id: 2,
          subjects: [
            {
              name: "English",
              ca: 10,
              exams: 20,
              total: 30,
              grade: "f",
              remarks: "failed",
            },
            {
              name: "maths",
              ca: 20,
              exams: 20,
              total: 40,
              grade: "f",
              remarks: "failed",
            },
          ],
        },
      ],
    },
    {
      name: "jss2",
      students: [
        { name: "jennifer", id: 1 },
        { name: "jacob", id: 2 },
      ],
    },
    {
      name: "jss3",
      students: [
        { name: "james", id: 1 },
        { name: "jamiu", id: 2 },
      ],
    },
  ];

  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={12} lg={2}>
        <CustomMenuList
          activeTab={activeClass}
          setActiveTab={setActiveClass}
          heading="Classes"
          list={classes.map((classs) => classs.name)}
        />
      </Grid>

      <Grid item xs={12} lg={3}>
        <CustomMenuList
          activeTab={activeStudent}
          setActiveTab={setActiveStudent}
          heading="Students"
          list={classes[activeClass].students.map((student) => student.name)}
        />
      </Grid>

      <Grid item xs={12} lg={7}>
        <ExamsScoreSheet
          student={classes[activeClass].students[activeStudent]}
        />
      </Grid>
    </Grid>
  );
}

export default SectionReport;
