import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Report"],
});

const classReportApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getClassReport: builder.query({
      query: () => `/report/classroom`,
      providesTags: ["Report"],
    }),
    createClassReport: builder.mutation({
      query: (values) => ({
        url: "/report/classroom",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Report"],
    }),
    deleteClassReport: builder.mutation({
      query: (values) => ({
        url: "/report/classroom",
        method: "DELETE",
        body: { values },
      }),
      invalidatesTags: ["Report"],
    }),
  }),
});

export const {
  useGetClassReportQuery,
  useCreateClassReportMutation,
  useDeleteClassReportMutation,
} = classReportApiSlice;
