import React from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  StyledSelect,
  StyledTextField,
} from "../../customComponents/styled/styledInputs";
import { useFormik, FieldArray, FormikProvider } from "formik";
import * as yup from "yup";
import {
  FilledButton,
  StyledLoadingButton,
} from "../../customComponents/styled/styledButtons";
import { useCreateSubjectMutation } from "./subjectsApiSlice";
import swal from "sweetalert";
import { useGetTeacherQuery } from "../staff/staffApiSlice";
import {
  useAddSubjectToClassroomMutation,
  useGetClassesQuery,
} from "../classroom/classApiSlice";

const validationSchema = yup.object({
  subjectName: yup.string().required("Subject is required"),
  description: yup.string().required("Required"),
  classes: yup.array().of(
    yup.object().shape({
      class: yup.string().required("Required"),
      teacher: yup.string().required("Required"),
    })
  ),
});

function NewSubjectForm() {
  const { data: classrooms } = useGetClassesQuery("all"); //to get updated list of classrooms
  const { data: teachers } = useGetTeacherQuery("all");
  const subjectTypes = [
    "score",
    "grade",
    "comment",
  ]

  const [createSubject, { isLoading }] = useCreateSubjectMutation();
  const [addSubjectToClass, { isLoading: addSubjectToClassLoading }] =
    useAddSubjectToClassroomMutation();

  const formik = useFormik({
    initialValues: {
      subjectName: "",
      description: "",
      type: "",
      classes: [{ class: "", teacher: "" }],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values)
      try {
        const body = {
          name: values.subjectName,
          description: values.description,
          type: values.type,
        };
        const response = await createSubject(body).unwrap();
        if (response.id) {
          swal("Success", "Subject created successfully", "success");
          //add each subject to each class
          values.classes.forEach(async (classroom) => {
            const subjectToClassBody = {
              class_Id: classroom.class,
              teacher_id: classroom.teacher,
              subject_id: response.id,
            };
            const res = await addSubjectToClass(subjectToClassBody).unwrap();
            formik.resetForm();
            if (res.message) {
              swal(
                "Success",
                "Subject added to classes successfully",
                "success"
              );
            }
          });
        }
      } catch (error) {
        console.log(error);
        swal("Error", "Something went wrong", "error");
      }
    },
  });

  return (
    <>
      <Box className="paperHeader">
        <Typography variant="formHeading">New Subject Form</Typography>
      </Box>
      <Box className="paperBody">
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Grid container columnSpacing={1} rowSpacing={3}>
            <Grid item xs={12} md={4}>
              <FormControl
                fullWidth
                error={formik.touched.subjectName && formik.errors.subjectName}
              >
                <Typography variant="formSubHeading">Subject Name</Typography>
                <StyledTextField
                  autoFocus
                  variant="outlined"
                  id="subjectName"
                  name="subjectName"
                  value={formik.values.subjectName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.subjectName &&
                    Boolean(formik.errors.subjectName)
                  }
                />
                <FormHelperText variant="error">
                  {formik.touched.subjectName && formik.errors.subjectName}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={8}>
              <FormControl
                fullWidth
                error={formik.touched.description && formik.errors.description}
              >
                <Typography htmlFor="description" variant="formSubHeading">
                  description
                </Typography>
                <StyledTextField
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
                <FormHelperText variant="error">
                  {formik.touched.description && formik.errors.description}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* beggining second row inputs */}

            <FormikProvider value={formik}>
              <FieldArray
                name="classes"
                render={(arrayHelpers) => {
                  const classes = formik.values.classes;
                  return (
                    <>
                      {classes && classes.length > 0
                        ? classes.map((row, index) => (
                            <React.Fragment key={index}>
                              <Grid item xs={12} md={3}>
                                <FormControl
                                  error={
                                    formik.touched.type &&
                                    formik.errors.type
                                  }
                                  fullWidth
                                >
                                  <Typography
                                    htmlFor="type"
                                    variant="formSubHeading"
                                  >
                                    Type
                                  </Typography>
                                  <StyledSelect
                                    displayEmpty
                                    id="type"
                                    name="type"
                                    value={row.type}
                                    onChange={formik.handleChange}
                                    error={
                                      formik.touched.type &&
                                      Boolean(formik.errors.type)
                                    }
                                  >
                                    <MenuItem value="" disabled>
                                      <em>select type</em>
                                    </MenuItem>
                                    {subjectTypes?.map((type) => (
                                      <MenuItem
                                        key={type}
                                        value={type}
                                      >
                                        {type}
                                      </MenuItem>
                                    ))}
                                  </StyledSelect>
                                  <FormHelperText variant="error">
                                    {formik.touched.type &&
                                      formik.errors.type}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <FormControl
                                  error={
                                    formik.touched.classes &&
                                    formik.errors.class
                                  }
                                  fullWidth
                                >
                                  <Typography
                                    htmlFor="class"
                                    variant="formSubHeading"
                                  >
                                    class
                                  </Typography>
                                  <StyledSelect
                                    displayEmpty
                                    id="class"
                                    name={`classes.${index}.class`}
                                    value={row.class}
                                    onChange={formik.handleChange}
                                    error={
                                      formik.touched.class &&
                                      Boolean(formik.errors.class)
                                    }
                                  >
                                    <MenuItem value="" disabled>
                                      <em>select classroom</em>
                                    </MenuItem>
                                    {classrooms?.map((classroom) => (
                                      <MenuItem
                                        key={classroom.id}
                                        value={classroom.id}
                                      >
                                        {classroom.name}
                                      </MenuItem>
                                    ))}
                                  </StyledSelect>
                                  <FormHelperText variant="error">
                                    {formik.touched.class &&
                                      formik.errors.class}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} md={4}>
                                <FormControl
                                  error={
                                    formik.touched.teacher &&
                                    formik.errors.teacher
                                  }
                                  fullWidth
                                >
                                  <Typography
                                    htmlFor="subjectType"
                                    variant="formSubHeading"
                                  >
                                    teacher
                                  </Typography>
                                  <StyledSelect
                                    displayEmpty
                                    id="teacher"
                                    name={`classes.${index}.teacher`}
                                    value={row.teacher}
                                    onChange={formik.handleChange}
                                    error={
                                      formik.touched.teacher &&
                                      Boolean(formik.errors.teacher)
                                    }
                                  >
                                    <MenuItem value="" disabled>
                                      <em>select teacher</em>
                                    </MenuItem>
                                    {teachers?.map((teacher) => (
                                      <MenuItem
                                        key={teacher.user_id}
                                        value={teacher.user_id}
                                      >
                                        {teacher.first_name} {teacher.last_name}
                                      </MenuItem>
                                    ))}
                                  </StyledSelect>
                                  <FormHelperText variant="error">
                                    {formik.touched.teacher &&
                                      formik.errors.teacher}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={1}
                                sx={{
                                  display: {
                                    md: "flex",
                                  },
                                  alignItems: "flex-end",
                                  justifyContent: "flex-end",
                                  mb: 0.5,
                                }}
                              >
                                {index === 0 ? (
                                  <FilledButton
                                    onClick={() =>
                                      arrayHelpers.push({
                                        class: "",
                                        teacher: "",
                                        assTeacher: "",
                                      })
                                    }
                                  >
                                    +
                                  </FilledButton>
                                ) : (
                                  <FilledButton
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    x
                                  </FilledButton>
                                )}
                              </Grid>
                            </React.Fragment>
                          ))
                        : null}
                    </>
                  );
                }}
              />
            </FormikProvider>

            <Grid item xs={12} md={12} textAlign={"right"}>
              <StyledLoadingButton
                type="submit"
                loading={isLoading || addSubjectToClassLoading}
              >
                SUBMIT
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default NewSubjectForm;
