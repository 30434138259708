import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { FilledButton } from "../../../customComponents/styled/styledButtons";
import { StyledTextField } from "../../../customComponents/styled/styledInputs";
import { StyledPaper } from "../../../customComponents/styled/styledPaper";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../customComponents/styled/styledTable";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";

function AddSpecialPeriods() {
  const [focusedItem, setFocusedItem] = React.useState(null);
  const [templateItems, setTemplatesItems] = React.useState([
    "long break",
    "short break",
  ]);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("Name is required"),
    }),
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      setTemplatesItems([...templateItems, values.name]);
      formik.resetForm();
    },
  });
  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={12} lg={4}>
        <StyledPaper>
          <Box className="paperHeader">
            <Typography variant="formHeading">Create Template Item</Typography>
          </Box>
          <Box className="paperBody">
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl error fullWidth>
                    <Typography variant="formSubHeading">Item Name</Typography>
                    <StyledTextField
                      variant="outlined"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                    />
                    <FormHelperText variant="error">
                      {formik.touched.name && formik.errors.name}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} textAlign={"right"}>
                  <FilledButton type="submit">SUBMIT</FilledButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </StyledPaper>
      </Grid>
      <Grid item xs={12} lg={8}>
        <StyledPaper>
          <Box className="paperHeader">
            <Typography variant="formHeading">Template Items</Typography>
          </Box>
          <TableContainer sx={{ pb: 4 }}>
            <Table sx={{ width: "100%", boxShadow: "none" }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Template</StyledTableCell>
                  <StyledTableCell align="left">edit</StyledTableCell>
                  <StyledTableCell align="left">delete</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {templateItems.map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row">
                      <Typography
                        sx={{ color: "primary.main", cursor: "pointer" }}
                      >
                        {row}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <SvgIcon
                        sx={{ color: "primary.main", cursor: "pointer" }}
                        component={EditIcon}
                        inheritViewBox
                        onClick={() => {
                          setFocusedItem(row);
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <DeleteIcon
                        sx={{ color: "primary.main", cursor: "pointer" }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledPaper>
      </Grid>
    </Grid>
  );
}

export default AddSpecialPeriods;
