import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Typography,
  Box,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import { React, useState } from "react";
import Delete from "@mui/icons-material/Delete";
import { useDeleteFeeMutation, useGetFeesQuery } from "./feeScheduleApiSlice";
import CustomWarningDialog from "../../customComponents/CustomWarningDialog";
import swal from "sweetalert";

function FeesScheduleDialog({ open, setOpen, selectedClass }) {
  const [openWarning, setOpenWarning] = useState(false);
  const [focusedItem, setFocusedItem] = useState(null);

  const { data: fees, isLoading: getFeesLoading } = useGetFeesQuery(
    `classroom/${selectedClass.id}`
  );

  const [deleteFee, { isLoading: deleteFeeLoading }] = useDeleteFeeMutation();

  console.log(fees);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteFee(focusedItem);
      console.log(response);
      if (response.data) {
        swal("Success", "Fee deleted successfully", "success");
      } else {
        swal("Error", "Error deleting Fee", "error");
      }
    } catch (error) {
      console.log(error);
      swal("Error", "Something went wrong", "error");
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "50vw",
          minHeight: "75vh",
        },
      }}
    >
      <CustomWarningDialog
        open={openWarning}
        setOpen={setOpenWarning}
        item={focusedItem}
        action={handleDelete}
        message="Are you sure you want to delete this fee?"
      />
      <Box
        sx={{ display: "flex", padding: 2, justifyContent: "space-between" }}
      >
        <Typography>{selectedClass?.name}</Typography>
        <CloseSharpIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
      </Box>
      <Divider />

      <DialogContent>
        <Grid container columnSpacing={3}>
          {/* FIRST TERM */}
          <Grid item xs={12} md={12}>
            <StyledPaper>
              <Box sx={{ padding: "15px" }}>
                <Typography
                  variant="formSubHeading"
                  sx={{ textTransform: "none" }}
                >
                  {selectedClass?.name} Fees Schedule
                </Typography>
              </Box>
              {getFeesLoading && <LinearProgress />}
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ borderBottom: "1.5px solid lightgrey " }}>
                      <StyledTableCell>NAME</StyledTableCell>
                      <StyledTableCell>AMOUNT</StyledTableCell>
                      <StyledTableCell>TYPE</StyledTableCell>
                      <StyledTableCell>DELETE</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fees?.map((fee) => (
                      <StyledTableRow>
                        <StyledTableCell
                          sx={{
                            color: "primary.main",
                            "&:hover": { cursor: "pointer" },
                          }}
                        >
                          {fee.name}
                        </StyledTableCell>

                        <StyledTableCell
                          sx={{
                            color: "primary.main",
                            "&:hover": { cursor: "pointer" },
                          }}
                        >
                          {fee.amount}
                        </StyledTableCell>

                        <StyledTableCell
                          sx={{
                            color: "primary.main",
                            "&:hover": { cursor: "pointer" },
                          }}
                        >
                          {fee.is_compulsory ? "Compulsory" : "Optional"}
                        </StyledTableCell>

                        <StyledTableCell>
                          <Box className="tableCellLink">
                            {focusedItem?.id === fee.id && deleteFeeLoading ? (
                              <CircularProgress />
                            ) : (
                              <Delete
                                onClick={() => {
                                  setOpenWarning(true);
                                  setFocusedItem(fee);
                                }}
                              />
                            )}
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </StyledPaper>
          </Grid>
          {/* SECOND TERM */}
          {/* <Grid item xs={12} md={12}>
            <StyledPaper>
              <Box sx={{ padding: "15px" }}>
                <Typography variant="formHeading">Second Term</Typography>
                <Typography
                  variant="formSubHeading"
                  sx={{ textTransform: "none" }}
                >
                  {selectedClass.class} Second Term Fees Schedule
                </Typography>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ borderBottom: "1.5px solid lightgrey " }}>
                      <StyledTableCell>ITEM</StyledTableCell>
                      <StyledTableCell>AMOUNT</StyledTableCell>
                      <StyledTableCell>TYPE</StyledTableCell>
                      <StyledTableCell>DELETE</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        sx={{
                          color: "primary.main",
                          "&:hover": { cursor: "pointer" },
                        }}
                      >
                        {(() => {
                          if (selectedClass.term === "Second Term") {
                            return selectedClass.item;
                          } else {
                            return "";
                          }
                        })()}
                      </StyledTableCell>

                      <StyledTableCell
                        sx={{
                          color: "primary.main",
                          "&:hover": { cursor: "pointer" },
                        }}
                      >
                        {(() => {
                          if (selectedClass.term === "Second Term") {
                            return selectedClass.amount;
                          } else {
                            return "";
                          }
                        })()}
                      </StyledTableCell>

                      <StyledTableCell
                        sx={{
                          color: "primary.main",
                          "&:hover": { cursor: "pointer" },
                        }}
                      >
                        {(() => {
                          if (selectedClass.term === "Second Term") {
                            return selectedClass.compulsory;
                          } else {
                            return "";
                          }
                        })()}
                      </StyledTableCell>

                      <StyledTableCell
                        sx={{
                          color: "primary.main",
                          "&:hover": { cursor: "pointer" },
                        }}
                      >
                        {(() => {
                          if (selectedClass.term === "Second Term") {
                            return <Delete />;
                          } else {
                            return "";
                          }
                        })()}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </StyledPaper>
          </Grid>
                      */}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default FeesScheduleDialog;
