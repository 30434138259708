import React, { useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { useFormik } from "formik";
import * as yup from "yup";
import { StyledTextField } from "../../customComponents/styled/styledInputs";
import { useLoginMutation } from "../../../app/authApiSlice";
import { useAppDispatch } from "../../../app/store";
import { login, selectCurrentToken, setAccessToken } from "./authSlice";
import { useGetSchoolProfileQuery } from "../settings/school/schoolApiSlice";
import { useSelector } from "react-redux";

function Login() {
  const token = useSelector(selectCurrentToken);
  const [loginMutation, { data: userData, isLoading, error }] =
    useLoginMutation();

  const { data: schoolProfile, isLoading: isGettingSchoolProfile } =
    useGetSchoolProfileQuery(undefined, { skip: !!!token });

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: yup.object({
      username: yup.string().required("Required"),
      password: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  const handleLogin = async (values) => {
    try {
      const userData = await loginMutation(values).unwrap();
      dispatch(setAccessToken({ access_token: userData.access_token }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (schoolProfile && userData) {
      dispatch(
        login({
          ...userData,
          user: { ...userData.user, current_school_id: schoolProfile.id },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolProfile, userData]);

  // added comment

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "#eee",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& paper": {
          background: "#fff",
          width: "60%",
        },
        "& .paperBody": {
          p: 4,
        },

        "& form": {
          "& > *": {
            mb: 2.5,
          },
          "& .heading": {
            mt: 2,
            color: "#27ae60",
            fontWeight: "bolder",
            fontVariant: "smallCaps",
            fontFamily:
              "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
            fontSize: "2.5rem",
          },
          "& .subHeading": {
            color: "#9faecb",
          },

          "& button": {
            background: "#27ae60",
            "& :hover": {
              background: "#27ae60",
            },
          },
        },
      }}
    >
      <StyledPaper>
        <Box className="paperBody">
          <Alert icon={false} severity="success">
            Please login to access this page
          </Alert>
          <Box component="form" onSubmit={formik.handleSubmit}>
            <Typography variant="h1" className="heading">
              LOGIN
            </Typography>
            <Typography className="subHeading">
              Sign in to your account
            </Typography>
            <FormControl error fullWidth>
              <StyledTextField
                autoFocus
                variant="outlined"
                id="username"
                name="username"
                placeholder="Mobile (e.g 08012345679)"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
              />
              <FormHelperText variant="error">
                {formik.touched.username && formik.errors.username}
              </FormHelperText>
            </FormControl>
            <FormControl error fullWidth>
              <StyledTextField
                autoComplete="false"
                autoFocus
                type="password"
                variant="outlined"
                id="password"
                name="password"
                placeholder="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
              />
              <FormHelperText variant="error">
                {formik.touched.password && formik.errors.password}
              </FormHelperText>
              {error && (
                <FormHelperText variant="error">
                  {error?.data?.message}
                </FormHelperText>
              )}
            </FormControl>
            <Stack direction="row">
              {isLoading || isGettingSchoolProfile ? (
                <CircularProgress />
              ) : (
                <Button type="submit" variant="contained">
                  Login
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
      </StyledPaper>
    </Box>
  );
}

export default Login;
