import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  StyledSelect,
  StyledTextField,
} from "../../../customComponents/styled/styledInputs";
import { useFormik } from "formik";
import * as yup from "yup";
import { StyledLoadingButton } from "../../../customComponents/styled/styledButtons";
import { useGetSubSectionQuery } from "../sections/sectionsApiSlice";
import swal from "sweetalert";
import { useCreateAssessmentMutation } from "./assessmentsApiSlice";

const validationSchema = yup.object({
  name: yup.string().required("Required"),
  obtainable_score: yup
    .number("must be a number")
    .max(100, "max is 100")
    .min(0, "min is 0")
    .required("Required"),
  subsection_id: yup.string().required("Required"),
});

function CreateAssessment() {
  const { data: subsections } = useGetSubSectionQuery("all");
  const [createAssessment, { isLoading: createAssessmentLoading }] =
    useCreateAssessmentMutation();

  const formik = useFormik({
    initialValues: {
      name: "",
      obtainable_score: "",
      subsection_id: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const body = {
        name: values.name,
        obtainable_score: parseInt(values.obtainable_score),
        subsection_id: values.subsection_id,
      };
      try {
        const response = await createAssessment(body).unwrap();
        if (response.id) {
          swal("Success", "Assessments created successfully", "success");
          formik.resetForm();
        } else {
          swal("Error", "Error creating assessment", "error");
        }
      } catch (error) {
        swal("Error", error.data?.message || "Something went wrong", "error");
      }
    },
  });

  return (
    <>
      <Box className="paperHeader">
        <Typography variant="formHeading">Create Assessment</Typography>
      </Box>

      <Box className="paperBody">
        <form onSubmit={formik.handleSubmit}>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12} lg={6}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">name</Typography>
                <StyledTextField
                  autoFocus
                  variant="outlined"
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                />
                <FormHelperText variant="error">
                  {formik.touched.name && formik.errors.name}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">
                  obtainable Score
                </Typography>
                <StyledTextField
                  autoFocus
                  variant="outlined"
                  id="obtainable_score"
                  name="obtainable_score"
                  value={formik.values.obtainable_score}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.obtainable_score &&
                    Boolean(formik.errors.obtainable_score)
                  }
                />
                <FormHelperText variant="error">
                  {formik.touched.obtainable_score &&
                    formik.errors.obtainable_score}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">sub-section</Typography>
                <StyledSelect
                  displayEmpty
                  variant="outlined"
                  id="subsection_id"
                  name="subsection_id"
                  value={formik.values.subsection_id}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.subsection_id &&
                    Boolean(formik.errors.subsection_id)
                  }
                >
                  <MenuItem value="" disabled>
                    <em>select subsection</em>
                  </MenuItem>
                  {subsections?.map((section) => (
                    <MenuItem key={section.id} value={section.id}>
                      {section.name}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <FormHelperText variant="error">
                  {formik.touched.section && formik.errors.section}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} textAlign={"right"}>
              <StyledLoadingButton
                loading={createAssessmentLoading}
                type="submit"
              >
                Submit
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default CreateAssessment;
