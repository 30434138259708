import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { StyledSelect } from "../../customComponents/styled/styledInputs";
import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { useGetTeacherQuery } from "../staff/staffApiSlice";
import {
  useAddTeacherToClassroomMutation,
  useGetClassesQuery,
  useRemoveTeacherFromClassroomMutation,
} from "./classApiSlice";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import DeleteIcon from "@mui/icons-material/Delete";
import swal from "sweetalert";
import CustomWarningDialog from "../../customComponents/CustomWarningDialog";

function ClassManagers({ item, setItem }) {
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [focusedTeacher, setFocusedTeacher] = useState(null);

  const { data: teachers } = useGetTeacherQuery("all");
  const { data: classrooms } = useGetClassesQuery("all"); //to get updated list of classrooms
  const [addTeacherToClass, { isLoading: addTeacherToClassLoading }] =
    useAddTeacherToClassroomMutation();
  const [removeTeacher] = useRemoveTeacherFromClassroomMutation();

  useEffect(() => {
    //set the selected class item to the updated list of classrooms item to reflect the changes in the modal
    const updatedClassroom = classrooms.find((c) => c.id === item.id);
    setItem(updatedClassroom);
  }, [classrooms]);

  const classForm = useFormik({
    initialValues: {
      formMaster: "",
    },
    validationSchema: yup.object({
      formMaster: yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      const body = {
        class_Id: item.id,
        user_id: values.formMaster,
      };
      try {
        const response = await addTeacherToClass(body).unwrap();
        if (response.message) {
          swal("Success", "Teacher added successfully", "success");
        } else {
          swal("Error", "Teacher not added", "error");
        }
      } catch (error) {
        swal("Error", "Something went wrong", "error");
      }
    },
  });

  const handleRemoveTeacher = async () => {
    try {
      const body = {
        class_Id: item.id,
        user_id: focusedTeacher.id,
      };
      const response = await removeTeacher(body).unwrap();
      if (response.message) {
        swal("Success", response.message, "success");
      }
    } catch (error) {
      swal("Error", "Something went wrong", "error");
    }
  };

  return (
    <StyledPaper>
      <CustomWarningDialog
        open={openWarningDialog}
        setOpen={setOpenWarningDialog}
        item={focusedTeacher}
        action={handleRemoveTeacher}
        message="are you sure you want to remove this teacher from this class?"
      />

      <Box className="paperHeader">
        <Typography variant="paperHeading">Class Form masters</Typography>
      </Box>
      <Box className="paperBody">
        <form onSubmit={classForm.handleSubmit}>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item xs="auto">
              <FormControl fullWidth>
                <Typography variant="formSubHeading">
                  add form master
                </Typography>
                <StyledSelect
                  displayEmpty
                  id="formMaster"
                  name="formMaster"
                  value={classForm.values.formMaster}
                  onChange={classForm.handleChange}
                  error={
                    classForm.touched.formMaster &&
                    Boolean(classForm.errors.formMaster)
                  }
                >
                  <MenuItem value="" disabled>
                    <em>select form master</em>
                  </MenuItem>
                  {teachers?.map((teacher) => (
                    <MenuItem key={teacher.user_id} value={teacher.user_id}>
                      {teacher.first_name} {teacher.last_name}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <FormHelperText variant="error">
                  {classForm.touched.formMaster && classForm.errors.formMaster}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid
              item
              xs="auto"
              sx={{
                display: "flex",
                alignItems: "flex-end",
                mb: 0.5,
              }}
            >
              <StyledLoadingButton
                loading={addTeacherToClassLoading}
                type="submit"
              >
                Add
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
      <TableContainer sx={{ pb: 1 }}>
        <Table sx={{ width: "100%", boxShadow: "none" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>id</StyledTableCell>
              <StyledTableCell>name</StyledTableCell>
              <StyledTableCell align="right">remove</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {item?.managers?.map((manager) => (
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  {manager?.profile?.user_id}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {manager?.profile?.first_name} {manager?.profile?.last_name}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="right">
                  <DeleteIcon
                    className="tableCellLink"
                    onClick={() => {
                      setOpenWarningDialog(true);
                      setFocusedTeacher(manager);
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledPaper>
  );
}

export default ClassManagers;
