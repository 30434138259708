import {
  Grid,
  MenuItem,
  Typography,
  FormControl,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Box,
  FormHelperText,
} from "@mui/material";
import { React, useState } from "react";
import {
  StyledSelect,
  StyledTextField,
} from "../../customComponents/styled/styledInputs";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import { useFormik } from "formik";
import * as yup from "yup";
import FeesScheduleDialog from "./feesScheduleDialog";
import { StyledMultiSelect } from "../../customComponents/styled/customMultiSelect";
import { useGetClassesQuery } from "../classroom/classApiSlice";
import { useCreateFeeMutation } from "./feeScheduleApiSlice";
import swal from "sweetalert";

const validationSchema = yup.object({
  class: yup.array().required("Required"),
  // term: yup.string().required("Required"),
  name: yup.string().required("Required"),
  amount: yup.string().required("Required"),
  compulsory: yup.string().required("Required"),
});

function FeesSchedule() {
  const { data: classrooms } = useGetClassesQuery("all");
  const [createFee, { isLoading: createFeeLoading }] = useCreateFeeMutation();

  const formik = useFormik({
    initialValues: {
      class: [],
      name: "",
      amount: "",
      compulsory: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      values.class?.forEach(async (item) => {
        try {
          let body = {
            name: values.name,
            amount: values.amount,
            is_compulsory: values.compulsory,
            classroom_id: item.value,
          };
          const response = await createFee(body).unwrap();
          if (response.id) {
            formik.resetForm();
            swal("Success", "Fee Created Successfully", "success");
          }
        } catch (error) {
          swal("Error", "Error Creating Fee", "error");
          console.log(error);
        }
      });
    },
  });

  function multiHandleChange(selectedOption) {
    formik.setFieldValue("class", selectedOption);
  }

  const [openEdit, setOpenEdit] = useState(false);
  const [selectedClass, setSelectedClass] = useState(false);

  return (
    <div style={{ padding: "0 10px" }}>
      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={4}>
          <StyledPaper sx={{ padding: "15px" }}>
            <Typography variant="formHeading" sx={{ marginBottom: "20px" }}>
              Fees Schedule
            </Typography>

            <Box component="form" onSubmit={formik.handleSubmit}>
              <Grid container rowSpacing={3}>
                <Grid item xs={12} md={12}>
                  <FormControl
                    error={formik.touched.class && formik.errors.class}
                    fullWidth
                  >
                    <Typography variant="formSubHeading">CLASS</Typography>
                    <StyledMultiSelect
                      id="class"
                      name="class"
                      onChange={multiHandleChange}
                      value={formik.setFieldValue.class}
                      error={
                        formik.touched.class && Boolean(formik.errors.class)
                      }
                      options={classrooms?.map((classroom) => ({
                        label: classroom.name,
                        value: classroom.id,
                      }))}
                      placeholder="Select a class"
                    />

                    <FormHelperText variant="error">
                      {formik.touched.class && formik.errors.class}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormControl
                    error={formik.touched.item && formik.errors.item}
                    fullWidth
                  >
                    <Typography variant="formSubHeading">Name</Typography>
                    <StyledTextField
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                    />
                    <FormHelperText variant="error">
                      {formik.touched.name && formik.errors.name}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormControl
                    error={formik.touched.amount && formik.errors.amount}
                    fullWidth
                  >
                    <Typography variant="formSubHeading">AMOUNT</Typography>
                    <StyledTextField
                      type="number"
                      id="amount"
                      name="amount"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.amount && Boolean(formik.errors.amount)
                      }
                    />
                    <FormHelperText variant="error">
                      {formik.touched.amount && formik.errors.amount}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormControl
                    error={
                      formik.touched.compulsory && formik.errors.compulsory
                    }
                    fullWidth
                  >
                    <Typography variant="formSubHeading">
                      IS COMPULSORY
                    </Typography>
                    <StyledSelect
                      id="compulsory"
                      name="compulsory"
                      onChange={formik.handleChange}
                      value={formik.values.compulsory}
                      error={
                        formik.touched.compulsory &&
                        Boolean(formik.errors.compulsory)
                      }
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </StyledSelect>
                    <FormHelperText variant="error">
                      {formik.touched.compulsory && formik.errors.compulsory}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} textAlign={"right"}>
                  <StyledLoadingButton
                    loading={createFeeLoading}
                    type="submit"
                    sx={{ textTransform: "none" }}
                  >
                    Save
                  </StyledLoadingButton>
                </Grid>
              </Grid>
            </Box>
          </StyledPaper>
        </Grid>

        {/* Generated Table */}

        <Grid item xs={12} md={8}>
          <StyledPaper sx={{ paddingBottom: "25px" }}>
            <FeesScheduleDialog
              open={openEdit}
              setOpen={setOpenEdit}
              selectedClass={selectedClass}
            />
            <Box sx={{ padding: "15px" }}>
              <Typography variant="formHeading">Classes</Typography>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ borderBottom: "1.5px solid lightgrey " }}>
                    <StyledTableCell>CLASSROOMS</StyledTableCell>
                    {/* <StyledTableCell>TERM</StyledTableCell>
                    <StyledTableCell>FEES</StyledTableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {classrooms?.map((classroom) => (
                    <StyledTableRow
                      className="tableCellLink"
                      onClick={() => {
                        setOpenEdit(true);
                        setSelectedClass(classroom);
                      }}
                    >
                      <StyledTableCell
                        sx={{
                          color: "primary.main",
                          "&:hover": { cursor: "pointer" },
                        }}
                      >
                        {classroom?.name}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </Grid>
      </Grid>
    </div>
  );
}

export default FeesSchedule;
