import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import PrintIcon from "@mui/icons-material/Print";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import { useReactToPrint } from "react-to-print";
import { FilledButton } from "../../customComponents/styled/styledButtons";
import { StyledTextField } from "../../customComponents/styled/styledInputs";
import { useGetClassesQuery } from "../classroom/classApiSlice";
import { Avatar, Checkbox, LinearProgress, TableCell } from "@mui/material";
import EditStudentDialog from "./EditStudentDialog";
import CustomWarningDialog from "../../customComponents/CustomWarningDialog";
import { useDeleteStudentMutation } from "./studentApiSlice";
import swal from "sweetalert";
import PromoteStudentsDialog from "./PromoteStudentsDialog";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const headCells = [
    {
      id: "photo",

      label: "Photo",
    },
    {
      id: "reg_number",
      label: "reg number",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "id_card",
      label: "id card",
    },
    {
      id: "delete",
      label: "Delete",
    },
  ];

  const {
    order,
    orderBy,
    onRequestSort,
    numSelected,
    rowCount,
    onSelectAllClick,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  rows: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const {
    setSearchkey,
    searchKey,
    handlePrint,
    getStudentsLoading,
    numSelected,
    handlePromoteSelected,
  } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },

        "& .search": {
          margin: "0px 5px",
        },
      }}
    >
      {getStudentsLoading && <LinearProgress />}
      {numSelected > 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            background: "#F5F5F5",
            padding: 1,
          }}
        >
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
          <FilledButton onClick={handlePromoteSelected}>Promote</FilledButton>
        </Box>
      ) : (
        <>
          <FilledButton onClick={handlePrint}>
            Print
            <PrintIcon />
          </FilledButton>
          <StyledTextField
            className="search"
            placeholder="search..."
            value={searchKey}
            onChange={(e) => setSearchkey(e.target.value)}
          />
        </>
      )}
    </Toolbar>
  );
};

function EnhancedTable({ classroom }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [page, setPage] = React.useState(0);
  const [focusedStudent, setFocusedStudent] = React.useState(null); // for edit student
  const [openEdit, setOpenEdit] = React.useState(false); // for edit student
  const [selected, setSelected] = React.useState([]); // for bulk operation
  const [openWarning, setOpenWarning] = React.useState(false); // for delete student
  const [openPromoteStudents, setOpenPromoteStudents] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchkey, setSearchkey] = React.useState("");

  const componentToPrintRef = React.useRef();

  const { data: rows, isLoading: getStudentsLoading } = useGetClassesQuery(
    `${classroom?.id}/students`
  );

  const [deleteStudent] = useDeleteStudentMutation();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((item) => item);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClickSelect = (event, item) => {
    let newSelected = [...selected];

    if (event.target.checked) {
      newSelected.push(item);
    } else {
      newSelected = newSelected.filter((i) => i !== item);
    }

    setSelected(newSelected);
  };

  const isSelected = (item) => selected.indexOf(item) !== -1;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteStudent(focusedStudent).unwrap();
      if (response) {
        swal("Success", "Student Deleted Successfully", "Success");
      }
    } catch (error) {
      swal("Error", "error deleting student", "error");
    }
  };

  const handlePromoteSelected = async () => {
    setOpenPromoteStudents(true);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      {/* Modals */}
      <EditStudentDialog
        open={openEdit}
        setOpen={setOpenEdit}
        selectedStudent={focusedStudent}
        currentClassroom={classroom?.id}
      />
      <PromoteStudentsDialog
        open={openPromoteStudents}
        setOpen={setOpenPromoteStudents}
        selectedStudents={selected}
        setSelectedStudents={setSelected}
        currentClassroom={classroom?.id}
      />
      <CustomWarningDialog
        open={openWarning}
        setOpen={setOpenWarning}
        item={focusedStudent}
        action={handleDelete}
        message="Are you sure you want to delete this student?"
      />

      <div style={{ display: "none" }}>
        {/* Table to print, containing all the table data without searching or sorting */}
        <TableContainer ref={componentToPrintRef} sx={{ p: 4 }}>
          <Table>
            <TableHead>
              <Typography variant="formHeading" sx={{ marginBottom: "10px" }}>
                Students
              </Typography>
              <TableRow sx={{ borderBottom: "2px solid lightgrey" }}>
                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell>Photo</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Phone</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell>{i + 1}</StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Avatar
                      alt="passport"
                      src={row.photo}
                      sx={{ width: 24, height: 24 }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.first_name} {row.last_name}
                  </StyledTableCell>
                  <StyledTableCell>{row.phone}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <EnhancedTableToolbar
        setSearchkey={setSearchkey}
        searchkey={searchkey}
        handlePrint={handlePrint}
        handlePromoteSelected={handlePromoteSelected}
        getStudentsLoading={getStudentsLoading}
        numSelected={selected?.length}
      />

      {getStudentsLoading && <LinearProgress />}
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            numSelected={selected?.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows?.length}
          />

          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(rows, getComparator(order, orderBy))
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.filter((row) => {
                return (
                  row?.first_name
                    ?.toLowerCase()
                    .includes(searchkey.toLowerCase()) ||
                  row?.last_name
                    ?.toLowerCase()
                    .includes(searchkey.toLowerCase())
                );
              })
              ?.map((row) => {
                const isItemSelected = isSelected(row);
                return (
                  <StyledTableRow hover tabIndex={-1} key={row.id}>
                    <TableCell
                      padding="checkbox"
                      onClick={(event) => handleClickSelect(event, row)}
                    >
                      <Checkbox color="primary" checked={isItemSelected} />
                    </TableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ paddingLeft: "10px" }}
                    >
                      <Avatar
                        alt="passport"
                        src={row.photo}
                        sx={{ width: 24, height: 24 }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{row.reg_number}</StyledTableCell>
                    <StyledTableCell>
                      <Box
                        className="tableCellLink"
                        onClick={() => {
                          setFocusedStudent(row);
                          setOpenEdit(true);
                        }}
                      >
                        {row.first_name} {row.last_name}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Switch size="small" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <a
                        href={`https://src.schoola.app/student-idcard?student_id=${row.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <PrintIcon className="tableCellLink" />
                      </a>
                    </StyledTableCell>
                    <StyledTableCell>
                      <DeleteIcon
                        className="tableCellLink"
                        onClick={() => {
                          setFocusedStudent(row);
                          setOpenWarning(true);
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <StyledTableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default EnhancedTable;
