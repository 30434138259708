import { Box, SvgIcon } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import DeleteIcon from "@mui/icons-material/Delete";

function TemplateCard({ editable, image, link, title, description, active }) {
  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "3px",
        boxShadow: "0 1px 1px 0 rgb(0 0 0 / 10%)",
        paddingBottom: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        width: "100%",
        "& .img": {
          borderRadius: "2px",
          overflow: "hidden",
          width: "100%",
          height: "150px",
        },
        "& .title": {
          margin: "16px auto 10px auto",
          width: "80%",
          whiteSpace: "nowrap",
          display: "block",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "center",
        },
        "& .border": {
          height: "3px",
          width: "40px",
          backgroundColor: "primary.main",
          margin: "10px auto",
        },
        "& .description": {
          color: "#777",
          textAlign: "center",
          fontSize: "10px",
          textDecoration: "capitalize",
        },
      }}
    >
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        {editable && (
          <Box>
            <SvgIcon
              component={EditIcon}
              sx={{ height: "15px", width: "15px", color: "primary.main" }}
              inheritViewBox
            />
            <DeleteIcon
              sx={{
                height: "15px",
                width: "15px",
                color: "primary.main",
                ml: 0.5,
              }}
            />
          </Box>
        )}
        {active && (
          <CheckCircleIcon
            fontSize="small"
            sx={{
              height: "15px",
              width: "15px",
              color: "primary.main",
            }}
          />
        )}
      </Box>
      <a href={link} rel="noreferrer" target="_blank">
        <img src={image} className="img" alt="template img" />
      </a>
      <p className="title">{title}</p>
      <Box className="border" />
      <p className="description">{description}</p>
    </Box>
  );
}

export default TemplateCard;
