import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Report"],
});

const studentReportApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getStudentReport: builder.query({
      query: () => `/report/student`,
      providesTags: ["Report"],
    }),
    createStudentReport: builder.mutation({
      query: (values) => ({
        url: "/report/student",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Report"],
    }),
    deleteStudentReport: builder.mutation({
      query: (values) => ({
        url: "/report/student",
        method: "DELETE",
        body: { values },
      }),
      invalidatesTags: ["Report"],
    }),
  }),
});

export const {
  useGetStudentReportQuery,
  useCreateStudentReportMutation,
  useDeleteStudentReportMutation,
} = studentReportApiSlice;
