import {
    Box,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    Stack,
    Typography,
    Button
  } from "@mui/material";
  import {
    StyledFileInput,
    StyledSelect,
    StyledTextField,
  } from "../../customComponents/styled/styledInputs";
  import { useFormik } from "formik";
  import * as yup from "yup";
  import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
  import { StyledPaper } from "../../customComponents/styled/styledPaper";
  import {
    useAddStudentToClassroomMutation,
    useCreateStudentMutation,
  } from "./studentApiSlice";
  import { useUploadFileMutation } from "../../../app/utilsApiSlice";
  import { useGetClassesQuery } from "../classroom/classApiSlice";
  import swal from "sweetalert";
  

  const templateUrl = "/student-bulk-upload-template.xlsx";
  const validationSchema = yup.object({
    first_name: yup.string().required("Required"),
    last_name: yup.string().required("Required"),
    gender: yup.string().required("Required"),
  });
  
  function AddBulkStudent() {
    const { data: classrooms } = useGetClassesQuery("all");
    const [createStudent, { isLoading: createStudentLoading }] =
      useCreateStudentMutation();
    const [uploadFile, { isLoading: fileLoading }] = useUploadFileMutation();
  
    const [addStudentToClass] = useAddStudentToClassroomMutation();
  
    const formik = useFormik({
      initialValues: {
        first_name: "",
        last_name: "",
        email: "",
        dob: "",
        gender: "",
        classroom: "",
        address: "",
        phone: "",
        photo: "",
      },
  
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        try {
          const response = await createStudent(values).unwrap();
          if (response.id) {
            swal("Success", "Student created successfully", "success");
            const res = await addStudentToClass({
              body: {
                student_ids: [response.id],
              },
              classroom_id: values.classroom,
            }).unwrap();
            if (res) {
              swal("Success", "Student added to classroom", "success");
              formik.resetForm();
            }
          }
        } catch (error) {
          if (error.status === 500) {
            swal("Error", error.data.message, "error");
          } else {
            swal("Error", "Something went wrong", "error");
          }
        }
      },
    });
  
    const handleFileChange = async (e) => {
      try {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        const response = await uploadFile(formData).unwrap();
        if (response) {
          formik.setFieldValue(e.target.name, response.url);
        }
      } catch (error) {}
    };  
    return (
      <StyledPaper>
        <Box className="paperHeader">
          <Typography variant="formHeading">
          Bulk Student Registration Form
          </Typography>
        </Box>
        <Box className="paperBody">
          <Box component="form" onSubmit={formik.handleSubmit}>
            <Grid container columnSpacing={1} rowSpacing={3}>
              
            <Grid item xs={12} md={6}>
                <FormControl
                  error={formik.touched.classroom && formik.errors.classroom}
                  fullWidth
                >
                  <Typography htmlFor="classroom" variant="formSubHeading">
                    classroom
                  </Typography>
                  <StyledSelect
                    displayEmpty
                    id="classroom"
                    name="classroom"
                    value={formik.values.classroom}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.classroom && Boolean(formik.errors.classroom)
                    }
                  >
                    <MenuItem value="" disabled>
                      <em>select classroom</em>
                    </MenuItem>
                    {classrooms?.map((classroom) => (
                      <MenuItem key={classroom.id} value={classroom.id}>
                        {classroom.name}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                  <FormHelperText variant="error">
                    {formik.touched.classroom && formik.errors.classroom}
                  </FormHelperText>
                </FormControl>
              </Grid>
            {/* beginning of third row inputs */}
              <Grid item xs={12} md={6} >
                <FormControl fullWidth>
                  <Typography variant="formSubHeading">file</Typography>
  
                  <Stack direction="row" spacing={1}>
                    <StyledFileInput
                      type="file"
                      id="photo"
                      name="photo"
                      onChange={(e) => handleFileChange(e)}
                      error={formik.touched.photo && Boolean(formik.errors.photo)}
                    />
                    {fileLoading && <CircularProgress />}
                  </Stack>
                  <FormHelperText variant="error">
                    {formik.touched.photo && formik.errors.photo}
                  </FormHelperText>
                </FormControl>
              </Grid>
  
              {/* beginning of third row inputs */}
  
              <Grid item xs={12} md={12} textAlign={"right"}>
                <StyledLoadingButton loading={createStudentLoading} type="submit">
                  SUBMIT
                </StyledLoadingButton>
              </Grid>
              <Button
              href={templateUrl}
              download
              >
           Download Template Guide
        </Button>
            </Grid>
          </Box>
        </Box>
      </StyledPaper>
    );
  }
  
  export default AddBulkStudent;
  