import { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  TableBody,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  SvgIcon,
  LinearProgress,
} from "@mui/material";
import { StyledPaper } from "../../../customComponents/styled/styledPaper";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../customComponents/styled/styledTable";
import EditTraitCategoryForm from "./EditTraitCategoryForm";
import {
  useDeleteBehaviorMutation,
  useDeleteTraitMutation,
  useGetTraitQuery,
} from "./traitsApiSlice";
import EditBehaviorForm from "./EditBehaviorForm";
import CreateTraitForm from "./CreateTraitForm";
import CreateBehaviorForm from "./CreateBehaviorForm";
import CustomWarningDialog from "../../../customComponents/CustomWarningDialog";
import swal from "sweetalert";
import { useEffect } from "react";

function Traits() {
  const { data: traits, isLoading: getTraitsLoading } = useGetTraitQuery("all");
  const [deleteTrait] = useDeleteTraitMutation();
  const [deleteBehavior] = useDeleteBehaviorMutation();
  const [focusedCategory, setFocusedCategory] = useState(null); //for editing
  const [selectedTrait, setSelectedTrait] = useState(null);
  const [selectedBehavior, setSelectedBehavior] = useState(null);
  const [openWarning, setOpenWarning] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    const updatedTraits = traits?.find(
      (trait) => trait.id === selectedTrait?.id
    );
    setSelectedTrait(updatedTraits);
  }, [traits]);

  const handleDelete = async ({ fn, item }) => {
    try {
      const response = await fn(item).unwrap();
      if (response.message) {
        swal("Success", response.message, "success");
      } else {
        swal("Success", "Error deleting item", "success");
      }
    } catch (error) {
      swal("Error", "Somethng went wrong", "error");
    }
  };

  return (
    <>
      <CustomWarningDialog
        open={openWarning}
        setOpen={setOpenWarning}
        action={handleDelete}
        message="Are you sure you want to delete this?"
        item={itemToDelete}
      />
      <Grid container columnSpacing={3}>
        <Grid item xs={12} md={4}>
          <StyledPaper>
            {focusedCategory ? (
              <EditTraitCategoryForm
                item={focusedCategory}
                setItem={setFocusedCategory}
              />
            ) : (
              <CreateTraitForm />
            )}
          </StyledPaper>

          <StyledPaper>
            {selectedBehavior ? (
              <EditBehaviorForm
                item={selectedBehavior}
                setItem={setSelectedBehavior}
                traits={traits}
                selectedTrait={selectedTrait}
              />
            ) : (
              <CreateBehaviorForm />
            )}
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={8}>
          <StyledPaper>
            {getTraitsLoading && <LinearProgress />}
            <Box className="paperHeader">
              <Typography variant="formHeading">Traits</Typography>
            </Box>
            <TableContainer sx={{ pb: 4 }}>
              <Table sx={{ width: "100%", boxShadow: "none" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Trait </StyledTableCell>
                    <StyledTableCell align="left">edit</StyledTableCell>
                    <StyledTableCell align="left">delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {traits?.map((row, i) => (
                    <StyledTableRow key={i}>
                      <StyledTableCell component="th" scope="row">
                        <Box
                          className="tableCellLink"
                          onClick={() => setSelectedTrait(row)}
                        >
                          {row.name}
                        </Box>
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        <SvgIcon
                          className="tableCellLink"
                          component={EditIcon}
                          inheritViewBox
                          onClick={() => {
                            setFocusedCategory(row);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <DeleteIcon
                          className="tableCellLink"
                          onClick={() => {
                            setItemToDelete({ item: row, fn: deleteTrait });
                            setOpenWarning(true);
                          }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>

          <StyledPaper>
            <Box className="paperHeader">
              <Typography variant="formHeading">
                {selectedTrait
                  ? selectedTrait.name
                  : "select a trait to view behaviors"}{" "}
                behaviors
              </Typography>
            </Box>
            <TableContainer sx={{ pb: 4 }}>
              <Table sx={{ width: "100%", boxShadow: "none" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>name</StyledTableCell>
                    <StyledTableCell align="left">edit</StyledTableCell>
                    <StyledTableCell align="left">delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedTrait?.behaviors?.map((row, i) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <SvgIcon
                          sx={{ color: "primary.main", cursor: "pointer" }}
                          component={EditIcon}
                          inheritViewBox
                          onClick={() => {
                            setSelectedBehavior(row);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <DeleteIcon
                          sx={{ color: "primary.main", cursor: "pointer" }}
                          onClick={() => {
                            setItemToDelete({ item: row, fn: deleteBehavior });
                            setOpenWarning(true);
                          }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </Grid>
      </Grid>
    </>
  );
}

export default Traits;
