import { apiSlice } from "../../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["School"],
});

export const schoolApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getSchoolProfile: builder.query({
      query: () => `/school/profile`,
      providesTags: ["School"],
    }),
    getMySchools: builder.query({
      query: () => `/school/mine`,
      providesTags: ["School"],
    }),
    createSchoolProfile: builder.mutation({
      query: (values) => ({
        url: "/school/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["School"],
    }),
    updateSchoolProfile: builder.mutation({
      query: (values) => ({
        url: "/school/update",
        method: "PUT",
        body: { ...values },
      }),
      invalidatesTags: ["School"],
    }),
  }),
});

export const {
  useGetSchoolProfileQuery,
  useGetMySchoolsQuery,
  useCreateSchoolProfileMutation,
  useUpdateSchoolProfileMutation,
} = schoolApiSlice;
