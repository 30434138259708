import { apiSlice } from "../../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Assessment"],
});

const assessmentsApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAssessments: builder.query({
      query: (parameter) => `/assessment/${parameter}`,
      providesTags: ["Assessment"],
    }),
    createAssessment: builder.mutation({
      query: (values) => ({
        url: "/assessment/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Assessment"],
    }),
    updateAssessment: builder.mutation({
      query: (values) => ({
        url: "/assessment/update",
        method: "PUT",
        body: { ...values },
      }),
      invalidatesTags: ["Assessment"],
    }),
    deleteAssessment: builder.mutation({
      query: (values) => ({
        url: `/assessment/${values.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Assessment"],
    }),
  }),
});

export const {
  useGetAssessmentsQuery,
  useCreateAssessmentMutation,
  useDeleteAssessmentMutation,
  useUpdateAssessmentMutation,
} = assessmentsApiSlice;
