import { Grid } from "@mui/material";
import { useState } from "react";
import CustomMenuList from "../../customComponents/CustomMenuList";
import ScoreSheet from "./ScoreSheet";

const ExamManager = () => {
  const [activeSubject, setActiveSubject] = useState(0);
  const [activeCA, setActiveCA] = useState(0);

  const subjects = [
    {
      id: 1,
      name: "English",
      class: "jss2",
      types: ["first", "second", "third"],
    },
    {
      id: 2,
      name: "integrated science",
      class: "jss2",
      types: ["Assignment", "test", "practiacal"],
    },
    {
      id: 3,
      name: "Maths",
      class: "jss2",
      types: ["first", "second", "third"],
    },
    {
      id: 3,
      name: "Biology",
      class: "ss3",
      types: ["first", "second", "third"],
    },
  ];

  const subjectsMenu = subjects.map(
    (subject) => `${subject.name}: ${subject.class}`
  );

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} lg={3}>
          <CustomMenuList
            activeTab={activeSubject}
            setActiveTab={setActiveSubject}
            heading="Subject(s)"
            list={subjectsMenu}
            icon={
              <img
                src={require(`../../../assets/images/books.png`)}
                alt="subject"
                height={25}
                width={25}
              />
            }
          />
        </Grid>
        <Grid item xs={12} lg={9}>
          <ScoreSheet subjects={subjects} activeCA={activeCA} />
        </Grid>
      </Grid>
    </>
  );
};

export default ExamManager;
