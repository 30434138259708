import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import { React } from "react";

function InvoiceDialog({ open, setOpen, selectedInvoice }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "50vw",
          minHeight: "75vh",
        },
      }}
    >
      <Box
        sx={{ display: "flex", padding: 2, justifyContent: "space-between" }}
      >
        <Typography>student</Typography>
        <CloseSharpIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
      </Box>
      <Divider />

      <DialogContent>
        <Grid container columnSpacing={3}>
          {/* FIRST TERM */}
          <Grid item xs={12} md={12}>
            <StyledPaper>
              <Box sx={{ padding: "15px" }}>
                <Typography
                  variant="formSubHeading"
                  sx={{ textTransform: "none" }}
                >
                  Invoices
                </Typography>
              </Box>
              {false && <LinearProgress />}
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ borderBottom: "1.5px solid lightgrey " }}>
                      <StyledTableCell>NAME</StyledTableCell>
                      <StyledTableCell>AMOUNT</StyledTableCell>
                      <StyledTableCell>TYPE</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedInvoice?.items?.map((item) => (
                      <StyledTableRow>
                        <StyledTableCell
                          sx={{
                            color: "primary.main",
                            "&:hover": { cursor: "pointer" },
                          }}
                        >
                          {item.name}
                        </StyledTableCell>

                        <StyledTableCell
                          sx={{
                            color: "primary.main",
                            "&:hover": { cursor: "pointer" },
                          }}
                        >
                          {item.amount}
                        </StyledTableCell>

                        <StyledTableCell
                          sx={{
                            color: "primary.main",
                            "&:hover": { cursor: "pointer" },
                          }}
                        >
                          {item.is_compulsory ? "compulsory" : "optional"}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </StyledPaper>
            <StyledPaper>
              <Box sx={{ padding: "15px" }}>
                <Typography
                  variant="formSubHeading"
                  sx={{ textTransform: "none" }}
                >
                  Payments
                </Typography>
              </Box>
              {false && <LinearProgress />}
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ borderBottom: "1.5px solid lightgrey " }}>
                      <StyledTableCell>NAME</StyledTableCell>
                      <StyledTableCell>AMOUNT</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody></TableBody>
                </Table>
              </TableContainer>
            </StyledPaper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default InvoiceDialog;
