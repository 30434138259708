import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  LinearProgress,
  MenuItem,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  StyledSelect,
  StyledTextField,
} from "../../customComponents/styled/styledInputs";
import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
import ClassDialog from "./ClassDialog";
import { useGetSubSectionQuery } from "../settings/sections/sectionsApiSlice";
import { useGetTeacherQuery } from "../staff/staffApiSlice";
import {
  useAddTeacherToClassroomMutation,
  useCreateClassroomMutation,
  useDeleteClassroomMutation,
  useGetClassesQuery,
} from "./classApiSlice";
import swal from "sweetalert";
import CustomWarningDialog from "../../customComponents/CustomWarningDialog";

const classFormValidation = yup.object({
  name: yup.string().required("Required"),
  description: yup.string().required("Required"),
  level: yup.string().required("Required"),
  subsection: yup.string().required("Required"),
  formMaster: yup.string().required("Required"),
});

function StudentsCell({ id }) {
  const { data } = useGetClassesQuery(`${id}/students`);
  return (
    <StyledTableCell component="th" scope="row">
      {data?.length}
    </StyledTableCell>
  );
}

function Classes() {
  const [focusedClass, setFocusedClass] = useState(null);
  const [openClassDialog, setOpenClassDialog] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);

  const { data: subsections } = useGetSubSectionQuery("all");
  const { data: teachers } = useGetTeacherQuery("all");

  const { data: classrooms, isLoading: getClassRoomsLoading } =
    useGetClassesQuery("all");

  const [createClass, { isLoading: createClassLoading }] =
    useCreateClassroomMutation();

  const [addTeacherToClass, { isLoading: addTeacherToClassLoading }] =
    useAddTeacherToClassroomMutation();

  const [deleteClass] = useDeleteClassroomMutation();

  const classForm = useFormik({
    initialValues: {
      name: "",
      description: "",
      level: "",
      subsection: "",
      formMaster: "",
    },
    enableReinitialize: true,
    validationSchema: classFormValidation,
    onSubmit: async (values) => {
      const body = {
        name: values.name,
        description: values.description,
        subsection_id: values.subsection,
      };
      try {
        const response = await createClass(body).unwrap();
        classForm.resetForm();
        if (response.id) {
          swal("Success", "Class created successfully", "success");
          const addTeacherBody = {
            class_Id: response.id,
            user_id: values.formMaster,
          };
          console.log(addTeacherBody);
          const res = await addTeacherToClass(addTeacherBody).unwrap();

          if (res) {
            swal("Success", "Teacher added successfully", "success");
          } else {
            swal("Error", "Teacher not added", "error");
          }
        } else {
          swal("Error", "Class already exists", "error");
        }
      } catch (error) {
        swal("Error", "Something went wrong", "error");
      }
    },
  });

  const handleDelete = async () => {
    try {
      const response = await deleteClass(focusedClass).unwrap();
      if (response) {
        swal("Success", "Class deleted successfully", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* modals */}
      <CustomWarningDialog
        open={openWarningDialog}
        setOpen={setOpenWarningDialog}
        item={focusedClass}
        action={handleDelete}
        message="Action is irreversable, are you sure you want to delete?"
      />
      <ClassDialog
        selectedClass={focusedClass}
        setSelectedClass={setFocusedClass}
        open={openClassDialog}
        setOpen={setOpenClassDialog}
      />
      <Grid container columnSpacing={3}>
        <Grid item xs={12} lg={4}>
          <StyledPaper>
            <Box className="paperHeader">
              <Typography variant="paperHeading">New Class Form</Typography>
            </Box>
            <Box className="paperBody">
              <form onSubmit={classForm.handleSubmit}>
                <Grid container columnSpacing={1} rowSpacing={2}>
                  <Grid item xs={12}>
                    <FormControl error fullWidth>
                      <Typography variant="formSubHeading">
                        Name of class
                      </Typography>
                      <StyledTextField
                        autoFocus
                        variant="outlined"
                        id="name"
                        name="name"
                        value={classForm.values.name}
                        onChange={classForm.handleChange}
                        error={
                          classForm.touched.name &&
                          Boolean(classForm.errors.name)
                        }
                      />
                      <FormHelperText variant="error">
                        {classForm.touched.name && classForm.errors.name}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl error fullWidth>
                      <Typography variant="formSubHeading">
                        Description
                      </Typography>
                      <StyledTextField
                        autoFocus
                        variant="outlined"
                        id="description"
                        name="description"
                        value={classForm.values.description}
                        onChange={classForm.handleChange}
                        error={
                          classForm.touched.description &&
                          Boolean(classForm.errors.description)
                        }
                      />
                      <FormHelperText variant="error">
                        {classForm.touched.description &&
                          classForm.errors.description}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl error fullWidth>
                      <Typography variant="formSubHeading">
                        level
                      </Typography>
                      <StyledTextField
                        autoFocus
                        variant="outlined"
                        id="level"
                        name="level"
                        value={classForm.values.level}
                        onChange={classForm.handleChange}
                        error={
                          classForm.touched.level &&
                          Boolean(classForm.errors.level)
                        }
                      />
                      <FormHelperText variant="error">
                        {classForm.touched.level &&
                          classForm.errors.level}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Typography variant="formSubHeading">
                        sub-Section
                      </Typography>
                      <StyledSelect
                        displayEmpty
                        id="subsection"
                        name="subsection"
                        value={classForm.values.subsection}
                        onChange={classForm.handleChange}
                        error={
                          classForm.touched.subsection &&
                          Boolean(classForm.errors.subsection)
                        }
                      >
                        <MenuItem value="" disabled>
                          <em>select subsection</em>
                        </MenuItem>
                        {subsections?.map((subsection) => (
                          <MenuItem key={subsection.id} value={subsection.id}>
                            {subsection.name}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                      <FormHelperText variant="error">
                        {classForm.touched.subsection &&
                          classForm.errors.subsection}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Typography variant="formSubHeading">
                        form master
                      </Typography>
                      <StyledSelect
                        displayEmpty
                        id="formMaster"
                        name="formMaster"
                        value={classForm.values.formMaster}
                        onChange={classForm.handleChange}
                        error={
                          classForm.touched.formMaster &&
                          Boolean(classForm.errors.formMaster)
                        }
                      >
                        <MenuItem value="" disabled>
                          <em>select form master</em>
                        </MenuItem>
                        {teachers?.map((teacher) => (
                          <MenuItem
                            key={teacher.user_id}
                            value={teacher.user_id}
                          >
                            {teacher.first_name} {teacher.last_name}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                      <FormHelperText variant="error">
                        {classForm.touched.formMaster &&
                          classForm.errors.formMaster}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} textAlign={"right"}>
                    <StyledLoadingButton
                      loading={createClassLoading || addTeacherToClassLoading}
                      type="submit"
                    >
                      Submit
                    </StyledLoadingButton>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} lg={8}>
          <StyledPaper>
            <Box className="paperHeader">
              <Typography variant="paperHeading">Classes</Typography>
            </Box>
            <TableContainer sx={{ pb: 4 }}>
              {getClassRoomsLoading && <LinearProgress />}
              <Table sx={{ width: "100%", boxShadow: "none" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>class</StyledTableCell>
                    <StyledTableCell>description</StyledTableCell>
                    <StyledTableCell>students</StyledTableCell>
                    <StyledTableCell align="left">edit</StyledTableCell>
                    <StyledTableCell align="left">delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {classrooms?.map((row, i) => (
                    <StyledTableRow key={i}>
                      <StyledTableCell component="th" scope="row">
                        <Box
                          className="tableCellLink"
                          onClick={() => {
                            setFocusedClass(row);
                            setOpenClassDialog(true);
                          }}
                        >
                          {row.name}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.description}
                      </StyledTableCell>
                      <StudentsCell id={row.id} />

                      <StyledTableCell align="left">
                        <SvgIcon
                          sx={{ color: "primary.main", cursor: "pointer" }}
                          component={EditIcon}
                          inheritViewBox
                          onClick={() => {
                            setFocusedClass(row);
                            setOpenClassDialog(true);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <DeleteIcon
                          className="tableCellLink"
                          onClick={() => {
                            setFocusedClass(row);
                            setOpenWarningDialog(true);
                          }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </Grid>
      </Grid>
    </>
  );
}

export default Classes;
