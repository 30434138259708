import { Grid, 
    MenuItem, 
    Typography, 
    FormControl, 
    TableContainer, 
    Table, 
    SvgIcon,
    TableBody, 
    TableRow, 
    TableHead, 
    Box, 
    FormHelperText } from '@mui/material';
import React, { useState } from 'react';
import { StyledSelect, StyledTextField } from '../../customComponents/styled/styledInputs';
import { StyledPaper } from '../../customComponents/styled/styledPaper';
import {FilledButton} from "../../customComponents/styled/styledButtons";
import { StyledTableCell, StyledTableRow } from "../../customComponents/styled/styledTable";
import { useFormik } from "formik";
import * as yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import EditHouseForm from './EditHouseForm';

const validationSchema = yup.object({
    house: yup.string().required("Required"),
    houseMaster: yup.string().required("Required"),
    
  });

function Houses() {
  const [focusedHouse, setFocusedHouse] = useState(null);


    const formik = useFormik ({
        initialValues: {
            house: "",
            houseMaster: "",
        },
        validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
    })

    const houses = [
        { name: "Gryffindor", houseMaster: "Abdulalim Ladan", students: 22 },
        { name: "Hufflepuff", houseMaster: "Abdullahi Bature", students: 19 },
        { name: "Slytherin", houseMaster: "Falilah Muhammad", students: 20 },
        { name: "Ravenclaw", houseMaster: "Nasir Mustapha", students: 21 },
    ]
  return (
    <div style={{padding: "0 10px"}}>
        <Grid container columnSpacing={4}>
            <Grid item xs={12} md={4}>
            <StyledPaper sx={{padding: "15px"}}>
              {focusedHouse ?(
                <EditHouseForm item={focusedHouse} />
              ) : ( 
              <>
            <Typography variant="formHeading" sx={{marginBottom: "20px"}}>New House Form</Typography>

            <Box component="form" onSubmit={formik.handleSubmit}>

            <Grid container rowSpacing={3}>

                <Grid item xs={12} md={12}>
                    <FormControl error={formik.touched.house && formik.errors.house} fullWidth>
                        <Typography variant="formSubHeading">NAME OF HOUSE</Typography>
                        <StyledTextField 
                        id="house" 
                        name="house" 
                        value={formik.values.house} 
                        onChange={formik.handleChange} 
                        error={formik.touched.house && Boolean(formik.errors.house)} />

                        <FormHelperText variant="error">
                           {formik.touched.house && formik.errors.house}
                        </FormHelperText>    
                    </FormControl>
                </Grid>


                <Grid item xs={12} md={12}>
                    <FormControl error={formik.touched.houseMaster && formik.errors.houseMaster} fullWidth>
                        <Typography variant="formSubHeading">HOUSE MASTER / MISTRESS</Typography>
                        <StyledSelect 
                        id="houseMaster" 
                        name="houseMaster" 
                        onChange={formik.handleChange} 
                        value={formik.values.houseMaster} 
                        error={formik.touched.houseMaster && Boolean(formik.errors.houseMaster)}>
                            <MenuItem value="Abdulalim Ladan">Abdulalim Ladan</MenuItem>
                            <MenuItem value="Nasir Mustapha">Nasir Mustapha</MenuItem>
                            <MenuItem value="Abdullahi Bature">Abdullahi Bature</MenuItem>
                            <MenuItem value="Falilah Muhammad">Falilah Muhammad</MenuItem>
                        </StyledSelect>
                        <FormHelperText variant="error">
                           {formik.touched.houseMaster && formik.errors.houseMaster}
                        </FormHelperText>
                    </FormControl>
                </Grid>

                

                <Grid item xs={12} md={12} textAlign={"right"}>
              <FilledButton type="submit" sx={{textTransform: "none"}}>Submit</FilledButton>
            </Grid>
            </Grid>
            </Box>
            </>
            )}
           </StyledPaper>

            </Grid>

                             {/* Generated Table */}

            <Grid item xs={12} md={8}>
        <StyledPaper sx={{paddingBottom: "25px"}}>
            <Box sx={{ padding: "15px"}}>
          <Typography  variant="formHeading">Houses</Typography>
          <Typography variant="formSubHeading" sx={{textTransform: "none"}}>To modify house info, click edit icon</Typography>
          </Box>
          <TableContainer>
            <Table>
            <TableHead>
          <TableRow sx={{borderBottom: "2px solid lightgrey "}}>
            <StyledTableCell>HOUSE</StyledTableCell>
            <StyledTableCell>HOUSE MASTER / MISTRESS</StyledTableCell>
            <StyledTableCell>STUDENTS</StyledTableCell>
            <StyledTableCell>EDIT</StyledTableCell>
            <StyledTableCell textAlign={"right"}>DELETE</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {houses.map((row, i) => (
                    <StyledTableRow key={i}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.houseMaster}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.students}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        <SvgIcon
                          sx={{ color: "primary.main", cursor: "pointer" }}
                          component={EditIcon}
                          inheritViewBox
                          onClick={() => {
                            setFocusedHouse(row);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <DeleteIcon
                          sx={{ color: "primary.main", cursor: "pointer" }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
          
        </TableBody>
            </Table>
          </TableContainer>

        </StyledPaper>
      </Grid>
        </Grid>
        
        </div>
  )
}

export default Houses