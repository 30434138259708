import { apiSlice } from "../../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Section", "SubSection"],
});

export const sectionsApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getSection: builder.query({
      query: (parameter) => `/section/${parameter}`,
      providesTags: ["Section"],
    }),
    getSubSection: builder.query({
      query: (parameter) => `section/subsection/${parameter}`,
      providesTags: ["SubSection"],
    }),
    createSection: builder.mutation({
      query: (values) => ({
        url: "/section/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Section"],
    }),
    updateSection: builder.mutation({
      query: (values) => ({
        url: "/section/update",
        method: "PUT",
        body: { ...values },
      }),
      invalidatesTags: ["Section"],
    }),
    deleteSection: builder.mutation({
      query: (values) => ({
        url: `/section/${values.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Section"],
    }),
    createSubSection: builder.mutation({
      query: (values) => ({
        url: "/section/subsection/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["SubSection", "Section"],
    }),
    updateSubSection: builder.mutation({
      query: (values) => ({
        url: "/section/subsection/update",
        method: "PUT",
        body: { ...values },
      }),
      invalidatesTags: ["SubSection", "Section"],
    }),
    addTemplateToSubSection: builder.mutation({
      query: (values) => ({
        url: "/section/subsection/add-template",
        method: "PATCH",
        body: { ...values },
      }),
      invalidatesTags: ["SubSection", "Section"],
    }),
    removeTemplate: builder.mutation({
      query: (values) => ({
        url: "/section/subsection/remove-template",
        method: "PATCH",
        body: { ...values },
      }),
      invalidatesTags: ["SubSection", "Section"],
    }),
    deleteSubSection: builder.mutation({
      query: (values) => ({
        url: `/section/subsection/${values.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SubSection", "Section"],
    }),
  }),
});

export const {
  useCreateSectionMutation,
  useCreateSubSectionMutation,
  useGetSectionQuery,
  useGetSubSectionQuery,
  useUpdateSectionMutation,
  useUpdateSubSectionMutation,
  useDeleteSectionMutation,
  useDeleteSubSectionMutation,
  useAddTemplateToSubSectionMutation,
  useRemoveTemplateMutation,
} = sectionsApiSlice;
