import {
  Avatar,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { StyledPaper } from "../../../customComponents/styled/styledPaper";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  StyledFileInput,
  StyledTextField,
} from "../../../customComponents/styled/styledInputs";
import { StyledLoadingButton } from "../../../customComponents/styled/styledButtons";
import {
  useGetSchoolProfileQuery,
  useUpdateSchoolProfileMutation,
} from "./schoolApiSlice";
import { useUploadFileMutation } from "../../../../app/utilsApiSlice";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { setColor } from "../../../../theme/themeSlice";
import CustomColorPicker from "../../../customComponents/CustomColorPicker";

const validationSchema = yup.object({
  name: yup.string().required("School name is required"),
  alias: yup.string().required("Required"),
  motto: yup.string().required("Required"),
  phone: yup.string().required("Required"),
  website: yup.string().required("Required"),
  email: yup.string().email("Enter a valid email").required("Required"),
  // logo: yup.string().required("Required"),
  // color: yup.string().required("Required"),
  address: yup.string().required("Required"),
});

function School() {
  const dispatch = useDispatch();
  const [fileLoading, setFileLoading] = React.useState({});
  const [openColorPicker, setOpenColorPicker] = React.useState(false);

  const { data: schoolInfo, isLoading: getLoading } =
    useGetSchoolProfileQuery();

  const [updateProfile, { isLoading: updateLoading }] =
    useUpdateSchoolProfileMutation();

  const [uploadFile, { isLoading: uploadingFile }] = useUploadFileMutation();

  const formik = useFormik({
    initialValues: {
      id: schoolInfo?.id,
      name: schoolInfo?.name || "",
      alias: schoolInfo?.alias || "",
      motto: schoolInfo?.motto || "",
      phone: schoolInfo?.phone || "",
      website: schoolInfo?.website || "",
      email: schoolInfo?.email || "",
      logo: schoolInfo?.logo || "",
      color:
        schoolInfo?.color === "blue"
          ? "#2574d1"
          : schoolInfo?.color || "#2574d1",
      address: schoolInfo?.address || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await updateProfile(values).unwrap();
        if (response.id) {
          swal("Success", "School profile updated successfully", "success");
        } else {
          swal("Error", "Something went wrong", "error");
        }
      } catch (error) {
        swal("Error", "Something went wrong", "error");
      }
    },
  });

  const handleFileChange = async (e) => {
    try {
      setFileLoading({ ...fileLoading, [e.target.name]: true }); // set file uploading status
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const response = await uploadFile(formData).unwrap();
      if (response) {
        setFileLoading({ ...fileLoading, [e.target.name]: false }); //setfile uploading status
        formik.setFieldValue(e.target.name, response.url);
      }
    } catch (error) {
      setFileLoading({ ...fileLoading, [e.target.name]: false }); //setfile uploading status
    }
  };

  useEffect(() => {
    //function to handleColorChange
    let currentColor = formik.values.color;
    const handleChangeColor = () => {
      if (currentColor) {
        dispatch(setColor({ color: currentColor }));
      }
    };

    handleChangeColor();
  }, [formik.values.color]);

  return (
    <>
      <Grid container columnSpacing={3} rowSpacing={1}>
        <Grid item xs={12} lg={8}>
          <StyledPaper>
            {getLoading && <LinearProgress />}
            <Box className="paperHeader">School Profile</Box>
            <Box className="paperBody">
              <Box component="form" onSubmit={formik.handleSubmit}>
                <Grid container columnSpacing={1} rowSpacing={3}>
                  <Grid item xs={12} lg={6}>
                    <FormControl error fullWidth>
                      <Typography variant="formSubHeading">
                        School Name
                      </Typography>
                      <StyledTextField
                        autoFocus
                        variant="outlined"
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                      />
                      <FormHelperText variant="error">
                        {formik.touched.name && formik.errors.name}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl error fullWidth>
                      <Typography variant="formSubHeading">alias</Typography>
                      <StyledTextField
                        variant="outlined"
                        id="alias"
                        name=""
                        alias
                        value={formik.values.alias}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.alias && Boolean(formik.errors.alias)
                        }
                      />
                      <FormHelperText variant="error">
                        {formik.touched.alias && formik.errors.alias}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {/* end of first row */}
                  <Grid item xs={12} lg={12}>
                    <FormControl error fullWidth>
                      <Typography variant="formSubHeading">motto</Typography>
                      <StyledTextField
                        variant="outlined"
                        id="motto"
                        name=""
                        motto
                        value={formik.values.motto}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.motto && Boolean(formik.errors.motto)
                        }
                      />
                      <FormHelperText variant="error">
                        {formik.touched.motto && formik.errors.motto}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControl error fullWidth>
                      <Typography variant="formSubHeading">
                        schools phone
                      </Typography>
                      <StyledTextField
                        variant="outlined"
                        id="phone"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                      />
                      <FormHelperText variant="error">
                        {formik.touched.phone && formik.errors.phone}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControl error fullWidth>
                      <Typography variant="formSubHeading">website</Typography>
                      <StyledTextField
                        variant="outlined"
                        id="website"
                        name="website"
                        value={formik.values.website}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.website &&
                          Boolean(formik.errors.website)
                        }
                      />
                      <FormHelperText variant="error">
                        {formik.touched.website && formik.errors.website}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormControl error fullWidth>
                      <Typography variant="formSubHeading">Email</Typography>
                      <StyledTextField
                        variant="outlined"
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                      />
                      <FormHelperText variant="error">
                        {formik.touched.email && formik.errors.email}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {/* end of second row */}

                  <Grid item xs={12} lg={3}>
                    <FormControl error fullWidth>
                      <Typography variant="formSubHeading">Logo</Typography>
                      <Stack direction="row" spacing={1}>
                        <StyledFileInput
                          type="file"
                          id="logo"
                          name="logo"
                          onChange={(e) => handleFileChange(e)}
                          error={
                            formik.touched.logo && Boolean(formik.errors.logo)
                          }
                        />
                        {fileLoading.logo && <CircularProgress />}
                      </Stack>
                      <FormHelperText variant="error">
                        {formik.touched.logo && formik.errors.logo}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <FormControl
                      error={
                        formik.touched.subjectType && formik.errors.subjectType
                      }
                      fullWidth
                    >
                      <Typography variant="formSubHeading">
                        preffered color
                      </Typography>
                      <Box
                        onClick={() => setOpenColorPicker(true)}
                        sx={{
                          backgroundColor: formik.values.color,
                          color: "white",
                          width: "100%",
                          p: 0.5,
                          cursor: "pointer",
                          textAlign: "center",
                          "&:hover": {
                            opacity: 0.6,
                          },
                        }}
                      >
                        <Typography>Pick a color</Typography>
                      </Box>
                      <CustomColorPicker
                        open={openColorPicker}
                        setOpen={setOpenColorPicker}
                        value={formik.values.color}
                        setValue={(color) =>
                          formik.setFieldValue("color", color.hex)
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <FormControl error fullWidth>
                      <Typography variant="formSubHeading">
                        school's address
                      </Typography>
                      <StyledTextField
                        variant="outlined"
                        id="address"
                        name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.address &&
                          Boolean(formik.errors.address)
                        }
                      />
                      <FormHelperText variant="error">
                        {formik.touched.address && formik.errors.address}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {/* end of third row */}
                  <Grid item xs={12} md={12} textAlign={"right"}>
                    <StyledLoadingButton
                      loading={updateLoading}
                      disabled={uploadingFile}
                      type="submit"
                    >
                      update
                    </StyledLoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} lg={4}>
          <StyledPaper>
            <Box
              className="paperBody"
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                alt="Logo"
                src={schoolInfo?.logo}
                sx={{ width: "124px", height: "124px", mb: 2 }}
              />
              <Typography variant="formHeading">{schoolInfo?.name}</Typography>
              <Typography
                sx={{ fontSize: "13px", fontWeight: 600, color: "#333" }}
              >
                {schoolInfo?.alias}
              </Typography>
              <Typography
                sx={{
                  color: "#333",
                  fontSize: "12px",
                  fontWeight: 600,
                  mb: 2,
                }}
              >
                {schoolInfo?.address}
              </Typography>
            </Box>
          </StyledPaper>
        </Grid>
      </Grid>
    </>
  );
}

export default School;
