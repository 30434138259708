import {
  Grid,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState, useRef } from "react";
import CustomMenuList from "../../../customComponents/CustomMenuList";
import { FilledButton } from "../../../customComponents/styled/styledButtons";
import { StyledPaper } from "../../../customComponents/styled/styledPaper";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../customComponents/styled/styledTable";

import { useReactToPrint } from "react-to-print";
import ModifySubjectDialog from "./ModifySubjectDialog";

function PreviewTimeTables() {
  const componentToPrintRef = useRef();
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState({});
  const periods = [
    {
      day: "monday",
      periods: [
        {
          start: "08:00",
          end: "09:00",
          subject: "Maths",
        },
        {
          start: "09:00",
          end: "10:00",
          subject: "English",
        },
        {
          start: "10:00",
          end: "11:00",
          subject: "Science",
        },
        {
          start: "11:00",
          end: "12:00",
          subject: "Maths",
        },
        {
          start: "12:00",
          end: "13:00",
          subject: "English",
        },
        {
          start: "13:00",
          end: "14:00",
          subject: "social studies",
        },
        {
          start: "14:00",
          end: "15:00",
          subject: "Arabic",
        },
        {
          start: "15:00",
          end: "16:00",
          subject: "French",
        },
      ],
    },
    {
      day: "Tuesday",
      periods: [
        {
          start: "08:00",
          end: "09:00",
          subject: "Maths",
        },
        {
          start: "09:00",
          end: "10:00",
          subject: "English",
        },
        {
          start: "10:00",
          end: "11:00",
          subject: "Science",
        },
        {
          start: "11:00",
          end: "12:00",
          subject: "Social Studies",
        },
        {
          start: "12:00",
          end: "13:00",
          subject: "Arabic",
        },
        {
          start: "13:00",
          end: "14:00",
          subject: "French",
        },
        {
          start: "14:00",
          end: "15:00",
          subject: "Maths",
        },
        {
          start: "15:00",
          end: "16:00",
          subject: "English",
        },
      ],
    },
  ];
  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  return (
    <>
      <Grid container columnSpacing={3}>
        {/* modals */}
        <ModifySubjectDialog
          open={open}
          setOpen={setOpen}
          selectedPeriod={selectedPeriod}
          periods={periods}
        />
        <Grid item xs={12} lg={4}>
          <CustomMenuList
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            list={["nursery section"]}
            heading="Templates"
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <StyledPaper>
            <Box className="paperHeader" textAlign={"center"}>
              <Typography variant="paperHeading">
                Select class to view timetable
              </Typography>
            </Box>
            <Box className="paperBody">
              <FilledButton>Jss One</FilledButton>
            </Box>
          </StyledPaper>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <Box className="paperHeader">
              <Typography className="paperHeading">JS one timetable</Typography>
            </Box>
            <TableContainer sx={{ pb: 4 }}>
              <Table sx={{ width: "100%", boxShadow: "none" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>day</StyledTableCell>
                    <StyledTableCell align="left">periods</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {periods.map((row, i) => (
                    <StyledTableRow key={i} sx={{ verticalAlign: "top" }}>
                      <StyledTableCell component="th" scope="row">
                        {row.day}
                      </StyledTableCell>

                      {row.periods.map((period, i) => (
                        <StyledTableCell key={i}>
                          <Typography sx={{ fontWeight: 300, fontSize: 12 }}>
                            {period.start}: <br />
                            {period.end}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "14px",
                              maxWidth: "min-content",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setOpen(true);
                              setSelectedPeriod({
                                day: row.day,
                                period: period,
                                periodIndex: i,
                              });
                            }}
                          >
                            {period.subject}
                          </Typography>
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
          <Stack direction="row" spacing={1}>
            <FilledButton onClick={handlePrint}>Print</FilledButton>
            <FilledButton>Delete</FilledButton>
          </Stack>
        </Grid>
        <div style={{ display: "none" }}>
          {/* Table to print, containing all the table data without searching or sorting */}
          <Box
            ref={componentToPrintRef}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 2,
            }}
          >
            <Box
              component={"img"}
              sx={{
                width: "124px",
                height: "124px",
                borderRadius: "50%",
                border: "5px solid #FFFFFF",
                marginBottom: "15px",
              }}
              src={require("../../../../assets/images/schoola-school-logo.png")}
              alt=""
            />
            <Typography className="paperHeading">JS one timetable</Typography>
            <TableContainer>
              <Table sx={{ width: "100%", boxShadow: "none" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>day</StyledTableCell>
                    <StyledTableCell align="left">periods</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {periods.map((row, i) => (
                    <StyledTableRow key={i} sx={{ verticalAlign: "top" }}>
                      <StyledTableCell component="th" scope="row">
                        {row.day}
                      </StyledTableCell>

                      {row.periods.map((period, i) => (
                        <StyledTableCell key={i}>
                          <Typography sx={{ fontWeight: 300, fontSize: 12 }}>
                            {period.start}: <br />
                            {period.end}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "14px",
                              maxWidth: "min-content",
                            }}
                          >
                            {period.subject}
                          </Typography>
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </Grid>
    </>
  );
}

export default PreviewTimeTables;
