import {
  Grid,
  Stack,
  Box,
  Typography,
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  MenuItem,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  FilledButton,
  StyledLoadingButton,
} from "../../customComponents/styled/styledButtons";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import {
  StyledSelect,
  StyledTextArea,
  StyledTextField,
} from "../../customComponents/styled/styledInputs";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import { useGetClassesQuery } from "../classroom/classApiSlice";
import {
  useCreateCommentMutation,
  useCreateStudentTraitsMutation,
  useDeleteCommentMutation,
  useDeleteStudentTraitsMutation,
  useGetCommentByStudentQuery,
  useGetCommentBySubjectAndClassroomQuery,
  useGetRecordsBySubjectAndClassQuery,
  useGetStudentTraitsQuery,
} from "../quick-entry/recordApiSlice";
import { useGetGradesQuery } from "../settings/grades/gradesApiSlice";
import {
  useGetCurrentTermQuery,
  useGetSessionQuery,
} from "../settings/session/sessionApiSlice";
import { useGetTraitQuery } from "../settings/traits/traitsApiSlice";
import swal from "sweetalert";
import CustomWarningDialog from "../../customComponents/CustomWarningDialog";
import { useGetSubSectionQuery } from "../settings/sections/sectionsApiSlice";
import { Print } from "@mui/icons-material";
import { useCreateStudentReportMutation } from "../student-report/studentReportApiSlice";

const SubjectTableRow = ({
  subject,
  classId,
  student,
  assessments,
  grades,
}) => {
  const { data: records } = useGetRecordsBySubjectAndClassQuery({
    subject_id: subject?.id,
    classroom_id: classId,
  });

  const { data: comments } = useGetCommentBySubjectAndClassroomQuery(
    `${subject?.id}/${classId}`
  );

  const studentRecords = records?.filter((r) => r.student_id === student.id);
  const studentSubjectRecord = studentRecords?.filter(
    (r) => r.subject_id === subject.id
  );
  const studentSubjectComment = comments?.find(
    (c) => c.student_id === student.id && c.subject_id === subject.id
  );

  const totalScore = studentSubjectRecord?.reduce(
    (acc, curr) => acc + curr.score,
    0
  );

  // match grade to total score
  const grade = grades?.find(
    (g) => g.min_score <= totalScore && g.max_score >= totalScore
  );

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row">
        {subject?.name}
      </StyledTableCell>
      {/* match record against assessments */}
      {assessments?.map((assessment) => {
        const record = studentSubjectRecord?.find(
          (r) => r.assessment_id === assessment.id
        );
        return (
          <StyledTableCell key={assessment.id} component="th" scope="row">
            {record?.score || 0}
          </StyledTableCell>
        );
      })}
      <StyledTableCell>{totalScore || 0}</StyledTableCell>
      <StyledTableCell>{grade?.name}</StyledTableCell>
      <StyledTableCell>{studentSubjectComment?.content}</StyledTableCell>
    </StyledTableRow>
  );
};

function ExamsScoreSheet({ student, classId, assessments }) {
  const [activeTab, setActiveTab] = React.useState(0);
  const [traitsRecord, setTraitsRecord] = React.useState([]);
  const [teacherComment, setTeacherComment] = React.useState("");
  const [openWarning, setOpenWarning] = React.useState(false);
  const [isPrintingReport, setIsPringtingReport] = React.useState(false);
  const [activeTemplate, setActiveTemplate] = React.useState(null);

  const { data: classroom } = useGetClassesQuery(classId); //to get updated list of classrooms
  const { data: commentGrades } = useGetGradesQuery(
    `all/comment/${classroom?.subsection_id}`
  );
  const { data: scoreGrades } = useGetGradesQuery(
    `all/score/${classroom?.subsection_id}`
  );
  const { data: session } = useGetSessionQuery("current");
  const { data: term } = useGetCurrentTermQuery();
  const { data: traits } = useGetTraitQuery("all");
  const { data: studentTraits } = useGetStudentTraitsQuery({
    student_id: student?.id,
    classroom_id: classId,
  });
  const { data: comments } = useGetCommentByStudentQuery(student?.id);
  const { data: subsection } = useGetSubSectionQuery(classroom?.subsection_id);

  const [saveTraits, { isLoading: saveTraitsLoading }] =
    useCreateStudentTraitsMutation();
  const [deleteTraits, { isLoading: deleteTraitsLoading }] =
    useDeleteStudentTraitsMutation();
  const [saveComment, { isLoading: saveCommentLoading }] =
    useCreateCommentMutation();
  const [deleteComment, { isLoading: deleteCommentLoading }] =
    useDeleteCommentMutation();
  const [createStudentReport, { isLoading: createStudentReportLoading }] =
    useCreateStudentReportMutation();

  useEffect(() => {
    // fill in fields with existing data on load
    const returnedTraits = studentTraits
      ?.filter((t) => t.term_id === term?.id)
      ?.map((t) => ({
        trait_id: t.trait_id,
        behavior_id: t.behavior_id,
        grade_id: t.grade_id,
      }));
    if (returnedTraits) {
      setTraitsRecord(returnedTraits);
    } else {
      setTraitsRecord([]);
    }

    const returnedComment = comments?.find(
      (item) => item.term_id === term?.id
    )?.content;
    if (returnedComment) {
      setTeacherComment(returnedComment);
    } else {
      setTeacherComment("");
    }
  }, [studentTraits, term, comments]);

  const subsectionTraits = traits?.filter(
    (t) => t.subsection_id === classroom?.subsection_id
  );

  const handleTraitGradeChange = (e, traitId, behaviorId) => {
    const item = {
      trait_id: traitId,
      behavior_id: behaviorId,
      grade_id: e.target.value,
    };

    const newTraitsRecord = [...traitsRecord];
    const index = newTraitsRecord?.findIndex(
      (t) => t.trait_id === traitId && t.behavior_id === behaviorId
    );
    if (index === -1) {
      newTraitsRecord.push(item);
    } else {
      newTraitsRecord[index] = item;
    }
    setTraitsRecord(newTraitsRecord);
  };

  const handleSave = async () => {
    const traitsBody = {
      classroom_id: parseInt(classId),
      student_id: student.id,
      grades: traitsRecord,
    };
    const commentBody = {
      classroom_id: parseInt(classId),
      // subsection_id: classroom?.subsection_id,
      type: "term",
      comments: [
        {
          student_id: student.id,
          title: "Form teacher comment",
          content: teacherComment,
        },
      ],
    };
    try {
      const traitResponse = await saveTraits(traitsBody).unwrap();
      const commentResponse = await saveComment(commentBody).unwrap();
      if (traitResponse) {
        swal("Success!", "Traits saved successfully", "success");
      }
      if (commentResponse) {
        swal("Success!", "Comment saved successfully", "success");
      }
    } catch (error) {
      console.log(error);
      swal("Error!", "Something went wrong", "error");
    }
  };

  const handleClickDelete = () => {
    handleDeleteComment();
    handleDeleteTraits();
  };

  const handleDeleteComment = async () => {
    const body = {
      student_id: student.id,
      classroom_id: parseInt(classId),
      // section_id: 4,
      type: "term",
    };

    try {
      const response = await deleteComment(body).unwrap();
      console.log(response);

      if (response) {
        swal("Success!", "Comment deleted successfully", "success");
      }
    } catch (error) {
      console.log(error);
      if (error.status === 404) {
        swal("Error!", error?.data?.message || "No record found", "error");
      } else {
        swal("Error!", "Something went wrong", "error");
      }
    }
  };

  const handleDeleteTraits = async () => {
    const body = {
      student_id: student.id,
      classroom_id: parseInt(classId),
    };

    try {
      const traitResponse = await deleteTraits(body).unwrap();
      if (traitResponse) {
        swal("Success!", "Traits deleted successfully", "success");
      }
    } catch (error) {
      console.log(error);
      if (error.status === 404) {
        swal("Error!", error?.data?.message || "No record found", "error");
      } else {
        swal("Error!", "Something went wrong", "error");
      }
    }
  };

  const handlePrint = async (template) => {
    try {
      setIsPringtingReport(true);
      const data = {
        student_id: student?.id,
        term_id: term?.id,
        classroom_id: parseInt(classId),
        template_id: template?.id,
        subsection_id: classroom?.subsection_id,
      };
      const BASE_URL = process.env.REACT_APP_SCHOOLA_SRC_SERVICE;
      const API_KEY = process.env.REACT_APP_AUTH_PLATFORM_KEY
      const API_KEY_NAME = process.env.REACT_APP_AUTH_PLATFORM_NAME
      const token = localStorage.getItem("access_token").replace('"', '').replace('"', '');
      const url = `/report/student?preview=html&student_id=${data.student_id}&term_id=${data.term_id}&classroom_id=${data.classroom_id}&template_id=${data.template_id}&subsection_id=${data.subsection_id}`;
      const req = await fetch(BASE_URL + url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-api-key': API_KEY,
          'x-api-key-name': API_KEY_NAME
        },
      });
      setIsPringtingReport(false);
      if (!req.ok) {
        return swal("Error", "Error printing result", "error");
      }
      const htmlText = await req.text();
      var resultWindow = window.open("", "Student Report Preview", "width=200,height=100");
      if (resultWindow){
        resultWindow.document.write(htmlText);
      }
    } catch (error) {
      setIsPringtingReport(false);
      console.log(error);
      swal("Error", "Error printing result", "error");
    }
  };

  return (
    <Grid container columnSpacing={3}>
      {/* modals */}
      <CustomWarningDialog
        open={openWarning}
        setOpen={setOpenWarning}
        action={handleClickDelete}
        message="Irreversible action!!! Are you sure you want to clear these records?"
      />
      <Grid item xs={12} lg={11}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            "& .tabItem": {
              padding: "10px",
              cursor: "pointer",
              "&.active": {
                backgroundColor: "white",
                borderLeft: "1px solid #ccc",
                borderTop: "1px solid #ccc",
                borderRight: "1px solid #ccc",
                borderRadius: "5px 5px 0 0",
              },
            },
          }}
        >
          <Box
            className={`tabItem ${activeTab === 0 && " active"}`}
            onClick={() => setActiveTab(0)}
          >
            Term
          </Box>
          {/* <Box
            className={`tabItem ${activeTab === 1 && " active"}`}
            onClick={() => setActiveTab(1)}
          >
            MidTerm
          </Box> */}
        </Stack>

        {activeTab === 0 && (
          <StyledPaper>
            <Box className="paperBody" sx={{ textAlign: "center" }}>
              <Typography variant="formHeading" color="primary.main">
                {student?.first_name + " " + student?.last_name}'s scoresheet
              </Typography>
              <Typography variant="formSubHeading">
                <b>Session:</b>
                {session?.name} | <b>Term:</b> {term?.name} |<b>Class:</b>{" "}
                {classroom?.name}
              </Typography>
            </Box>
            <TableContainer>
              <Table sx={{ width: "100%", boxShadow: "none" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Subject</StyledTableCell>
                    {assessments?.map((assessment) => (
                      <StyledTableCell
                        key={assessment.id}
                      >{`${assessment.name} (${assessment.obtainable_score})`}</StyledTableCell>
                    ))}
                    <StyledTableCell>Total marks</StyledTableCell>
                    <StyledTableCell>grades</StyledTableCell>
                    <StyledTableCell>remarks</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {classroom?.subjects?.map((subject) => (
                    <SubjectTableRow
                      key={subject?.id}
                      subject={subject}
                      classId={classId || 0}
                      student={student || {}}
                      assessments={assessments || []}
                      grades={scoreGrades || []}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ textAlign: "left", p: 2 }}>
              <FormControl fullWidth sx={{ my: 4 }}>
                <Typography variant="formSubHeading">
                  Principal's comment
                </Typography>
                <StyledTextArea disabled minRows={4} />
              </FormControl>
              <FormControl fullWidth>
                <Typography variant="formSubHeading">
                  Form teacher's comment
                </Typography>
                <StyledTextArea
                  minRows={4}
                  value={teacherComment}
                  onChange={(e) => setTeacherComment(e.target.value)}
                />
              </FormControl>
            </Box>
            <Typography variant="formHeading" sx={{ textAlign: "center" }}>
              Traits
            </Typography>
            <TableContainer sx={{ pb: 4 }}>
              <Table sx={{ width: "100%", boxShadow: "none" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Key</StyledTableCell>
                    <StyledTableCell align="left">description</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {commentGrades?.map((row) => (
                    <TableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.description}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className="paperBody">
              <Grid container>
                <Grid item container spacing={1.5} xs={12} md={6}>
                  {subsectionTraits?.map((trait) => (
                    <React.Fragment key={trait.id}>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <StyledTextField
                            disabled
                            bold="true"
                            value={trait.name}
                            name={trait.name}
                            id={trait.id}
                          />
                        </FormControl>
                      </Grid>
                      {trait?.behaviors?.map((behavior) => (
                        <React.Fragment key={behavior.id}>
                          <Grid item xs={8}>
                            <FormControl fullWidth>
                              <StyledSelect
                                displayEmpty
                                name={trait.id.toString()}
                                id={trait.id}
                                value={behavior.id}
                              >
                                <MenuItem value="" disabled>
                                  <em>select behavior</em>
                                </MenuItem>
                                <MenuItem value={behavior.id}>
                                  {behavior.name}
                                </MenuItem>
                              </StyledSelect>
                            </FormControl>
                          </Grid>
                          <Grid item xs={3}>
                            <FormControl fullWidth>
                              <StyledSelect
                                displayEmpty
                                name={trait.id.toString()}
                                id={trait.id}
                                value={
                                  traitsRecord?.find(
                                    (item) =>
                                      item.trait_id === trait?.id &&
                                      item.behavior_id === behavior?.id
                                  )?.grade_id || ""
                                }
                                onChange={(e) =>
                                  handleTraitGradeChange(
                                    e,
                                    trait.id,
                                    behavior.id
                                  )
                                }
                              >
                                <MenuItem value="" disabled>
                                  <em>select grade</em>
                                </MenuItem>
                                {commentGrades?.map((grade) => (
                                  <MenuItem key={grade.id} value={grade.id}>
                                    {grade.name}
                                  </MenuItem>
                                ))}
                              </StyledSelect>
                            </FormControl>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </StyledPaper>
        )}

        {activeTab === 1 && (
          <StyledPaper>
            <Box className="paperBody" sx={{ textAlign: "center" }}>
              <Typography variant="formHeading" color="primary.main">
                {student?.first_name + " " + student?.last_name}'s scoresheet
              </Typography>
              <Typography variant="formSubHeading">
                <b>Session:</b>
                {session?.name} | <b>Term:</b> {term?.name} |<b>Class:</b>{" "}
                {classroom?.name}
              </Typography>
            </Box>
            <TableContainer sx={{ pb: 4 }}>
              <Table sx={{ width: "100%", boxShadow: "none" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Subjects </StyledTableCell>
                    <StyledTableCell>Comments</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow></StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        )}
      </Grid>
      <Grid item xs={12} lg={1}>
        <Stack
          direction={{ xs: "row", md: "column" }}
          spacing={1}
          sx={{
            "& .btn-icon": {
              fontSize: "14px",
            },
          }}
        >
          {subsection?.templates?.map((template) => (
            <Tooltip placement="left" title={`Print ${template.name}`}>
              <StyledLoadingButton
                loading={isPrintingReport && activeTemplate?.id === template.id}
                key={template.id}
                onClick={() => {
                  setActiveTemplate(template);
                  handlePrint(template);
                }}
              >
                <Print className="btn-icon" />
              </StyledLoadingButton>
            </Tooltip>
          ))}
          {saveTraitsLoading || saveCommentLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Tooltip title="Save" placement="left">
              <FilledButton onClick={handleSave}>
                <SaveIcon className="btn-icon" />
              </FilledButton>
            </Tooltip>
          )}
          {deleteTraitsLoading || deleteCommentLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Tooltip placement="left" title="Clear record">
              <FilledButton onClick={() => setOpenWarning(true)}>
                <DeleteIcon className="btn-icon" />
              </FilledButton>
            </Tooltip>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ExamsScoreSheet;
