import { apiSlice } from "../../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Grade"],
});

const gradesApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getGrades: builder.query({
      query: (parameter) => `/grade/${parameter}`,
      providesTags: ["Grade"],
    }),
    createGrade: builder.mutation({
      query: (values) => ({
        url: "/grade/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Grade"],
    }),
    updateGrade: builder.mutation({
      query: (values) => ({
        url: "/grade/update",
        method: "PUT",
        body: { ...values },
      }),
      invalidatesTags: ["Grade"],
    }),
    deleteGrade: builder.mutation({
      query: (values) => ({
        url: `/grade/${values.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Grade"],
    }),
  }),
});

export const {
  useGetGradesQuery,
  useCreateGradeMutation,
  useDeleteGradeMutation,
  useUpdateGradeMutation,
} = gradesApiSlice;
