import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import swal from "sweetalert";
import { logout } from "../components/pages/auth/authSlice";

const srcPlatformName = process.env.REACT_APP_SRC_PLATFORM_NAME;
const srcPlatformKey = process.env.REACT_APP_SRC_PLATFORM_KEY;

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_SCHOOLA_SRC_SERVICE,
  //credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const access_token = getState().auth.access_token;
    if (access_token) {
      headers.set("x-api-key-name", srcPlatformName);
      headers.set("x-api-key", srcPlatformKey);
      headers.set("authorization", `Bearer ${access_token}`);
    }
    return headers;
  },
});

const baseQuerywithAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    // unauthorized
    swal("Error", "Unauthorized or expired session", "error");
    api.dispatch(logout()); // logout user
    api.dispatch(apiSlice.util.resetApiState());
  }

  return result; //if everything is ok, return the result
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQuerywithAuth,
  endpoints: (builder) => ({}),
});
