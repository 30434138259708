import {
  Grid,
  MenuItem,
  Typography,
  FormControl,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Box,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledSelect } from "../../customComponents/styled/styledInputs";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import { StyledMultiSelect } from "../../customComponents/styled/customMultiSelect";
import { useFormik } from "formik";
import * as yup from "yup";
import { useGetClassesQuery } from "../classroom/classApiSlice";
import { useGetFeesQuery } from "../fees-schedule/feeScheduleApiSlice";
import { useCreateInvoiceMutation } from "./invoicesApiSlice";
import swal from "sweetalert";
import { useGetSessionQuery } from "../settings/session/sessionApiSlice";
import InvoiceDialog from "./InvoiceDialog";

const validationSchema = yup.object({
  class: yup.string().required("Required"),
  // student: yup.object().required("Please select a student"),
});

function Invoices() {
  const [invoices, setInvoices] = useState(null);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(false);

  const formik = useFormik({
    initialValues: {
      class: "",
      student: "",
      // session: "",
      items: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const body = {
        classroom_id: values.class,
        items: values.items.map((item) => item.value),
      };
      try {
        const response = await generateFee(body);
        console.log(response);
        if (response.data) {
          setInvoices(response.data.data);
          localStorage.setItem("invoices", JSON.stringify(response.data.data));
          swal("Success", response.data.message, "success");
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const { data: classrooms } = useGetClassesQuery("all");
  const { data: students } = useGetClassesQuery(
    `${formik.values.class}/students`
  );
  const { data: fees } = useGetFeesQuery(`classroom/${formik.values.class}`);
  const { data: sessions } = useGetSessionQuery("all");

  const [generateFee, { isLoading: generateFeeLoading }] =
    useCreateInvoiceMutation();

  function multiHandleChange(selectedOption) {
    formik.setFieldValue("items", selectedOption);
  }

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("invoices"));
    if (items) {
      setInvoices(items);
    }
  }, []);

  return (
    <div style={{ padding: "0 10px" }}>
      <InvoiceDialog
        open={openInvoice}
        setOpen={setOpenInvoice}
        selectedInvoice={selectedInvoice}
      />
      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={4}>
          <StyledPaper sx={{ padding: "15px" }}>
            <Typography variant="formHeading" sx={{ marginBottom: "20px" }}>
              Generate Invoice
            </Typography>

            <Box component="form" onSubmit={formik.handleSubmit}>
              <Grid container rowSpacing={3}>
                <Grid item xs={12} md={12}>
                  <FormControl
                    error={formik.touched.class && formik.errors.class}
                    fullWidth
                  >
                    <Typography variant="formSubHeading">CLASS</Typography>
                    <StyledSelect
                      id="class"
                      name="class"
                      onChange={formik.handleChange}
                      value={formik.values.class}
                      error={
                        formik.touched.class && Boolean(formik.errors.class)
                      }
                    >
                      <MenuItem value={""} disabled>
                        <em>Select a class</em>
                      </MenuItem>
                      {classrooms?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    <FormHelperText variant="error">
                      {formik.touched.class && formik.errors.class}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormControl
                    error={formik.touched.items && formik.errors.items}
                    fullWidth
                  >
                    <Typography variant="formSubHeading">items</Typography>
                    <StyledMultiSelect
                      id="items"
                      name="items"
                      onChange={multiHandleChange}
                      value={formik.setFieldValue.items}
                      error={
                        formik.touched.items && Boolean(formik.errors.items)
                      }
                      options={fees?.map((fee) => ({
                        label: fee.name,
                        value: fee.id,
                      }))}
                      placeholder="Select Fees"
                    />

                    <FormHelperText variant="error">
                      {formik.touched.items && formik.errors.items}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} textAlign={"right"}>
                  <StyledLoadingButton
                    loading={generateFeeLoading}
                    type="submit"
                    sx={{ textTransform: "none" }}
                    onClick={formik.handleSubmit}
                  >
                    Generate
                  </StyledLoadingButton>
                </Grid>
              </Grid>
            </Box>
          </StyledPaper>
        </Grid>

        {/* Generated Table */}

        <Grid item xs={12} md={8}>
          <StyledPaper sx={{ paddingBottom: "25px" }}>
            <Box sx={{ padding: "15px" }}>
              <Typography variant="formHeading">Invoices</Typography>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ borderBottom: "2px solid lightgrey" }}>
                    <StyledTableCell>STUDENT</StyledTableCell>
                    <StyledTableCell>CLASS</StyledTableCell>
                    <StyledTableCell>TERM</StyledTableCell>
                    <StyledTableCell>SESSION</StyledTableCell>
                    <StyledTableCell>AMOUNT</StyledTableCell>
                    <StyledTableCell textAlign={"right"}>
                      STATUS
                    </StyledTableCell>
                    <StyledTableCell>VIEW</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {console.log("students", students)}
                  {invoices?.map((item) => (
                    <StyledTableRow key={item.id}>
                      <StyledTableCell>
                        {`${
                          students?.find(
                            (student) => student.id === item.student_id
                          )?.first_name || ""
                        }
                        
                          ${
                            students?.find(
                              (student) => student.id === item.student_id
                            )?.last_name || ""
                          }`}
                      </StyledTableCell>
                      <StyledTableCell>
                        {classrooms?.find(
                          (classroom) => classroom.id === formik.values.class
                        )?.name || ""}
                      </StyledTableCell>
                      <StyledTableCell>
                        {sessions
                          ?.find((session) => session.id === item.session_id)
                          ?.terms?.find((term) => term.id === item.term_id)
                          .name || ""}
                      </StyledTableCell>
                      <StyledTableCell>
                        {sessions?.find(
                          (session) => session.id === item.session_id
                        )?.name || ""}
                      </StyledTableCell>
                      <StyledTableCell>
                        {item.items.reduce(
                          (total, item) => total + item.amount,
                          0
                        )}
                      </StyledTableCell>
                      <StyledTableCell>-</StyledTableCell>
                      <StyledTableCell>
                        <Box
                          className="tableCellLink"
                          onClick={() => {
                            setSelectedInvoice(item);
                            setOpenInvoice(true);
                          }}
                        >
                          Invoice
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Invoices;
