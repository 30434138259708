import {
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  StyledSelect,
  StyledTextField,
} from "../../../customComponents/styled/styledInputs";
import { FilledButton } from "../../../customComponents/styled/styledButtons";
import { Box } from "@mui/system";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { StyledPaper } from "../../../customComponents/styled/styledPaper";

const validationSchema = yup.object({});

function ModifySubjectDialog({ open, setOpen, selectedPeriod, periods }) {
  const [selectedSubject, setSelectedSubject] = React.useState(
    selectedPeriod?.period?.subject
  );

  console.log(selectedPeriod);
  console.log(selectedPeriod?.period?.subject);

  //   const peri = periods.find((period) => period.day === selectedPeriod?.day)
  //     .periods[selectedPeriod?.periodIndex].subject;

  //   console.log(peri);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    console.log("saved");
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogContent>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CloseSharpIcon
            onClick={handleClose}
            sx={{
              cursor: "pointer",
              fontSize: "14px",
              color: "gray",
              "&:hover": { color: "black" },
            }}
          />
        </Box>
        <Typography variant="h4">Modify Timetable Subject</Typography>
      </DialogContent>
      <Divider />
      <DialogContent>
        <StyledTextField
          fullWidth
          disabled
          value={`${selectedPeriod?.period?.start} : ${selectedPeriod?.period?.end}`}
        />
        <StyledTextField
          fullWidth
          disabled
          value={selectedPeriod?.period?.subject}
        />
        <StyledSelect
          fullWidth
          label="Subject"
          name="subject"
          value={selectedSubject}
          onChange={(e) => {
            setSelectedSubject(e.target.value);
          }}
        >
          <MenuItem value="Maths">Maths</MenuItem>
          <MenuItem value="English">English</MenuItem>
          <MenuItem value="Science">Science</MenuItem>
          <MenuItem value="History">History</MenuItem>
          <MenuItem value="Geography">Geography</MenuItem>
          <MenuItem value="Computer">Computer</MenuItem>
          <MenuItem value="Physics">Physics</MenuItem>
          <MenuItem value="Chemistry">Chemistry</MenuItem>
          <MenuItem value="Biology">Biology</MenuItem>
        </StyledSelect>
        <FilledButton fullWidth onClick={handleSave}>
          Save
        </FilledButton>
      </DialogContent>
    </Dialog>
  );
}

export default ModifySubjectDialog;
