import {
  Grid,
  Stack,
  Box,
  Typography,
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import React from "react";
import { FilledButton } from "../../customComponents/styled/styledButtons";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { StyledTextArea } from "../../customComponents/styled/styledInputs";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";

function ExamsScoreSheet({ student }) {
  const [activeTab, setActiveTab] = React.useState(0);
  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={12} lg={11}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            "& .tabItem": {
              padding: "10px",
              cursor: "pointer",
              "&.active": {
                backgroundColor: "white",
                borderLeft: "1px solid #ccc",
                borderTop: "1px solid #ccc",
                borderRight: "1px solid #ccc",
                borderRadius: "5px 5px 0 0",
              },
            },
          }}
        >
          <Box
            className={`tabItem ${activeTab === 0 && " active"}`}
            onClick={() => setActiveTab(0)}
          >
            Term
          </Box>
          <Box
            className={`tabItem ${activeTab === 1 && " active"}`}
            onClick={() => setActiveTab(1)}
          >
            MidTerm
          </Box>
        </Stack>

        {activeTab === 0 && (
          <StyledPaper>
            <Box className="paperBody" sx={{ textAlign: "center" }}>
              <Typography variant="formHeading" color="primary.main">
                {student.name}'s scoresheet
              </Typography>
              <Typography variant="formSubHeading">
                <b>Session:</b>2020/2020 | <b>Term:</b> Second Term |
                <b>Class:</b> JSS2
              </Typography>
            </Box>
            <TableContainer>
              <Table sx={{ width: "100%", boxShadow: "none" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Subjects </StyledTableCell>
                    <StyledTableCell>CA</StyledTableCell>
                    <StyledTableCell>exam</StyledTableCell>
                    <StyledTableCell>Total marks</StyledTableCell>
                    <StyledTableCell>grades</StyledTableCell>
                    <StyledTableCell>remarks</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {student?.subjects?.map((subject) => (
                    <StyledTableRow key={subject.name}>
                      <StyledTableCell component="th" scope="row">
                        {subject.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {subject.ca}
                      </StyledTableCell>
                      <StyledTableCell>{subject.exams}</StyledTableCell>
                      <StyledTableCell>{subject.total}</StyledTableCell>
                      <StyledTableCell>{subject.grade}</StyledTableCell>
                      <StyledTableCell>{subject.remarks}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ textAlign: "left", p: 2 }}>
              <FormControl fullWidth sx={{ my: 4 }}>
                <Typography variant="formSubHeading">
                  Principal's comment
                </Typography>
                <StyledTextArea minRows={4} />
              </FormControl>
              <FormControl fullWidth>
                <Typography variant="formSubHeading">
                  Form teacher's comment
                </Typography>
                <StyledTextArea minRows={4} />
              </FormControl>
            </Box>
          </StyledPaper>
        )}

        {activeTab === 1 && (
          <StyledPaper>
            <Box className="paperBody" sx={{ textAlign: "center" }}>
              <Typography variant="formHeading" color="primary.main">
                {student.name}'s scoresheet
              </Typography>
              <Typography variant="formSubHeading">
                <b>Session:</b>2020/2020 | <b>Term:</b> Second Term |
                <b>Class:</b> JSS2
              </Typography>
            </Box>
            <TableContainer sx={{ pb: 4 }}>
              <Table sx={{ width: "100%", boxShadow: "none" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Subjects </StyledTableCell>
                    <StyledTableCell>Comments</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow></StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        )}
      </Grid>
      <Grid item xs={12} lg={1}>
        <Stack
          direction={{ xs: "row", md: "column" }}
          spacing={1}
          sx={{
            "& .btn-icon": {
              fontSize: "14px",
            },
          }}
        >
          <FilledButton>
            <SaveIcon className="btn-icon" />
          </FilledButton>
          <FilledButton>
            <DeleteIcon className="btn-icon" />
          </FilledButton>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ExamsScoreSheet;
