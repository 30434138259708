import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Fees"],
});


const accountSettingsApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAccountSettings: builder.query({
      query: (parameter) => `/fees/account/${parameter}`,
      providesTags: ["Fees"],
    }),
    getVerifiedAccount: builder.query({
      query: ({account_number, bank_code}) => `/fees/account?account_number=${account_number}&bank_code=${bank_code}`,
      providesTags: ["Fees"],
    }),
    getBanks: builder.query({
        query: () => `/fees/banks?country=nigeria`,
        providesTags: ["Fees"],
      }),
    createAccountSettings: builder.mutation({
      query: (values) => ({
        url: "/fees/account",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Fees"],
    }),
    updateAccountSettings: builder.mutation({
        query: (values) => ({
          url: "/fees/account",
          method: "PUT",
          body: { ...values },
        }),
        invalidatesTags: ["Fees"],
      }),
    deleteAccountSettings: builder.mutation({
      query: (values) => ({
        url: "/fees/account",
        method: "DELETE",
        body: { values },
      }),
      invalidatesTags: ["Fees"],
    }),
  }),
});

export const {
  useGetAccountSettingsQuery,
  useGetBanksQuery,
  useGetVerifiedAccountQuery,
  useUpdateAccountSettingsMutation,
  useCreateAccountSettingsMutation,
  useDeleteAccountSettingsMutation,
} = accountSettingsApiSlice;
