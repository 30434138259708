import { Avatar, Grid, LinearProgress } from "@mui/material";
import { useState } from "react";
import CustomMenuList from "../../customComponents/CustomMenuList";
import ExamsScoreSheet from "./ExamsScoreSheet";
import { useParams } from "react-router-dom";
import { useGetClassesQuery } from "../classroom/classApiSlice";
import { useGetAssessmentsQuery } from "../settings/assessments/assessmentsApiSlice";

function ClassReportSheet() {
  const { classId } = useParams();
  const [activeStudent, setActiveStudent] = useState(0);
  const { data: students, isLoading: getStudentsLoading } = useGetClassesQuery(
    `${classId}/students`
  );
  const { data: allAssessments, isLoading: loadingAssessments } =
    useGetAssessmentsQuery("all");
  const { data: classroom } = useGetClassesQuery(classId); //to get updated list of classrooms

  const subSectionAssessments = allAssessments?.filter(
    (assessment) => assessment.subsection_id === classroom?.subsection_id
  );

  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={12} lg={4}>
        {getStudentsLoading && <LinearProgress />}
        <CustomMenuList
          activeTab={activeStudent}
          setActiveTab={setActiveStudent}
          heading="Students"
          list={students?.map(
            (student) => student.first_name + " " + student.last_name
          )}
          icon={<Avatar />}
        />
      </Grid>

      <Grid item xs={12} lg={8}>
        <ExamsScoreSheet
          classId={classId}
          student={students ? students[activeStudent] : []}
          assessments={subSectionAssessments || []}
        />
      </Grid>
    </Grid>
  );
}

export default ClassReportSheet;
