import { useState } from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  SvgIcon,
  TableRow,
  Typography,
  LinearProgress,
} from "@mui/material";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import NewSubjectForm from "./NewSubjectForm";
import EditSubjectForm from "./EditSubjectForm";
import {
  useDeleteSubjectMutation,
  useGetSubjectsQuery,
} from "./subjectsApiSlice";
import swal from "sweetalert";
import CustomWarningDialog from "../../customComponents/CustomWarningDialog";

function Subjects() {
  const [openEdit, setOpenEdit] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [focusedSubject, setFocusedSubject] = useState(null);
  const { data: subjects, isLoading } = useGetSubjectsQuery("all");
  const [deleteSubject, { isLoading: deleting }] = useDeleteSubjectMutation();

  const handleDelete = async (subject) => {
    try {
      const response = await deleteSubject(subject).unwrap();
      if (response) {
        swal("Success", "Subject deleted successfully", "success");
      }
    } catch (error) {
      console.log(error);
      if (error.status === 404) {
        swal("Success", "Subject deleted successfully", "success");
      }
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <CustomWarningDialog
          open={openWarning}
          setOpen={setOpenWarning}
          action={handleDelete}
          item={deleteItem}
          message="Are you sure you want to delete this subject?"
        />

        <Grid item xs={12} md={12} lg={6}>
          <StyledPaper>
            {openEdit ? (
              <EditSubjectForm
                focusedSubject={focusedSubject}
                setFocusedSubject={setFocusedSubject}
                setOpenEdit={setOpenEdit}
              />
            ) : (
              <NewSubjectForm />
            )}
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <StyledPaper>
            <Box className="paperHeader">
              <Typography variant="formHeading">Subjects</Typography>
              <Typography
                variant="formSubHeading"
                sx={{ textTransform: "none" }}
              >
                To modify subject info, click edit icon
              </Typography>
            </Box>
            <Box className="e">
              {isLoading && <LinearProgress />}
              <TableContainer>
                <Table sx={{ width: "100%", boxShadow: "none" }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Subject </StyledTableCell>
                      <StyledTableCell align="left">
                        description
                      </StyledTableCell>
                      <StyledTableCell align="right">edit</StyledTableCell>
                      <StyledTableCell align="left">delete</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subjects?.map((subject) => (
                      <StyledTableRow key={subject.id}>
                        <StyledTableCell component="th" scope="row">
                          {subject.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {subject.description}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <SvgIcon
                            sx={{ color: "primary.main", cursor: "pointer" }}
                            component={EditIcon}
                            inheritViewBox
                            onClick={() => {
                              setFocusedSubject(subject);
                              setOpenEdit(true);
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <DeleteIcon
                            className="tableCellLink"
                            onClick={() => {
                              setDeleteItem(subject);
                              setOpenWarning(true);
                            }}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </StyledPaper>
        </Grid>
      </Grid>
    </>
  );
}

export default Subjects;
