import {
  FormControl,
  Grid,
  Typography,
  MenuItem,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  LinearProgress,
  FormHelperText,
} from "@mui/material";
import React from "react";
import { StyledSelect } from "../../customComponents/styled/styledInputs";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { useFormik } from "formik";
import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import * as yup from "yup";

import {
  useAddRoleMutation,
  useGetRolesQuery,
  useRemoveRoleMutation,
} from "./rolesApiSlice";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../auth/authSlice";
import { useGetAllUsersQuery } from "../../../app/authApiSlice";

function Roles() {
  const currentUser = useSelector(selectCurrentUser);

  const { data: roles, isLoading: getRolesLoading } = useGetRolesQuery();

  const { data: users } = useGetAllUsersQuery(currentUser.current_school_id);
  const [addRole, { isLoading: addRoleLoading }] = useAddRoleMutation();
  const [removeRole, { isLoading: removeRoleLoading }] =
    useRemoveRoleMutation();

  const formik = useFormik({
    initialValues: {
      user_id: "",
      role: "",
      action: "",
    },
    validationSchema: yup.object({
      user_id: yup.string().required("Required"),
      role: yup.string().required("Required"),
      action: yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        let response;
        if (values.action === "add") {
          response = await addRole({
            user_id: values.user_id,
            role: values.role,
            school_id: currentUser?.current_school_id,
          });
        } else {
          response = await removeRole({
            user_id: values.user_id,
            role: values.role,
            school_id: currentUser?.current_school_id,
          });
        }
        if (response.data) {
          swal("Success", "Role updated successfully", "success");
          formik.resetForm();
        } else {
          swal("Error", "Something went wrong", "error");
        }
      } catch (error) {
        console.log(error);
        swal("Error", "Something went wrong", "error");
      }
    },
  });

  return (
    <div style={{ padding: "0 15px" }}>
      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={4}>
          <StyledPaper sx={{ padding: "15px" }}>
            <Typography variant="formHeading">Roles Manager</Typography>
            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                rowSpacing={3}
                sx={{ padding: "5px", marginTop: "1px" }}
              >
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <Typography htmlFor="user_id" variant="formSubHeading">
                      SELECT STAFF
                    </Typography>
                    <StyledSelect
                      displayEmpty
                      id="user_id"
                      name="user_id"
                      value={formik.values.user_id}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="" disabled>
                        <em>select staff</em>
                      </MenuItem>

                      {users?.users?.map((user, i) => (
                        <MenuItem value={user.id} key={i}>
                          {user?.profile?.first_name} {user?.profile?.last_name}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    <FormHelperText variant="error">
                      {formik.touched.user_id && formik.errors.user_id}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <Typography htmlFor="role" variant="formSubHeading">
                      SELECT ROLE
                    </Typography>
                    <StyledSelect
                      displayEmpty
                      id="role"
                      name="role"
                      value={formik.values.role}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="">
                        <em>select role</em>
                      </MenuItem>
                      {roles
                        ?.filter((role) => (role.name !== "agent") && (role.name !== "superadmin") && (role.name !== "teacher"))
                        ?.map((role, i) => (
                          <MenuItem value={role?.name} key={i}>
                            {role?.name}
                          </MenuItem>
                        ))}
                    </StyledSelect>
                    <FormHelperText variant="error">
                      {formik.touched.role && formik.errors.role}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <Typography htmlFor="action" variant="formSubHeading">
                      Select ACTION
                    </Typography>
                    <StyledSelect
                      displayEmpty
                      id="action"
                      name="action"
                      value={formik.values.action}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="">
                        <em>select action</em>
                      </MenuItem>
                      <MenuItem value="add">Add Role</MenuItem>
                      <MenuItem value="remove">Remove Role</MenuItem>
                    </StyledSelect>
                    <FormHelperText variant="error">
                      {formik.touched.action && formik.errors.action}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} textAlign={"right"}>
                  <StyledLoadingButton
                    loading={addRoleLoading || removeRoleLoading}
                    type="submit"
                  >
                    SUBMIT
                  </StyledLoadingButton>
                </Grid>
              </Grid>
            </form>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={8}>
          <StyledPaper>
            <Typography sx={{ padding: "15px" }} variant="formHeading">
              Current Roles
            </Typography>
            <TableContainer>
              {getRolesLoading && <LinearProgress />}
              <Table>
                <TableHead>
                  <TableRow sx={{ borderBottom: "2px solid lightgrey " }}>
                    <StyledTableCell>NAME</StyledTableCell>
                    <StyledTableCell>PHONE</StyledTableCell>
                    {roles
                      ?.filter((role) => (role.name !== "agent") && (role.name !== "superadmin") && (role.name !== "teacher"))
                      ?.map((role, i) => (
                        <StyledTableCell key={i}>{role.name}</StyledTableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users?.users?.map((row, i) => (
                    <StyledTableRow key={i}>
                      <StyledTableCell component="th" scope="row">
                        {row?.profile?.first_name +
                          " " +
                          row?.profile?.last_name}
                      </StyledTableCell>
                      <StyledTableCell>{row?.profile?.phone}</StyledTableCell>
                      {roles
                        ?.filter((role) => (role.name !== "agent") && (role.name !== "superadmin") && (role.name !== "teacher"))
                        ?.map((role, i) => (
                          <StyledTableCell key={i}>
                            {row.roles?.some(
                              (userRole) => userRole.role.name === role.name
                            ) ? (
                              <CheckIcon />
                            ) : (
                              <ClearIcon />
                            )}
                          </StyledTableCell>
                        ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Roles;
