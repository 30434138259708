import { Box, FormControl, Grid, MenuItem, Typography } from "@mui/material";
import { StyledPaper } from "../../../customComponents/styled/styledPaper";
import React, { useState } from "react";
import { FilledButton } from "../../../customComponents/styled/styledButtons";
import {
  StyledSelect,
  StyledTextField,
} from "../../../customComponents/styled/styledInputs";

function GenerateTimeTable() {
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");

  const [periods, setPeriods] = useState([
    {
      day: "monday",
      periods: [
        {
          start: "08:00",
          end: "09:00",
          subject: "short lunch",
        },
        {
          start: "09:00",
          end: "10:00",
          subject: "short lunch",
        },
        {
          start: "10:00",
          end: "11:00",
          subject: "short lunch",
        },
        {
          start: "11:00",
          end: "12:00",
          subject: "short lunch",
        },
        {
          start: "12:00",
          end: "13:00",
          subject: "short lunch",
        },
        {
          start: "13:00",
          end: "14:00",
          subject: "short lunch",
        },
        {
          start: "14:00",
          end: "15:00",
          subject: "short lunch",
        },
        {
          start: "15:00",
          end: "16:00",
          subject: "short lunch",
        },
      ],
    },
    {
      day: "Tuesday",
      periods: [
        {
          start: "08:00",
          end: "09:00",
          subject: "short lunch",
        },
        {
          start: "09:00",
          end: "10:00",
          subject: "short lunch",
        },
        {
          start: "10:00",
          end: "11:00",
          subject: "short lunch",
        },
        {
          start: "11:00",
          end: "12:00",
          subject: "short lunch",
        },
        {
          start: "12:00",
          end: "13:00",
          subject: "short lunch",
        },
        {
          start: "13:00",
          end: "14:00",
          subject: "short lunch",
        },
        {
          start: "14:00",
          end: "15:00",
          subject: "short lunch",
        },
        {
          start: "15:00",
          end: "16:00",
          subject: "short lunch",
        },
      ],
    },
  ]);

  const classes = [
    "nursery one",
    "nursery two",
    "primary one",
    "primary two",
    "primary three",
    "primary four",
    "primary five",
    "primary six",
  ];

  const templates = ["nursery section", "primary section"];

  const specialPeriods = ["long lunch", "short lunch", "extra lessons"];

  const handlePeriodChange = (event, day, index) => {
    setPeriods((prevPeriods) => {
      const newPeriods = [...prevPeriods];
      newPeriods.find((period) => period.day === day).periods[index].subject =
        event.target.value;
      return newPeriods;
    });
  };

  const handleClickGenerate = (e) => {
    e.preventDefault();
    alert(JSON.stringify({ selectedClass, selectedTemplate }));
  };

  const handleSave = () => {
    console.log("Updated periods for ", selectedClass, periods);
  };

  return (
    <>
      <StyledPaper>
        <Box className="paperHeader">
          <Typography variant="formHeading">Generate Time Table</Typography>
        </Box>
        <Box className="paperBody">
          <form onSubmit={(e) => handleClickGenerate(e)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl error={!selectedClass} fullWidth>
                  <Typography variant="formSubHeading">class</Typography>
                  <StyledSelect
                    required
                    id="class"
                    name="class"
                    value={selectedClass}
                    onChange={(e) => setSelectedClass(e.target.value)}
                  >
                    {classes.map((item, i) => (
                      <MenuItem value={item} key={i}>
                        {item}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl error={!selectedTemplate} fullWidth>
                  <Typography variant="formSubHeading">Template</Typography>
                  <StyledSelect
                    required
                    id="template"
                    name="template"
                    value={selectedTemplate}
                    onChange={(e) => setSelectedTemplate(e.target.value)}
                  >
                    {templates.map((item, i) => (
                      <MenuItem value={item} key={i}>
                        {item}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <FilledButton type="submit">Generate</FilledButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </StyledPaper>
      <StyledPaper>
        <Box className="paperHeader">
          <Typography variant="formHeading">
            {selectedClass} Time Table
          </Typography>
        </Box>
        <Box className="paperBody">
          <Grid container spacing={3}>
            {periods.map((period, i) => (
              <React.Fragment key={i}>
                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth>
                    <Typography variant="formSubHeading">
                      {period.day}
                    </Typography>
                    <StyledTextField
                      required
                      id="day"
                      name="day"
                      disabled={true}
                      value={period.day}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={8}>
                  {period.periods.map((item, i) => (
                    <FormControl fullWidth key={i}>
                      <Typography variant="formSubHeading">
                        {item.start} - {item.end}
                      </Typography>
                      <StyledSelect
                        required
                        id="period"
                        name="period"
                        value={item.subject}
                        onChange={(e) => handlePeriodChange(e, period.day, i)}
                      >
                        {specialPeriods.map((item, i) => (
                          <MenuItem value={item} key={i}>
                            {item}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControl>
                  ))}
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12} md={12} textAlign="right">
              <FilledButton type="submit" onClick={handleSave}>
                save
              </FilledButton>
            </Grid>
          </Grid>
        </Box>
      </StyledPaper>
    </>
  );
}

export default GenerateTimeTable;
