import {
  Grid,
  MenuItem,
  Typography,
  FormControl,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Box,
  FormHelperText,
  LinearProgress,
  CircularProgress,
  Stack,
} from "@mui/material";
import { React, useEffect, useState } from "react";
import {
  StyledSelect,
  StyledTextField,
} from "../../customComponents/styled/styledInputs";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import { useFormik } from "formik";
import * as yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useGetBanksQuery,
  useCreateAccountSettingsMutation,
  useUpdateAccountSettingsMutation,
  useGetAccountSettingsQuery,
  useGetVerifiedAccountQuery,
} from "./accountSettingsApiSlice";
import { useGetSchoolProfileQuery } from "../settings/school/schoolApiSlice";
import {
  useGetOptionsQuery,
  useCreateOptionMutation,
  useDeleteOptionMutation,
} from "./optionsApiSlice";
import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
import swal from "sweetalert";
import CustomWarningDialog from "../../customComponents/CustomWarningDialog";

const validationSchema = yup.object({
  accountNumber: yup.string().required("Required"),
  accountName: yup.string().required("Required"),
  bank: yup.string().required("Required"),
});

const manageFeesValidation = yup.object({
  option: yup.string().required("Cannot be blank"),
});

function AccountSettings() {
  // Account endpoint implementation
  const [createAccountSettings, { isLoading: isCreatingAccountSettings }] =
    useCreateAccountSettingsMutation();
  const { data: banks } = useGetBanksQuery("all");
  const { data: schoolInfo, isLoading: schoolInfoLoading } =
    useGetSchoolProfileQuery();
  const { data: account } = useGetAccountSettingsQuery(`${schoolInfo?.id}`);
  const [updateAccountSettings, { isLoading: isUpdatingAccountSettings }] =
    useUpdateAccountSettingsMutation();

  // Options endpoint implementation
  const { data: options, isLoading: optionsLoading } =
    useGetOptionsQuery("all");
  const [createOption, { isLoading: isCreatingOption }] =
    useCreateOptionMutation();
  const [deleteOption] = useDeleteOptionMutation();

  // Delete option event handler
  const [openWarning, setOpenWarning] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [verifyAccount, setVerifyAccount] = useState(true);
  const handleOptionDelete = async (option) => {
    try {
      const response = await deleteOption(option).unwrap();
      if (response) {
        swal("Success", "Payment option deleted successfully", "success");
      }
    } catch (error) {
      console.log(error);
      if (error.status === 404) {
        swal("Success", "Payment option deleted successfully", "success");
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      accountNumber: account?.account_number || "",
      accountName: account?.account_name || "",
      bank: account?.bank_code || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        let body;
        let response;

        if (account?.id) {
          body = {
            account_id: account?.id,
            account_name: values.accountName,
            account_number: values.accountNumber,
            bank_code: values.bank,
          };
          response = await updateAccountSettings(body).unwrap();
        } else {
          body = {
            account_name: values.accountName,
            account_number: values.accountNumber,
            bank_code: values.bank,
          };

          response = await createAccountSettings(body).unwrap();
        }

        if (response) {
          swal("Success", `Account Settings updated successfully`, "success");
        } else {
          swal("Error", "Account Update failed", "error");
        }
      } catch (error) {
        console.log(error);
        swal("Error", "something went wrong", "error");
      }
    },
  });

  const manageFees = useFormik({
    initialValues: {
      option: "",
    },
    validationSchema: manageFeesValidation,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const body = {
          name: `${values.option} Percent Payment Option`,
          percentage: values.option,
        };
        const response = await createOption(body).unwrap();
        if (response) {
          swal("success", "Payment option created successfully", "success");
        } else {
          swal(
            "error",
            "Payment Option was not added, Please try again",
            "error"
          );
        }
      } catch (error) {
        console.log(error);
        swal("Error", "something went wrong", "error");
      }
    },
  });

  // ACCOUNT VERIFICATION

  const handleVerifyAccount = () => {
    if (formik.values.accountNumber.length === 9) {
      setVerifyAccount(false);
    } else {
      formik.setFieldValue("accountName", " ");
    }
  };

  const { data: verifiedAccount, isFetching: verifiedAccountLoading } =
    useGetVerifiedAccountQuery(
      {
        account_number: formik.values.accountNumber,
        bank_code: formik.values.bank,
      },
      { skip: verifyAccount }
    );

  useEffect(() => {
    if (verifiedAccount) {
      formik.setFieldValue("accountName", verifiedAccount.account_name);
    }
  }, [verifiedAccount]);

  console.log(account);

  return (
    <div style={{ padding: "0 10px" }}>
      <Grid container columnSpacing={4}>
        <CustomWarningDialog
          open={openWarning}
          setOpen={setOpenWarning}
          action={handleOptionDelete}
          item={deleteItem}
          message="Are you sure you want to delete this payment option?"
        />

        <Grid item xs={12} md={4}>
          <StyledPaper sx={{ padding: "0 0px 15px 0" }}>
            {schoolInfoLoading && <LinearProgress />}

            <Box sx={{ padding: "0 15px" }}>
              <Typography
                variant="formHeading"
                sx={{ marginBottom: "20px", paddingTop: "15px" }}
              >
                School Account Manager
              </Typography>

              <Box component="form" onSubmit={formik.handleSubmit}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12} md={12}>
                    <FormControl
                      error={formik.touched.bank && formik.errors.bank}
                      fullWidth
                    >
                      <Typography variant="formSubHeading">BANK</Typography>
                      <StyledSelect
                        id="bank"
                        name="bank"
                        onChange={formik.handleChange}
                        value={formik.values.bank}
                        error={
                          formik.touched.bank && Boolean(formik.errors.bank)
                        }
                      >
                        <MenuItem value="" disabled>
                          <em>Select a Bank</em>
                        </MenuItem>
                        {banks?.map((bank) => (
                          <MenuItem key={bank.id} value={bank.code}>
                            {bank.name}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                      <FormHelperText variant="error">
                        {formik.touched.bank && formik.errors.bank}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormControl
                      error={
                        formik.touched.accountNumber &&
                        formik.errors.accountNumber
                      }
                      fullWidth
                    >
                      <Typography variant="formSubHeading">
                        ACCOUNT NUMBER
                      </Typography>
                      <StyledTextField
                        id="accountNumber"
                        name="accountNumber"
                        value={formik.values.accountNumber}
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleVerifyAccount();
                        }}
                        error={
                          formik.touched.accountNumber &&
                          Boolean(formik.errors.accountNumber)
                        }
                      />
                      <FormHelperText variant="error">
                        {formik.touched.accountNumber &&
                          formik.errors.accountNumber}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormControl
                      error={
                        formik.touched.accountName && formik.errors.accountName
                      }
                      fullWidth
                    >
                      <Typography variant="formSubHeading">
                        ACCOUNT NAME
                      </Typography>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <StyledTextField
                          sx={{ flex: 1 }}
                          disabled
                          id="accountName"
                          name="accountName"
                          value={formik.values.accountName}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.accountName &&
                            Boolean(formik.errors.accountName)
                          }
                        />
                        {verifiedAccountLoading && (
                          <CircularProgress size="24px" />
                        )}
                      </Stack>
                      <FormHelperText variant="error">
                        {formik.touched.accountName &&
                          formik.errors.accountName}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12} textAlign={"right"}>
                    <StyledLoadingButton
                      loading={
                        isCreatingAccountSettings || isUpdatingAccountSettings
                      }
                      type="submit"
                      sx={{ textTransform: "none" }}
                    >
                      Submit
                    </StyledLoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </StyledPaper>

          {/* MANAGE FEES PAYMENT OPTIONS */}

          <StyledPaper sx={{ padding: "15px" }}>
            <Typography variant="formHeading" sx={{ marginBottom: "20px" }}>
              Manage Fee Payment Options
            </Typography>

            <Box component="form" onSubmit={manageFees.handleSubmit}>
              <Grid container rowSpacing={3}>
                <Grid item xs={12} md={12}>
                  <FormControl
                    error={
                      manageFees.touched.option && manageFees.errors.option
                    }
                    fullWidth
                  >
                    <Typography variant="formSubHeading">OPTION</Typography>
                    <StyledTextField
                      placeholder="e.g 50 for 50%"
                      type="number"
                      id="option"
                      name="option"
                      value={manageFees.values.option}
                      onChange={manageFees.handleChange}
                      error={
                        manageFees.touched.option &&
                        Boolean(manageFees.errors.option)
                      }
                    />
                    <FormHelperText variant="error">
                      {manageFees.touched.option && manageFees.errors.option}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} textAlign={"right"}>
                  <StyledLoadingButton
                    loading={isCreatingOption}
                    type="submit"
                    sx={{ textTransform: "none" }}
                  >
                    Add
                  </StyledLoadingButton>
                </Grid>
              </Grid>
            </Box>
          </StyledPaper>
        </Grid>

        {/* Generated Table */}

        <Grid item xs={12} md={8}>
          <StyledPaper sx={{ paddingBottom: "25px" }}>
            <Box sx={{ padding: "15px" }}>
              <Typography variant="formHeading">
                Current School Account
              </Typography>
            </Box>
            {schoolInfoLoading && <LinearProgress />}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ borderBottom: "1.5px solid lightgrey " }}>
                    <StyledTableCell>ACCOUNT NAME</StyledTableCell>
                    <StyledTableCell>ACCOUNT NUMBER</StyledTableCell>
                    <StyledTableCell>BANK</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <TableCell
                      id="acctName"
                      sx={{ textTransform: "uppercase" }}
                    >
                      {account?.account_name || ""}
                    </TableCell>
                    <TableCell id="acctNumber">
                      {account?.account_number || ""}
                    </TableCell>
                    <TableCell
                      id="bankName"
                      sx={{ textTransform: "uppercase" }}
                    >
                      {banks?.find((bank) => bank.code === account?.bank_code)
                        ?.name || ""}
                    </TableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>

          {/* FEE PAYMENTS OPTIONS TABLE */}
          <StyledPaper sx={{ paddingBottom: "25px" }}>
            <Box sx={{ padding: "15px" }}>
              <Typography variant="formHeading">
                Available Payment Options
              </Typography>
            </Box>
            {optionsLoading && <LinearProgress />}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ borderBottom: "1.5px solid lightgrey " }}>
                    <StyledTableCell sx={{ width: "12%" }}>
                      OPTION
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "100%" }}>
                      DESCRIPTION
                    </StyledTableCell>
                    <StyledTableCell sx={{ paddingRight: "2rem" }}>
                      DELETE
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {options?.map((option) => (
                    <StyledTableRow key={option.id}>
                      <StyledTableCell>
                        {option?.percentage || ""}
                      </StyledTableCell>

                      <StyledTableCell>
                        {(() => {
                          if (option?.percentage === 100) {
                            return `with this option, a parent will pay ${option.percentage}% of the fees`;
                          } else {
                            return `with this option, a parent may choose to pay ${option.percentage}% of the fees as their initial payment`;
                          }
                        })() || ""}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          color: "primary.main",
                          "&:hover": { cursor: "pointer" },
                        }}
                      >
                        <DeleteIcon
                          className="tableCellLink"
                          onClick={() => {
                            setDeleteItem(option);
                            setOpenWarning(true);
                          }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </Grid>
      </Grid>
    </div>
  );
}

export default AccountSettings;
