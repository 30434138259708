import {
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  StyledFileInput,
  StyledSelect,
  StyledTextField,
} from "../../customComponents/styled/styledInputs";
import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
import { Box } from "@mui/system";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import {
  useAddStudentToClassroomMutation,
  useUpdateStudentMutation,
} from "./studentApiSlice";
import { useUploadFileMutation } from "../../../app/utilsApiSlice";
import { useGetClassesQuery } from "../classroom/classApiSlice";
import swal from "sweetalert";
import { format, parseISO } from "date-fns";

const validationSchema = yup.object({
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  email: yup.string().email("Enter a valid email").required("Required"),
  gender: yup.string().required("Required"),
  classroom: yup.string().required("Required"),
  dob: yup.string().required("Required"),
  address: yup.string().required("Required"),
  phone: yup.string().required("Required"),
});

function EditStudentDialog({
  open,
  setOpen,
  selectedStudent,
  currentClassroom,
}) {
  const { data: classrooms } = useGetClassesQuery("all");
  const [uploadFile, { isLoading: fileLoading }] = useUploadFileMutation();
  const [updateStudent, { isLoading: updateStudentLoading }] =
    useUpdateStudentMutation();
  const [addStudentToClass] = useAddStudentToClassroomMutation();

  const formik = useFormik({
    initialValues: {
      id: selectedStudent?.id,
      first_name: selectedStudent?.first_name || "",
      last_name: selectedStudent?.last_name || "",
      email: selectedStudent?.email || "",
      dob: selectedStudent?.dob
        ? format(parseISO(selectedStudent?.dob), "yyyy-MM-dd")
        : "",
      gender: selectedStudent?.gender || "",
      classroom: (currentClassroom && currentClassroom) || "",
      address: selectedStudent?.address || "",
      phone: selectedStudent?.phone || "",
      photo: selectedStudent?.photo || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await updateStudent(values).unwrap();
        swal("Success", "Student updated successfully", "success");
        if (values.classroom !== currentClassroom) {
          const res = await addStudentToClass({
            id: values.id,
            classroom_id: values.classroom,
          }).unwrap();
          swal("Success", "Student classroom changed", "success");
        }
      } catch (error) {}
      handleClose();
    },
  });

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const response = await uploadFile(formData).unwrap();
      if (response) {
        formik.setFieldValue(e.target.name, response.url);
      }
    } catch (error) {}
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        ".css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          maxWidth: "800px",
        },
      }}
    >
      <Box sx={{ display: "flex", padding: 2, justifyContent: "flex-end" }}>
        <CloseSharpIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
      </Box>
      <Divider />

      <DialogContent>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} lg={8}>
            <StyledPaper>
              <Box className="paperHeader">Update Profile</Box>
              <Box className="paperBody">
                <Box component="form" onSubmit={formik.handleSubmit}>
                  <Grid container columnSpacing={1} rowSpacing={3}>
                    <Grid item xs={12} lg={6}>
                      <FormControl
                        fullWidth
                        error={
                          formik.touched.first_name && formik.errors.first_name
                        }
                      >
                        <Typography variant="formSubHeading">
                          first name
                        </Typography>
                        <StyledTextField
                          bold
                          variant="outlined"
                          id="first_name"
                          name="first_name"
                          value={formik.values.first_name}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.first_name &&
                            Boolean(formik.errors.first_name)
                          }
                        />
                        <FormHelperText variant="error">
                          {formik.touched.first_name &&
                            formik.errors.first_name}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <FormControl
                        fullWidth
                        error={
                          formik.touched.last_name &&
                          Boolean(formik.errors.last_name)
                        }
                      >
                        <Typography variant="formSubHeading">
                          last name
                        </Typography>
                        <StyledTextField
                          bold
                          variant="outlined"
                          id="last_name"
                          name="last_name"
                          value={formik.values.last_name}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.last_name &&
                            Boolean(formik.errors.last_name)
                          }
                        />
                        <FormHelperText variant="error">
                          {formik.touched.last_name && formik.errors.last_name}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {/* end of first row */}

                    {/* beggining second row inputs */}

                    <Grid item xs={12} md={4}>
                      <FormControl
                        error={formik.touched.dob && formik.errors.dob}
                        fullWidth
                      >
                        <Typography htmlFor="section" variant="formSubHeading">
                          Date of birth
                        </Typography>
                        <StyledTextField
                          type="date"
                          id="dob"
                          name="dob"
                          value={formik.values.dob}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.dob && Boolean(formik.errors.dob)
                          }
                        />

                        <FormHelperText variant="error">
                          {formik.touched.dob && formik.errors.dob}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl
                        error={formik.touched.gender && formik.errors.gender}
                        fullWidth
                      >
                        <Typography htmlFor="gender" variant="formSubHeading">
                          gender
                        </Typography>
                        <StyledSelect
                          displayEmpty
                          id="gender"
                          name="gender"
                          value={formik.values.gender}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.gender &&
                            Boolean(formik.errors.gender)
                          }
                        >
                          <MenuItem value="" disabled>
                            <em>select gender</em>
                          </MenuItem>
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                        </StyledSelect>
                        <FormHelperText variant="error">
                          {formik.touched.gender && formik.errors.gender}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <Typography variant="formSubHeading">photo</Typography>

                        <Stack direction="row" spacing={1}>
                          <StyledFileInput
                            type="file"
                            id="photo"
                            name="photo"
                            onChange={(e) => handleFileChange(e)}
                            error={
                              formik.touched.photo &&
                              Boolean(formik.errors.photo)
                            }
                          />
                          {fileLoading && <CircularProgress />}
                        </Stack>

                        <FormHelperText variant="error">
                          {formik.touched.photo && formik.errors.photo}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {/* beginning of third row inputs */}

                    <Grid item xs={12} md={4}>
                      <FormControl
                        error={
                          formik.touched.classroom && formik.errors.classroom
                        }
                        fullWidth
                      >
                        <Typography
                          htmlFor="classroom"
                          variant="formSubHeading"
                        >
                          classroom
                        </Typography>
                        <StyledSelect
                          displayEmpty
                          id="classroom"
                          name="classroom"
                          value={formik.values.classroom}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.classroom &&
                            Boolean(formik.errors.classroom)
                          }
                        >
                          <MenuItem value="" disabled>
                            <em>select classroom</em>
                          </MenuItem>
                          {classrooms?.map((classroom) => (
                            <MenuItem key={classroom.id} value={classroom.id}>
                              {classroom.name}
                            </MenuItem>
                          ))}
                        </StyledSelect>
                        <FormHelperText variant="error">
                          {formik.touched.classroom && formik.errors.classroom}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl error fullWidth>
                        <Typography variant="formSubHeading">phone</Typography>
                        <StyledTextField
                          variant="outlined"
                          id="phone"
                          name="phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.phone && Boolean(formik.errors.phone)
                          }
                        />
                        <FormHelperText variant="error">
                          {formik.touched.phone && formik.errors.phone}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl error fullWidth>
                        <Typography variant="formSubHeading">email</Typography>
                        <StyledTextField
                          variant="outlined"
                          id="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                        />
                        <FormHelperText variant="error">
                          {formik.touched.email && formik.errors.email}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {/* end of third row inputs */}

                    <Grid item xs={12}>
                      <FormControl error fullWidth>
                        <Typography variant="formSubHeading">
                          address
                        </Typography>
                        <StyledTextField
                          variant="outlined"
                          id="address"
                          name="address"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.address &&
                            Boolean(formik.errors.address)
                          }
                        />
                        <FormHelperText variant="error">
                          {formik.touched.address && formik.errors.address}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12} textAlign={"right"}>
                      <StyledLoadingButton
                        loading={updateStudentLoading}
                        type="submit"
                      >
                        SUBMIT
                      </StyledLoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </StyledPaper>
          </Grid>
          <Grid item xs={12} lg={4}>
            <StyledPaper>
              <Box
                className="paperBody"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  "& img": {
                    width: "124px",
                    height: "124px",
                    borderRadius: "50%",
                    border: "5px solid #eeeee",
                    marginBottom: 2,
                  },
                  "& .nameText": {
                    fontSize: "18px",
                    lineHeight: "20px",
                    marginBottom: "5px",
                    fontWeight: 300,
                  },
                  "& .regNo": {
                    marginBottom: "10px",
                  },
                }}
              >
                <Box
                  component="img"
                  alt="student photo"
                  src={selectedStudent?.photo}
                />
                <Typography variant="formHeading" className="nameText">
                  {selectedStudent?.first_name} {selectedStudent?.last_name}
                </Typography>
                <Typography variant="subtitle" className="regNo">
                  ID No: {selectedStudent?.id}
                </Typography>
              </Box>
            </StyledPaper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default EditStudentDialog;
