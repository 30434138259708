import { authApiSlice } from "../../../app/authApiSlice";

const apiSliceWithTags = authApiSlice.enhanceEndpoints({
  addTagTypes: ["Roles"],
});

const rolesApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: () => `/auth/roles`,
      providesTags: ["Roles"],
    }),
    addRole: builder.mutation({
      query: (values) => ({
        url: "auth/add-role",
        method: "PATCH",
        body: { ...values },
      }),
      invalidatesTags: ["Roles", "Users"],
    }),
    removeRole: builder.mutation({
      query: (values) => ({
        url: "auth/remove-role",
        method: "PATCH",
        body: { ...values },
      }),
      invalidatesTags: ["Roles", "Users"],
    }),
  }),
});

export const { useGetRolesQuery, useAddRoleMutation, useRemoveRoleMutation } =
  rolesApiSlice;
