import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";
import { visuallyHidden } from "@mui/utils";
import PrintIcon from "@mui/icons-material/Print";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import { useReactToPrint } from "react-to-print";
import { FilledButton } from "../../customComponents/styled/styledButtons";
import { StyledTextField } from "../../customComponents/styled/styledInputs";
import { Avatar, Stack } from "@mui/material";
import { format } from "date-fns";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const headCells = [
    {
      id: "photo",

      label: "Photo",
    },
    {
      id: "title",

      label: "Title",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "phone",
      label: "Phone",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "reset",
      label: "Reset",
    },
    {
      id: "delete",
      label: "Delete",
    },
  ];

  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  rows: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { setSearchkey, searchKey, handlePrint } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },

        "& .search": {
          margin: "0px 5px",
        },
      }}
    >
      <FilledButton onClick={handlePrint}>
        Print
        <PrintIcon />
      </FilledButton>
      <StyledTextField
        className="search"
        placeholder="search..."
        value={searchKey}
        onChange={(e) => setSearchkey(e.target.value)}
      />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  setSearchkey: PropTypes.func.isRequired,
  searchkey: PropTypes.string.isRequired,
  handlePrint: PropTypes.func.isRequired,
};

export default function StaffTable({
  rows,
  schoolInfo,
  setSelectedStaff,
  setOpenEdit,
  setOpenWarningDialog,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchkey, setSearchkey] = React.useState("");
  const componentToPrintRef = React.useRef();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <EnhancedTableToolbar
        setSearchkey={setSearchkey}
        searchkey={searchkey}
        handlePrint={handlePrint}
      />
      <div style={{ display: "none", padding: "8px" }}>
        {/* Table to print, containing all the table data without searching or sorting */}
        <Box
          ref={componentToPrintRef}
          p={3}
          mb={2}
          sx={{
            "& table": {
              width: "100%",
              "& .th": {
                paddingBottom: "5px",
                borderBottom: "1px solid #f1f2f3",
                textTransform: "uppercase",
              },
            },
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography>{format(new Date(), "MM/dd/yyyy, KK:mm a")}</Typography>
            <Typography>{schoolInfo?.name} - Schoola SRC</Typography>
          </Stack>
          <Typography variant={"h5"} textAlign="center" my={2}>
            {schoolInfo?.name} - Schoola SRC
          </Typography>
          <table>
            <tr>
              <td className="th">S/N</td>
              <td className="th">Title</td>
              <td className="th">Name</td>
              <td className="th">Phone</td>
            </tr>
            {rows?.map((row, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{row.title}</td>
                <td>
                  {row.first_name} {row.last_name}
                </td>
                <td>{row.phone}</td>
              </tr>
            ))}
          </table>
        </Box>
        {/* <TableContainer ref={componentToPrintRef}>
          <Table>
            <TableHead>
              <Stack direction="row" justifyContent="space-between">
                <Typography>
                  {format(new Date(), "MM/dd/yyyy, KK:mm a")}
                </Typography>
                <Typography>{schoolInfo?.name}</Typography>
              </Stack>
              <Typography variant="h4" sx={{ marginBottom: "10px" }}>
                {schoolInfo?.name}
              </Typography>
              <TableRow sx={{ borderBottom: "2px solid lightgrey" }}>
                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell>title</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Phone</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell>{i + 1}</StyledTableCell>
                  <StyledTableCell>{row.title}</StyledTableCell>
                  <StyledTableCell>
                    {row.first_name} {row.last_name}
                  </StyledTableCell>
                  <StyledTableCell>{row.phone}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
      </div>

      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(rows, getComparator(order, orderBy))
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.filter((row) => {
                return (
                  row?.first_name
                    ?.toLowerCase()
                    .includes(searchkey.toLowerCase()) ||
                  row?.last_name
                    ?.toLowerCase()
                    .includes(searchkey.toLowerCase())
                );
              })
              ?.map((row) => {
                return (
                  <StyledTableRow hover tabIndex={-1} key={row.id}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ paddingLeft: "10px" }}
                    >
                      <Avatar
                        alt="passport"
                        src={row.image}
                        sx={{ width: 24, height: 24 }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{row.title}</StyledTableCell>
                    <StyledTableCell>
                      <Box
                        className="tableCellLink"
                        onClick={() => {
                          setSelectedStaff(row);
                          setOpenEdit(true);
                        }}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {row.first_name} {row.last_name}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>{row.phone}</StyledTableCell>
                    <StyledTableCell>
                      <Switch size="small" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <ReplayIcon
                        sx={{
                          color: "primary.main",
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <DeleteIcon
                        className="tableCellLink"
                        onClick={() => {
                          setSelectedStaff(row);
                          setOpenWarningDialog(true);
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 33 * emptyRows,
                }}
              >
                <StyledTableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
