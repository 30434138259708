import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box } from "@mui/system";
import swal from "sweetalert";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  StyledSelect,
  StyledTextField,
} from "../../../customComponents/styled/styledInputs";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../customComponents/styled/styledTable";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledLoadingButton } from "../../../customComponents/styled/styledButtons";
import CustomWarningDialog from "../../../customComponents/CustomWarningDialog";

import { useGetTemplatesQuery } from "../templates/templatesApiSlice";
import {
  useAddTemplateToSubSectionMutation,
  useRemoveTemplateMutation,
  useUpdateSubSectionMutation,
  useGetSectionQuery,
  useGetSubSectionQuery,
} from "./sectionsApiSlice";
import { useState } from "react";

const subSectionFormValidation = yup.object({
  name: yup.string().required("Required"),
  reportType: yup.string().required("Required"),
  // reportTemplate: yup.string().required("Required"),
});

function EditSubSectionForm({ item }) {
  const { data: templates, isLoading: templatesLoading } =
    useGetTemplatesQuery("all");
  const { data: subsections, isLoading: subsectionsLoading } =
    useGetSubSectionQuery("all");
  const [addTemplateToSubSection, { isLoading: isAddingTemplateToSubSection }] =
    useAddTemplateToSubSectionMutation();
  const [updateSubSection, { isLoading: isUpdatingSubSection }] =
    useUpdateSubSectionMutation();
  const [removeTemplate] = useRemoveTemplateMutation();
  const { data: sections, isLoading: sectionsLoading } =
    useGetSectionQuery("all");

  const currentItem = subsections?.find(
    (subsection) => subsection.id === item.id
  );

  const [deleteTemplate, setDeleteTemplate] = useState(null);
  const [openWarning, setOpenWarning] = useState(false);

  const handleTemplateDelete = async (template) => {
    try {
      const body = {
        subsection_id: item.id,
        template_id: template.id,
      };

      const response = await removeTemplate(body).unwrap();
      if (response) {
        swal("Success", "Template removed successfully", "success");
        //  const test = sections?.find((section) => section.subsections.find((subsection) => subsection.id === item.id));
      }
    } catch (error) {
      if (error.status === 404) {
        swal("Success", "Template removed successfully", "success");
      }
    }
  };

  const subSectionForm = useFormik({
    initialValues: {
      name: item.name,
      reportType: item.report_type,
      reportTemplate: item.templates.name,
    },
    enableReinitialize: true,
    validationSchema: subSectionFormValidation,
    onSubmit: async (values) => {
      try {
        const body = {
          subsection_id: item.id,
          template_id: values.reportTemplate,
        };
        const update = {
          id: item.id || "",
          name: values.name || "",
          report_type: values.reportType || "",
          section_id:
            sections?.find((section) =>
              section.subsections.find(
                (subsection) => subsection.id === item.id
              )
            ).id || "",
          head_id: item.head?.id || "",
          head_title:
            sections?.find((section) =>
              section.subsections.find(
                (subsection) => subsection.id === item.id
              )
            ).head_title || "",
        };

        const response = await addTemplateToSubSection(body).unwrap();
        const updateResponse = await updateSubSection(update).unwrap();
        if (response && updateResponse) {
          swal(
            "success",
            `${item.name} sub-section updated successfully`,
            "success"
          );
        } else {
          swal("error", "Template was not added, Please try again", "error");
        }
      } catch (error) {
        swal("Error", "something went wrong", "error");
      }
    },
  });

  return (
    <>
      <CustomWarningDialog
        open={openWarning}
        setOpen={setOpenWarning}
        item={deleteTemplate}
        action={handleTemplateDelete}
        message="Are you sure you want to remove this template?"
      />

      <Box className="paperHeader">
        <Typography variant="formHeading">Edit Sub-Section</Typography>
      </Box>
      <Box className="paperBody">
        <form onSubmit={subSectionForm.handleSubmit}>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">
                  Sub-Section Name
                </Typography>
                <StyledTextField
                  autoFocus
                  variant="outlined"
                  id="name"
                  name="name"
                  value={subSectionForm.values.name}
                  onChange={subSectionForm.handleChange}
                  error={
                    subSectionForm.touched.name &&
                    Boolean(subSectionForm.errors.name)
                  }
                />
                <FormHelperText variant="error">
                  {subSectionForm.touched.name && subSectionForm.errors.name}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography variant="formSubHeading">Report Type</Typography>
                <StyledSelect
                  id="reportType"
                  name="reportType"
                  value={subSectionForm.values.reportType}
                  onChange={subSectionForm.handleChange}
                  error={
                    subSectionForm.touched.reportType &&
                    Boolean(subSectionForm.errors.reportType)
                  }
                >
                  <MenuItem value="score">Score Based</MenuItem>
                  <MenuItem value="skill">Comment/Skill Based</MenuItem>
                </StyledSelect>
                <FormHelperText variant="error">
                  {subSectionForm.touched.reportType &&
                    subSectionForm.errors.reportType}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography variant="formSubHeading">
                  Report Template
                </Typography>
                <StyledSelect
                  id="reportTemplate"
                  name="reportTemplate"
                  value={subSectionForm.values.reportTemplate}
                  onChange={subSectionForm.handleChange}
                  error={
                    subSectionForm.touched.reportTemplate &&
                    Boolean(subSectionForm.errors.reportTemplate)
                  }
                >
                  <MenuItem value="">
                    <em>Select a Template</em>
                  </MenuItem>
                  {templates?.map((template) => (
                    <MenuItem value={template.id} key={template.id}>
                      {" "}
                      {template.name}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <FormHelperText variant="error">
                  {subSectionForm.touched.reportTemplate &&
                    subSectionForm.errors.reportTemplate}
                </FormHelperText>
              </FormControl>
            </Grid> */}

            <Grid item xs={12} textAlign="right">
              <StyledLoadingButton
                type="submit"
                loading={isAddingTemplateToSubSection && isUpdatingSubSection}
              >
                Update
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>

      {/* sub-section templates table */}
      <Typography variant="formHeading" sx={{ padding: "25px 0 5px 15px" }}>
        {item.name} templates
      </Typography>

      <TableContainer sx={{ pb: 4 }}>
        <Table sx={{ width: "100%", boxShadow: "none" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Section </StyledTableCell>
              <StyledTableCell align="right">delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItem?.templates?.map((template) => (
              <StyledTableRow key={template.id}>
                <StyledTableCell component="th" scope="section">
                  {template.name}
                </StyledTableCell>

                <StyledTableCell align="right">
                  <DeleteIcon
                    className="tableCellLink"
                    onClick={() => {
                      setDeleteTemplate(template);
                      setOpenWarning(true);
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default EditSubSectionForm;
