import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Teacher"],
});

const teacherApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getTeacher: builder.query({
      query: (parameter) => `/teacher/${parameter}`,
      providesTags: ["Teacher"],
    }),
    getTeacherSubjects: builder.query({
      query: () => `/teacher/subjects`,
      providesTags: ["Teacher"],
    }),
    createTeacher: builder.mutation({
      query: (values) => ({
        url: "/teacher/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Teacher"],
    }),
    updateTeacher: builder.mutation({
      query: (values) => ({
        url: "/teacher/update",
        method: "PUT",
        body: { ...values },
      }),
      invalidatesTags: ["Teacher"],
    }),
    deleteTeacher: builder.mutation({
      query: (values) => ({
        url: `/teacher/${values.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Teacher"],
    }),
  }),
});

export const {
  useCreateTeacherMutation,
  useDeleteTeacherMutation,
  useGetTeacherQuery,
  useUpdateTeacherMutation,
  useGetTeacherSubjectsQuery,
} = teacherApiSlice;
