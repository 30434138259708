import { apiSlice } from "../../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Session"],
});

export const sessionApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getSession: builder.query({
      query: (parameter) => `/session/${parameter}`,
      providesTags: ["Session"],
    }),
    getCurrentTerm: builder.query({
      query: () => `session/term/current`,
      providesTags: ["Session"],
    }),
    createSession: builder.mutation({
      query: (values) => ({
        url: "/session/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Session"],
    }),
    updateSession: builder.mutation({
      query: (values) => ({
        url: "/session/update",
        method: "PUT",
        body: { ...values },
      }),
      invalidatesTags: ["Session"],
    }),
    createTerm: builder.mutation({
      query: (values) => ({
        url: "/session/term/create",
        method: "POST",
        body: { ...values },
      }),
      invalidatesTags: ["Session"],
    }),
    updateTerm: builder.mutation({
      query: (values) => ({
        url: "/session/term/update",
        method: "PUT",
        body: { ...values },
      }),
      invalidatesTags: ["Session"],
    }),
  }),
});

export const {
  useCreateSessionMutation,
  useGetSessionQuery,
  useGetCurrentTermQuery,
  useCreateTermMutation,
  useUpdateTermMutation,
  useUpdateSessionMutation,
} = sessionApiSlice;
