import React from "react";
import {
  MenuItem,
  Typography,
  FormControl,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Box,
  Grid,
  FormHelperText,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledSelect } from "../../customComponents/styled/styledInputs";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../customComponents/styled/styledTable";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { useGetTeacherQuery } from "../staff/staffApiSlice";
import { useGetSubjectsQuery } from "../subjects/subjectsApiSlice";
import {
  useAddSubjectToClassroomMutation,
  useGetClassesQuery,
  useRemoveSubjectFromClassroomMutation,
} from "../classroom/classApiSlice";
import swal from "sweetalert";
import CustomWarningDialog from "../../customComponents/CustomWarningDialog";

function ClassSubjects() {
  let { classId } = useParams();
  const [openWarningDialog, setOpenWarningDialog] = React.useState(false);
  const [focusedSubject, setFocusedSubject] = React.useState(null);
  const { data: teachersList } = useGetTeacherQuery("all");
  const { data: subjectsList, isLoading: getSubjectLoading } =
    useGetSubjectsQuery("all");
  const { data: classroom } = useGetClassesQuery(classId); //to get updated list of classrooms

  const [addSubjectToClass, { isLoading: addSubjectToClassLoading }] =
    useAddSubjectToClassroomMutation();

  const [
    removeSubjectFromClassroom,
    { isLoading: removeSubjectFromClassroomLoading },
  ] = useRemoveSubjectFromClassroomMutation();

  console.log(classId);

  const formik = useFormik({
    initialValues: {
      teacher: "",
      subject: "",
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      teacher: yup.string().required("Required"),
      subject: yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      const body = {
        class_Id: classId,
        teacher_id: values.teacher,
        subject_id: values.subject,
      };
      try {
        const response = await addSubjectToClass(body).unwrap();
        if (response.message) {
          formik.resetForm();
          swal("Success", "Subject added successfully", "success");
        }
      } catch (error) {
        console.log(error);
        swal("Error", "Something went wrong", "error");
      }
    },
  });

  const handleRemoveSubject = async () => {
    const body = {
      class_Id: classId,
      subject_Id: focusedSubject.id,
    };
    try {
      const response = await removeSubjectFromClassroom(body).unwrap();
      console.log(response);
      if (response.message) {
        swal("Success", response.message, "success");
      }
    } catch (error) {
      console.log(error);
      swal("Error", "Something went wrong", "error");
    }
  };

  return (
    <div style={{ padding: "0 5px" }}>
      {/* modals */}
      <CustomWarningDialog
        open={openWarningDialog}
        setOpen={setOpenWarningDialog}
        item={focusedSubject}
        action={handleRemoveSubject}
        message="!!!WARNING!!! This will remove the subject from the class. Are you sure you want to continue?"
      />
      <Grid container columnSpacing={3}>
        <Grid item xs={12} md={6}>
          <StyledPaper sx={{ padding: "15px" }}>
            <Typography variant="formHeading" sx={{ marginBottom: "20px" }}>
              New Class Subject Form
            </Typography>

            <Box component="form" onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl
                    error={formik.touched.subject && formik.errors.subject}
                    fullWidth
                  >
                    <Typography variant="formSubHeading">SUBJECT</Typography>
                    <StyledSelect
                      displayEmpty
                      id="subject"
                      name="subject"
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.subject && Boolean(formik.errors.subject)
                      }
                    >
                      <MenuItem value="" disabled>
                        <em>select subject</em>
                      </MenuItem>
                      {subjectsList?.map((subject) => (
                        <MenuItem key={subject.id} value={subject.id}>
                          {subject.name}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    <FormHelperText variant="error">
                      {formik.touched.subject && formik.errors.subject}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    error={formik.touched.teacher && formik.errors.teacher}
                    fullWidth
                  >
                    <Typography variant="formSubHeading">TEACHER</Typography>
                    <StyledSelect
                      displayEmpty
                      id="teacher"
                      name="teacher"
                      value={formik.values.teacher}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.teacher && Boolean(formik.errors.teacher)
                      }
                    >
                      <MenuItem value="" disabled>
                        <em>select teacher</em>
                      </MenuItem>
                      {teachersList?.map((teacher) => (
                        <MenuItem key={teacher.user_id} value={teacher.user_id}>
                          {teacher.first_name} {teacher.last_name}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    <FormHelperText variant="error">
                      {formik.touched.teacher && formik.errors.teacher}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} textAlign={"right"}>
                  <StyledLoadingButton
                    loading={addSubjectToClassLoading}
                    type="submit"
                  >
                    Add
                  </StyledLoadingButton>
                </Grid>
              </Grid>
            </Box>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Box sx={{ padding: "15px" }}>
              <Typography variant="formHeading">
                Current Subjects in Class
              </Typography>
              <Typography variant="formSubHeading"></Typography>
            </Box>
            {getSubjectLoading && <LinearProgress />}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ borderBottom: "2px solid lightgrey " }}>
                    <StyledTableCell>SUBJECT</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell>TEACHER</StyledTableCell>
                    <StyledTableCell align={"right"}>DELETE</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {classroom?.subjects?.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.description}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        -
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {removeSubjectFromClassroomLoading &&
                        focusedSubject === row ? (
                          <CircularProgress size={20} />
                        ) : (
                          <DeleteIcon
                            className="tableCellLink"
                            onClick={() => {
                              setFocusedSubject(row);
                              setOpenWarningDialog(true);
                            }}
                          />
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ClassSubjects;
