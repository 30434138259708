import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "../theme/themeSlice";
import { authReducer } from "../components/pages/auth/authSlice";
import { authApiSlice } from "./authApiSlice";
import { useDispatch } from "react-redux";
import { apiSlice } from "./apiSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [authApiSlice.reducerPath]: authApiSlice.reducer,
    auth: authReducer,
    theme: themeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApiSlice.middleware)
      .concat(apiSlice.middleware),
  devTools: true,
});

export const useAppDispatch = useDispatch;
