import { Grid } from "@mui/material";
import React from "react";
import CustomMenuList from "../../customComponents/CustomMenuList";
import AddSpecialPeriods from "./add-special-periods/AddSpecialPeriods";
import CreateTimeTableTemplate from "./Create-timetable-template/CreateTimeTableTemplate";
import GenerateTimeTable from "./generate-timetable/GenerateTimeTable";
import PreviewTimeTables from "./preview-timetables/PreviewTimeTables";

function TimeTable() {
  const [activeTab, setActiveTab] = React.useState(0);

  const list = [
    "create timetable template",
    "Add Special Periods",
    "Generate TimeTable",
    "Preview TimeTables",
  ];

  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={12} lg={3}>
        <CustomMenuList
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          heading="Menu"
          list={list}
        />
      </Grid>
      <Grid item xs={12} lg={9}>
        {activeTab === 0 && <CreateTimeTableTemplate />}
        {activeTab === 1 && <AddSpecialPeriods />}
        {activeTab === 2 && <GenerateTimeTable />}
        {activeTab === 3 && <PreviewTimeTables />}
      </Grid>
    </Grid>
  );
}

export default TimeTable;
