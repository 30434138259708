import { Avatar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import MessageHistoryTable from "./MessageHistoryTable";

function History() {
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedStudent, setSelectedStudent] = React.useState(false);

  const headCells = [
    {
      id: "recipient",

      label: "Recipient",
    },
    {
      id: "content",

      label: "Content",
    },
    {
      id: "date",
      label: "date",
    },
  ];

  const messages = [
    {
      type: "Email",
      details: [
        {
          recipient: "test@gmail.com",
          content: "make sense app",
          date: "10, Aug, 2021",
        },
        {
          recipient: "teboy.com@gmail.com",
          content: "Good day Mr. Mustapha how is Abbas?",
          date: "11th Aug, 2021",
        },
        {
          recipient: "payevabd@gmail.com",
          content: "lorem ipsum ",
          date: "21st Jun, 2021",
        },
        {
          recipient: "johndoe@gmail.com",
          content:
            "Dear Parent, Kindly be advised that school activities resume as usual from 20th September, 2021",
          date: "15th Sep, 2021",
        },
        {
          recipient: "janedoe@gmail.com",
          content: "make sense app",
          date: "23rd Apr, 2021",
        },
        {
          recipient: "Skrrskrr@yahoo.com",
          content:
            "Dear Mr. Baba please allow Abdul to participate in the tournament below",
          date: "21st Apr, 2021",
        },
      ],
    },
    {
      type: "SMS",
      details: [
        {
          recipient: "+234958493923",
          content: "make sense app",
          date: "10, Aug, 2021",
        },
        {
          recipient: "+2342838492834",
          content: "Good day Mr. Mustapha how is Abbas?",
          date: "11th Aug, 2021",
        },
        {
          recipient: "+234012345678",
          content: "lorem ipsum ",
          date: "21st Jun, 2021",
        },
        {
          recipient: "+234012345678",
          content:
            "Dear Parent, Kindly be advised that school activities resume as usual from 20th September, 2021",
          date: "15th Sept, 2021",
        },
        {
          recipient: "+234012345678",
          content: "make sense app",
          date: "23rd Apr, 2021",
        },
        {
          recipient: "+234012345678",
          content:
            "Dear Mr. Baba please allow Abdul to participate in the tournament below",
          date: "21st Apr, 2021",
        },
      ],
    },
  ];

  return (
    <>
      {messages.map((message, index) => (
        <StyledPaper key={index} sx={{ padding: "0 15px" }}>
          <Box className="paperHeader">
            <Typography variant="formHeading">
              {message.type} History
            </Typography>
          </Box>
          <MessageHistoryTable rows={message.details} headCells={headCells} />
        </StyledPaper>
      ))}
    </>
  );
}

export default History;
