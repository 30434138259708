import { apiSlice } from "../../../app/apiSlice";

const apiSliceWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Profile"],
});

const profileApiSlice = apiSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => `/teacher/profile`,
      // providesTags: ["Profile"],
    }),
    updateProfile: builder.mutation({
      query: (values) => ({
        url: "/teacher/profile",
        method: "PATCH",
        body: { ...values },
      }),
      // invalidatesTags: ["Profile"],
    }),
  }),
});

export const { useGetProfileQuery, useUpdateProfileMutation } = profileApiSlice;
