import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { Link, NavLink } from "react-router-dom";
import { Box, Toolbar, Typography } from "@mui/material";
import { RiBookMarkLine } from "react-icons/ri";
import { BiPieChartAlt2 } from "react-icons/bi";
import { BsSafe2 } from "react-icons/bs";
import { BsCalendar2Date } from "react-icons/bs";
import { VscGraphLine } from "react-icons/vsc";
import { RiMessage2Line } from "react-icons/ri";
import { useGetClassesQuery } from "../pages/classroom/classApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../pages/auth/authSlice";

function SideNav({ drawerWidth, handleDrawerToggle, window, mobileOpen }) {
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const { data: myClasses } = useGetClassesQuery("all/mine");

  const isClassManager = !!myClasses?.length;

  const user = useSelector(selectCurrentUser);

  // determine the role of the user
  const isAdmin = user?.roles
    ?.filter((role) => role.school_id === user.current_school_id)
    .map((item) => item.role.name)
    .includes("admin")
    ? true
    : false;

  const isTeacher = user?.roles
    ?.filter((role) => role.school_id === user.current_school_id)
    .map((item) => item.role.name)
    .includes("teacher")
    ? true
    : false;

  const isAccountant = user?.roles
    ?.filter((role) => role.school_id === user.current_school_id)
    .map((item) => item.role.name)
    .includes("accountant")
    ? true
    : false;

  const teacherClassrooms =
    myClasses?.flatMap((classroom) => {
      //creates nav items for each existing class for the teacher
      return [
        {
          text: classroom.name + " report",
          icon: (
            <RiMessage2Line
              style={{
                fontSize: "24px",
                color: "rgb(225 234 249)",
                marginLeft: "2px",
              }}
            />
          ),
          link: `/class-reportsheet/${classroom.id}`,
        },

        {
          text: classroom.name + " subjects",
          icon: (
            <RiBookMarkLine
              style={{ fontSize: "24px", color: "rgb(225 234 249)" }}
            />
          ),
          link: `/class-subjects/${classroom.id}`,
        },
      ];
    }) || [];

  const classManagerNavs = isClassManager
    ? // nav that are only available to classTeacher
      [
        {
          text: "manage attendance",
          icon: (
            <BsCalendar2Date
              style={{
                fontSize: "24px",
                color: "rgb(225 234 249)",
                marginLeft: "2px",
              }}
            />
          ),
          link: "/manage-attendance",
        },
      ]
    : [];

  const teacherNavs = [
    // append the teacher navlist created above to the teacher navs
    ...teacherClassrooms,
    ...classManagerNavs,

    {
      text: "class broadsheet",
      icon: (
        <BiPieChartAlt2
          style={{ fontSize: "24px", color: "rgb(225 234 249)" }}
        />
      ),
      link: "/class-broadsheet",
    },
    {
      text: "class report",
      icon: (
        <VscGraphLine style={{ fontSize: "24px", color: "rgb(225 234 249)" }} />
      ),
      link: "/class-report",
    },
    {
      text: "student's report",
      icon: <AccountCircleOutlinedIcon style={{ color: "rgb(225 234 249)" }} />,
      link: "/student-report",
    },

    {
      text: "quick entry",
      icon: (
        <BiPieChartAlt2
          style={{ fontSize: "24px", color: "rgb(225 234 249)" }}
        />
      ),
      link: "/quick-entry",
    },
    // {
    //   text: "exam manager",
    //   icon: <BiPieChartAlt2 style={{ fontSize: "24px", color: "#ffffff" }} />,
    //   link: "/exam-manager",
    // },
    {
      text: "Score Manager",
      icon: (
        <VscGraphLine style={{ fontSize: "24px", color: "rgb(225 234 249)" }} />
      ),
      link: "/ca-manager",
    },
    // {
    //   text: "Subject timetable",
    //   icon: (
    //     <BsCalendar2Date
    //       style={{ fontSize: "24px", color: "#ffffff", marginLeft: "2px" }}
    //     />
    //   ),
    //   link: "/subject-timetable",
    // },
    // {
    //   text: "Section Report",
    //   icon: (
    //     <BsCalendar2Date
    //       style={{ fontSize: "24px", color: "#ffffff", marginLeft: "2px" }}
    //     />
    //   ),
    //   link: "/section-report",
    // },
  ];

  const accountantNavs = [
    {
      text: "Payments Dashboard",
      icon: (
        <AccountBalanceOutlinedIcon style={{ color: "rgb(225 234 249)" }} />
      ),
      link: "/payments-dashboard",
    },
    {
      text: "Account Settings",
      icon: (
        <BsSafe2
          style={{
            fontSize: "24px",
            color: "rgb(225 234 249)",
            marginLeft: "2px",
          }}
        />
      ),
      link: "/account-settings",
    },
    {
      text: "Fees Schedule",
      icon: (
        <AccountBalanceWalletOutlinedIcon
          style={{ color: "rgb(225 234 249)" }}
        />
      ),
      link: "/fees-schedule",
    },
    {
      text: "Invoices",
      icon: <DescriptionOutlinedIcon style={{ color: "rgb(225 234 249)" }} />,
      link: "/invoices",
    },
  ];

  const adminNavs = [
    {
      text: "staff",
      icon: <GroupOutlinedIcon style={{ color: "rgb(225 234 249)" }} />,
      link: "/staff",
    },
    {
      text: "classes",
      icon: <LanOutlinedIcon style={{ color: "rgb(225 234 249)" }} />,
      link: "/classes",
    },
    // {
    //   text: "houses",
    //   icon: <GoHome style={{ fontSize: "24px", color: "white" }} />,
    //   link: "/houses",
    // },
    {
      text: "Subjects",
      icon: (
        <RiBookMarkLine
          style={{ fontSize: "24px", color: "rgb(225 234 249)" }}
        />
      ),
      link: "/subjects",
    },
    {
      text: "students",
      icon: <SchoolOutlinedIcon style={{ color: "rgb(225 234 249)" }} />,
      link: "/students",
    },
    {
      text: "timetable",
      icon: (
        <BsCalendar2Date
          style={{
            fontSize: "24px",
            color: "rgb(225 234 249)",
            marginLeft: "2px",
          }}
        />
      ),
      link: "/timetable",
    },
    {
      text: "message center",
      icon: <MailOutlinedIcon style={{ color: "rgb(225 234 249)" }} />,
      link: "/message-center",
    },
    {
      text: "roles",
      icon: <BadgeOutlinedIcon style={{ color: "rgb(225 234 249)" }} />,
      link: "/roles",
    },
    {
      text: "settings",
      icon: <SettingsOutlinedIcon style={{ color: "rgb(225 234 249)" }} />,
      link: "/settings",
    },
  ];

  const drawer = (
    <Box
      sx={{
        "& .MuiDivider-root": {
          backgroundColor: "#ffffff",
        },
      }}
    >
      <Toolbar sx={{ justifyContent: "center" }}>
        <Link
          to="/"
          style={{ textDecoration: "none", color: "rgb(225 234 249)" }}
        >
          <Typography variant="h6" noWrap>
            Dashboard
          </Typography>
        </Link>
      </Toolbar>
      <Divider />
      <List
        sx={{
          fontSize: "12px",
          "& .MuiTypography-root": {
            fontSize: "12px",
            fontWeight: 600,
          },
          "& a": {
            color: "rgb(225 234 249)",
            textDecoration: "none",
            margin: "0 15px",
            borderRadius: "4px",
            width: "100%",
            textTransform: "uppercase",
          },
          "& .MuiSvgIcon-root": {
            color: "white",
            fontSize: "27px",
          },
          "& .MuiListItemIcon-root": {
            minWidth: "fit-content",
            marginRight: "15px",
          },
        }}
      >
        {isAdmin &&
          adminNavs.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ margin: "5px 0" }}>
              <Box
                component={NavLink}
                to={item.link}
                style={({ isActive }) =>
                  isActive
                    ? { backgroundColor: "rgba(255,255,255, 0.23)" }
                    : undefined
                }
              >
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </Box>
            </ListItem>
          ))}

        {isTeacher &&
          teacherNavs.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ margin: "5px 0" }}>
              <NavLink
                to={item.link}
                style={({ isActive }) =>
                  isActive
                    ? { backgroundColor: "rgba(255,255,255, 0.23)" }
                    : undefined
                }
              >
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ))}

        {isAccountant &&
          accountantNavs.map((item, index) => (
            <ListItem key={index} disablePadding sx={{ margin: "5px 0" }}>
              <NavLink
                to={item.link}
                style={({ isActive }) =>
                  isActive
                    ? { backgroundColor: "rgba(255,255,255, 0.23)" }
                    : undefined
                }
              >
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ))}
      </List>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
        backgroundColor: "blue",
      }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "primary.main",
            color: "white",
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "primary.main",
            color: "white",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

export default SideNav;
