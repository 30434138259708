import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  StyledSelect,
  StyledTextField,
} from "../../customComponents/styled/styledInputs";
import { StyledLoadingButton } from "../../customComponents/styled/styledButtons";
import { StyledPaper } from "../../customComponents/styled/styledPaper";
import { useGetSubSectionQuery } from "../settings/sections/sectionsApiSlice";
import { useUpdateClassroomMutation } from "./classApiSlice";
import swal from "sweetalert";

const classFormValidation = yup.object({
  name: yup.string().required("Required"),
  description: yup.string().required("Required"),
});

function UpdateClass({ item }) {
  const { data: subsections } = useGetSubSectionQuery("all");
  const [updateClassroom, { isLoading: updateClassroomLoading }] =
    useUpdateClassroomMutation();

  console.log("class", item);

  const classForm = useFormik({
    initialValues: {
      name: item?.name || "",
      description: item?.description || "",
      subsection: item?.subsection_id || "",
    },
    enableReinitialize: true,
    validationSchema: classFormValidation,
    onSubmit: async (values) => {
      try {
        const body = {
          id: item.id,
          name: values.name,
          description: values.description,
          subsection_id: values.subsection,
        };
        const response = await updateClassroom(body).unwrap();
        if (response.id) {
          swal("Success", "Classroom updated successfully", "success");
        } else {
          swal("Error", "Classroom not updated", "error");
        }
      } catch (error) {
        console.log(error);
        swal("Error", "Something went wrong", "error");
      }
    },
  });

  return (
    <StyledPaper>
      <Box className="paperHeader">
        <Typography variant="paperHeading">Update Class</Typography>
      </Box>
      <Box className="paperBody">
        <form onSubmit={classForm.handleSubmit}>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12} md={4}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">Name of class</Typography>
                <StyledTextField
                  autoFocus
                  variant="outlined"
                  id="name"
                  name="name"
                  value={classForm.values.name}
                  onChange={classForm.handleChange}
                  error={
                    classForm.touched.name && Boolean(classForm.errors.name)
                  }
                />
                <FormHelperText variant="error">
                  {classForm.touched.name && classForm.errors.name}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl error fullWidth>
                <Typography variant="formSubHeading">
                  Class Description
                </Typography>
                <StyledTextField
                  autoFocus
                  variant="outlined"
                  id="description"
                  name="description"
                  value={classForm.values.description}
                  onChange={classForm.handleChange}
                  error={
                    classForm.touched.description &&
                    Boolean(classForm.errors.description)
                  }
                />
                <FormHelperText variant="error">
                  {classForm.touched.description &&
                    classForm.errors.description}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <Typography variant="formSubHeading">sub-Section</Typography>
                <StyledSelect
                  displayEmpty
                  id="subsection"
                  name="subsection"
                  value={classForm.values.subsection}
                  onChange={classForm.handleChange}
                  error={
                    classForm.touched.subsection &&
                    Boolean(classForm.errors.subsection)
                  }
                >
                  <MenuItem value="" disabled>
                    <em>select subsection</em>
                  </MenuItem>
                  {subsections?.map((subsection) => (
                    <MenuItem key={subsection.id} value={subsection.id}>
                      {subsection.name}
                    </MenuItem>
                  ))}
                </StyledSelect>
                <FormHelperText variant="error">
                  {classForm.touched.subsection && classForm.errors.subsection}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} textAlign={"right"}>
              <StyledLoadingButton
                loading={updateClassroomLoading}
                type="submit"
              >
                Update
              </StyledLoadingButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </StyledPaper>
  );
}

export default UpdateClass;
