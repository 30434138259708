import React from "react";
import {
  StyledFileInput,
  StyledSelect,
  StyledTextField,
} from "../../../customComponents/styled/styledInputs";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  LinearProgress,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import {
  FilledButton,
  StyledLoadingButton,
} from "../../../customComponents/styled/styledButtons";
import { StyledPaper } from "../../../customComponents/styled/styledPaper";
import TemplateCard from "./TemplateCard";
import { useUploadFileMutation } from "../../../../app/utilsApiSlice";
import {
  useCreateTemplateMutation,
  useGetTemplatesQuery,
} from "./templatesApiSlice";

const validationSchema = yup.object({
  name: yup.string().required("Required"),
  type: yup.string().required("Required"),
  // url: yup.string().required("Required"),
});

function Templetes() {
  const { data: templates, loading: getTemplatesLoading } =
    useGetTemplatesQuery("all");

  const [uploadFile, { isLoading: fileUploadLoading }] =
    useUploadFileMutation();

  const [createTemplate, { isLoading: createTemplateLoading, error }] =
    useCreateTemplateMutation();

  console.log(templates);

  const formik = useFormik({
    initialValues: {
      file: "",
      name: "",
      type: "",
      description: "",
      url: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const body = {
        name: values.name,
        type: values.score,
        description: values.description,
        url: values.url,
      };
      try {
        console.log(JSON.stringify(body));
        const response = await createTemplate(body);
        console.log(response);
        formik.resetForm();
      } catch (e) {
        console.log(e);
      }
    },
  });

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0]; // get the file from the event
      // formik.setFieldValue("file", file); // set the file to the formik state

      const formData = new FormData(); // create a new form data
      formData.append("file", file); // append the file to the form data
      // log form data
      const response = await uploadFile(formData).unwrap(); // call the mutation
      if (response) {
        console.log(response);
        formik.setFieldValue("url", response.url); // set the logo url to the formik state
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} lg={4}>
          <StyledPaper>
            <Box className="paperHeader">
              <Typography variant="formHeading">Upload New Template</Typography>
            </Box>
            <Box className="paperBody">
              <form onSubmit={formik.handleSubmit}>
                <Grid container columnSpacing={1} rowSpacing={2}>
                  <Grid item xs={12}>
                    <FormControl error fullWidth>
                      <Typography variant="formSubHeading">
                        Template file
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <StyledFileInput
                          type="file"
                          id="file"
                          name="file"
                          value={formik.values.file}
                          onChange={(e) => handleFileChange(e)}
                          error={
                            formik.touched.file && Boolean(formik.errors.file)
                          }
                        />
                        {fileUploadLoading && <CircularProgress />}
                      </Stack>
                      <FormHelperText variant="error">
                        {formik.touched.file && formik.errors.file}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl error fullWidth>
                      <Typography variant="formSubHeading">
                        template name
                      </Typography>
                      <StyledTextField
                        autoFocus
                        variant="outlined"
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                      />
                      <FormHelperText variant="error">
                        {formik.touched.name && formik.errors.name}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl error fullWidth>
                      <Typography variant="formSubHeading">
                        description
                      </Typography>
                      <StyledTextField
                        autoFocus
                        variant="outlined"
                        id="description"
                        description="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.description &&
                          Boolean(formik.errors.description)
                        }
                      />
                      <FormHelperText variant="error">
                        {formik.touched.description &&
                          formik.errors.description}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl error fullWidth>
                      <Typography variant="formSubHeading">
                        Template type
                      </Typography>
                      <StyledSelect
                        autoFocus
                        variant="outlined"
                        id="type"
                        name="type"
                        value={formik.values.type}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.type && Boolean(formik.errors.type)
                        }
                      >
                        <MenuItem value="score">Score based</MenuItem>
                        <MenuItem value="c.a">C.A</MenuItem>
                        <MenuItem value="mid-term">Mid Term</MenuItem>
                      </StyledSelect>
                      <FormHelperText variant="error">
                        {formik.touched.type && formik.errors.type}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} textAlign={"right"}>
                    <StyledLoadingButton
                      loading={createTemplateLoading}
                      type="submit"
                    >
                      Update
                    </StyledLoadingButton>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} lg={8}>
          <StyledPaper>
            {getTemplatesLoading && <LinearProgress />}
            <Box className="paperHeader">
              <Typography variant="formHeading">Schoola Templates</Typography>
            </Box>
            <Box className="paperBody">
              <Grid container columnSpacing={1} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <TemplateCard
                    image={require("../../../../assets/images/template.png")}
                    link="https://src.schoola.app/static/templates/guide.html"
                    title="Templating Guide"
                    description="Learn how to use the templates"
                    editable={false}
                    active={false}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TemplateCard
                    image="https://res.cloudinary.com/mti-cloud/image/upload/scr.png"
                    link="https://res.cloudinary.com/mti-cloud/image/upload/scr.png"
                    title="Farouk"
                    description="score based"
                    editable={true}
                    active={true}
                  />
                </Grid>
                {templates?.map((template) => (
                  <Grid item xs={12} md={6}>
                    <TemplateCard
                      image={template.url}
                      link={template.url}
                      title={template.name}
                      description={template.type}
                      editable={true}
                      active={true}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </StyledPaper>
        </Grid>
      </Grid>
    </>
  );
}

export default Templetes;
