import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",

  initialState: {
    user: null,
    refresh_token: null,
    access_token: null,
  },

  reducers: {
    login: (state, action) => {
      const { access_token, refresh_token, user } = action.payload;
      state.user = user;
      state.access_token = access_token;
      state.refresh_token = refresh_token;

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("access_token", JSON.stringify(access_token));
      localStorage.setItem("refresh_token", JSON.stringify(refresh_token));
    },
    setAccessToken: (state, action) => {
      const { access_token } = action.payload;
      state.access_token = access_token;
      localStorage.setItem("access_token", JSON.stringify(access_token));
    },
    logout: (state) => {
      state.access_token = "";
      state.refresh_token = "";
      state.user = null;
      localStorage.clear();
    },
  },
});

export const { login, setAccessToken, logout } = authSlice.actions;

export const authReducer = authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.access_token;
